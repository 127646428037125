<template>

  <div class="container" v-bind:style="{  'background-image': 'url(' + require('@/assets/banner-overlay.svg') + ')' }">

        <HomeNavbar :sticky_navbar="true" />

        <div id="login-wrapper">

            <h2>Sign in</h2>

            <div v-if="is_loading === true" class="loader">
                <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>
            
            <form @submit.prevent="signin" v-else>
                <div class="error-message" v-if="error">{{ error }} </div>

                <div>
                    <label for="email">Email address</label>
                    <input type="email" v-model="email" id="email" placeholder="Enter email">
                </div>

                <div>
                    <label for="password">Password</label>
                    <input type="password" v-model="password" id="password" placeholder="Enter password">
                </div>

                <button type="submit">Login</button>

            </form>

            <div class="create-account" @click="redirectToSignUp()">
                <img src="@/assets/account_icon.svg"/>
                Create an account
            </div>

            <div class="create-account google" @click="googleLogIn()">
                Sign in with Google account
            </div>

            <div class="create-account forgot-password" @click="forgotPassword()">
                Forgot your password?
            </div>

        </div>

    </div>

</template>

<script>
import HomeNavbar from '@/components/home/HomeNavbar.vue'

export default {
    name: 'Login',
    components: {
        HomeNavbar
    },
    data () {
        return {
            email: '',
            password: '',
            is_loading: true,
            error: '',
            url: process.env.VUE_APP_API_URL
        }
    },
    created () {
        this.checkForSession()
        this.checkSignedIn()
        this.scrollToTop();
        this.is_loading = false
        this.getLanguage();
        console.log(`API: ${this.url}`)
    },
    updated () {
        this.checkSignedIn()
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0,0);
        },
        getLanguage() {
            if (localStorage.default_language === undefined) {
                this.$store.commit('setDefaultLanguage', 'en')
                localStorage.setItem('default_language', 'en')
            } else {
                this.$store.commit('setDefaultLanguage', localStorage.default_language)
            }
        },
        signin (e) {
            if (this.email == '') {
                this.error = 'Invalid email or password'
                e.preventDefault();
            } else if (this.password == '')  {
                this.error = 'Invalid email or password'
                e.preventDefault();
            } else {
                this.is_loading = true
                this.$http.plain.post('/login', { email: this.email, password: this.password })
                .then(response => this.signinSuccessful(response))
                .catch(error => this.signinFailed(error))
            }
            
        },
        redirectToSignUp() {
            // window.location.replace(this.url + '/users/sign_up')
            this.$router.push('/sign_up')
        },
        signinSuccessful (response) {
            localStorage.token = response.data.access
            localStorage.csrf = response.data.csrf
            localStorage.refresh = response.data.refresh
            localStorage.signedIn = true
            // this.emitSignedIn()
            this.error = ''
            this.$router.replace('/dashboard')
        },
        googleLogIn() {
            window.location.replace(this.url + '/users/auth/google_oauth2')
        },
        forgotPassword() {
            window.location.replace(this.url + '/users/password/new')
        },
        loading() {
            this.is_loading = true
        },
        signinFailed (error) {
            this.error = 'Invalid email or password'
            delete localStorage.token
            delete localStorage.csrf
            delete localStorage.signedIn
            this.is_loading = false
        },
        checkForSession() {
            this.$http.plain.post('/check_for_login')
            .then(response => {
                // if (response.data.access != null) {
                    localStorage.token = response.data.access
                    localStorage.csrf = response.data.csrf
                    localStorage.refresh = response.data.refresh
                    localStorage.signedIn = true
                    // this.emitSignedIn()
                    this.$router.replace('/dashboard')
                // }
                // this.checkSignedIn()
                })
            .catch(error => 'Didnt work')
        },
        checkSignedIn() {
            if (localStorage.signedIn) {
                this.$router.replace('/dashboard')
            }
        },
        getCookie(name) {
        let cookie = {};
        document.cookie.split(';').forEach(function(el) {
            let [k,v] = el.split('=');
            cookie[k.trim()] = v;
        })
            return cookie[name];
            // console.log(cookie[name])
        }
    }
}


</script>

<style scoped>

.container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 90vh;
    margin-top: 75px;
    background-blend-mode: lighten;
    background-color: rgba(255,255,255,0.6);
    /* background-image: url("@/assets/banner-overlay.svg"); */
}

#login-wrapper {
    max-width: 400px;
    width: 90%;
    margin: auto;
    background-color: white;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    /* justify-content: center; */
    padding: 30px;
    border: 1px solid lightgray;
    border-radius: 10px;
    margin-top: 50px;
    margin-bottom: 50px;
    /* margin-top: 75px; */
    /* margin-bottom: 200px; */
}


#login-wrapper h2 {
    font-weight: 600;
    margin: 0px 0px 40px 0px;
}

form {
    display: flex;
    flex-direction: column;
}

input {
    width: 100%;
    border-bottom: 1px solid lightgray;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    font-size: 15px;
    height: 40px;
    margin: 10px 0px 50px 0px;
    font-family: 'Montserrat', sans-serif;
}

label {
    font-size: 15px;
    font-weight: 600;
    margin: 20px 0px 10px 0px;
}

button {
    background-image: linear-gradient(to right, rgb(153, 202, 60), rgb(7, 153, 72));
    display: flex;
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    justify-content: center;
    border: 0px;
    height: 40px;
    align-items: center;
    border-radius: 5px;
}

.error-message {
    color: red;
    font-size: 12.5px;
    font-weight: 600;
    box-sizing: border-box;
    padding: 5px;
    border: 1px solid red;
    border-radius: 2.5px;
    margin: 0px 0px 20px 0px;
    align-self: flex-start;
}

.loader {
    height: 284px;
}

.create-account {
    width: 100%;
    border: 1px solid lightgray;
    margin-top: 25px;
    display: flex;
    align-items: center;
    font-weight: 600;
    justify-content: center;
    box-sizing: border-box;
    padding: 5px;
    border-radius: 5px;
    font-size: 12.5px;
    font-weight: 600;
    cursor: pointer;
}

.create-account:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    transition: 0.2s;
}

.create-account img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.forgot-password {
    /* color: rgba(100, 100, 100, 1); */
    font-weight: 400;
    border: 0px;
}

@media(max-width: 600px) {
    #login-wrapper {
        width: 100%;
        max-width: 600px;
        border: 0px;
        border-radius: 0px;
        background-color: rgba(255, 255, 255, 0.5);
    }

}



</style>