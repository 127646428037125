<template>

    <div id="menu-header-options">

        <div class="close-modal" @click="$emit('close-modal')">
            <img src="../assets/close.svg" />
            Close
        </div>

        <h2>Menu header options</h2>

        <form action="" @submit.prevent="updateMenuHeaderDetails">

            <p class="form-label">Custom menu name</p>

                <input class="input"
                    autofocus autocomplete="off"
                    name="menu[name_en]"
                    :placeholder="'Custom menu name'"
                    v-model="menu['name_en']" 
                    @keyup="updateMenuHeaderDetails"
                />

            <p class="form-label">Top of menu text</p>

            <div v-for="language in languages" v-bind:key="language" >

                <div class="form-label">

                    <div class="form-tag">

                        {{ languageLongForm(language) }}

                    </div>

                </div>
            
                <input class="input"
                    autofocus autocomplete="off"
                    name="food[name]"
                    :placeholder="languageLongForm(language) + ' text'"
                    v-model="menu['special_tag_text_' + language]" 
                    @keyup="updateMenuHeaderDetails"
                />

            </div>

            <div class="menu-name-toggle" @click="$emit('toggle-menu-name-visibility'), show_menu_name = !show_menu_name" :class=" {'shown' : show_menu_name === true } ">
               
                <div v-if="show_menu_name === true">Hide menu name</div>

                <div v-if="show_menu_name === false">Show menu name</div>

            </div>

            <div class="menu-name-toggle" @click="$emit('toggle-menu-scrollbar'), hide_top_scrollbar = !hide_top_scrollbar" :class=" {'shown' : hide_top_scrollbar === false } ">
               
                <div v-if="hide_top_scrollbar === true">Show top scrollbar</div>

                <div v-if="hide_top_scrollbar === false">Hide top scrollbar</div>

            </div>        

            <div class="menu-name-toggle" @click="$emit('toggle-menu-search-bar'), show_search_bar = !show_search_bar" :class=" {'shown' : show_search_bar === true } ">
               
                <div v-if="show_search_bar === true">Hide search bar</div>

                <div v-if="show_search_bar === false">Show search bar</div>

            </div>    

            <div class="menu-name-toggle" @click="$emit('toggle-paginate-menu'), paginate = !paginate" :class=" {'shown' : paginate === false} ">
               
                <div v-if="menu.paginate === true">Set menu to scroll</div>

                <div v-if="menu.paginate === false">Paginate menu</div>

            </div> 

            <div class="font-options" v-if="show_menu_name === true">
                <p class="form-label">Menu name font size</p>
                <div class="fonts">
                    <div @click="$emit('decrease-font-size')">-</div>
                    <img src="../assets/font_icon.svg" />
                    <div @click="$emit('increase-font-size')">+</div>
                </div>
            </div>

            <p class="form-label">Choose a font</p>

            <FontSelector :selected="font" v-on:select-font="selectMenuHeaderFont" />

            <div class="color-options">
                <p class="form-label">Background color</p>
                <sketch-picker 
                @input="updateMenuHeaderDetails" 
                v-model="menu.menu_header_background_color"
                :value="menu.menu_header_background_color"
                :preset-colors="[ 
                    '#f00', '#00ff00', '#00ff0055', 'rgb(201, 76, 76)', 'rgba(0,0,255,1)', 'hsl(89, 43%, 51%)', 'hsla(89, 43%, 51%, 0.6)'
                ]"             
                ></sketch-picker>
            </div>

            <div class="color-options">
                <p class="form-label">Font color</p>
                <sketch-picker 
                @input="updateMenuHeaderDetails" 
                v-model="menu.menu_header_font_color"
                :value="menu.menu_header_font_color"
                :preset-colors="[ 
                    '#f00', '#00ff00', '#00ff0055', 'rgb(201, 76, 76)', 'rgba(0,0,255,1)', 'hsl(89, 43%, 51%)', 'hsla(89, 43%, 51%, 0.6)'
                ]"             
                ></sketch-picker>
            </div>

                <p class="form-label">Additional options</p>

                <div class="form-label">

                    <div class="form-tag">

                        Tax rate (%)

                    </div>

                </div>

                <input class="input"
                    autofocus autocomplete="off"
                    :placeholder="'Add a tax rate (percentage)'"
                    v-model="menu.tax" 
                    @keyup="updateMenuHeaderDetails"
                />  

                <div class="form-label">

                    <div class="form-tag">

                        Service charge (%)

                    </div>

                </div>

                <input class="input"
                    autofocus autocomplete="off"
                    :placeholder="'Add a service charge (percentage)'"
                    v-model="menu.service" 
                    @keyup="updateMenuHeaderDetails"
                />     

                <div class="form-label">

                    <div class="form-tag">

                        Packaging fee (%)

                    </div>

                </div>

                <input class="input"
                    autofocus autocomplete="off"
                    :placeholder="'Add a packaging fee (percentage)'"
                    v-model="menu.packaging_fee" 
                    @keyup="updateMenuHeaderDetails"
                />        

                <div class="form-label">

                    <div class="form-tag">

                        Delivery fee

                    </div>

                </div>

                <input class="input"
                    autofocus autocomplete="off"
                    :placeholder="'Add a delivery fee'"
                    v-model="menu.delivery_fee" 
                    @keyup="updateMenuHeaderDetails"
                />                    

        </form>





    </div>





</template>

<script>
import { Sketch } from 'vue-color'
import FontSelector from '@/components/FontSelector.vue'

export default {
    name: 'MenuHeaderOptions',
    props: ['menu', 'languages', 'show_menu_name', 'hide_top_scrollbar', 'show_search_bar'],
    components: {
        'sketch-picker': Sketch,
        FontSelector
    },
    data () {
        return {
            updateMenu: {},
            background_color: '',
            font_color: '',
            font_size: Number,
            background_color: '',
            font: '',
            name_en: '',
            paginate_menu: Boolean,
            hide_top_scrollbar: Boolean
        }
    },
    created () {
        this.font_size = this.menu.app_menu_name_font_size,
        this.font_color = this.menu.menu_header_font_color,
        this.background_color = this.menu_header_font_color,
        this.font = this.menu.menu_header_font,
        this.name_en = this.menu.name_en,
        this.show_menu_name = this.menu.show_menu_name,
        this.paginate = this.menu.paginate
    },
    methods: {
        updateMenuHeaderDetails() {
            const value = this.menu
            if (!value) {
                return
            }
            var menu_header_background_color_string = ''
            var menu_header_font_color_string = ''
            if (this.menu.menu_header_background_color.hex == null) {
                menu_header_background_color_string = this.menu.menu_header_background_color
            } else {
                menu_header_background_color_string = this.menu.menu_header_background_color.hex
            }

            if (this.menu.menu_header_font_color.hex == null) {
                menu_header_font_color_string = this.menu.menu_header_font_color
            } else {
                menu_header_font_color_string = this.menu.menu_header_font_color.hex
            }

            this.$http.secured.post('app_update_menu/' + this.menu.id, { menu: {
                special_tag_text_en: this.menu.special_tag_text_en,
                special_tag_text_ru: this.menu.special_tag_text_ru,
                special_tag_text_jp: this.menu.special_tag_text_jp,
                special_tag_text_sp: this.menu.special_tag_text_sp,
                special_tag_text_fr: this.menu.special_tag_text_fr,
                special_tag_text_ca: this.menu.special_tag_text_ca,
                special_tag_text_kr: this.menu.special_tag_text_kr,
                special_tag_text_de: this.menu.special_tag_text_de,
                special_tag_text_zh: this.menu.special_tag_text_zh,
                special_tag_text_nl: this.menu.special_tag_text_nl,
                special_tag_text_pt: this.menu.special_tag_text_pt,
                special_tag_text_it: this.menu.special_tag_text_it,   
                special_tag_text_ar: this.menu.special_tag_text_ar,
                special_tag_text_ina: this.menu.special_tag_text_ina,
                special_tag_text_gre: this.menu.special_tag_text_gre,
                special_tag_text_ro: this.menu.special_tag_text_ro,
                name_en: this.menu.name_en,
                tax: this.menu.tax,
                service: this.menu.service,
                delivery_fee: this.menu.delivery_fee,
                packaging_fee: this.menu.packaging_fee,
                menu_header_background_color: menu_header_background_color_string,
                menu_header_font_color: menu_header_font_color_string
            }})
            .then(response => {
                    // this.$emit('toggle-options')
                    this.$emit('update-background-color', menu_header_background_color_string)
                    this.$emit('update-font-color', menu_header_font_color_string)
                })
                .catch(error => this.setError(error, 'Cannot create food.')) 
        },
        selectMenuHeaderFont: function (font) {
            this.$http.secured.post('app_update_menu/' + this.menu.id, { menu: {
            menu_header_font: font
            }}).then(response => {
                    this.menu_header_font = font
                    this.font = font
                    this.$emit('select-menu-header-font', font)
                }).catch(error => this.setError(error, 'Cannot update menu.'))
        }, 
        languageLongForm(language) {
            if (language == 'en') {
                return 'English'
            } else if (language == 'ru') {
                return 'Russian'
            } else if (language == 'jp') {
                return 'Japanese'
            } else if (language == 'sp') {
                return 'Spanish'
            } else if (language == 'fr') {
                return 'French'
            } else if (language == 'ca') {
                return 'Catalan'
            } else if (language == 'kr') {
                return 'Korean'
            } else if (language == 'de') {
                return 'German'
            } else if (language == 'zh') {
                return 'Chinese'
            } else if (language == 'nl') {
                return 'Dutch'
            } else if (language == 'pt') {
                return 'Portuguese'
            } else if (language == 'it') {
                return 'Italian'
            } else if (language == 'ina') {
                return 'Indonesian'
            } else if (language == 'ar') {
                return 'Arabic'
            } else if (language == 'gre') {
                return 'Greek'
            } else if (language == 'ro') {
                return 'Romanian'
            }
        }        
    }
}



</script>

<style scoped>

#menu-header-options {
    width: 100%;
    box-sizing: border-box;
}

h2 {
    font-size: 17.5px;
}

.form-label {
    font-size: 13px;
    font-weight: 600;
}

.form-tag {
    display: flex;
    width: max-content;
    border: 1px solid black;
    box-sizing: border-box;
    padding: 2.5px;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    border-radius: 2.5px;
}

.input {
    width: 100%;
    border-bottom: 1px solid lightgray;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    font-size: 15px;
    height: 40px;
    margin: 10px 0px 30px 0px;
    font-family: 'Montserrat', sans-serif;
}

input[type="submit"] {
    /* background-image: linear-gradient(to right, rgb(153, 202, 60), rgb(7, 153, 72)); */
    background-color: #4794CB;
    cursor: pointer;
    display: flex;
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    justify-content: center;
    border: 0px;
    height: 30px;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    margin-top: 0px;
    font-size: 15px;
}

.font-options {
    width: 100%;
    display: flex;
    flex-direction: column;

}

.fonts {
    display: flex;
    flex-direction: row;
    width: 100px;
    justify-content: space-between;
}

.fonts div {
    cursor: pointer;
}

.font-options img {
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    padding: 2.5px;
    border: 1px solid black;
    border-radius: 50%;
}

.menu-name-toggle {
    display: flex;
    width: 100%;
    border: 1px solid #4794CB;
    color: #4794CB;
    cursor: pointer;
    box-sizing: border-box;
    padding: 10px 0px 10px 0px;
    border-radius: 5px;
    justify-content: center;
    align-content: center;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
    /* margin-bottom: 50px; */
}

.shown {
    background-color: #4794CB;
    color: white;
    transition: 0.2s;
}

.color-options {
    margin-bottom: 30px;
}


</style>