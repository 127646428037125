<template>






</template>

<script>

export default {
    name: "MenuCollectionRedirect",
    beforeCreate() {
        this.$http.secured.get(`/app_get_menu_collection_url/${this.$router.currentRoute.params.menu_collection_id}/${this.$router.currentRoute.params.language}`)
            .then(response => { 
                window.location.replace(response.data.url)
            }).catch(error => this.setError(error, 'Something went wrong'))       
    }
}




</script>