<template>

    <div id="pricing">

        <h3 v-html="$homepageTranslations.pricing.header[$store.getters.getDefaultLanguage]"></h3>

        <p class="subhead" v-html="$homepageTranslations.pricing.starting_from[$store.getters.getDefaultLanguage]"></p>

        <div class="details" v-html="$homepageTranslations.miscellaneous.details[$store.getters.getDefaultLanguage]" @click="$router.push('/pricing')"></div>


    </div>



</template>

<script>

export default {
    name: "Pricing"
}





</script>

<style scoped>

#pricing {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: linear-gradient(to bottom, rgb(105, 70, 141), rgb(67, 42, 106));
    box-sizing: border-box;
    padding: 75px 20% 75px 20%;
}

h3 {
    color: white;
    text-align: center;
    font-weight: 700;
    font-size: 40px;
    margin: 0px 0px 0px 0px;
}

.subhead {
    font-family: "Cabin", sans-serif;
    margin: auto;
    margin-top: 50px;
    color: white;
    letter-spacing: 1px;
    text-align: center;
    max-width: 650px;
    line-height: 35px;
    font-size: 20px;

}

.details {
    font-family: "Cabin", sans-serif;
    margin: 7.5px auto 7.5px auto;
    color: white;
    letter-spacing: 1px;
    margin-top: 50px;
    box-sizing: border-box;
    padding: 2.5px 0px 2.5px 0px;
    border-bottom: 1px solid white;
    cursor: pointer;
}


::v-deep .super-bold {
    font-weight: 800 !important;
}

@media(max-width: 600px) {
    #pricing {
        padding: 75px 2.5% 75px 2.5%;
    }
}

</style>