<template>

    <div id="image-upload-page">

        <div class="top-buttons">
            
            <div class="back-button" @click="exit()">
                <img src="@/assets/closedialogue.svg" />
            </div>

            <div v-if="is_loading === true" class="image-upload-loader">

                <div class="lds-ripple"><div></div><div></div></div>

                <p>Uploading image...</p>

            </div>

            <div v-else>
    
                <h3>Image</h3>

                <div class="current-image" v-if="selected_item_details[0].image.url != null && selected_item_details[0].image.url != '' && selected_item_details[0].image_hidden != true">

                    <div class="image-container">

                        <img class="current-image-img" :src="selected_item_details[0].image.url" />

                        <div class="delete-button" @click="deleteImage()">
                            <img src="@/assets/delete_image.svg" />
                            <p>Delete</p>
                        </div>

                    </div>

                </div>

                <div class="image-uploader" v-if="selected_item_details[0].image.url === null || selected_item_details[0].image.url === '' || selected_item_details[0].image_hidden === true">
                    <image-uploader
                        :debug="1"
                        :maxWidth="512"
                        :quality="0.7"
                        :autoRotate=true
                        outputFormat="verbose"
                        :preview=true
                        :className="['fileinput']"
                        :capture="false"
                        accept="video/*,image/*"
                        doNotResize="['gif', 'svg']"
                        @input="setImage">
                    </image-uploader>  
                </div> 

            </div>

        </div>

    </div>


</template>

<script>
import ImageUploader from 'vue-image-upload-resize'

export default {
    name: 'ImageUploadPage',
    props: ['selected_item_details', 'selected_items', 'menu'],
    components: {
        ImageUploader
    },
    data () {
        return {
            image: {},
            is_loading: false
        }
    },
    created() {
        console.log(this.selected_item_details[0].image.url)
    },
    methods: {
        exit() {
            this.$emit('return-to-buttons');
        },
        setImage: function (file) {
            // this.is_loading = true
            this.image = file
            
            
            this.selected_item_details[0].image_hidden = false
            this.selected_item_details[0].image.url = file.dataUrl
            this.$emit('items-updated', JSON.stringify(this.selected_item_details))
            console.log(this.selected_item_details[0]);
            const data = new FormData();
            Object.entries(file).forEach(([key, value]) => { data.append(key, value) })
            this.$http.upload.patch('/app_upload_food_image/' + this.selected_items[0], data
                ).then(response => {
                    this.selected_item_details[0].image_hidden = false
                    this.selected_item_details[0].image = response.data
                    console.log(`IMAGE URL: ${response.data.url}`)
                    this.$emit('items-updated', JSON.stringify(this.selected_item_details))
                    this.is_loading = false
                })
                .catch(error => this.setError(error, 'Cannot update food.'))
        },
        deleteImage() {
            if(confirm("Are you sure you want to delete this image?")) {
                this.$http.secured.post('/app_update_food/' + this.menu.id + '/' + this.selected_items[0], { food: 
                { 
                    image_hidden: true
                }}).then(response => {
                    this.selected_item_details[0].image_hidden = true
                    this.$emit('items-updated', JSON.stringify(this.selected_item_details))
                })
                .catch(error => this.setError(error, 'Cannot update food.'))
            }
        }
    }
}



</script>

<style scoped>

#image-upload-page {
    box-sizing: border-box;
    padding: 0px 10px 0px 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    color: #2F2F2E;
}

.top-buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

h3 {
    font-size: 20px;
    /* width: max-content; */
    font-weight: 400;
    text-align: center;
    width: 100%;
}

.back-button {
    width: max-content;
    cursor: pointer;
}

.back-button img {
    width: 20px;
    height: 20px;
}

.image-container {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    width: 100%;
    align-items: center;
}

.delete-button {
    width: max-content;
    box-sizing: border-box;
    padding: 10px 50px 10px 50px;
    border: 1px solid #929292;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 2.5px;
}

.delete-button p {
    color: #929292;
    font-weight: 600;
    margin: 0px 0px 0px 0px;
    text-transform: uppercase;
    margin-left: 5px;
}

.delete-button img {
    width: 25px;
    height: 25px;
}

.current-image {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.current-image-img {
    width: 100%;
    max-width: 150px;
    margin-bottom: 20px;
}

.image-upload-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
    width: 100%;
}

.image-upload-loader p {
    font-size: 12.5px;
    font-weight: 600;
    color: #8CC63E;
}

/deep/ #fileInput {
    border: 0px rgba(0, 0, 0, 0);
    display: flex;
    flex-direction: column;
    padding: 20px 20px 20px 20px;
    border-radius: 2.5px;
    font-family: "Montserrat", sans-serif;
    cursor: pointer;
}

/deep/ input[type="file"] {
    display: none;
}

/deep/ .fileinput {
    background-color: #8CC63E;
    color: white;
    border: 1px rgba(0, 0, 0, 0);
}

</style>