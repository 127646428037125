<template>

    <div id="features">

        <HomeNavbar v-if="signed_in != true" :sticky_navbar="scrolled_from_top" />

        <div class="features-content">

            <div class="features-section">

                <img src="@/assets/features/illo_1.svg" class="margin-right" />

                <div class="features-text">

                    <h1 v-html="$homepageTranslations.pages.features.header[$store.getters.getDefaultLanguage]"></h1>

                    <p v-html="$homepageTranslations.pages.features.subhead[$store.getters.getDefaultLanguage]"></p>

                </div>

            </div>

            <div class="features-section">

                <div class="features-text">

                    <h2 v-html="$homepageTranslations.pages.features.digitally_accessible[$store.getters.getDefaultLanguage]"></h2>

                    <p v-html="$homepageTranslations.pages.features.own_device[$store.getters.getDefaultLanguage]"></p>

                </div>

                <img src="@/assets/features/illo_2.svg" class="margin-left" />

            </div>

            <div class="features-section">

                <img src="@/assets/features/illo_3.svg" class="margin-right" />

                <div class="features-text">

                    <h2 v-html="$homepageTranslations.pages.features.multiple_languages[$store.getters.getDefaultLanguage]"></h2>

                    <p v-html="$homepageTranslations.pages.features.translation[$store.getters.getDefaultLanguage]"></p>

                </div>

            </div>

            <div class="features-section">

                <div class="features-text">

                    <h2 v-html="$homepageTranslations.pages.features.teamwork[$store.getters.getDefaultLanguage]"></h2>

                    <p v-html="$homepageTranslations.pages.features.admin_members[$store.getters.getDefaultLanguage]"></p>

                </div>

            </div>

        </div>


        <Pro />

        <PDF />

        <div class="price-container">

            <Price :price="price" />

        </div>

        <ContactUs />

        <div class="filler"></div>

        <AdditionalServices />

        <HomeFooter :signed_in="signed_in" />
    

    </div>


</template>

<script>
import HomeNavbar from '@/components/home/HomeNavbar.vue'
import Pro from '@/components/features/Pro.vue'
import PDF from '@/components/features/PDF.vue'
import Price from '@/components/pricing/Price.vue'
import ContactUs from '@/components/home/ContactUs.vue'
import AdditionalServices from '@/components/home/AdditionalServices.vue'
import HomeFooter from '@/components/home/HomeFooter.vue'

export default {
    name: "Features",
    metaInfo: {
        title: "Features | Menulingua Digital Menus",
        // titleTemplate: null,
        meta: [
            {
                name: 'description',
                content: "Managing your menu has never been easier. Create and update your menu wherever and whenever you’re connected to the internet.",
                keywords: 'menu, digital, accessible, guest friendly, own device, qr code'
            }
        ],
        htmlAttrs: {
        lang: 'en'
        },
        link: [
            {rel: 'canonical', href: 'https://menulingua.com/features'}
        ]
    },
    components: {
        HomeNavbar,
        Pro,
        PDF,
        Price,
        ContactUs,
        AdditionalServices,
        HomeFooter
    },
    data () {
        return {
            signed_in: Boolean,
            scrolled_from_top: false,
            price:  {
                        name: "Free trial",
                        price_us: "7 days",
                        price_id: "7 hari",
                        width: 'full',
                        top_colour: "linear-gradient(to right, rgb(7, 153, 72), rgb(7, 153, 72))",
                        timeline: "valid_one_outlet",
                        button_text: "sign_up",
                        route: "/sign_up",
                        bottom_colour: "linear-gradient(to right, rgb(7, 153, 72), rgb(7, 153, 72))",
                        features: [
                            {
                                text_only: true,
                                name: "try_features"
                            }
                        ]   
                }
        }
    },
    created() {
        this.checkSignedIn()
        this.getLanguage()
        this.scrollToTop()
        window.addEventListener('scroll', this.handleScroll);
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0,0);
        },
        checkSignedIn() {
            if (localStorage.signedIn) {
                this.signed_in = true
            } else {
                this.signed_in = false
            }
        },
        handleScroll (event) {
            var target = document.querySelector('#home-navbar');
            if (window.scrollY >= target.offsetHeight + 30) {
                this.scrolled_from_top = true
            } else if (window.scrollY < target.offsetHeight + 30) {
                this.scrolled_from_top = false
            }
        },
        getLanguage() {
            if (localStorage.default_language === undefined) {
                this.$store.commit('setDefaultLanguage', 'en')
                localStorage.setItem('default_language', 'en')
            } else {
                this.$store.commit('setDefaultLanguage', localStorage.default_language)
            }
        }
    }
}




</script>

<style scoped>

.features-content {
    width: 95%;
    max-width: 1000px;
    margin: auto;
    margin-top: 200px;
}

.features-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #3B3B3A;
    margin-bottom: 50px;
}

.features-section img {
    max-height: 300px;
    max-width: 500px;
}

.margin-right {
    margin-right: 50px;
}

.margin-left {
    margin-left: 50px;
}

.features-text {
    display: flex;
    flex-direction: column;
}

.features-text h1, .features-text h2 {
    font-size: 3.5vw;
    margin: 0px 0px 0px 0px;
    font-weight: 700;
}

.features-text p {
    font-family: "Cabin", sans-serif;
    font-size: 20px;
    letter-spacing: 1px;
    line-height: 30px;
}

.price-container {
    margin: auto;
    margin-top: 150px;
    width: 95%;
}

.filler {
    height: 50px;
}

@media(max-width: 800px) {

    .features-content {
        margin-top: 120px;
        margin-bottom: 100px;
    }

    .features-section {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 25px;
    }

    .features-section img {
        order: 1;
        margin-bottom: 20px;
        max-width: 400px;
        max-height: 200px;
    }

    .features-text {
        order: 2;
    }

    .features-text h1, .features-text h2 {
       
        font-size: 25px;
    }

    .margin-left {
        margin-left: 0px;
    }

    .margin-right {
        margin-right: 0px;
    }
    
    .price-container {
        margin-top: 50px;
    }
}

</style>