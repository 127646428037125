<template>

  <div>

    <h2>Historical orders</h2>

    <div v-if="this.is_loading === true" class="loader">

      <div class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>

    </div>

    <div v-else>

      <div class="historical-orders-container">

        <div class="orders" v-if="historicalOrdersExist()">

          <a @click="redirectToOrder(order.id)"
            class="historical-order" v-for="order in orders" :key="order.id"
            :class="{ 'order-open' : order.open }">
            <p><span>#{{ order.id }}</span></p>
            <p>{{ date(order.created_at) }}</p>
            <p>{{ timestamp(order.created_at) }}</p>
            <p v-if="order.order_type === 'in-restaurant'"><img src="@/assets/orders/table_black.svg" /> {{
              order.table_number }}</p>
            <p v-if="order.order_type === 'delivery'">Delivery</p>
            <p v-if="order.order_type === 'pickup'">Pickup</p>
            <p v-if="order.order_type === 'in-room'">In-room</p>
            <p>{{ order.currency }} {{ numberFormat(order.currency, order.grand_total) }}</p>
            <p v-if="order.open"><span class="open-indicator"></span>Open</p>
            <p v-else><span class="closed-indicator"></span>Closed</p>
          </a>

          <div class="paginate" v-if="total_orders_count > 5">

            <img v-if="prevPageNumberNotZero()" @click="prevPage(); getOrders()"
              src="@/assets/orders/arrow_backward_black.svg" />
            <div v-else class="filler-wide"></div>
            <p v-if="prevPageNumberNotZero()" @click="prevPage(); getOrders()">{{ prevPageNumber }}</p>
            <div v-else class="filler"></div>
            <p class="current-page">{{ currentPage}} </p>
            <p v-if="nextPageNotEmpty()" @click="nextPage(); getOrders()">{{ nextPageNumber }}</p>
            <img v-if="nextPageNotEmpty()" @click="nextPage(); getOrders()"
              src="@/assets/orders/arrow_forward_black.svg" />

          </div>

          <div v-if="user_type === 'admin'">

            <input @change="updateTargetUser()" v-model="viewed_user" placeholder="Enter a user ID for CSV export">

          </div>

          <a class="export-to-csv" @click="exportToCSV()">Export orders to CSV</a>

        </div>

        <div v-else>

          No orders yet

        </div>

      </div>

    </div>

  </div>

</template>


<script>
import moment from 'moment';

export default {
    name: 'HistoricalOrders',
    props: ['current_user', 'user_type'],
    data () {
        return {
            orders: [],
            is_loading: true,
            total_orders_count: 0,
            numberOfPages: 0,
            pages: [],
            currentPage: 1,
            prevPageNumber: 0,
            nextPageNumber: 2,
            startMin: 0,
            startMax: 5,
            viewed_user: '',
            url: process.env.VUE_APP_API_URL
        }
    },
    created () {
        if (!localStorage.signedIn) {
            this.$router.replace('/')
        } else {
        this.$http.secured.get('/app_total_orders_count')
            .then(response => { this.total_orders_count = response.data, this.getOrders() })
            .catch(error => this.setError(error, 'Something went wrong'))
        }
    },
    computed: {
        calculatePages () {
            return Math.ceil(this.total_orders_count / 5)
        }
    },
    mounted () {
    },
    methods: {
        nextPage() {
            if (this.startMin < this.total_orders_count) {
                this.startMin += 5
                this.startMax += 5
                this.currentPage += 1
                this.nextPageNumber += 1
                this.prevPageNumber += 1
            }
        },
        prevPage() {
            if (this.startMin > 0) {
                this.startMin -= 5
                this.startMax -= 5
                this.currentPage -= 1
                this.nextPageNumber -= 1
                this.prevPageNumber -= 1
            }
      },
      redirectToOrder(order_id) {
        this.$router.push('/show_order/' + order_id);
      },
        prevPageNumberNotZero() {
            if (this.prevPageNumber == 0) {
                return false
            } else {
                return true
            }
        },
        nextPageNotEmpty() {
            if (this.nextPageNumber > Math.ceil(this.total_orders_count / 5)) {
                return false
            } else {
                return true
            }
        },           
        timestamp(orderPlaced) {
            return moment(orderPlaced).format('LT');
        },
        date(orderPlaced) {
            return moment(orderPlaced).format('ll');
        },
        historicalOrdersExist() {
            if (this.total_orders_count > 0) {
                return true
            } else {
                return false
            }
        },
        numberFormat(currency, number) {
            if (currency === 'OMR') {
                return Number(number).toFixed(3)
            } else if (currency === 'Rp') {
                return Number(number).toLocaleString()
            } else {
                return Number(number).toFixed(2)

            }
        },
        getOrders() {
        this.is_loading = true
        var rangeStart = ((this.currentPage * 5) - 5)
        var rangeEnd = ((this.currentPage * 5) - 1)
        this.$http.secured.get('/app_my_orders/' + rangeStart + '/' + rangeEnd)
            .then(response => { this.orders = response.data, this.is_loading = false })
            .catch(error => this.setError(error, 'Something went wrong'))
        },
        exportToCSV() {
            var target = ''
            if (this.viewed_user != '' && this.viewed_user != null) {
                target = this.viewed_user
            } else {
                target = localStorage.user_id
            }
            this.$http.secured.get(this.url +'/app_export_to_csv/' + target + '/orders.csv')
            .then(response => { 
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'orders.csv');
                document.body.appendChild(link);
                link.click();         
            })
            .catch(error => this.setError(error, 'Something went wrong'))
        },
        setError (error, text) {
            this.error = (error.response && error.response.data && error.response.data.error) || text
        }
    }
}


</script>

<style scoped>

h2 {
    font-weight: 600;
}

.historical-orders-container {
    display: flex;
    flex-direction: column;
    /* height: 520px; */
}

.orders {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    /* height: 520px; */
}

.historical-order {
    width: 100%;
    /* border: 1px solid lightgray; */
    /* box-shadow: 0 0 10px rgba(50, 169, 222, 0.2); */
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 10px;
    justify-content: space-between;
}

.order-open {
    box-shadow: 0 0 10px rgba(248, 173, 75, 0.2);
}

.historical-order p {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 600;
    font-size: 12.5px;
}

.historical-order p span {
    font-weight: 600;
}

.historical-order p img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.historical-order:hover {
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
    transition: 0.5s;
}

.open-indicator {
    background-color: #f8ae4b;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-right: 5px;
}

.closed-indicator {
    background-color: gray;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-right: 5px;
}

.paginate {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center !important;
    /* align-self: flex-end; */
}

.paginate p {
    margin: 0px 25px 0px 25px;
    display: flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    box-sizing: border-box;
    align-items: center;
    cursor: pointer;  
}

.paginate p:hover {
    font-weight: 600;
    transition: 0.2s;
}

.paginate img {
    width: 50px;
    cursor: pointer;
}

.paginate img:hover {
    opacity: 0.7;
    transition: 0.2s;
}

.current-page {
    padding: 5px;
    border: 1px solid black;
    border-radius: 50%;
    font-weight: 600;
}

.filler { 
    width: 30px;
    height: 30px;
    margin: 0px 25px 0px 25px;
}

.filler-wide {
    width: 50px;
    height: 30px;
}

input {
    width: 100%;
    margin-bottom: 20px;
    font-family: 'Montserrat', sans-serif;
    height: 45px;
    font-size: 25px;
    border: 1px solid lightgray;
    font-weight: 200;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 10px;
    margin-top: 35px;
}

.export-to-csv {
    width: 100%;
    border: 1px solid lightgray;
    margin-top: 50px;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12.5px;
    /* margin-bottom: 100px; */
}

.delete-button {
  border: 1px solid darkred;
  box-sizing: border-box;
  padding: 2.5px;
  border-radius: 2.5px;
  font-size: 10px;
  display: inline-flex;
  color: darkred;
  cursor: pointer;
}

.delete-button:hover {
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
  transition: 0.2s;
}

.export-to-csv:hover {
    cursor: pointer;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
    transition: 0.2s;
}


@media(max-width: 700px) {
    .historical-order p {
        font-size: 12.5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .paginate {
        justify-content: space-between;
    }
}

@media(max-width: 500px) {
    .historical-order p {
        font-size: 9px;
    } 
}


</style>