<template>

    <div id="toolbar">

        <transition name="fade">

            <div class="quick-message" v-if="quick_message != ''">

                {{ quick_message }}

            </div>

        </transition>

        <!-- ######### wide breakpoint buttons -->
        <div @click="newItem()" v-if="add_item != false" class="wide-button">

            <img src="../assets/first_item.svg" />
            
            <p>Add item</p>
        
        </div>

        <div @click="editMenu()" class="wide-button">
            
            <img src="../assets/settings.svg" />
            
            <p>Settings</p>
            
        </div>

        <div @click="qrCode()" class="wide-button" v-if="menu.can_download_qr != false">

            <img src="../assets/qr_icon.svg" />
            
            <p>Show QR code</p>
                

        </div>

        <div @click="generatePdf()" v-if="generate_pdf != false" class="wide-button">

            <img src="../assets/pdf_icon.svg" />
            
            <p>Generate PDF</p>
            
        </div>

        <div @click="copyURL()" class="wide-button">

            <img src="../assets/link-button.svg" />

            <p>Copy URL</p>

        </div>

        <div @click="csv_picker_shown = !csv_picker_shown" class="wide-button" id="csv-picker" v-if="$store.getters.getUserType == 'admin' || $store.getters.getUserCSVAccess == true">

          <img src="../assets/csv_icon.svg" />
          <p>CSV</p>

          <CSVOptions :menu_id="menu_id" v-if="csv_picker_shown === true"></CSVOptions>

        </div>

        <div @click="qrCode()" class="wide-button">

            <div class="status-indicator status-indicator-wide" :class="{ 'live' : getMenuStatus() === 'LIVE' }"></div>

            <p class="small-text">{{ getMenuStatus() }}</p>

        </div>

        <!-- ######### narrow breakpoint buttons -->
        <div class="narrow-button" @click="editMenu()">

            <img src="../assets/menu-settings-button.svg" />
            
            <p>Settings</p>

        </div>

        <div class="narrow-button" @click="newItem()">

            <img src="../assets/add-item-button.svg" />
            
            <p>Add item</p>

        </div>

        <div class="narrow-button" @click="show_more = !show_more">

            <img src="../assets/more-button.svg" />

            <p>More</p>

        </div>

        <transition name="slide-fade">

            <div id="more-options" v-if="show_more === true">

                <div class="close-box" @click="show_more = false">
                    <img src="../assets/closedialogue.svg" />
                </div>

                <div class="more-item" @click="qrCode()" v-if="menu.can_download_qr != false">

                    <img src="../assets/qr_icon.svg" />
                    
                    <p>Show QR Code</p>

                </div>

                <div class="more-item" @click="copyURL()">

                    <img src="../assets/link-button.svg" />

                    <p>Copy URL</p>

                </div>

                <div class="more-item" @click="generatePdf()">
                    
                    <img src="../assets/pdf_icon.svg" />

                    <p>Generate PDF</p>

                </div>

                <div class="more-item" @click="qrCode()">

                    <div class="status-indicator" :class="{ 'live' : getMenuStatus() === 'LIVE' }">

                    </div>

                    <p>{{ getMenuStatus() }}</p>

                </div>

            </div>

        </transition>


    </div>




</template>


<script>
import CSVOptions from './csv/CSVOptions.vue';

export default {
    name: 'Toolbar',
    components: {
      CSVOptions
    },
    props: ['menu_id', 'can_edit', 'menu', 'add_item', 'generate_pdf', 'user_type', 'access_to_menu_csv_import_and_export'],
    data () {
        return {
            show_more: false,
            quick_message: '',
            url: process.env.VUE_APP_API_URL,
            csv_picker_shown: false
        }
    },
    methods: {
        newItem() {
            // this.$router.replace(`/${this.menu_id}/new_item`);
            this.$emit('add-an-item');
        },
        qrCode() {
            this.$emit('show-qr-codes')
        },
        editMenu() {
            this.$router.replace(`/${this.menu_id}/edit`);
        },
        generatePdf() {
            this.$emit('show-pdf-generator')
        },
        copyURL() {
            navigator.clipboard.writeText(`${window.location.origin}/${this.menu_id}`)
            this.quickMessage("URL copied to clipboard")
        },
        chooseCSVOption() {

        },
        quickMessage(message) {
            this.quick_message = message
            setTimeout(() => this.clearQuickMessage(), 2000);
        },
        clearQuickMessage() {
            this.quick_message = ''
        },
        getMenuStatus() {
            if (this.menu.mobile_version_live === true) {
                return 'LIVE'
            } else {
                return 'UNPUBLISHED'
            }
        }
    }
}



</script>

<style scoped>

#toolbar {
    z-index: 101;
}

.wide-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5px 0px 5px 0px;
    cursor: pointer;
    box-sizing: border-box;
    padding: 10px 0px 10px 0px;
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 5px;

}

.wide-button img {
    width: 20px;
    height: 20px;
}

.wide-button:hover {
    transition: 0.2s;
    border: 1px solid black;

}

.wide-button p {
    font-size: 12.5px;
    font-weight: 600;
    margin: 5px 0px 5px 0px;
    text-align: center;
}

.narrow-button {
    display: none;
    padding: 5px 5px 5px 5px;
    box-sizing: border-box;
    width: 33.3%;
    border: 0px;
    height: 100px;
    border-radius: 0px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0);
    cursor: pointer;
    color: white;
}

.narrow-button:hover {
    background-color: #B3CBE7;
    background-color: white;
    color: #5A5753;
}

.narrow-button img {
    width: 50px;
    height: 50px;
}

.narrow-button p {
    font-size: 12.5px;
    font-weight: 600;
    margin: 10px 0px 0px 0px;
}

#more-options {
    position: fixed;
    bottom: 0;
    z-index: 1000;
    background-color: white;
    width: 95%;
    max-width: 750px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    padding: 20px 0px 20px 0px;
    border-radius: 10px 10px 0px 0px;
}

.more-item {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 10px 0px 10px 0px;
    box-sizing: border-box;
    padding: 5px 20px 5px 20px;
    cursor: pointer;
}

.more-item img {
    width: 25px;
    height: 25px;
    margin-right: 10px;
}

.more-item p {
    font-weight: 600;
    font-size: 12.5px;
    margin: 0px 0px 0px 0px;
    color: black;
}

.close-box {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;
}

.close-box img {
    width: 20px;
    height: 20px;
    margin-bottom: 25px;
}

.quick-message {
    background-color: #D5C7D5;
    border: 1px solid #977395;
    background-color: #EDD15D;
    border: 1px solid #B3A259;
    color: black;
    width: max-content;
    position: fixed;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    box-sizing: border-box;
    padding: 5px 10px 5px 10px;
    color: black;
    border-radius: 2.5px;
    font-size: 12.5px;
    font-weight: 600;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.status-indicator {
    background-color: #BE1E2D;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-right: 10px;
}

.status-indicator-wide {
    margin-right: 0px;
}

.live {
    background-color: #95BF32;
}

.small-text {
    font-size: 10px !important;
}

#csv-picker {
  position: relative;
}

@media(max-width: 700px) {
    
    #toolbar {
        background-color: #5A5753;
        color: white;
        justify-content: space-between;
        border-top: 0px;
    }

    .wide-button {
        display: none;
    }

    .narrow-button {
        display: flex;
    }

}



</style>