<template>

    <div class="item-options" v-if="selected_items.includes(food_id)">

        <div class="close-modal" @click.stop="$emit('close-modal')">
            <img src="../assets/close.svg" />
            Close
        </div>

        <div class="flex-container">

            <div class="label" v-if="font_options === true">
                <p>Font size</p>
            </div>

            <div class="font-options" v-if="font_options === true">                
                <div @click.stop="$emit('decrease-font-size')">-</div>
                <img src="../assets/font_icon.svg" />
                <div @click.stop="$emit('increase-font-size')">+</div>
            </div>

            <div class="page-break" v-if="page_break_options === true">
                <p v-if="page_break === false" @click.stop="$emit('add-page-break')">Add page break for PDF</p>
                <p v-if="page_break === true" @click.stop="$emit('remove-page-break')">Remove page break for PDF</p>
            </div>     

        </div>

        <div class="flex-container">

            <div class="label" v-if="color_options === true">
                <p>Choose colour</p>
            </div>

            <div class="color-options" v-if="color_options === true" @click.stop="">
                <sketch-picker @input.self="$emit('set-color', `${colors.hex}`)" v-model="colors" :preset-colors="preset_colours"></sketch-picker>
            </div>

            <!-- <div class="position-options" v-if="position_options === true">
                <h5>Price position</h5>
                <div class="position-picker" :class="{ 'position-picker-selected' : price_position === 'align_left' }" @click="$emit('set-position', 'align_left')">Align left</div>
                <div class="position-picker" :class="{ 'position-picker-selected' : price_position === 'default' }" @click="$emit('set-position', 'default')">Align right</div>
            </div>   --> 
        
        </div>

    </div>



</template>


<script>
import { Sketch } from 'vue-color'

export default {
    name: 'ItemOptions',
    props: ['font-size', 'font_options', 'color_options', 'color', 'selected_items', 'food_id', 'position_options', 'price_position', 'page_break_options', 'page_break'],
    components: {
        'sketch-picker': Sketch
    },
    data () {
        return {
            show_color_picker: false,
            colors: this.color,
            preset_colours: []
        }
    },
    created() {
        this.getSelectedColours()
    },
    methods: {
        getSelectedColours() {
            var colours = JSON.parse(localStorage.colour)
            this.preset_colours = colours.reverse().slice(0, 20);
        }
    }
}



</script>

<style scoped>

.item-options {
    font-family: 'Montserrat', sans-serif;
    /* border: 1px solid lightgray; */
    background-color: white;
    display: flex;
    box-sizing: border-box;
    color: black;
    padding: 20px;
    border-radius: 0px 0px 5px 5px;
    flex-direction: row;
    flex-wrap: wrap;
    /* position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%); */
    width: 90%;
    /* max-width: 300px; */
    margin: auto;
    justify-content: space-between;
    z-index: 8000;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.font-options {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
    max-width: 200px;
    box-sizing: border-box;
    padding: 10px;
    /* padding-bottom: 20px; */
    border: 1px solid lightgray;
    background-color: white;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    border-bottom: 1px solid lightgray;
}

.font-options div {
    width: 25px;
    display: flex;
    font-size: 20px;
    justify-content: center;
    cursor: pointer;
    /* border: 1px dotted lightgray; */
}

.font-options img {
    width: 20px;
    height: 20px;
    border: 1px solid black;
    box-sizing: border-box;
    padding: 2.5px;
    border-radius: 50%;
}

.position-options {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}

.position-options h5 {
    margin: 0px 0px 5px 0px;
    font-weight: 600;
    font-size: 14px;
}

.position-picker {
    width: 100%;
    margin: 0px 0px 5px 0px;
    box-sizing: border-box;
    padding: 5px;
    font-size: 12.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    background-color: white;
    color: gray;
    border: 1px solid gray;
    border-radius: 5px;
}

.position-picker-selected {
    background-color: #4794CB;
    color: white;
    border: 1px solid #4794CB;
}

.page-break {
    width: 100%;
    font-size: 12.5px;
    font-weight: 600;
    align-self: flex-end;
    margin-top: 25px;
    cursor: pointer;
    box-sizing: border-box;
    padding-top: 10px;
    border-top: 1px solid lightgray;

    /* text-align: center; */
}

.page-break p {
    background-color: white;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    padding: 5px;
    border-radius: 5px;
    width: max-content;
    border: 1px solid lightgray;
}

.label p {
    font-weight: 600;
    font-size: 12.5px;
    margin-top: 10px;
    margin-bottom: 20px;
    border: 1px solid black;
    box-sizing: border-box;
    padding: 2.5px;
    border-radius: 2.5px;
    width: max-content;
}

.color-options { 
    margin-bottom: 25px;
    width: 100%;
}

.flex-container {
    width: 45%;
}

@media(max-width: 500px) {
    .flex-container {
        width: 100%;
    }
}

</style>