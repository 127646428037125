<template>

    <div>

        <div v-if="is_loading === true" class="loader">
            
            
                <transition name="fade">

                    <div class="loader-with-message">

                        <img src="../assets/loading_gif.gif" />

                        <p>Building your menu ...</p>

                        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>

                    </div>

                </transition>

                

        </div> 

        <div v-if="is_loading === false">

            <div v-if="this.$store.getters.getImageOnlyMenu === false">

                <h2>Choose a currency</h2>

                <div class="currencies-container">

                    <Currency v-for="currency in currencies" :key="currency" :currency = currency v-on:select-currency="selectCurrency(currency)"/>

                </div>

            </div>

            <div v-else>

                <h2>Upload your files</h2>

                <div v-for="language in languages" :key="language">

                    <ImageMenuUploader :editing="editing" :menu="menu" :menu_id="menu_id" :language="language" v-on:uploading="fileUploading()" v-on:finished-uploading="finishedUploading()" v-on:can-proceed="uploadSuccess()" />

                </div>

            </div>


                <div class="step-container" >

                    <transition name="fade">

                        <NextStep v-if="can_proceed == true" :next_step="'Finish'" :editing="editing" :menu="menu" v-on:submitted="submitted()" :key="componentKey" />

                    </transition>

                </div>

        </div>

    </div>


</template>

<script>
import Currency from '@/components/Currency.vue'
import NextStep from '@/components/NextStep.vue'
import ImageMenuUploader from '@/components/ImageMenuUploader.vue'

export default {
    name: 'StepFour',
    components: {
        Currency,
        NextStep,
        ImageMenuUploader
    },
    props: ['editing', 'menu'],
    data () {
        return {
            currencies: ['USD', 'Pound Sterling', 'Rupiah', 'Baht', 'Ringgit', 'Singapore Dollar', 'Euros', 'Qatari Riyals', 'Omani Rial', 'Indian rupee', 'Philippine Peso', 'Kroner', 'Japanese Yen', 'Turkish Lira', 'Swiss Franc', 'Canadian Dollar', 'Hong Kong Dollar', 'New Zealand Dollar', 'Russian Ruble', 'Brazilian Real', 'Argentine Peso', 'Mexican Peso', 'South African Rand', 'Egyptian Pound', 'Saudi Riyal', 'United Arab Emirates Dirham', 'Pakistani Rupee', 'South Korean Won', 'New Taiwan Dollar', 'Romanian Leu'].sort(),
            can_proceed: false,
            is_loading: false,
            menu_id: 0,
            languages: [],
            componentKey: 0
        }
    },
    created () {
        this.languages = this.$store.getters.getSelectedLanguages
        this.canProceed()
        if (this.editing === true) {
            this.menu_id = this.menu.id
        } else {
            this.menu_id = 0
        }
    },
    methods: {
        selectCurrency(currency) {
            this.$store.commit("selectCurrency", currency)
            this.canProceed()
        },
        submitted() {
            this.is_loading = true
        },
        canProceed() {
          this.can_proceed = true
            // if (this.$store.getters.getImageOnlyMenu === false) {
            //     if (this.$store.getters.getCurrency != '') {
            //         this.can_proceed = true
            //     } else {
            //         this.can_proceed = false
            //     }
            // }
        },
        uploadSuccess() {
            console.log('upload success')
            this.$store.commit("selectCurrency", "none")
            this.can_proceed = true
            this.componentKey++
        }
    }
}


</script>

<style scoped>

.currencies-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.loader {
    height: 100vh;
    width: 100vw;
    background-color: white;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 1000;
    overflow-x: hidden;
    overflow-y: hidden;
    
}

.loader p {
    font-size: 50px;
    color: black;
}

input[type="file"] {
    width: 100px !important;
    height: 50px !important;
}

.loader img {
    width: 100px;
    height: 100px;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 50%;
    background-color: #8CC63E;
    box-shadow: 0 0 32px rgba(100, 100, 100, 0.3);
}



</style>