<template>

    <div :style="{ 'background-color' : getBackgroundColor(), 'color' : getTextColor(), 'font-family' : font }">
        {{ special_tag_for_item }}
    </div>



</template>


<script>

export default {
    name: 'SpecialTag',
    props: ['special_tag_for_item', 'background_color', 'text_color', 'font'],
    methods: {
        getBackgroundColor() {
            if (this.background_color === 'default') {
                return '#000000'
            } else {
                return this.background_color
            }
        },
        getTextColor() {
            if (this.text_color === 'default') {
                return '#FFFFFF'
            } else {
                return this.text_color
            }
        }
    }
}




</script>

<style scoped>

div {
    width: max-content;
    border-radius: 2.5px;
    font-size: 12.5px;
    font-weight: 600;
    box-sizing: border-box;
    padding: 2.5px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    max-width: 90%;
    text-wrap: wrap;
}


</style>