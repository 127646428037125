<template>

    <div class="order-mini" @click="showCart()">
        <div class="length">{{ this.$store.getters.getOrder.length }}</div>
        <div class="total-price">{{ menu.currency_short_form }} <NumberFormat :number="getTotalPrice()" :currency="currency_short_form" /></div>
        <div class="arrow"> <img src="@/assets/arrow_white_2.svg" /></div>


    </div>



</template>

<script>
import NumberFormat from '@/components/NumberFormat.vue'

export default {
    name: 'OrderMini',
    props: ['menu', 'currency_short_form'],
    components: {
        NumberFormat
    },
    data () {
        return {

        }
    },
    methods: {
        getTotalPrice() {
            var order = this.$store.getters.getOrder
            var total_price = 0
            console.log(order.length)
            for (let i = 0; i < order.length; i++) {
                total_price = total_price + order[i].item_price
                for (let s = 0; s < order[i].selections.length; s++) {
                    total_price = total_price + Number(order[i].selections[s].additional_fee)
                }
            }
            return total_price
        },
        showCart() {
            if (this.$store.getters.getOrder.length > 0) {
                this.$emit('show-cart')
            }
        }
    }
}


</script>

<style scoped>

.order-mini {
    background-color: black;
    position: fixed;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    height: 50px;
    width: 90%;
    max-width: 750px;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0px 15px 0px 15px;
    border-radius: 5px;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.5);
    z-index: 600;
}

.length {
    background-color: white;
    color: black;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 25px;
    width: 25px;
    font-size: 12px;
    font-weight: 600;
    border-radius: 50%;
}

.total-price {
    font-weight: 600;
    font-size: 16px;
}

.arrow {
    width: 50px;
    max-width: 50px;
    display: flex;
    align-items: center;
    /* height: 25px; */
}

.arrow img {
    max-width: 50px;
}


</style>