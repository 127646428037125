<template>

    <div id="partner-icons">


        <img src="@/assets/homepage/arrow.svg" class="arrow" @click="scrollLeft()" />

        <div class="partners" id="partner-container">

            <img :src="require(`@/assets/homepage/logos/${logo.name}.png`)" v-for="logo in logos" :key="logos.indexOf(logo)" :class="{ 'extra-wide' : logo.extra_wide === true }" /> 

        </div>

        <img src="@/assets/homepage/arrow.svg" class="arrow forward-arrow" @click="scrollRight()" />


    </div>

</template>


<script>

export default {
    name: "PartnerIcons",
    data () {
        return {
            logos: [
                {
                    name: 'palace'
                },
                {
                    name: 'edge'
                },
                {
                    name: 'silversands',
                    extra_wide: true
                },
                {
                    name: 'hilton'
                },
                {
                    name: 'shire',
                    extra_wide: true
                },
                {
                    name: 'mamalous'
                },
                {
                    name: 'boardwalk'
                },           
                {
                    name: 'campagnia'
                },
                {
                    name: 'cafedelmor'
                },
                {
                    name: 'kantina'
                },
                {
                    name: 'chocolatebar'
                }, 
                {
                    name: 'kayumanis'
                },
                {
                    name: 'woodlands'
                }, 
                {
                    name: 'mumtaz'
                },     
                {
                    name: 'mireio'
                }
            ]
        }
    },
    methods: {
        scrollRight() {
            const container = document.getElementById('partner-container');
            const distance = 200;

            container.scrollBy({
                left: distance,
                behavior: 'smooth'
            });
        },
        scrollLeft() {
            const container = document.getElementById('partner-container');
            const distance = 200;

            container.scrollBy({
                left: -distance,
                behavior: 'smooth'
            });
        }
    }
}



</script>


<style scoped>

#partner-icons {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 50px;

}

.partners {
    display: flex;
    flex-direction: row;
    align-items: center;
    
    width: 80%;
    margin: auto;
    overflow: hidden;
    -ms-overflow-style: none;  /* IE and Edge */
    overflow-x: scroll;
    scrollbar-width: none; 
    /* margin-top: 50px; */
}

.partners::-webkit-scrollbar {
  display: none;
}

.partners img {
    max-width: 100px;
    max-height: 75px;
    margin: 0px 50px 0px 50px;
}

.arrow {
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.forward-arrow {
    transform: rotate(180deg);
}

.extra-wide {
    max-width: 200px !important;
}

@media(max-width: 600px) {
    .partners img {
        max-width: 30%;
        margin: 0 10% 0 10%;
    }

    .extra-wide {
        max-width: 50% !important;
    }
}

</style>