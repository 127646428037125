<template>

    <svg version="1.1" id="Layer_2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 25 25" enable-background="new 0 0 25 25" xml:space="preserve">
        <polyline fill="none" :stroke="currentColour" stroke-width="2.2777" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="
            1.3,12.4 23.7,12.4 14.3,3 "/>
        <line fill="none" :stroke="currentColour" stroke-width="2.2777" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="20.7" y1="15.5" x2="14" y2="22.3"/>
        <polygon :fill="currentColour" points="19.5,15.1 20.7,14 23.8,14 21.4,16.4 "/>
    </svg>

</template>



<script>

export default {
    name: "DynamicallyColouredArrow",
    props: ['currentColour'],
    created() {
        if (this.currentColour === 'default') {
            this.currentColour = '#000000'
        }
    }
}



</script>
<style scoped>



</style>


