<template>


    <div class="container" v-bind:style="{  'background-image': 'url(' + require('@/assets/banner-overlay.svg') + ')' }">

        <HomeNavbar :sticky_navbar="true" />

        <div id="login-wrapper">

            <h2>Create account</h2>

            <p>Your first 7 days are free.</p>

            <div v-if="is_loading === true" class="loader">
                <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>
            
            <form @submit.prevent="signUp" v-else>

                <div v-if="error != []">

                    <div v-for="e in error" :key="e" class="error-message">
                        {{ String(e) }}
                    </div>

                </div>

                <div>

                    <label for="email">Email address</label>
                    <input type="email" v-model="email" id="email" placeholder="Enter email">
                </div>

                <div>
                    <label for="password">Password</label>
                    <input type="password" v-model="password" id="password" placeholder="Enter password">
                </div>
                <div>
                    <label for="password">Password Confirmation</label>
                    <input type="password" v-model="password_confirmation" id="password_confirmation" placeholder="Enter password again">
                </div>

                <vue-hcaptcha sitekey="10000000-ffff-ffff-ffff-000000000001" @verify="can_submit = true" v-if="url === 'http://localhost:3000'"></vue-hcaptcha>
                <vue-hcaptcha v-else sitekey="6a949cf2-a9f4-49f2-87c5-bdce28f4a2ec" @verify="can_submit = true"></vue-hcaptcha>

                <button type="submit" @click="signUp()">Create account</button>

                

            </form>

            <div class="login" @click="redirectToSignIn()">
                <img src="@/assets/account_icon.svg"/>
                Already have an account? Sign in
            </div>

            <div class="login google" @click="googleLogIn()">
                Sign up with Google account
            </div>

        </div>


    </div>




</template>

<script>
import VueHcaptcha from '@hcaptcha/vue-hcaptcha';
import HomeNavbar from '@/components/home/HomeNavbar.vue'


export default {
    name: 'SignUp',
    components: {
        VueHcaptcha,
        HomeNavbar
    },
    data () {
        return {
            is_loading: false,
            can_submit: false,
            email: '',
            password: '',
            password_confirmation: '',
            error: [],
            url: process.env.VUE_APP_API_URL
        }
    },
    created() {
        this.checkSignedIn();
        this.scrollToTop();
        this.getLanguage();
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0,0);
        },
        redirectToSignIn() {
            this.$router.push('/login')
        },
        checkSignedIn() {
            if (localStorage.signedIn) {
                this.$router.replace('/dashboard')
            }
        },
        getLanguage() {
            if (localStorage.default_language === undefined) {
                this.$store.commit('setDefaultLanguage', 'en')
                localStorage.setItem('default_language', 'en')
            } else {
                this.$store.commit('setDefaultLanguage', localStorage.default_language)
            }
        },
        signUp() {
            this.is_loading = true;
            if (this.can_submit === true) {
                if (this.password === this.password_confirmation) {  
                    this.$http.plain.post('/signup', { 
                        email: this.email, password: this.password, password_confirmation: this.password_confirmation })
                    .then(response => this.signUpSuccessful(response))
                    .catch(error => this.signupFailed(error))
                    // this.is_loading = false
                } else {
                    this.is_loading = false
                    this.error = ["Passwords don't match"]
                }
            } else {
                this.error = ["Please complete the captcha to continue"]
                this.is_loading = false;
            }
        },
        signUpSuccessful (response) {
            localStorage.token = response.data.access
            localStorage.csrf = response.data.csrf
            localStorage.refresh = response.data.refresh
            localStorage.signedIn = true
            // this.emitSignedIn()
            this.error = []
            this.$router.replace('/dashboard')
        },
        googleLogIn() {
            window.location.replace(this.url + '/users/auth/google_oauth2')
        },
        signupFailed (error) {
            this.error = (error.response && error.response.data && error.response.data.error) || ''
            this.is_loading = false
        }
    }
}





</script>

<style scoped>

.container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 90vh;
    margin-top: 75px;
    background-blend-mode: lighten;
    background-color: rgba(255,255,255,0.6);
    /* background-image: url("@/assets/banner-overlay.svg"); */
}

#login-wrapper {
    max-width: 400px;
    width: 90%;
    margin: auto;
    background-color: white;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 30px;
    border: 1px solid lightgray;
    border-radius: 10px;
    margin-top: 50px;
    margin-bottom: 50px;
}

#login-wrapper h2 {
    font-weight: 600;
    margin: 0px 0px 30px 0px;
}

#login-wrapper p {
    margin: 0px 0px 30px 0px;
    font-size: 12.5px;
    font-weight: 600;
    color: #716558;
}

form {
    display: flex;
    flex-direction: column;
}

input {
    width: 100%;
    border-bottom: 1px solid lightgray;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    font-size: 15px;
    height: 40px;
    margin: 10px 0px 50px 0px;
    font-family: 'Montserrat', sans-serif;
}

label {
    font-size: 15px;
    font-weight: 600;
    margin: 20px 0px 10px 0px;
}

button {
    background-image: linear-gradient(to right, rgb(153, 202, 60), rgb(7, 153, 72));
    display: flex;
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    justify-content: center;
    border: 0px;
    height: 40px;
    align-items: center;
    width: 100%;
    border-radius: 5px;
    margin-top: 50px;
}

.error-message {
    color: red;
    font-size: 12.5px;
    font-weight: 600;
    box-sizing: border-box;
    padding: 5px;
    border: 1px solid red;
    border-radius: 2.5px;
    margin: 0px 0px 20px 0px;
    align-self: flex-start;
    width: max-content;
}

.loader {
    height: 284px;
    width: 100%;
}

.login {
    width: 100%;
    border: 1px solid lightgray;
    margin-top: 20px;
    display: flex;
    align-items: center;
    font-weight: 600;
    justify-content: center;
    box-sizing: border-box;
    padding: 5px;
    border-radius: 5px;
    font-size: 12.5px;
    font-weight: 600;
    cursor: pointer;
}

.login:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    transition: 0.2s;
}

.login img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.icon {
    width: 15px;
    height: 15px;
    margin-right: 10px;
    margin-bottom: 10px;
}

h2 {
    display: flex;
    flex-direction: row;
    align-items: center;
}

@media(max-width: 600px) {
    #login-wrapper {
        width: 100%;
        max-width: 600px;
        border: 0px;
        border-radius: 0px;
        background-color: rgba(255, 255, 255, 0.5);
    }

}

</style>