
const translations = {
    in_restaurant: {
        en: 'Dine-In',
        ina: 'Makan di Tempat',
        zh: '店内用餐',
        ru: 'Внутри ресторана',
        jp: '店内ダイニング',
        nl: 'Dineren-in',
        fr: 'Sur Place',
        ar: 'طلب داخل المطعم',
        sp: 'Comer en el restaurante',
        ca: 'Menjar al restaurant',
        kr: '매장 내 식사',
        it: 'Cenare-in',
        de: 'Dine-In',
        gre: 'Dine-In'
    },
    pickup: {
        en: 'Takeaway',
        ina: 'Bungkus',
        zh: '外带',
        ru: 'На вынос',
        jp: 'テイクアウト',
        nl: 'Afhaal',
        fr: 'Plats à emporter',
        ar: 'إستلام',
        sp: 'Para llevar',
        ca: 'Recollida',
        kr: '테이크 아웃',
        it: 'Da asporto',
        de: 'Abholung',
        gre:'Takeaway'
    },
    delivery: {
        en: 'Delivery',
        ina: 'Pesan Antar',
        zh: '送餐',
        ru: 'Доставка',
        jp: 'デリバリー',
        nl: 'Bezorg',
        fr: 'Livraisons',
        ar: 'توصيل',
        sp: 'Entrega a domicilio',
        ca: 'Entrega a domicili',
        kr: '배달',
        it: 'Consegna',
        de: 'Lieferung',
        gre: 'Παράδοση'
    },
    in_room: {
        en: 'Room Service',
        ina: 'Room Service',
        zh: '客房服务',
        ru: 'Обслуживание номеров',
        jp: 'ルームサービス',
        nl: 'Room Service',
        fr: 'Service en chambre',
        ar: 'خـدمة الــغرف',
        sp: 'Servicio de habitaciones',
        ca: "Servei d'habitacions",
        kr: '룸 서비스',
        it: 'Servizio in camera',
        de: 'Zimmerservice',
        gre: 'Υπηρεσία δωματίου'
    },
    item: {
        en: 'Item',
        ina: 'Barang',
        zh: '商品',
        ru: 'Пункт меню',
        jp: '商品',
        nl: 'Item',
        fr: 'Designation',
        ar: 'العنصر',
        sp: 'Artículo',
        ca: 'Article',
        kr: '상품',
        it: 'Articolo',
        de: 'Artikel',
        gre: 'Αντικείμενο'
    },  
    price: {
        en: 'Price',
        ina: 'Harga',
        zh: '单价',
        ru: 'Цена',
        jp: '価格',
        nl: 'Prijs',
        fr: 'Prix',
        ar: 'السعر',
        sp: 'Precio',
        ca: 'Preu',
        kr: '가격',
        it: 'Prezzo',
        de: 'Preis',
        gre: 'Τιμή'
    },     
    quantity: {
        en: 'Quantity',
        ina: 'Jumlah',
        zh: '数量',
        ru: 'Количество',
        jp: '量',
        nl: 'Hoeveelheid',
        fr: 'Quantité',
        ar: "الكمية",
        sp: 'Cantidad',
        ca: 'Quantitat',
        kr: '수량',
        it: 'Quantità',
        de: 'Menge',
        gre:'Ποσότητα'
    }, 
    note: {
        en: 'Note',
        ina: 'Catatan',
        zh: '备注',
        ru: 'Примечание',
        jp: '追記',
        nl: 'Nota',
        fr: 'Note',
        ar: 'مذكرة',
        sp: 'Nota',
        ca: 'Nota',
        kr: '메모',
        it: 'Nota',
        de: 'Notiz',
        gre: 'Σχόλιο'
    },          
    subtotal: {
        en: 'Subtotal',
        ina: 'Subtotal',
        zh: '小计',
        ru: 'Промежуточный итог',
        jp: '小計',
        nl: 'Subtotaal',
        fr: 'Sous-total',
        ar: 'المجموع الفرعي',
        sp: 'Subtotal',
        ca: 'Subtotal',
        kr: '소계',
        it: 'Subtotale',
        de: 'Zwischensumme',
        gre: 'Υποσύνολο'
    },         
    service: {
        en: 'Service Fee',
        ina: 'Biaya Layanan',
        zh: '服务费',
        ru: 'Плата за обслуживание',
        jp: 'サービス料',
        nl: 'Servicekosten',
        fr: 'Frais de service',
        ar: 'رسم الخدمة',
        sp: 'Cargo por servicio',
        ca: 'Càrrec per servei',
        kr: '서비스 요금',
        it: 'Servizio',
        de: 'Service-Gebühr',
        gre: 'Χρέωση υπηρεσιών'
    },   
    tax: {
        en: 'VAT',
        ina: 'PPN',
        zh: '增值税',
        ru: 'НДС',
        jp: '付加価値税',
        nl: 'BTW',
        fr: 'TVA',
        ar: 'VAT',
        sp: 'IVA',
        ca: 'IVA',
        kr: '부가가치세',
        it: 'IVA',
        de: 'MwSt.',
        gre:'ΦΠΑ'
    },    
    delivery_fee: {
        en: 'Delivery Fee',
        ina: 'Biaya Antar',
        zh: '配送费',
        ru: 'Стоимость доставки',
        jp: '配送料',
        nl: 'Leveringskosten',
        fr: 'Frais de livraison',
        ar: 'رسوم التوصيل',
        sp: 'Tarifa de entrega',
        ca: 'Tarifa de lliurament',
        kr: '배달비가',
        it: 'Tassa di consegna',
        de: 'Liefergebühr',
        gre:'Χρέωση παράδοσης'
    },    
    packaging_fee: {
        en: 'Packaging Fee',
        ina: 'Biaya Bungkus',
        zh: '包装费',
        ru: 'Цена за упаковку',
        jp: '梱包料',
        nl: 'Verpakkingskosten',
        fr: "Frais d'emballage",
        ar: 'رسوم التعبئة والتغليف',
        sp: 'Tarifa de empaque',
        ca: 'Tarifa d’embalatge',
        kr: '포장비',
        it: 'Tassa di imballaggio',
        de: 'Gebühr für die Verpackung',
        gre:'Τέλος συσκευασίας'
    },  
    total: {
        en: 'Total',
        ina: 'Total',
        zh: '总计',
        ru: 'Итого',
        jp: '総額',
        nl: 'Totaal',
        fr: 'Total',
        ar: 'الإجمالي',
        sp: 'Total',
        ca: 'Total',
        kr: '총 결제 금액',
        it: 'Totale',
        de: 'Summe',
        gre:'Σύνολο'
    },    
    table_number: {
        en: 'Table No.',
        ina: 'No. Meja',
        zh: '桌号',
        ru: 'Номер столика',
        jp: 'テーブル番号',
        nl: 'Tafelnummer',
        fr: 'Table Nº',
        ar: 'رقم الطاولة',
        sp: 'Mesa Nº',
        ca: 'Taula Nº',
        kr: '테이블 넘버',
        it: 'Tavolo numero',
        de: 'Tisch Nr.',
        gre: 'Τραπέζι'
    },       
    guests: {
        en: 'Guests',
        ina: 'Tamu',
        zh: '人数',
        ru: 'Количество гостей',
        jp: '人数',
        nl: 'Aantal personen',
        fr: 'Pax',
        ar: 'عدد الضيوف',
        sp: 'Pax',
        ca: 'Pax',
        kr: '여행인원',
        it: 'Pax',
        de: 'Personen',
        gre:'Pax'
    },
    name: {
        en: 'Name',
        ina: 'Nama',
        zh: '姓名',
        ru: 'Имя',
        jp: '名前',
        nl: 'Naam',
        fr: 'Prénom',
        ar: 'الاسم',
        sp: 'Nombre',
        ca: 'Nom',
        kr: '이름',
        it: 'Nome',
        de: 'Name',
        gre:'Ονομα'
    },       
    phone_number: {
        en: 'Mobile No.',
        ina: 'No. HP',
        zh: '手机号',
        ru: 'Номер телефона',
        jp: '電話番号',
        nl: 'Telefoonnummer',
        fr: 'Numéro de téléphone',
        ar: 'رقم التليفون',
        sp: 'Número de celular',
        ca: 'Número de telèfon',
        kr: '전화번호',
        it: 'Numero telefonico',
        de: 'Rufnummer',
        gre:'Τηλεφωνικό νούμερο'
    },  
    address: {
        en: 'Address',
        ina: 'Alamat',
        zh: '地址',
        ru: 'Адрес',
        jp: '住所',
        nl: 'Adres',
        fr: 'Adresse',
        ar: 'العنوان',
        sp: 'Dirección',
        ca: 'Direcció',
        kr: '주소',
        it: 'Indirizzo',
        de: 'Adresse',
        gre:'Διεύθυνση'
    },  
    room_number: {
        en: 'Room No.',
        ina: 'No. Kamar',
        zh: '房号',
        ru: 'Номер комнаты',
        jp: '部屋番号',
        nl: 'Kamernummer',
        fr: 'Chambre №',
        ar: 'رقم الغرفة',
        sp: 'Habitación Nº',
        ca: 'Habitació Nº',
        kr: '방 번호',
        it: 'Numero di camera',
        de: 'Zimmernummer',
        gre:'Αριθμός δωματίου'
    },    
    last_name: {
        en: 'Last Name',
        ina: 'Nama Belakang',
        zh: '姓名',
        ru: 'Фамилия',
        jp: '姓',
        nl: 'Achternaam',
        fr: 'Nom',
        ar: 'الكنية',
        sp: 'Apellido',
        ca: 'Cognoms',
        kr: '성',
        it: 'Nome',
        de: 'Nachnamen',
        gre:'Επίθετο'
    },  
    submit_order: {
        en: 'Submit Order',
        ina: 'Pesan',
        zh: '确认下单',
        ru: 'Подтвердить заказ',
        jp: '注文する',
        nl: 'Bestelling indienen',
        fr: 'Passer commande',
        ar: 'إرسال الطلب',
        sp: 'Enviar pedido',
        ca: 'Enviar comanda',
        kr: '주문하기',
        it: 'Invia ordine',
        de: 'Bestellung Abschicken',
        gre:'Ολοκλήρωση παραγγελίας'
    }, 
    submitted: {
        en: 'Submitted',
        ina: 'Pesanan Terkirim',
        zh: '提交成功',
        ru: 'Заказ оформлен',
        jp: '正常に送信されました',
        nl: 'Ingediend',
        fr: 'Commande passée',
        ar: 'الطلب المقدم',
        sp: 'Pedido exitoso',
        ca: 'Comanda satisfactòria',
        kr: '주문 성공',
        it: 'Ordine effettuato',
        de: 'Erfolgreich eingereicht',
        gre:'Έγινε'
    },
    order_submitted_successfully: {
        en: 'Order submitted successfully',
        ina: 'Pesanan Anda berhasil diajukan',
        zh: '已完成下单',
        ru: 'Заказ успешно принят',
        jp: '注文成功',
        nl: 'Bestelling succesvol ingediend',
        fr: 'Commande passée avec succès',
        ar: 'تم إرسال الطلب بنجاح',
        sp: 'Pedido confirmado',
        ca: 'Comanda confirmada',
        kr: '주문 성공',
        it: 'Ordine inviato con successo',
        de: 'Bestellung erfolgreich übermittelt',
        gre:'Η παραγγελία σας έχει υποβληθεί'
    },     
    confirmed: {
        en: 'Confirmed',
        ina: 'Terkonfirmasi',
        zh: '订单已确认',
        ru: 'Заказ подтвержден',
        jp: '確認済み',
        nl: 'Bevestigd',
        fr: 'Confirmé',
        ar: 'مؤكد',
        sp: 'Confirmado',
        ca: 'El restaurant confirma la vostra comanda',
        kr: 'Restaurant per confirmar la vostra comanda',
        it: 'Confermato',
        de: 'Bestätigt',
        gre:'Επιβεβαιωμένο'
    },       
    restaurant_has_acknowledged_your_order: {
        en: 'The restaurant has acknowledged your order.',
        ina: 'Pihak restoran sudah melihat pesanan Anda.',
        zh: '餐厅以确认您的订单。',
        ru: 'Ресторан подтвердил ваш заказ.',
        jp: 'ご注文を確認するレストラン。',
        nl: 'Het restaurant heeft uw bestelling ontvangen.',
        fr: 'Le restaurant a bien reçu votre commande.',
        ar: 'اعترف المطعم بطلبك.',
        sp: 'El restaurante ha recibido su pedido.',
        ca: 'El restaurant ha rebut la vostra comanda.',
        kr: '주문을 확인하는 레스토랑.',
        it: 'Il ristorante ha ricevuto il tuo ordine.',
        de: 'Das Restaurant hat Ihre Bestellung erhalten.',
        gre:'Το εστιατόριο επιβεβαίωσε την παραγγελία σας.'
    },
    on_the_way: {
        en: 'On the way!',
        ina: 'Sudah di jalan!',
        zh: '配送中',
        ru: 'В пути!',
        jp: '途中',
        nl: 'Onderweg!',
        fr: 'En chemin!',
        ar: 'علي الطريق!',
        sp: '¡En camino!',
        ca: 'En camí!',
        kr: '도중에!',
        it: 'Sulla strada!',
        de: 'Auf dem Weg' , 
        gre: 'Έρχεται!'
    },
    on_the_way_to_you: {
        en: 'Your order is on its way to you.',
        ina: 'Pesanan Anda sedang diantar ke tujuan.',
        zh: '您的订单正在配送中。',
        ru: 'Ваш заказ уже в пути',
        jp: 'ご注文の商品が発送されます。',
        nl: 'Uw bestelling wordt afgeleverd.',
        fr: 'Votre commande vous est livrée.',
        ar: 'يتم تسليم طلبك إليك.',
        sp: 'Tu comida está en camino.',
        ca: 'El teu menjar està en camí.',
        kr: '주문하신 상품이 배송되고 있습니다.',
        it: 'Il tuo ordine ti è stato consegnato.',
        de: 'Ihre Bestellung ist auf dem Weg zu Ihnen.',
        gre: "Η παραγγελία σας είναι καθ' οδόν προς εσάς."
    },
    help_is_on_the_way: {
        en: 'Submitted successfully! Help is on the way.',
        ina: 'Bantuan akan segera datang.',
        zh: '服务人员会马上来到您的桌位。',
        ru: 'Помощь уже в пути.',
        jp: 'すぐ助けに行くからね。',
        nl: 'Hulp is onderweg.',
        fr: "L'aide est en route.",
        ar: 'المساعده في طريقها.',
        sp: 'La ayuda está en camino.',
        ca: "L'ajuda està en camí.",
        kr: '지원이 진행 중입니다',
        it: 'Stanno arrivando i soccorsi.',
        de: 'Hilfe ist auf dem Weg.',  
        gre: "Η βοήθεια βρίσκεται καθ' οδόν! "
    } 
}


export { translations }