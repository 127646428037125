<template>

    <div id="user-details">

        <div class="overlay">

            <img src="@/assets/banner-overlay.svg" />

        </div>

        <AccountStatus :user_type="user_type" :admin_or_dashboard_user="admin_or_dashboard_user" :currentUser="currentUser" :email="email" :latest_subscription_status="latest_subscription_status" :current_plan_expiry_date="current_plan_expiry_date" :cancellation_requested="cancellation_requested" :subscription_payment_provider="subscription_payment_provider" :key="componentKey" />

        <div class="account-info" @click="goToAccountInfo()">
            <img src="@/assets/account_icon.svg"/>
            <p>{{ user_email }}</p>
            <div class="admin-user" v-if="user_type === 'admin'">Admin</div>
        </div>



        <form @submit.prevent="updateUser()">

            <h2>Account details</h2>

            <div class="field">

                <label>Email</label>

                <input type="email" v-model="email" required />

            </div>

            <div class="field">

                <label>New password</label>

                <input type="password" v-model="new_password" placeholder="Leave this blank if you don't want to change your password" minlength="8" />

            </div>

            <div class="field">

                <label>Confirm new password</label>

                <input type="password" v-model="confirm_new_password" placeholder="Confirm your new password" minlength="8" />

            </div>


            <div class="field">

                <label>Current password</label>

                <input type="password" v-model="password" placeholder="Enter your current password" />

            </div>

            <button :class="{ 'can-submit' : canSubmit() }">

                Submit

            </button>

        </form>








    </div>



</template>


<script>
import AccountStatus from '@/components/AccountStatus.vue'

export default {
    name: "UserDetails",
    components: {
        AccountStatus
    },
    data() {
        return {
            user_type: "",
            cancellation_requested: Boolean,
            subscription_payment_provider: "",
            admin_or_dashboard_user: Boolean,
            currentUser: '',
            email: "",
            user_email: "",
            new_password: "",
            confirm_new_password: "",
            password: "",
            message: "",
            current_plan_expiry_date: '',
            latest_subscription_status: "",
            componentKey: 0
        }
    },
    created() {
        this.getUserInfo();
    },
    methods: {  
        getUserInfo() {
            if (localStorage.signedIn) {
                this.$http.secured.get('/app_current_user')
                    .then(response => { 
                        this.currentUser = response.data.user,
                        this.user_type = response.data.user_type,
                        this.email = response.data.email,
                        this.user_email = response.data.email,
                        this.admin_or_dashboard_user = response.data.admin_or_dashboard_user,
                        this.latest_subscription_status = response.data.latest_subscription_status,
                        this.current_plan_expiry_date = response.data.current_plan_expiry_date,
                        this.cancellation_requested = response.data.cancellation_requested,
                        this.subscription_payment_provider = response.data.subscription_payment_provider,
                        this.$store.commit('setLoggedIn', response.data.user)
                        this.$store.commit('setUserType', response.data.user_type)
                        this.$store.commit('setUserEmail', response.data.email)
                        this.componentKey++
                    })
                .catch(error => console.log(error))
            } else {
                this.$router.replace('/login')
            }
        },
        // updateFailed(error) {
        //     // this.message = (error.response && error.response.data && error.response.data.error)
        //     // this.message = "Failed to update"

        //     setTimeout(() => {
        //         this.message = ''
        //     }, 5000)
        // },
        updateUser() {
            this.$http.secured.post(`/app_update_user/` , {
                email: this.email,
                new_password: this.new_password,
                confirm_new_password: this.confirm_new_password,
                password: this.password
                })
                .then(response => { 
                    console.log('Updated successfully')
                    this.$store.commit('setMessage', { 
                            content: "Updated successfully"
                        }
                    )
                    this.$router.replace('/dashboard')
                    
                }).catch(error => this.$store.commit('setMessage', { 
                            content: "Failed to update.",
                            error: true
                        }
                    ))
        },
        canSubmit() {
            if (this.new_password != '') {
                if (this.new_password.length > 8) {
                    return true
                } else {
                    // this.message = 'Password must be at least 8 characters in length'
                    // setTimeout(() => {
                    //     this.message = ''
                    // }, 5000)
                }
            } else {
                if (this.password != '') {
                    this.message = ''
                    return true
                }
            }
        },
        goToAccountInfo() {
            this.$router.push('/account')
        }
    }
}



</script>

<style scoped>

#user-details {
    margin-top: 75px;
    display: flex;
    flex-direction: column;
    position: relative;
}

form {
    width: 95%;
    max-width: 750px;
    margin: auto;
    margin-top: 100px;
    z-index: 1000;
}

input {
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    box-sizing: border-box;
    border: 0px;
    padding: 10px 0px 10px 0px;
    border-bottom: 1px solid rgba(200, 200, 200, 1);
}

h2 {
    font-weight: 600;
    /* font-size: 20px; */
    margin: 0px 0px 50px 0px;
}

.field {
    margin: 0px 0px 75px 0px;
}

label {
    font-size: 12.5px;
    font-weight: 600;
}

button {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    border: 0px;
    box-sizing: border-box;
    padding: 20px;
    cursor: pointer;
    font-size: 20px;
    font-weight: 600;
}

.can-submit {
    background-image: linear-gradient(to left, rgb(153, 202, 60), rgb(7, 153, 72));
    color: white;
}

input::placeholder {
    color:rgb(153, 202, 60);
    color: lightgray;
}
@media(max-width: 700px) {
    input {
        font-size: 12.5px;
    }
}

</style>