<template>

    <div id="item-alignment">

            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 25 25" enable-background="new 0 0 25 25" xml:space="preserve" v-if="align_left === true">
            <path :fill="backgroundColour" d="M23.8,25H1.2C0.6,25,0,24.4,0,23.8V1.2C0,0.6,0.6,0,1.2,0h22.5C24.4,0,25,0.6,25,1.2v22.5
                C25,24.4,24.4,25,23.8,25z"/>
            <path :fill="currentColour" d="M22.1,6.1H3.1c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h18.9c0.6,0,1,0.4,1,1v2C23.1,5.6,22.6,6.1,22.1,6.1z"/>
            <path :fill="currentColour" d="M22.1,11.6H3.1c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h18.9c0.6,0,1,0.4,1,1v2C23.1,11.1,22.6,11.6,22.1,11.6z
                "/>
            <path :fill="currentColour" d="M19.8,17.3H3.3c-0.7,0-1.2-0.5-1.2-1.2v-1.5c0-0.7,0.5-1.2,1.2-1.2h16.5c0.7,0,1.2,0.5,1.2,1.2v1.5
                C21,16.8,20.5,17.3,19.8,17.3z"/>
            <path :fill="currentColour" d="M15.2,23h-12c-0.6,0-1.1-0.5-1.1-1.1v-1.7c0-0.6,0.5-1.1,1.1-1.1h12c0.6,0,1.1,0.5,1.1,1.1v1.7
                C16.4,22.5,15.9,23,15.2,23z"/>
            </svg>

            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 25 25" enable-background="new 0 0 25 25" xml:space="preserve" v-if="align_centre === true">
            <path :fill="backgroundColour" d="M23.8,25H1.2C0.6,25,0,24.4,0,23.8V1.2C0,0.6,0.6,0,1.2,0h22.5C24.4,0,25,0.6,25,1.2v22.5
                C25,24.4,24.4,25,23.8,25z"/>
            <path :fill="currentColour" d="M21.7,6.1H2.8c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h18.9c0.6,0,1,0.4,1,1v2C22.7,5.6,22.3,6.1,21.7,6.1z"/>
            <path :fill="currentColour" d="M19.9,11.6h-15c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h15c0.6,0,1,0.4,1,1v2C20.9,11.1,20.4,11.6,19.9,11.6z"
                />
            <path :fill="currentColour" d="M20.7,17.3H4.2c-0.7,0-1.2-0.5-1.2-1.2v-1.5c0-0.7,0.5-1.2,1.2-1.2h16.5c0.7,0,1.2,0.5,1.2,1.2v1.5
                C21.9,16.8,21.4,17.3,20.7,17.3z"/>
            <path :fill="currentColour" d="M18.4,23h-12c-0.6,0-1.1-0.5-1.1-1.1v-1.7c0-0.6,0.5-1.1,1.1-1.1h12c0.6,0,1.1,0.5,1.1,1.1v1.7
                C19.5,22.5,19,23,18.4,23z"/>
            </svg>


    </div>



</template>


<script>

export default {
    name: "ItemAlignment",
    props: ['align_centre_toggled', 'align_left', 'align_centre'],
    data () {
        return {
            backgroundColour: '',
            currentColour: ''
        }   
    },
    created() {
        this.getColours();
    },
    methods: {
        getColours() {
            if ((this.align_centre === true && this.align_centre_toggled === true) || (this.align_left === true && this.align_centre_toggled === false)) {
                this.backgroundColour = '#B1D6F1'
                this.currentColour = '#27A9E1'
            } else {
                this.backgroundColour = '#E6E7E8'
                this.currentColour = '#404041'
            }
        }
    }
}



</script>

<style scoped>

svg {
    position: relative;
    left: 0px;
    /* position: absolute; */
    width: 50px;
    height: 50px;
    cursor: pointer;
}


</style>