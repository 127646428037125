<template>

    <New :editing="true" />

</template>


<script>
import New from '@/components/New.vue'

export default {
    name: 'EditMenu',
    components: {
        New
    },
    created() {
        this.$store.commit("setEditing");
    }
}



</script>

<style scoped>



</style>