

<template>

    <div>

        <div class="export-button" @click="engageExportDialogueBox()">

            <h3>Export to VHP</h3>

        </div>

        <div class="integration-dialogue-box" v-if="show_dialogue_box === true">

            <div class="response-message" v-if="response_message != ''">

                <transition name="fade">

                    <div class="success" v-if="response_message === 'Order successfully posted to VHP'">
                    
                        {{ response_message }}

                    </div>

                    <div class="failure" v-if="response_message === 'Order failed to post'">
                    
                        {{ response_message }}

                    </div>

                </transition>
            
            </div>

            <div v-if="response_message === ''">

                <div class="close" @click="engageExportDialogueBox()">Close</div>

                <h3>Export to VHP</h3>

                <form>
                    
                    <div>
                        <div class="required">Required</div>
                        <label for="pax_for_vhp">Pax</label>
                        
                        <input type="number" v-model="pax_for_vhp" placeholder="Enter pax" required>
                    </div>

                    <div>
                        <div class="required">Required</div>
                        <label for="table_number_for_vhp">Table number</label>
                        <input type="number" v-model="table_number_for_vhp" placeholder="Enter table number" required>
                    </div>
                    
                    <div>
                        <label for="gname">Guest name</label>
                        <input type="text" v-model="gname" placeholder="Enter guest name">
                    </div>

                    <div>
                        <label for="gname">Guest number</label>
                        <input type="number" v-model="guestnr" placeholder="Enter guest number">
                    </div>


                    <div class="export-button submit" @click.prevent="exportToVhp()">Export Order</div>

                </form>


        </div>

        </div>

    </div>

</template>

<script>
import axios from 'axios';

export default {
    name: 'ExportToVHP',
    props: ['order_rows', 'table_number', 'foods', 'languages', 'menu', 'pax', 'order'],
    data () {
        return {
            show_dialogue_box: false,
            order_object: {},
            error: '',
            table_number_for_vhp: Number,
            outlet_number: Number,
            pax_for_vhp: Number,
            gname: '',
            guestnr: Number,
            response_message: ''
        }
    },
    mounted() {
        this.buildOrderObject();
        this.table_number_for_vhp = Number(this.table_number)
        this.outlet_number = Number(this.menu.vhp_outlet_number)
        this.pax_for_vhp = Number(this.pax)
    },
    methods: {
        engageExportDialogueBox() {
            this.show_dialogue_box = !this.show_dialogue_box
        },
        getItemName(order_row_id) {            
            const foods = this.foods
            const result = this.foods.filter(food => food.id == order_row_id);
            if (this.languages.includes('en')) {
                return result[0]['translation_en'] || result[0]['name']
            } else {
                return result[0]['translation_' + this.languages[0]] || result[0]['name']
            }
        },
        buildLineItemsObject() {
            const lineItems = this.order_rows
            const lineItemsObject = {}
            lineItemsObject["menu-list"] = []
            lineItems.forEach((lineItem) => {
                var newLineItem = {}
                var foodName = this.getItemName(lineItem.food_id)
                newLineItem["rec-id"] = lineItem.food_id
                newLineItem.description = foodName
                newLineItem.qty = lineItem.item_quantity
                newLineItem.price = lineItem.item_price
                newLineItem["special-request"] = String(lineItem.item_note)
                lineItemsObject["menu-list"].push(newLineItem)
            });
            return lineItemsObject
        },
        buildOrderObject() {
            const orderObject = {}
            orderObject.request = {}
            orderObject.request["inputUserkey"] = this.menu.vhp_user_key
            orderObject.request["inputUsername"] = this.menu.vhp_user_name
            orderObject.request.pax = this.pax_for_vhp
            orderObject.request.tableNumber = this.table_number_for_vhp
            orderObject.request.outletNumber = Number(this.outlet_number)
            orderObject.request.guestName = this.gname
            orderObject.request.guestnr = this.guestnr
            orderObject.request.menuList = this.buildLineItemsObject() 
            return orderObject
        },
        exportToVhp() {
            const order = this.buildOrderObject()
            axios.post("https://ws1.e1-vhp.com:8443/VHPMobile3/rest/CommonInterfaces/postOrder", order).then((response) => {
                if (response.data.response.returnmessage === "Post Menu Success") {
                    this.response_message = "Order successfully posted to VHP"
                    this.$http.secured.post("/app_export_to_vhp/" + this.order.order_data.id)
                    .then(response => { 
                        if (response.status === 200) {
                            this.$emit('exported')
                            this.show_dialogue_box = false;
                        }
                    })
                    .catch(error => this.setError(error, 'Something went wrong')) 
                } else {
                    this.response_message = "Order failed to post"
                }
            })

        }
    }
}



</script>


<style scoped>

.export-button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: #1b75bb;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 30px;
    cursor: pointer;
}

.submit {
    font-weight: 600;
}

.export-button h3 {
    font-weight: 600;
    margin: 0px 0px 0px 0px;
    font-size: 15px;
}

.integration-dialogue-box {
    position: fixed;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    height: 100vh;
    width: 100%;
    box-sizing: border-box;
    padding: 20px 30% 50px 30%;
    overflow-y: scroll;
}

.integration-dialogue-box h3 {
    font-weight: 600;
}

.close {
    font-weight: 600;
    font-size: 12.5px;
    width: max-content;
    box-sizing: border-box;
    padding: 2.5px 5px 2.5px 5px;
    border: 1px solid black;
    border-radius: 2.5px;
    cursor: pointer;
}


form {
    display: flex;
    flex-direction: column;
}

input {
    width: 100%;
    border-bottom: 1px solid lightgray;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    font-size: 15px;
    height: 40px;
    margin: 10px 0px 50px 0px;
    font-family: 'Montserrat', sans-serif;
}

label {
    font-size: 15px;
    font-weight: 600;
    margin: 20px 0px 10px 0px;
}

button {
    background-image: linear-gradient(to right, rgb(153, 202, 60), rgb(7, 153, 72));
    display: flex;
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    justify-content: center;
    border: 0px;
    height: 40px;
    align-items: center;
    border-radius: 5px;
}

.required {
    font-size: 10px;
    font-weight: 600;
    box-sizing: border-box;
    width: max-content;
    border: 1px solid lightgray;
    border-radius: 2.5px;
    padding: 2.5px 5px 2.5px 5px;
    margin-bottom: 5px;
    color: gray;
}

.error-message {
    color: red;
    font-size: 12.5px;
    font-weight: 600;
    box-sizing: border-box;
    padding: 5px;
    border: 1px solid red;
    border-radius: 2.5px;
    margin: 0px 0px 20px 0px;
    align-self: flex-start;
}

.response-message {
    display: flex;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateY(-50%);
    transform: translateX(-50%);
    flex-grow: 1;
}

.success {
    display: flex;
    flex-grow: 1;
    background-color:rgb(153, 202, 60);
    color: white;
    font-size: 20px;
    font-weight: 600;
    box-sizing: border-box;
    padding: 25px;
    border-radius: 5px;
}

.failure {
    display: flex;
    flex-grow: 1;
    background-color: darkred;
    color: white;
    font-size: 20px;
    font-weight: 600;  
    box-sizing: border-box;
    padding: 25px;
    border-radius: 5px; 
}

form {
    box-sizing: border-box;
    padding: 20px;
    border: 1px solid lightgray;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

@media(max-width: 1000px) {
    .integration-dialogue-box {
        padding: 20px 20px 20px 20px;
    }
}


</style>