<template>






</template>

<script>

export default {
    name: "MenuRedirect",
    beforeCreate() {
        console.log(this.$router.currentRoute.params.language === undefined)
        if (this.$router.currentRoute.params.language != undefined) {
           this.$router.push(`/${this.$router.currentRoute.params.menu_id}/${this.$router.currentRoute.params.language}`) 
        } else {
            this.$router.push(`/${this.$router.currentRoute.params.menu_id}`)
        }
    },
    methods: {
        redirect() {
            this.$router.push(`/${this.$router.currentRoute.params.menu_id}`)
        }
    }
}




</script>