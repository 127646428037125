<template>

  <div id="csv-options">

    <div class="option first-option" @click="exportCSV()">
      
      <!-- <img src="@/assets/link-button.svg" /> -->

      <p>Export CSV</p>

    </div>

    <div class="option" @click="csvImport()">
      
      <!-- <img src="@/assets/link-button.svg" /> -->

      <p>Import CSV</p>
      
    </div>


  </div>



</template>


<script>

export default {
  name: "CSVOptions",
  props: ["menu_id"],
  methods: {
    exportCSV() {
      const id = this.$router.currentRoute.params.id;
      this.$http.secured.get(`/app_export_menu_to_csv/${this.menu_id}/menu.csv`)
        .then(response => { 
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'menu.csv');
          document.body.appendChild(link);
          link.click();     
        })
        .catch(error => this.setError(error, 'Something went wrong'))  
      },
    csvImport() {
      this.$router.replace(`/csv_import/${this.menu_id}`);
    }
  }
}



</script>


<style scoped>

#csv-options {
  position: absolute;
  left: 100px;
}

.option {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  box-sizing: border-box;
  min-width: 150px;
  padding: 10px 20px 10px 20px;
}

.option p {
  margin: 0px 0px 0px 0px;
  font-size: 13px;
  font-weight: 600;
}

.option img {
  width: 25px;
  height: 25px;
  margin-right: 20px;
}

.first-option {
  border-bottom: 1px solid lightgray;
}


</style>