<template>

    <div class="current-orders-container" v-if="menusWithSessionBasedOrdering().length > 0">

        <div class="order-sessions active-order-sessions">

            <h3>Active order sessions</h3>

            <div class="sessions-container" v-if="active_order_sessions.length > 0">

                <div v-for="active_order_session in active_order_sessions" :key="active_order_session.id" @click="redirectToOrderSession(active_order_session.id)" class="order-session active-order-session">

                    <div class="table-indicator">
                    
                        <h4>{{ active_order_session.table_number }}</h4>

                    </div>

                    <div>

                        <h5>{{ active_order_session.pax }} guests</h5>

                    </div>

                    <div>

                        <h5 v-if="active_order_session.guest_name != 'none'">{{ active_order_session.guest_name }}</h5>

                    </div>

                    <div>

                        <h6>{{ timestamp(active_order_session.created_at) }}</h6>

                    </div>

                    <div class="filler"></div>

                    <img class="arrow" src="@/assets/orders/arrow_forward_black.svg" />
                    
                </div>  

            </div>

            <div v-else class="no-sessions">
                <img src="@/assets/orders/order_session_icon.svg" />
                <p>No active order sessions right now</p>
            </div>

        </div>

        <div class="order-sessions closed-order-sessions">

            <h3>Closed order sessions</h3>

            <div class="sessions-container" v-if="closed_order_sessions.length > 0">

                <div v-for="closed_order_session in closed_order_sessions" :key="closed_order_session.id" @click="redirectToOrderSession(closed_order_session.id)" class="order-session closed-order-session">
                    
                    <div class="table-indicator">
                    
                        <h4>{{ closed_order_session.table_number }}</h4>

                    </div>

                    <div>

                        <h6>{{ timestamp(closed_order_session.created_at) }}</h6>

                    </div>

                    <div class="filler"></div>

                    <img class="arrow" src="@/assets/orders/arrow_forward_black.svg" />
                    
                </div>  

            </div>

            <div v-else class="no-sessions">
                <p>No closed order sessions in past 24 hours</p>
            </div>

        </div>   

        <div class="create-order-session-button" @click="createOrderSession()">

            <p>Create Order Session</p>

        </div>     

    </div>


</template>


<script>
import moment from 'moment';

export default {
    name: 'OrderSessions',
    data () {
        return {
            active_order_sessions: [],
            closed_order_sessions: [],
            menus: []
        }
    },
    created() {
        this.getOrderSessions();
        this.getMenus();
    },
    methods: {
        getOrderSessions() {
            this.$http.secured.get('/app_get_order_sessions')
                .then(response => { 
                    this.active_order_sessions = response.data.active_order_sessions,
                    this.closed_order_sessions = response.data.closed_order_sessions
                }).catch(error => this.setError(error, 'Something went wrong'))
        },
        getMenus() {
            this.$http.secured.get('/app_get_my_menus')
                .then(response => { this.menus = response.data })
                .catch(error => this.setError(error, 'Something went wrong'))
        },
        redirectToOrderSession(order_session_id) {
            this.$router.replace(`/order_sessions/${order_session_id}`)
        },
        timestamp(orderPlaced) {
            return moment(orderPlaced).format('LT');
        },
        createOrderSession() {
            this.$router.replace('/order_sessions/new')
        },
        menusWithSessionBasedOrdering() {
            return this.menus.filter(menu => menu.session_based_ordering === true);
        }
    }
}



</script>

<style scoped>

.current-orders-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    box-sizing: border-box;
    margin-top: 25px;
    padding-top: 25px;
    border-top: 1px dotted lightgray;

}

.current-orders-container h3 {
    margin: 0px 0px 20px 0px;
    font-weight: 600;
}

.order-sessions {
    display: flex;
    flex-direction: column;
}

.active-order-sessions {
    width: 60%;
    box-sizing: border-box;
    padding-right: 20px;
}

.closed-order-sessions {
    width: 40%;
    box-sizing: border-box;
    padding-left: 20px;
}

.no-sessions {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.no-sessions img {
    width: 25px;
    height: 25px;
    margin-right: 5px;
}

.order-session {
    width: 100%;
    display: flex;
    flex-direction: row;
    /* border: 1px solid black; */
    box-sizing: border-box;
    padding: 20px;
    border-radius: 5px;
    cursor: pointer;
    margin: 0px 0px 20px 0px;

}

.order-session div {
    width: max-content;
    /* border-bottom: 1px dotted black; */
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 20px;
}

.order-session h4 {
    font-weight: 600;
    font-size: 17.5px;
    margin: 0px 0px 0px 0px;
}

.order-session h4 span {
    font-weight: 600;
}

.order-session div h5 {
    font-weight: 600;
    font-size: 12.5px;
    margin: 0px 0px 0px 0px;
}

.order-session div h6 {
    font-weight: 400;
    font-size: 12px;
    /* letter-spacing: 0.5px; */
    margin: 0px 0px 0px 0px;
}

.break {
    width: 5px;
}

.active-order-session {
    background-color: #E6EED4;
}

.closed-order-session {
    background-color: #F0EBE5;
}

.filler {
    flex-grow: 1;
}

.arrow {
    width: 40px;
}

.table-indicator {
    border: 1px solid gray;
    border-radius: 2.5px;
    box-sizing: border-box;
    padding: 0px 10px 0px 10px;
}

.create-order-session-button {
    width: max-content;
    /* background-color: #00a19a; */
    color: #00a19a;
    border: 1px solid #00a19a;
    color: white;
    border-radius: 2.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 0px 20px 0px 20px;
    cursor: pointer;
    margin-top: 50px;
}

.create-order-session-button:hover {
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
    transition: 0.2s;
}

.create-order-session-button p {
    font-weight: 600;
    font-size: 12.5px;
    color: #00a19a;
}

@media(max-width: 800px) {
    .active-order-sessions {
        width: 100%;
        padding: 0px;
    }

    .closed-order-sessions {
        width: 100%;
        padding: 0px;
        margin-top: 50px;
    }
}

</style>