<template>

    <CreateTable :editing="true" :id="this.$router.currentRoute.params.id" />

</template>

<script>
import CreateTable from '@/components/orders/CreateTable.vue'

export default {
    name: 'EditTable',
    components: {
        CreateTable
    }
}


</script>

<style scoped>




</style>