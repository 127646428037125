<template>

  <div v-bind:class="{ 'order-info-container' : session_order != true }" class="container">

    <div v-if="this.is_loading === true" class="loader">

      <div class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>

    </div>

    <div v-else>

      <div class="export-status" v-if="status != ''" @click="closeStatus()">
        <div class="close"><img src="@/assets/orders/close_new.svg" /></div>{{ status }}
      </div>

      <transition name="fade">

        <div class="integration-box" v-if="menu.vhp_integration === true">

          <ExportToVHP v-if="exported === false" :order_rows="order_rows" :table_number="table_number" :foods="foods"
            :languages="languages" :menu="menu" :pax="pax" :order="order_info" v-on:exported="setStatusToExported()" />

          <div class="export-complete" v-if="exported === true">

            Order successfully exported to VHP
          </div>

        </div>

      </transition>

      <div id="section-to-print">

        <div class="order-header" :class="{ 'order-header-no-border' : session_order === true }"
          v-if="combined_order != true">

          <h1>

            <span>

              #{{ order_id }} {{ order_type }}

            </span>

            <a class="menu-box"
              @click="navigateToMenuSettings(order_info.menu_id)">

              <div v-if="order_info.menu_restaurant_name != null && order_info.menu_restaurant_name != ''">
                <img class="menu-logo" v-bind:src="order_info.menu_logo.url" />
                {{ order_info.menu_restaurant_name }}
              </div>

              <div v-else>
                <img class="menu-logo" v-bind:src="order_info.menu_logo.url" />
                {{ order_info.menu_name }}
              </div>

            </a>

          </h1>

          <p>Placed: <span>{{ timestamp(created_at) }}</span> </p>

          <div class="order-header-details">

            <div v-if="order_type === 'in-restaurant'">

              <p><img src="@/assets/table.svg" />Table number:<span>{{ table_number }}</span></p>

              <p><img src="@/assets/pax.svg" />Pax: <span>{{ pax }}</span> </p>

            </div>

            <div v-if="order_type === 'delivery'">

              <p><img src="@/assets/icon_recipient.svg" />Name:<span>{{ this.order_info.order_data.name }}</span></p>

              <p><img src="@/assets/location.svg" />Address:<span>{{ this.order_info.order_data.physical_address
                  }}</span></p>

              <p><img src="@/assets/phone_new.svg" />Phone:<span>{{ this.order_info.order_data.phone_number }}</span>
              </p>

            </div>

            <div v-if="order_type === 'pickup'">

              <p><img src="@/assets/icon_recipient.svg" />Name:<span>{{ this.order_info.order_data.name }}</span></p>

              <p><img src="@/assets/phone_new.svg" />Phone:<span>{{ this.order_info.order_data.phone_number }}</span>
              </p>

            </div>

            <div v-if="order_type === 'in-room'">

              <p><img src="@/assets/room_number.svg" />Room number:<span>{{ this.order_info.order_data.room_number
                  }}</span></p>

              <p><img src="@/assets/icon_recipient.svg" />Surname:<span>{{ this.order_info.order_data.last_name
                  }}</span></p>

            </div>

            <div v-if="order_info.order_data.payment_type === 'stripe'">

              <p class="paid-tag">Paid</p>

              <p class="payment-id-tag">Payment ID: <span>{{ this.order_info.order_data.payment_intent }}</span></p>

            </div>

          </div>

        </div>

        <div class="order-rows-container" :class="{ 'order-rows-container-no-border' : session_order === true}">

          <div class="headers">

            <p class="column-type">Item</p>

            <p class="column-type">Price</p>

            <p class="column-type">Quantity</p>

          </div>

          <div class="order-row" v-for="order_row in order_rows" :key="order_row.id">

            <div class="order-row-basic-info">

              <span v-if="order_row.bundle_item === true" class="bundle-item">Bundle</span>

              <div class="timestamp" v-if="session_order === true">

                Submitted {{ timeOnlyTimestamp(order_row.created_at ) }}

              </div>

              <p class="item-name" v-if="order_row.item_name != '' && order_row.item_name != null">{{
                order_row.item_name }}</p>

              <p class="item-name" v-else>{{ getItemName(order_row.food_id) }}</p>

              <p v-if="order_row.item_unit !== null && order_row.item_unit != 'none'" class="item-selections">{{
                order_row.item_unit }}</p>

              <p v-if="checkIfBasePriceDoesNotMatch(order_row.item_base_price, order_row.item_price)"
                class="item-base-price">Base price: <span>{{ currency }} {{ numberFormat(order_row.item_base_price)
                  }}</span></p>

              <p v-if="order_row.selection_name !== null" class="item-selections">{{ order_row.item_selection_name }}
              </p>

              <div class="item-selections-container"
                v-if="order_row.selections != null && order_row.selections != '' && (order_row.item_selection_name === null || order_row.item_selection_name === '')">

                <div v-for="selection in order_row.selections" :key="selection" class="selection">

                  <div>
                    <p>{{ getSelectionName(Number(selection)) }}</p>
                    <p v-if="getSelectionPrice(Number(selection)) != null && getSelectionPrice(Number(selection)) != 0">
                      + {{ currency}} {{numberFormat(getSelectionPrice(Number(selection))) }}</p>

                  </div>

                </div>

              </div>

              <p v-if="order_row.item_note.length > 0" class="item-selections"><img src="@/assets/note_icon.svg" />{{
                order_row.item_note.toString() }}</p>

            </div>

            <p v-if="order_row.item_price !== null" class="item-price">{{ currency }} {{
              numberFormat(order_row.item_price) }}</p>

            <p class="item-quantity">{{ order_row.item_quantity }}</p>

          </div>

        </div>

        <div v-if="subtotal != grand_total" class="order-rows-container price-info-container">

          <p class="price-info price-info-subtotal">Subtotal: {{ currency }} {{ numberFormat(subtotal) }}</p>

          <p v-if="discount_amount != 0 && discount_amount != null" class="price-info">Discount: {{ currency }} {{
            numberFormat(discount_amount) }}</p>

          <p v-if="calculated_order_tax != 0" class="price-info">Tax ({{ tax }}%): {{ currency }} {{
            numberFormat(calculated_order_tax) }}</p>

          <p v-if="calculated_order_service != 0" class="price-info">Service ({{ service }}%): {{ currency }} {{
            numberFormat(calculated_order_service) }}</p>

          <p v-if="calculated_order_packaging_fee != 0" class="price-info">Packaging fee ({{ packaging }}%): {{ currency
            }} {{ numberFormat(calculated_order_packaging_fee) }}</p>

          <p v-if="delivery_fee != 0 && order_type === 'delivery'" class="price-info"> Delivery fee: {{ currency }} {{
            numberFormat(delivery_fee) }}</p>

        </div>

        <div class="order-rows-container grand-total-container">

          <p>Grand total: {{currency}} {{ numberFormat(grand_total) }}</p>

        </div>

      </div>

      <div v-if="combined_order != true">

        <div v-if="order_open === true" class="order-status open" @click="openCloseOrder()">

          <h5>Order open</h5>
          <p>Tap here to close</p>

        </div>

        <div v-else class="order-status closed" @click="openCloseOrder()">

          <h5>Order closed</h5>
          <p>Tap here to open</p>

        </div>

        <div class="print-button" @click="print()" v-if="session_order != true">PRINT ORDER</div>

        <div class="print-button" @click="redirectToOrder(order.id)" v-if="session_order === true">Go to order page to
          print order</div>

        <div class="explainer" @click="navigateToPrintInstructions()" v-if="session_order != true">
          <p class="question-mark">?</p>
          <div class="explainer-text">
            <h3>Having trouble?</h3>
            <p>Tap here for printing instructions</p>
          </div>
        </div>


        <div v-if="menu_builder_v2 === true" class="customer-notif-container" @click="notifyCustomer()"
          :class="{ 'notified' : customer_notified === true, 'not-notified' : customer_notified === false }">

          <div v-if="customer_notified === true">

            Customer notified successfully that order is on the way

          </div>

          <div v-else>

            Notify customer that order is on the way

          </div>

        </div>



        <a class="export-to-csv" @click="exportToCSV()" v-if="session_order != true">Export to CSV</a>

        <div class="delete-button" @click="deleteOrder(orderId())" v-if="session_order != true">Delete this order</div>

      </div>

    </div>

  </div>





</template>


<script>
import moment from 'moment'
import { EventBus } from '@/event-bus.js'
import ExportToVHP from '@/components/orders/ExportToVHP.vue'

export default {
    name: 'OrderRestaurantView',
    props: ['session_order', 'session_order_id', 'order', 'additional_order_rows', 'additional_selections', 'combined_subtotal', 'combined_tax', 'combined_service', 'combined_grand_total', 'combined_order'],
    components: {
        ExportToVHP
    },
    data () {
        return {
            order_info: {},
            is_loading: true,
            order_id: '',
            order_type: '',
            created_at: '',
            table_number: '',
            pax: '',
            menu: [],
            tax: 0,
            calculated_order_tax: 0,
            service: 0,
            calculated_order_service: 0,
            packaging: 0,
            calculated_order_packaging_fee: 0,
            subtotal: 0,
            delivery_fee: 0,
            grand_total: 0,
            order_open: Boolean,
            currency: '',
            order_rows: [],
            discount_amount: 0,
            foods: [], 
            foods_from_connected_outlets: [],
            languages: [],
            selections: [],
            user_type: '',
            menu_builder_v2: Boolean,
            customer_notified: Boolean,
            url: process.env.VUE_APP_API_URL,
            exported: Boolean,
            status: ''
        }
    },
    created() {
        this.$http.secured.get('/app_current_user')
            .then(response => { this.currentUser = response.data.user,
                this.user_type = response.data.user_type })
            .catch(error => this.setError(error, 'Something went wrong'))
    },
    mounted () {
        if (!localStorage.signedIn) {
            this.$router.replace('/')
        } else {
            let id = Number
            if (this.session_order === true) {
                console.log(`SESSION ORDER ID ${this.session_order_id}`)
                id = this.session_order_id
            } else {
                id = this.$router.currentRoute.params.id;
            }
            this.$http.secured.get("/app_show_order/" + id)
            .then(response => { this.order_info = response.data,
                this.order_id = response.data.order_data.id,
                this.menu = response.data.menu,
                this.order_type = response.data.order_data.order_type,
                this.created_at = response.data.order_data.created_at,
                this.customer_notified = response.data.order_data.on_the_way,
                this.table_number = response.data.order_data.table_number,
                this.pax = response.data.order_data.pax,
                this.currency = response.data.order_data.currency,
                this.tax = response.data.order_data.tax,
                this.calculated_order_tax = response.data.order_data.calculated_order_tax,
                this.service = response.data.order_data.service,
                this.calculated_order_service = response.data.order_data.calculated_order_service,
                this.packaging = response.data.order_data.packaging_fee,
                this.calculated_order_packaging_fee = response.data.order_data.calculated_order_packaging_fee,
                this.subtotal = response.data.order_data.price,
                this.grand_total = response.data.order_data.grand_total,
                this.delivery_fee = response.data.order_data.delivery_fee,
                this.order_open = response.data.order_data.open,
                this.order_rows = response.data.order_rows,
                this.discount_amount = response.data.order_data.discount,
                this.order_rows = this.findUnique(this.order_rows),
                this.foods = response.data.foods,
                this.foods_from_connected_outlets = response.data.foods_from_connected_outlets,
                this.selections = response.data.selections,
                this.languages = response.data.languages,
                this.menu_builder_v2 = response.data.menu_builder_v2,
                this.exported = response.data.order_data.posted_to_vhp,
                this.combineOrderRows();
                this.combineConnectedFoods();
                EventBus.$emit('order_seen', this.orderSeen);
                this.is_loading = false
            })
            .catch(error => this.setError(error, 'Something went wrong'))

            this.$http.secured.post("/app_acknowledge_order/" + id)
            .catch(error => this.setError(error, 'Something went wrong'))
            
        }
    },
    methods: {
        setError (error, text) {
            this.error = (error.response && error.response.data && error.response.data.error) || text
      },
      navigateToMenuSettings(menuId) {
        this.$router.push('/menu_settings/' + menuId);
      },
        combineOrderRows() {
            if (this.additional_order_rows != null) {
                this.order_rows = this.findUnique(this.additional_order_rows)
            }
            if (this.additional_selections != null) {
                this.selections = this.additional_selections
            }
            if (this.combined_subtotal != null) {
                this.subtotal = this.combined_subtotal
            }
            if (this.combined_tax != null) {
                this.calculated_order_tax = this.combined_tax
            }
            if (this.combined_service != null) {
                this.calculated_order_service = this.combined_service
            }
            if (this.combined_grand_total != null) {
                this.grand_total = this.combined_grand_total
            }
        },
        print() {
            window.print()
        },
        timestamp(orderPlaced) {
            return moment(orderPlaced).format('LLL');
        },
        timeOnlyTimestamp(orderPlaced) {
            return moment(orderPlaced).format('HH:mm') 
        },
        checkIfBasePriceDoesNotMatch(basePrice, computedPrice) {
            if (basePrice != computedPrice && basePrice != null) {
                return true
            } else {
                return false
            }
        },
        setStatusToExported() {
            this.exported = true
            this.status = "Exported Successfully"
        },
        numberFormat(number) {
            if (this.currency === 'OMR') {
                return Number(number).toFixed(3)
            } else if (this.currency == 'L') {
                  return Number(number).toLocaleString('de-DE', { minimumFractionDigits: 2 })
            } else if (this.currency === 'Rp') {
                return Number(number).toLocaleString()
            } else {
                return Number(number).toFixed(2)
            }
        },
        orderId() {
            return this.$router.currentRoute.params.id
        },
        findUnique(array) {
            const result = [];
            const map = new Map();
            for (const item of array) {
                let quantity = 0
                let food_id = item.food_id
                let selection_name = item.selection_name
                let unit = item.unit
                let note = []
                let parsedSelections = []
                for (let i = 0; i < array.length; i++) {  
                    if ((array[i].food_id === item.food_id) && (array[i].selections === item.selections) && (array[i].selection_name === item.selection_name) && (array[i].unit === item.unit)) quantity++, note.push(array[i].note)
                }
                if (item.selections != null && item.selections != [] && item.selections != '') {
                    parsedSelections = item.selections.split(",")
                } else {
                    parsedSelections = []
                }
                
                note = note.filter(n => n)
                if(!map.has(item.food_id + item.selection_name + item.unit + item.selections)) {
                    map.set((item.food_id + item.selection_name + item.unit + item.selections), true); // set any value to Map
                    result.push({
                        food_id: item.food_id,
                        item_name: item.item_name,
                        item_quantity: quantity,
                        item_selection_name: item.selection_name,
                        selections: parsedSelections,
                        item_price: item.item_price,
                        item_base_price: item.base_price,
                        item_unit: item.unit,
                        bundle_item: item.bundle_item,
                        item_note: note,
                        created_at: item.created_at
                    });
                }
            }
            return result.sort((a, b) => new Date(a.created_at) - new Date(b.created_at)).reverse()
        },
        openCloseOrder() {
            this.checkOrderStatus()
            let id = this.$router.currentRoute.params.id;
            this.$http.secured.post("/app_toggle_open_close_order/" + id)
            .then(response => {  })
            .catch(error => this.setError(error, 'Something went wrong'))
        },
        checkOrderStatus() {
            if (this.order_open === true) {
                this.order_open = false
            } else {
                this.order_open = true
            }
        },
        notifyCustomer() {
            let id = Number
            if (this.session_order === true) {
                id = this.session_order_id
            } else {
                id = this.$router.currentRoute.params.id;
            }
            if (this.customer_notified === false) {
                this.$http.secured.post("/app_set_order_to_on_the_way/" + id)
                    .then(response => { this.customer_notified = true })
                    .catch(error => this.setError(error, 'Something went wrong'))                
            }
        },
        combineConnectedFoods() {
            const foodsFromConnectedOutlets = this.foods_from_connected_outlets
            for (let i = 0; i < foodsFromConnectedOutlets.length; i++) {
                this.foods.push(foodsFromConnectedOutlets[i])
            }
        },
        getItemName(order_row_id) {            
            const foods = this.foods
            const result = this.foods.filter(food => food.id == order_row_id);
            if (this.languages.includes('en')) {
                return result[0]['translation_en'] || result[0]['name']
            } else {
                return result[0]['translation_' + this.languages[0]] || result[0]['name']
            }
        },
        deleteOrder(order_id) {
            if(confirm("Are you sure you want to delete this order?")) {
                this.$http.secured.delete("/app_order_destroy/" + order_id)
                .then(response => { 
                    this.$router.replace('/')
                })
                .catch(error => this.setError(error, 'Something went wrong'))
            }
        },
        closeStatus() {
            this.status = ''
        },
        exportToCSV() {
            this.$http.secured.get(this.url + '/app_export_line_items_to_csv/' + this.orderId() + '/order_info.csv')
                .then(response => { 
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'order.csv');
                    document.body.appendChild(link);
                    link.click();         
                })
                .catch(error => this.setError(error, 'Something went wrong'))
        },
       getSelectionName(selectionId) {
            var selection = this.selections.filter(selection => selection.id == selectionId)
            return selection[0][`name_${this.language}`] || selection[0][`name`] || selection[0][`name_en`]
        },
        getSelectionPrice(selectionId) {
           var selection = this.selections.filter(selection => selection.id == selectionId)
            return Number(selection[0].additional_fee)           
        },
        navigateToPrintInstructions() {
            this.$router.replace('/printing')
        },
        redirectToOrder(order_id) {
            this.$router.replace(`/orders/${order_id}`)
        }
    }
}



</script>

<style scoped>

.loader {
    height: 80vh;
}

.order-info-container {
    max-width: 1200px;
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
    margin-top: 50px;
}

.container {
    margin-top: 100px;
}

.delete-button {
    margin-top: 50px;
}

.order-header {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid lightgray;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 25px;
}

.order-header-no-border {
    border: 0px;
    border-radius: 0px;
    border-bottom: 1px dotted lightgray;
    padding: 0px;
    padding-bottom: 40px;
}

.order-header h1 {
    font-size: 25px;
    font-weight: 600;
    margin: 0px 0px 10px 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.order-header h1 span {
    margin: 5px 5px 5px 0px;
}


.order-header h1 div {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid lightgray;
    font-size: 12.5px;
    font-weight: 600;
    align-self: flex-end;
    /* margin-left: 10px; */
    box-sizing: border-box;
    padding: 2.5px 5px 2.5px 5px;
    border-radius: 5px;
    cursor: pointer;
    height: 35px;
    /* margin: 10px 0px 10px 0px; */
}

.order-header h1 div:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    transition: 0.2s;
}

.order-header h1 div img {
    max-height: 25px;
    max-width: 25px;
    margin-right: 5px;
}

.order-header p {
    font-size: 13px;
    margin: 15px 0px 0px 0px;
    display: flex;
    align-items: center;
}

.order-header p span {
    font-weight: 600;
    margin-left: 5px;
}

.order-header-details {
    display: flex;
    flex-direction: column;
}

.order-header-details p {
    margin-right: 10px;
}

.order-header-details img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.order-rows-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid lightgray;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 25px;
}

.order-rows-container-no-border {
    border: 0px;
    padding: 0px;
}

.headers {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    justify-content: space-between;
}

.headers p {
    font-size: 11px;
    text-transform: uppercase;
    margin: 0px 0px 0px 0px;
    font-weight: 400;
    display: flex;
    /* border: 1px dotted lightgray; */
}

.headers p:first-child {
    width: 60%;
}

.headers p:nth-child(2), .headers p:nth-child(3) {
    width: 20%;
    justify-content: center;
}

.order-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0px 0px 20px 0px;
}

.item-price, .item-quantity {
    width: 20%;
    margin-top: 0px;
    /* border: 1px dotted lightgray; */
    display: flex;
    justify-content: center;
    font-size: 13px;
    font-weight: 600;
}

.order-row-basic-info {
    display: flex;
    flex-direction: column;
    width: 60%;
    box-sizing: border-box;
    padding-right: 10%;
}

.item-name {
    font-size: 15px;
    margin: 0px 0px 5px 0px;
    font-weight: 600;
}

.item-base-price {
    font-size: 12px;
    margin: 5px 0px 5px 0px;
}

.item-base-price span {
    font-weight: 600;
}

.item-selections {
    font-size: 12px;
    margin: 5px 0px 5px 0px;
    display: flex;
    /* align-items: center; */
}

.item-selections img {
    width: 15px;
    height: 15px;
    margin-right: 5px;
}

.price-info-container {
    align-items: flex-end;
}

.price-info {
    font-size: 13px;
    margin: 0px 0px 0px 0px;
    box-sizing: border-box;
    padding: 5px 0px 5px 0px;
}

.price-info-subtotal {
    border-bottom: 1px dotted lightgray;
    font-weight: 600;
}

.grand-total-container {
    background-color: rgb(60, 60, 59);
    align-items: flex-end;
    color: white;
    font-size: 15px;
    font-weight: 600;
    border: 1px solid rgb(60, 60, 59);
}

.order-status {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* border: 1px solid lightgray; */
    /* height: 100px; */
    box-sizing: border-box;
    padding: 15px;
    align-items: center;
    cursor: pointer;
    border-radius: 10px;
}

.order-status h5 {
    font-weight: 600;
    margin: 0px 0px 5px 0px;
    font-size: 17.5px;
}

.order-status p {
    font-weight: 400;
    margin: 0px 0px 0px 0px;
    font-size: 12.5px;
}

.open {
    background-color: #fbaf3f;
    /* color: white; */
}

.closed {
    background-color: lightgray;
    color: gray;
}

.close {
    width: 10px;
    height: 10px;
    margin-left: 0px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.export-status {
    position: fixed;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    width: max-content;
    max-width: 90%;
    left: 50%;
    top: 130px;
    background-color: #F7931D;
    color: black;
    border: 1px solid #F7931D;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    box-sizing: border-box;
    padding: 5px 20px 5px 20px;
    background-color: white;
    font-weight: 600;
    font-size: 12.5px;
    transform: translate(-50%, -50%);
    cursor: pointer;
    z-index: 100000;
}

.bundle-item {
    border: 1px solid lightgray;
    color: black;
    font-size: 10px;
    font-weight: 600;
    box-sizing: border-box;
    padding: 2.5px;
    border-radius: 2.5px;
    width: 52.5px;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.paid-tag {
    border: 1px solid gray;
    box-sizing: border-box;
    padding: 5px;
    border-radius: 2.5px;
    font-weight: 600;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.item-selections-container {
    display: flex;
    flex-direction: column;
    font-size: 11px;
    font-weight: 600;
}

.selection {
    display: flex;
    flex-direction: column;
    margin: 0px 0px 5px 0px;
}

.selection div {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.selection div p {
    margin: 0px 5px 0px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.export-complete {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: gray;
    background-color: lightgray;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 30px;
    cursor: pointer;
}

.print-button {
    width: 100%;
    margin-top: 50px;
    border: 1px solid #4394ce;
    color: #4394ce;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    font-weight: 600;
    cursor: pointer;
    border-radius: 5px;
}

.explainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    cursor: pointer;
}

.explainer-text {
    display: flex;
    flex-direction: column;
    font-size: 13px;
    margin-left: 20px;
}

.explainer-text h3 {
    margin: 0px 0px 5px 0px;
    font-weight: 600;
}

.explainer-text p {
    margin: 0px 0px 0px 0px;
}

.question-mark {
    font-weight: 600;
    background-color: black;
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
}

.customer-notif-container {
    width: 100%;
    margin: 50px 0px 50px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-weight: 600;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 10px 10px 10px 0px;
    cursor: pointer;
}

.not-notified {
    background-color: #9E1E62;
    color: white;
    
}

.not-notified:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.notified {
    background-color: white;
    color: gray;
    border: 1px solid lightgray;
    text-align: center;
    font-size: 13px;
}

.timestamp {
    background-color: lightgray;
    font-size: 10px;
    box-sizing: border-box;
    padding: 2.5px 5px 2.5px 5px;
    width: max-content;
    border-radius: 2.5px;
    margin-bottom: 2.5px;
    font-weight: 600;
}

.export-to-csv {
    width: 100%;
    border: 1px solid lightgray;
    margin-top: 50px;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12.5px;
    /* margin-bottom: 100px; */
}

.delete-button {
  border: 1px solid darkred;
  box-sizing: border-box;
  padding: 2.5px;
  border-radius: 2.5px;
  font-size: 10px;
  display: inline-flex;
  color: darkred;
  cursor: pointer;
}

.delete-button:hover {
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
  transition: 0.2s;
}

.export-to-csv:hover {
    cursor: pointer;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
    transition: 0.2s;
}


@media print {
    body * {
        visibility: hidden;
    }

    #section-to-print p {
        font-size: 14px;
    }

    .column-type {
        font-size: 8px !important;
    }

    #section-to-print h1 {
        font-size: 14px
    }

    #section-to-print div {
        border: 0px;
        padding: 0px;
        background-color: white;
        color: black;
    }

    #section-to-print, #section-to-print * {
        visibility: visible;
    }
    #section-to-print {
        position: absolute;
        left: 0;
        top: 0;
    }
}


</style>