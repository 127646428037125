<template>


    <div id="additional-services">

        <h3 class="gradient-text" v-html="$homepageTranslations.additional_services.header[$store.getters.getDefaultLanguage]"></h3>

        <p class="service" v-html="$homepageTranslations.additional_services.menu_translation[$store.getters.getDefaultLanguage]"></p>

        <p class="service" v-html="$homepageTranslations.additional_services.menu_design[$store.getters.getDefaultLanguage]"></p>

        <p class="service" v-html="$homepageTranslations.additional_services.marketing_design[$store.getters.getDefaultLanguage]"></p>

        <p class="service" v-html="$homepageTranslations.additional_services.custom_menu_site[$store.getters.getDefaultLanguage]"></p>

        <div class="enquire" @click="$router.push('/contact')">Enquire</div>

    </div>









</template>

<script>

export default {
    name: "AdditionalServices"
}



</script>

<style scoped>

#additional-services {
    background-color: #3B3B3A;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 50px 5% 50px 5%;
}

h3 {
    text-align: center;
    font-size: 40px;
}

.service {
    font-family: "Cabin", sans-serif;
    color: white;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 17.5px;
    text-align: center;
    margin: 0px 0px 25px 0px;
}

.enquire {
    border: 2px solid rgb(153, 202, 60);
    color: rgb(153, 202, 60);
    box-sizing: border-box;
    padding: 10px;
    min-width: 500px;
    margin: auto;
    border-radius: 20px;
    text-align: center;
    font-weight: 600;
    margin-top: 50px;
    cursor: pointer;
    font-size: 20px;
}

@media(max-width: 600px) {
    .enquire {
        width: 95%;
        min-width: 200px;
    }
}

</style>