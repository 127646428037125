<template>

    <div class="sidebar-container">

        <p>Step {{ current_step }} of {{ total_steps }}</p>

    </div>



</template>


<script>

export default {
    name: 'Sidebar',
    props: ['current_step', 'total_steps'],
    data () {
        return {

        }
    }
}

</script>


<style scoped>

.sidebar-container {
    width: 100%;
    min-height: 100%;
    /* background-color: #078E45; */
    background-image: linear-gradient(45deg, rgb(7, 142, 69), rgb(11, 66, 36)); 
    box-sizing: border-box;
    padding: 25px;
    color: white;
    display: flex;
    flex-direction: column;
    /* justify-content: flex-end; */
    z-index: 1;
}

p {
    font-size: 13px;
    font-weight: 600;
}

@media(max-width: 700px) {
    p {
        text-align: center;
        color: #078E45;
        border: 1px solid #078E45;
        box-sizing: border-box;
        padding: 5px;
        border-radius: 5px;
        width: max-content;
    }

    .sidebar-container {
        flex-grow: 1;
        justify-content: center;
        /* align-self: flex-end; */
        background-image: none;
        background-color: white;
        flex-direction: row;
        width: 100%;
    }

}



</style>