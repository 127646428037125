<template>

    <div class="testimonial">

        <img src="@/assets/homepage/quotes_white.svg" />

        <p class="tagline">{{ testimonial.tagline_en }}</p>

        <p class="content">{{ testimonial.content_en }}</p>

        <p class="name">{{ testimonial.name }}</p>

        <p class="title">{{ testimonial.title_en }}</p>

        <p class="attribution">{{ testimonial.company }}</p>

        <p class="attribution">{{ testimonial.location }}</p>

    </div>






</template>

<script>

export default {
    name: "Testimonial",
    props: ['testimonial']
}






</script>

<style scoped>

.testimonial {
    /* width: 1000px !important; */
    margin: 0px 2.5% 0px 2.5%;
    display: flex;
    flex-direction: column;
    /* box-shadow: 0 0 32px rgba(0, 0, 0, 0.1); */
    box-sizing: border-box;
    padding: 50px;
    /* height: 500px; */
    min-width: 45%;
    font-family: "Cabin", sans-serif;
    letter-spacing: 0.5px;
    border-radius: 10px;
    background: linear-gradient(to bottom, rgb(105, 70, 141), rgb(67, 42, 106));
    color: white;

}

.testimonial img {
    width: 40px;
    height: 40px;
}

.tagline {
    font-size: 30px;
    font-weight: 700;
}

.name {
    font-size: 20px;
    font-weight: 600;
    margin: 20px 0px 5px 0px;
}

.title {
    margin: 0px 0px 20px 0px;
}

.attribution {
    font-weight: 700;
    margin: 0px 0px 5px 0px;
    color: #f39200;
}

@media(max-width: 800px) {
    .testimonial {
        min-width: 95%;
        align-self: center;
    }
}
</style>