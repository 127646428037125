<template>


    <div id="pricing">
        
        <HomeNavbar :sticky_navbar="scrolled_from_top" :transparent_navbar="true" v-if="signed_in != true" />

        <div class="pricing-content">

            <h1 v-html="$homepageTranslations.pages.pricing.header[$store.getters.getDefaultLanguage]"></h1>

            <h2 v-html="$homepageTranslations.pages.pricing.subhead_intl[$store.getters.getDefaultLanguage]"></h2>

        </div>

        <PricingDetails :signed_in="signed_in" :user_email="user_email" :user_id="user_id" />

        <ContactUs />

        <div class="filler"></div>

        <AdditionalServices />

        <HomeFooter />

    </div>







</template>

<script>
import HomeNavbar from '@/components/home/HomeNavbar.vue'
import PricingDetails from '@/components/pricing/PricingDetails.vue'
import ContactUs from '@/components/home/ContactUs.vue'
import AdditionalServices from '@/components/home/AdditionalServices.vue'
import HomeFooter from '@/components/home/HomeFooter.vue'

export default {
    name: "Pricing",
    metaInfo: {
        title: "Pricing | Menulingua Digital Menus",
        // titleTemplate: null,
        meta: [
            {
                name: 'description',
                content: "Get started building your Menulingua QR code menu for just $5 per month. Dynamic and orderable menus from $21 per month.",
                keywords: 'menu, digital, accessible, guest friendly, pricing, subscription, own device, qr code'
            }
        ],
        htmlAttrs: {
        lang: 'en'
        },
        link: [
            {rel: 'canonical', href: 'https://menulingua.com/pricing'}
        ]
    },
    components: {
        HomeNavbar,
        PricingDetails,
        ContactUs,
        AdditionalServices,
        HomeFooter
    },
    data () {
        return {
            scrolled_from_top: false,
            signed_in: Boolean,
            user_email: "",
            user_id: ""
        }
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
        this.getLanguage();
        this.scrollToTop();
        this.checkSignedIn();
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0,0);
        },
        checkSignedIn() {
            if (localStorage.signedIn) {
                this.signed_in = true
                this.getCurrentUser();
            } else {
                this.signed_in = false
            }
        },
        getCurrentUser() {
            this.$http.secured.get('/app_current_user')
                .then(response => { this.user_email = response.data.email, this.user_id = response.data.user })
                .catch(error => this.setError(error, 'Something went wrong'))
        },
        handleScroll (event) {
            var target = document.querySelector('#home-navbar');
            if (window.scrollY >= target.offsetHeight + 30) {
                this.scrolled_from_top = true
            } else if (window.scrollY < target.offsetHeight + 30) {
                this.scrolled_from_top = false
            }
        },
        getLanguage() {
            if (localStorage.default_language === undefined) {
                this.$store.commit('setDefaultLanguage', 'en')
                localStorage.setItem('default_language', 'en')
            } else {
                this.$store.commit('setDefaultLanguage', localStorage.default_language)
            }
        }
    }
}





</script>

<style scoped>

.pricing-content {
    box-sizing: border-box;
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding: 100px 5% 0px 5%;
    background: linear-gradient(to bottom, rgb(105, 70, 141), rgb(67, 42, 106));
}

h1 {
    color: white;
    font-size: 40px;
    text-align: center;
    font-weight: 600;
    margin-top: 50px;
}

h2 {
    color: white;
    font-family: "Cabin", sans-serif;
    font-weight: 400;
    font-size: 20px;
    letter-spacing: 0.5px;
    max-width: 600px;
    margin: auto;
    line-height: 30px;
    margin-bottom: 200px;
    text-align: center;
}

.filler {
    height: 100px;
}



</style>