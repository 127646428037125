<template>

    <div id="subscription-status">

        <div v-if="is_loading == true" class="loader">
            
            <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>

        </div> 

        <div v-else>

            <div class="overlay">

                <img src="@/assets/banner-overlay.svg" />

            </div>

            <AccountStatus :user_type="user_type" :admin_or_dashboard_user="admin_or_dashboard_user" :currentUser="currentUser" :email="email" :latest_subscription_status="latest_subscription_status" :current_plan_expiry_date="current_plan_expiry_date" :cancellation_requested="cancellation_requested" :subscription_payment_provider="subscription_payment_provider" :key="componentKey" />

            <div class="account-info" @click="goToAccountInfo()">
                <img src="@/assets/account_icon.svg"/>
                <p>{{ email }}</p>
                <div class="admin-user" v-if="user_type === 'admin'">Admin</div>
            </div>

            <div class="contact-us" @click="goToContactUs()">

                <p>Have any questions?</p>

                <div>

                    <img src="@/assets/homepage/contact_icon.svg" />

                    <p><span>Contact us</span>. Our support team is here to help.</p>

                </div>


            </div>
            
            <div class="cancel" v-if="cancellation_requested === false && admin_or_dashboard_user != true" @click="cancel()">

                Cancel my subscription

            </div>

            <div class="uncancel" v-if="cancellation_requested === true" @click="uncancel()">

                Keep my menus active

            </div>

        </div>

    </div>

</template>


<script>
import AccountStatus from '@/components/AccountStatus.vue'

export default {
    name: "SubscriptionSettings",
    components: {
        AccountStatus
    },
    data () {
        return {
            url: process.env.VUE_APP_API_URL,
            user_type: '',
            currentUser: '',
            email: '',
            latest_subscription_status: '',
            current_plan_expiry_date: '',
            cancellation_requested: Boolean,
            componentKey: 0,
            admin_or_dashboard_user: Boolean,
            subscription_payment_provider: '',
            admin_outlet_collections_selected: false,
            is_loading: true       
        }
    },
    created() {
        this.getUserInfo()
    },
    methods: {
        getUserInfo() {
            if (localStorage.signedIn) {
                this.$http.secured.get('/app_current_user')
                    .then(response => { 
                        this.currentUser = response.data.user,
                        this.user_type = response.data.user_type,
                        this.email = response.data.email,
                        this.latest_subscription_status = response.data.latest_subscription_status,
                        this.current_plan_expiry_date = response.data.current_plan_expiry_date,
                        this.cancellation_requested = response.data.cancellation_requested,
                        this.admin_or_dashboard_user = response.data.admin_or_dashboard_user,
                        this.subscription_payment_provider = response.data.subscription_payment_provider,
                        this.$store.commit('setLoggedIn', response.data.user)
                        this.$store.commit('setUserType', response.data.user_type)
                        this.$store.commit('setUserEmail', response.data.email)
                        this.componentKey++
                        this.is_loading = false
                    })
                .catch(error => console.log(error))
            } else {
                this.$router.replace('/login')
            }
        },
        cancel() {
            if(confirm(`Are you sure you want to cancel your subscription?`)) {
                this.is_loading = true
                this.$http.secured.post("/app_request_cancellation")
                .then(response => { 
                    console.log('cancellation requested')
                    this.cancellation_requested = true
                    this.is_loading = false;
                    this.$store.commit('setMessage', { 
                            content: "We are sad to see you go! Your menus will remain active until the current billing period expires."
                        }
                    )
                })
                .catch(error => console.log(error))
            }
        },
        uncancel() {
            this.is_loading = true
            this.$http.secured.post("/app_request_uncancellation")
                .then(response => { 
                    console.log('subscription restored')
                    this.cancellation_requested = false
                    this.is_loading = false;
                    this.$store.commit('setMessage', { 
                            content: "We are glad you've decided to stay! Your account will auto renew at the end of the current billing period."
                        }
                    )
                })
                .catch(error => console.log(error))           
        },
        goToAccountInfo() {
            this.$router.push('/account')
        },
        goToContactUs() {
            this.$router.push('/contact')
        }
    }
}




</script>

<style scoped>

#subscription-status {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    position: relative;
    flex-direction: column;
    margin-top: 75px;
    min-height: 80vh;
}

.contact-us {
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 100;
    position: relative;
    /* flex-wrap: wrap; */
    max-width: 95%;
    margin: auto;
    margin-top: 100px;
    /* border: 1px dotted lightgray; */
    cursor: pointer !important;
}

.contact-us p {
    font-weight: 600;
    width: 100%;
}

.contact-us div {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.contact-us div p {
    font-size: 2.4vw;
}

.contact-us div p span {
    font-weight: 800;
}

.contact-us img {
    width: 100px;
    height: 100px;
    margin-right: 40px;
}

.cancel {
    width: max-content;
    margin-left: 2.5%;
    font-size: 12.5px;
    margin-top: 200px;
    cursor: pointer;
}

.uncancel {
    width: max-content;
    margin-left: 2.5%;
    font-size: 20px;
    margin-top: 200px;
    cursor: pointer;
    border: 1px solid #1B75BB;
    font-weight: 600;
    /* background-color: #1B75BB; */
    color: #1B75BB;
    /* color: white; */
    box-sizing: border-box;
    padding: 10px 20px 10px 20px;
    border-radius: 2.5px;
}

.loader {
    min-height: 80vh;
}

@media(max-width: 800px) {
    .contact-us div p {
        font-size: 20px;
    }

    .contact-us div {
        flex-direction: column;
        align-items: flex-start;
    }
}


</style>