<template>

    <div>



    </div>

    

</template>


<script>

export default {
    name: 'SampleQr'
}


</script>

<style scoped>
    
    div {
	    font-family: 'Avenir', Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
		margin-top: 60px;
	}

</style>