<template>

    <div id="fonts">

        <div class="top-buttons">
            
            <div class="back-button" @click="exit()" v-if="fontSelectorsShown() === false">
                <img src="@/assets/closedialogue.svg" />
            </div>

            <div class="back-button" @click="back()" v-if="fontSelectorsShown()">
                <img src="@/assets/closedialogue.svg" />
            </div>
        
            <h3 v-if="fontSelectorsShown() === false">Fonts</h3>
            <h3 v-if="show_primary_font_selector">Primary font</h3>
            <h3 v-if="show_secondary_font_selector">Secondary font</h3>

        </div>

        <div class="fonts-container" v-if="fontSelectorsShown() === false">

            <p>Primary font</p>

            <div :style="{ 'font-family' : primary_font }" class="font-selector-main" @click="toggleFontSelector('primary')" >

                {{ primary_font }}

            </div>

            <div class="font-size-options">

                <p @click="changeFontSize('decrease', 'primary')" class="font-size-button">
                    -
                </p>

                <p class="font-size" :style="{ 'font-size' : primary_font_size }">{{ primary_font_size }}</p>

                <p @click="changeFontSize('increase', 'primary')" class="font-size-button">
                    +
                </p>

            </div>

            <div class="save-button" @click="saveFonts()">
                <img src="@/assets/save.svg" />
                <p>Save</p>
            </div>

        </div>

        <div class="fonts-container" v-if="fontSelectorsShown() === false">

            <p>Secondary font</p>

            <div :style="{ 'font-family' : secondary_font }" class="font-selector-main" @click="toggleFontSelector('secondary')">

                {{ secondary_font }}

            </div>


            <div class="font-size-options">

                <p @click="changeFontSize('decrease', 'secondary')" class="font-size-button">
                    -
                </p>

                <p class="font-size" :style="{ 'font-size' : secondary_font_size }">{{ secondary_font_size }}</p>

                <p @click="changeFontSize('increase', 'secondary')" class="font-size-button">
                    +
                </p>

            </div>

            <div class="save-button" @click="saveFonts()">
                <img src="@/assets/save.svg" />
                <p>Save</p>
            </div>

        </div>

        <div class="font-selector primary-font-selector" v-if="show_primary_font_selector === true">

            <div class="font" v-for="font in fonts" :key="font" :style="{ 'font-family' : font }" :class="{ 'selected' : font === primary_font }" @click="setPrimaryFont(font)"> 

                {{ font }}

            </div>

        </div>

        <div class="font-selector secondary-font-selector" v-if="show_secondary_font_selector === true">

            <div class="font" v-for="font in fonts" :key="font" :style="{ 'font-family' : font }" :class="{ 'selected' : font === secondary_font }" @click="setSecondaryFont(font)">

                {{ font }}

            </div>

        </div>

        
    </div>






</template>


<script>

export default {
    name: 'Fonts',
    props: ['selected_item_details', 'selected_items', 'menu'],
    data () {
        return {
            fonts: ['Montserrat', 'Roboto', 'Merriweather', 'Ubuntu', 'Inconsolata', 'Overpass', 'Source Serif Pro', 'Open Sans', 'Economica', 'Permanent Marker', 'Sacramento', 'Josefin Slab', 'Zilla Slab'],
            selected_font: '',
            primary_font: '',
            secondary_font: '',
            primary_font_size: '',
            secondary_font_size: '',
            show_primary_font_selector: false,
            show_secondary_font_selector: false
        }
    },
    created() {
        this.getPrimaryFont();
        this.getSecondaryFont();
        this.getFontSizes();
    },
    methods: {
        exit() {
            this.$emit('return-to-buttons');
        },
        back() {
            this.show_primary_font_selector = false
            this.show_secondary_font_selector = false
        },
        fontSelectorsShown() {
            if (this.show_primary_font_selector === true || this.show_secondary_font_selector === true) {
                return true
            } else {
                return false
            }
        },
        toggleFontSelector(selected) {
            if (selected === "primary") {
                this.show_primary_font_selector = true
            }
            if (selected === "secondary") {
                this.show_secondary_font_selector = true
            }            
        },
        getFontSizes() {
            var primary_font_size = this.selected_item_details[0].item_name_font_size
            var secondary_font_size = this.selected_item_details[0].item_description_font_size
            var item_type = this.selected_item_details[0].item_type

            if (primary_font_size === 'default') {
                if (this.selected_item_details[0].major_category_header === true) {
                    primary_font_size = '25px'
                } else if (this.selected_item_details[0].major_category_header === false && this.selected_item_details[0].category_header === true) {
                    primary_font_size = '20px'
                } else {
                    primary_font_size = '15px'
                }
            } else {
                primary_font_size = primary_font_size
            }
            
            if (secondary_font_size === 'default') {
                secondary_font_size = '12.5px'
            }

            this.primary_font_size = primary_font_size
            this.secondary_font_size = secondary_font_size
        },
        getPrimaryFont() {
            if (this.selected_item_details[0].item_name_font === 'default') {
                this.primary_font = 'Montserrat'
            } else {
                this.primary_font = this.selected_item_details[0].item_name_font
            }
        },
        getSecondaryFont() {
            if (this.selected_item_details[0].item_description_font === 'default') {
                this.secondary_font = 'Montserrat'
            } else {
                this.secondary_font = this.selected_item_details[0].item_description_font
            }
        },
        setPrimaryFont(font) {
            this.primary_font = font
            this.setFonts();
        },
        setSecondaryFont(font) {
            this.secondary_font = font
            this.setFonts();
        },
        saveFonts() {
            this.setFonts();
        },
        setFonts() {
            for (let i = 0; i < this.selected_items.length; i++) {
                this.$http.secured.post('/app_update_food/' + this.menu.id + '/' + this.selected_items[i], { food: 
                    { 
                        item_name_font: this.primary_font,
                        item_description_font: this.secondary_font,
                        item_price_font: this.primary_font,
                        item_name_font_size: this.primary_font_size,
                        item_description_font_size: this.secondary_font_size
                    }}).then(response => {
                        this.selected_item_details[i].item_name_font = this.primary_font
                        this.selected_item_details[i].item_description_font = this.secondary_font
                        this.selected_item_details[i].item_price_font = this.primary_font
                        this.selected_item_details[i].item_name_font_size = this.primary_font_size
                        this.selected_item_details[i].item_description_font_size = this.secondary_font_size
                        this.$emit('items-updated', JSON.stringify(this.selected_item_details))
                    }).catch(error => this.setError(error, 'Cannot update food.'))
            }
        },
        emitChanges() {
            for (let i = 0; i < this.selected_items.length; i++) {
                this.selected_item_details[i].item_name_font = this.primary_font
                this.selected_item_details[i].item_description_font = this.secondary_font
                this.selected_item_details[i].item_price_font = this.primary_font
                this.selected_item_details[i].item_name_font_size = this.primary_font_size
                this.selected_item_details[i].item_description_font_size = this.secondary_font_size
                this.$emit('items-updated', JSON.stringify(this.selected_item_details))
            }
        },
        changeFontSize(increaseOrDecrease, font) {
           
            if (font === 'primary') {
                var startingFontSize = this.primary_font_size.slice(0, 2)
                if (increaseOrDecrease === 'increase') {
                    if (Number(startingFontSize) < 50) {
                        var newFontSize = (Number(startingFontSize) + 1)
                        this.primary_font_size = String(newFontSize + 'px')
                    }
                } else {
                    if (Number(startingFontSize) > 10) {
                        var newFontSize = (Number(startingFontSize) - 1)
                        this.primary_font_size = String(newFontSize + 'px')
                    }
                 }
            }
           
           if (font === 'secondary') {
                var startingFontSize = this.secondary_font_size.slice(0, 2)
                if (increaseOrDecrease === 'increase') {
                    if (Number(startingFontSize) < 50) {
                        var newFontSize = (Number(startingFontSize) + 1)
                        this.secondary_font_size = String(newFontSize + 'px')
                    }
                } else {
                    if (Number(startingFontSize) > 10) {
                        var newFontSize = (Number(startingFontSize) - 1)
                        this.secondary_font_size = String(newFontSize + 'px')
                    }
                }
            }
            this.emitChanges();
        }
    }
}



</script>

<style scoped>

#fonts {
    box-sizing: border-box;
    padding: 0px 10px 0px 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    color: #2F2F2E;
}

.top-buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

h3 {
    font-size: 20px;
    width: max-content;
    font-weight: 400;
}

.back-button {
    width: max-content;
    cursor: pointer;
}

.back-button img {
    width: 20px;
    height: 20px;
}

.fonts-container {
    display: flex;
    flex-direction: column;
    width: 50%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-sizing: border-box;
    padding: 20px 0px 20px 0px;
}

.fonts-container p {
    font-weight: 600;
    font-size: 12.5px;
    text-align: center;
    margin: 0px 0px 20px 0px;
}

.selected {
    background-color: #00ADEF;
    border: 1px solid #00ADEF;
    color: white;
}

.font-selector {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.font-selector-main {
    width: max-content;
    border: 1px solid lightgray;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 2.5px;
}

.font {
    width: max-content;
    margin: 10px 10px 0px 0px;
    box-sizing: border-box;
    padding: 2.5px 10px 2.5px 10px;
    border: 1px solid rgba(0, 0, 0, 0);
    /* border: 1px solid lightgray; */
    border-radius: 2.5px;
    cursor: pointer;
}

.font-size-options {
    display: flex;
    width: 150px;
    margin: 25px 0px 0px 0px;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    /* border: 1px dotted lightgray; */
}

.font-size-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: max-content;
    border: 1px solid lightgray;
    box-sizing: border-box;
    padding: 5px 10px 5px 10px;
    border-radius: 2.5px;
    cursor: pointer;
    color: gray;
}

.save-button {
    width: max-content;
    box-sizing: border-box;
    padding: 10px 50px 10px 50px;
    border: 1px solid #98BD3C;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 2.5px;
}

.save-button p {
    color: #98BD3C;
    font-weight: 600;
    margin: 0px 0px 0px 0px;
    text-transform: uppercase;
    margin-left: 5px;
}

.save-button:hover {
    background-color: #B3CBE7;
    transition: 0.2s;
}

.save-button img {
    width: 25px;
    height: 25px;
}

@media(max-width: 600px) {
    .save-button {
        padding: 10px 25px 10px 25px;
    }
}

</style>