

<template>

    <div>

        <UsageChart v-if="childDataLoaded === true && menuDataLoaded === true" :menus="menus" :scans="scans" :orders="orders" :order_counts="order_counts" :dates="dates" v-on:show-user-data="showUserData" />

    </div>




</template>


<script>
import UsageChart from '@/components/orders/UsageChart.vue'

export default {
    
    name: 'NewAdminData',
    components: {
        UsageChart
    },
    data() {
        return {
            scans: [],
            orders: [],
            order_counts: [],
            dates: [],
            menus: [],
            childDataLoaded: false,
            menuDataLoaded: false,
            error: ''
        }
    },
    created() {
        this.getData();
        this.getMenus();
    },
    methods: {
        showUserData: function (order_recipient_id) {
            this.$emit('show-user-data', order_recipient_id)

        },
        getData() {
            console.log(this.currentUser);
            this.$http.secured.get('/app_admin_analytics/')
                .then(response => { 
                    this.scans = response.data.scans
                    this.orders = response.data.orders
                    this.dates = response.data.dates
                    this.childDataLoaded = true;
                    this.is_loading = false;
                }).catch(error => this.setError(error, error))
        },
        getMenus() {
            this.$http.secured.get('/app_admin_top_performers/')
                .then(response => { 
                    this.menus = response.data.scans
                    this.orders = response.data.orders
                    this.order_counts = response.data.order_counts
                    this.menuDataLoaded = true;
                }).catch(error => this.setError(error, error))
        },
        setError (error, text) {
            this.error = (error.response && error.response.data && error.response.data.error) || text
        }
    }
}



</script>

<style scoped>

#chart {
    width: 100%;
    height: 500px;
}

.bar {
    fill: gray;
}

</style>