<template>

    <div id="menu">


        <div class="info">

            <img :src="require(`@/assets/homepage/${logo}`)" /> 

            <div class="text">

                <p class="name" v-html="name"></p>

                <p class="location" v-html="location"></p>

            </div>


        </div>

        <div class="image-container">

            <img :src="require(`@/assets/homepage/${image}`)" /> 

        </div>




    </div>



</template>

<script>

export default {
    name: "Menu",
    props: ['name', 'location', 'logo', 'image']
}


</script>

<style scoped>

.info {
    margin: 25px auto 25px auto;
    width: max-content;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.info img {
    max-width: 100px;
    margin-right: 20px;
}

.text {
    display: flex;
    flex-direction: column;
}

.name {
    font-weight: 600;
}

.location {
    font-weight: 600;
}

::v-deep .bold {
    font-weight: 700;
}

p {
    margin: 0px 0px 0px 0px;
}

.image-container {
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: center;
    margin-top: -50px;
    margin-bottom: 50px;
}

.image-container img {
    max-width: 500px;
}

@media(max-width: 500px) {
    .image-container img {
        max-width: 100%;
    }

    .info {
        width: 90%;
    }
}

</style>