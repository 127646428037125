<template>

    <div id="my-outlet-collections">

        <div class="header-container">

            <div class="header-search">

                <h1 class="main-header">My outlet collections</h1>

                <div class="search" v-if="outlet_collections.length > 0">

                    <img src="@/assets/search.svg" v-if="search_phrase == ''" />

                    <img src="@/assets/close_search.svg" @click="closeSearch()" v-if="search_phrase != ''" />

                    <input ref="search" v-model="search_phrase" :placeholder="'Search outlet collections'" />

                </div>

            </div>

            <div class="filler"></div>

            <div class="button" @click="newOutletCollection()" v-if="user_type === 'admin'">
                <p class="full-text">Create an outlet collection</p>
                <p class="mobile-text">New +</p>
            </div>

        </div>

        <div class="menus-container">

            <div v-for="outlet_collection in filteredOutletCollections" :key="outlet_collection.id" class="container">

                <div class="id-info">ID: <span>{{ outlet_collection.id }}</span></div>

                <MenuInfo :menu="outlet_collection" :outlet_collection="true" :item_type="'outlet_collection'" />

            </div>

        </div>
        
    </div>

</template>


<script>
import MenuInfo from '@/components/MenuInfo.vue'

export default {
    name: 'MyOutletCollections',
    props: ['outlet_collections', 'user_type', 'url'],
    data () {
        return {
            search_phrase: ''
        }
    },
    components: {
        MenuInfo
    },
    methods: {
        newOutletCollection() {
            window.location.replace(this.url + '/outlet_collections/new')
        },
        closeSearch() {
            this.search_phrase = ''
        }
    },
    computed: {
        filteredOutletCollections() {
            const outlet_collections = this.outlet_collections
            const filteredOutletCollections = []
            if (this.search_phrase != '') { 
                for (let i = 0; i < outlet_collections.length; i++) {
                    var name = (outlet_collections[i].name).toLowerCase()
                    var url = (outlet_collections[i].custom_url).toLowerCase()
                    if (name.includes(this.search_phrase.toLowerCase()) || (url.includes(this.search_phrase.toLowerCase()))) {
                        filteredOutletCollections.push(outlet_collections[i])
                    }   
                }
                return filteredOutletCollections
            } else {
                return this.outlet_collections
            }
        }
    }
}


</script>

<style scoped>

#my-outlet-collections {
    margin-top: 50px;
    position: relative;
    z-index: 1;
}

.header-container {
    width: 95%;
    margin: auto;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    margin-bottom: 50px;
}

.menus-container {
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 2000px;
    /* border-bottom: 1px solid lightgray; */
    position: relative;
    border-top: 1px solid lightgray;
    background-color: white;
    position: relative;
    z-index: 1;
    margin-bottom: 100px;
    /* padding-bottom: 50vh; */
}

.button {
    border: 1px solid #8CC63E;
    display: flex;
    box-sizing: border-box;
    padding: 5px 20px 5px 20px;
    border-radius: 2.5px;
    cursor: pointer;
    background-color: #8CC63E;
    color: white;
}

.button:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    transition: 0.2s;
}

.search {
    width: max-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 250px;
    margin-left: 0px;
    margin-top: 25px;
}

.search img {
    width: 20px;
    height: 20px;
    cursor: pointer;

}

input {
    width: 200px;
    /* height: 50px; */
    border: 0px;
    /* border-bottom: 1px solid lightgray; */
    box-sizing: border-box;
    padding: 10px 10px 10px 10px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
}

.full-text {
    margin: 0px 0px 0px 0px;
    width: max-content;
}

.mobile-text {
    margin: 0px 0px 0px 0px;
    width: max-content;
    display: none;
}

.container {
    position: relative;
}

@media(max-width: 700px) {
    .button {
        font-size: 10px;
        font-weight: 600;
    }

    h1 {
        font-size: 22.5px !important;
        font-weight: 600 !important;
    }
    
    .full-text {
        display: none;
    }

    .mobile-text {
        display: flex;
    }
}



</style>