<template>

    <div :style="{ 'font-family' : menu.menu_header_font }" id="text-box">


        <div v-if="menu.en === true">

           Scan the QR code to see our menu!

        </div>

        <div v-if="menu.sp === true">

           ¡Escanea el código QR para ver nuestro menú!	

        </div>        

        <div v-if="menu.ca === true">

           Escaneja el codi QR per veure el nostre menú!

        </div>      

        <div v-if="menu.zh === true">

           扫一扫看我们家的菜单!

        </div>      

        <div v-if="menu.fr === true">

           Scannez pour découvrir notre menu !	

        </div>        

        <div v-if="menu.ru === true">

           Отсканируйте, чтобы посмотреть меню!	

        </div>      

        <div v-if="menu.jp === true">

           QRコードをスキャンしてメニューを確認！	

        </div>      

        <div v-if="menu.kr === true">

           QR 코드를 스캔하시고 메뉴를 확인하세요!	

        </div>        

        <div v-if="menu.ar === true">

           قم بفحص رمز الإستجابة السريعة (QR) لترى قائمة الطعام!	

        </div>      

        <div v-if="menu.de === true">

           Scannen Sie den QR-Code, um unsere Speisekarte zu sehen!

        </div>                      

    </div>



</template>


<script>

export default {
    name: "ScanToSeeMenuText",
    props: ['menu']
}


</script>

<style scoped>

#text-box {
    width: 100%;
    display: flex;
    flex-direction: column;
}

#text-box div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}


</style>