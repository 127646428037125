<template>

    <div class="discount">

        <h3>Type: {{ getDiscountType(discount.discount_type) }} </h3>

        <div v-if="discount.minimum_spend_required == true">

            <p>Minimum spend: <NumberFormat v-bind:number="discount.minimum_spend_amount" v-bind:currency="currency"></NumberFormat></p>
        
        </div>

        <div v-if="discount.discount_type == 'percentage_discount'" class="discount-details">

            <p>{{ discount.percentage_discount }}% off</p>
        
        </div>        

        <div v-if="discount.discount_type == 'fixed_discount'" class="discount-details">

            <p><NumberFormat v-bind:number="discount.fixed_amount_discount" v-bind:currency="currency"></NumberFormat>off</p>
        
        </div> 

        <div class="discount-options">

            <span v-if="discount.active === true" @click="$emit('deactivate-discount')" class="status active">Active</span>

            <span v-if="discount.active === false" @click="$emit('activate-discount')" class="status not-active">Not active</span>

            <img @click="$emit('delete-discount')" class="delete-button" src="@/assets/garbage_icon.svg" /> 

        </div>      


    </div>



</template>


<script>
import NumberFormat from '@/components/NumberFormat'

export default {
    name: 'Discount',
    components: {
        NumberFormat
    },
    props: ['discount', 'currency', 'active'],
    data () {
        return {

        }
    },
    methods: {
        getDiscountType(discount_type) {
            if (discount_type == 'percentage_discount') {
                return 'Percent discount'
            }
            if (discount_type == 'fixed_discount') {
                return 'Fixed discount'
            }           
        }
    }
}


</script>

<style scoped>

.discount {
    border-bottom: 1px dotted lightgray;
    box-sizing: border-box;
    padding: 5px 0px 5px 0px;

}

.discount:last-child {
    border-bottom: 0px;
}

h3 {
    font-weight: 600;
    margin: 10px 0px 10px 0px;
    font-size: 14px;
}

.discount p {
    font-weight: 600;
    font-size: 15px;
    margin: 0px 0px 0px 0px;
}

.discount div p {
    display: flex;
    flex-direction: row;
    font-weight: 400;
    font-size: 12.5px;
}

.discount-details {
    border: 1px solid lightgray;
    display: inline-flex;
    box-sizing: border-box;
    padding: 5px;
    border-radius: 2.5px;
    font-size: 12.5px;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
}

.discount-options {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.delete-button {
    width: 25px;
    height: 25px;
    left: 60px;
    display: flex;
    box-sizing: border-box;
    padding: 5px;
    border-radius: 50%;
    z-index: 2; 
    background-color: #A33B3B;
    border: 0;
}

.delete-button:hover {
    opacity: 0.7;
}

.status {
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12.5px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
}

.status:hover {
    opacity: 0.8;
    transition: 0.2s;
}

.active {
    background-color: #8cc63e;
    color: white;
}

.not-active {
    border: 1px solid lightgray;
    color: gray;
}


</style>