<template>

    <div id="restaurant-type-selector-container">

        <h2>This QR code menu is for...</h2>

        <div class="radio-buttons">

            <div class="radio-button" @click="setType('restaurant')" :class="{ 'radio-button-selected' : restaurant_type === 'restaurant' }">

                <p>a restaurant</p>

                <img src="@/assets/restaurant.svg" /> 



            </div>

             <div class="radio-button" @click="setType('golf course')" :class="{ 'radio-button-selected' : restaurant_type === 'golf course' }">

                <p>a golf course</p>

                <img src="@/assets/golf_bag.svg" /> 

            </div>           

            <div class="radio-button" @click="setType('spa')" :class="{ 'radio-button-selected' : restaurant_type === 'spa' }">

                <p>a spa</p>

                <img src="@/assets/spa.svg" /> 

            </div>

        </div>

        <div class="step-container" >

            <transition name="fade">

                <NextStep v-if="can_proceed == true" :next_step="'Next Step'" />

            </transition>

        </div>


    </div>


    




</template>
    
<script>
import NextStep from '@/components/NextStep.vue'

export default {
    name: 'StepOne',
    components: {
        NextStep
    },
    props: ['editing_outlet_type'],
    data () {
        return {
            restaurant_type: '',
            can_proceed: false
        }
    },
    created () {
        
        this.restaurant_type = this.$store.getters.getOutletType
        console.log(this.$store.getters.getOutletType)
        localStorage.current_step = 1
        if (this.restaurant_type != '') {
            this.can_proceed = true
        }
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0,0);
        },
        setType(type) {
            this.restaurant_type = type
            this.$store.commit("setOutletType", type);
            this.can_proceed = true

        },
        getType() {
            return this.restaurant_type
        }
    }
}

</script>

<style scoped>

#restaurant-type-selector-container {
    display: flex;
    flex-direction: column;
}

h2 {
    font-weight: 600;
}

.radio-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.radio-button {
    width: 30%;
    height: auto;
    background-color: white;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    padding: 25px;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.radio-button:hover {
    background-color: #94C13D;
    background-color: #B3CBE7;
    transition: 0.2s;
}

.radio-button-selected:hover {
    background-color: #4794CB;
}

.radio-button-selected {
    background-color: #94C13D;
    background-color: #4794CB;
    /* border: 1px solid #078E45; */
    /* background-image: linear-gradient(to right, rgb(153, 202, 60), rgb(7, 153, 72)); */
    color: white;
    /* transition: 0.2s; */
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
}

.radio-button p {
    font-weight: 600;
    width: 100%;
    margin: 0px 0px 0px 0px;
}

.radio-button img {
    width: 50%;
    margin-top: 30px;
    margin-bottom: 30px;
}

.radio-button-selected img {
    filter: invert(99%) sepia(0%) saturate(5593%) hue-rotate(197deg) brightness(115%) contrast(100%);
    transition: 0.2s;
}

@media(max-width: 700px) {
    .radio-button {
        width: 100%;
        flex-direction: row;
        height: 40px;
        margin: 0px 0px 20px 0px;
        box-shadow: 0 0 0;
        border: 1px solid lightgray;
    }

    .radio-button-selected {
        border: 1px solid #4794CB;
    }

    .radio-button img {
        width: 10%;
        max-height: 30px;
    }
}



</style>