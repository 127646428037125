<template>

    <div id="admin-data-container">

        <div v-if="is_loading == true" class="loader">
                
            <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>

        </div>  

        <div v-else>

            <!-- <h3>Admin data</h3> -->

            <div class="menu-view-data">

                <div class="menu-views">

                    <h4>Today</h4>

                    <!-- {{ getMaxDailyScanCount() }} -->

                    <div class="menus" v-for="menu_id in menu_ids" :key="menu_id">

                        <div v-if="scansToday(menu_id) > 0" class="menu-info">

                            <div class="menu-name">{{ getMenuName(menu_id) }}</div>

                            <div class="scan-count">
                                <!-- <div class="bar" :style="{ 'width' : (scansToday(menu_id) / getMaxDailyScanCount() * 80)  + '%'}"></div> -->
                                <p>{{ scansToday(menu_id) }}</p>
                            </div>

                        </div>

                    </div>
                    


                </div>
                
                <!-- <div class="menu-views">

                    <h4>This Week</h4>

                    <div class="menus" v-for="menu_id in menu_ids" :key="menu_id">

                        <div v-if="scansThisWeek(menu_id) > 0" class="menu-info">

                            <div class="menu-name">{{ getMenuName(menu_id) }}</div>

                            <div class="scan-count">
                                <div class="bar" :style="{ 'width' : (scansThisWeek(menu_id) / getMaxWeeklyScanCount() * 80)  + '%'}"></div>
                                <p>{{ scansThisWeek(menu_id) }}</p>
                            </div>

                        </div>

                    </div>

                </div>

                <div class="menu-views">

                    <h4>This Month</h4>

                    <div class="menus" v-for="menu_id in menu_ids" :key="menu_id">

                        <div v-if="scansThisMonth(menu_id) > 0" class="menu-info">

                            <div class="menu-name">{{ getMenuName(menu_id) }}</div>

                            <div class="scan-count">
                                <div class="bar" :style="{ 'width' : (scansThisMonth(menu_id) / getMaxMonthlyScanCount() * 80)  + '%'}"></div>
                                <p>{{ scansThisMonth(menu_id) }}</p>
                            </div>

                    </div>

                </div> -->

            </div>

        </div>

    </div>





</template>


<script>
// import ScanData from '@/components/ScanData.vue'

export default {
    name: 'AdminData',
    props: ['user'],
    components: {
        // ScanData
    },
    data() {
        return {
            scans_today: {},
            // scans_this_week: {},
            // scans_this_month: {},
            scan_counts_daily: [],
            // scan_counts_weekly: [],
            // scan_counts_monthly: [],
            menu_ids: [],
            menu_names: [],
            is_loading: true
        }
    },
    created() {
        this.getData();
    },
    methods: {
        getData() {
            console.log(this.currentUser);
            this.$http.secured.get('/app_admin_analytics/')
                .then(response => { 
                    this.scans_today = response.data.scans_today,
                    // this.scans_this_week = response.data.scans_this_week,
                    // this.scans_this_month = response.data.scans_this_month,
                    this.menu_ids = response.data.menu_ids,
                    this.menu_names = response.data.menu_names,
                    this.is_loading = false;
                }).catch(error => this.setError(error, 'Something went wrong'))
        },
        scansToday(menu_id) {
            const scans = this.scans_today.filter(x => x.menu_id === menu_id)
            this.scan_counts_daily.push(scans.length)
            return scans.length
        },
        // scansThisWeek(menu_id) {
        //     const scans = this.scans_this_week.filter(x => x.menu_id === menu_id)
        //     this.scan_counts_weekly.push(scans.length)
        //     return scans.length
        // },
        // scansThisMonth(menu_id) {
        //     const scans = this.scans_this_month.filter(x => x.menu_id === menu_id)
        //     this.scan_counts_monthly.push(scans.length)
        //     return scans.length
        // },
        getMaxDailyScanCount() {
            var max_of_array = Math.max.apply(Math, this.scan_counts_daily);
            return max_of_array
        },
        // getMaxWeeklyScanCount() {
        //     var max_of_array = Math.max.apply(Math, this.scan_counts_weekly);
        //     return max_of_array
        // },
        // getMaxMonthlyScanCount() {
        //     var max_of_array = Math.max.apply(Math, this.scan_counts_monthly);
        //     return max_of_array
        // },

        getMenuName(menu_id) {
            const menu_name = this.menu_names.filter(x => x.id === menu_id)
            return menu_name[0].name
        }

    }
}



</script>

<style scoped>

.menus {
    display: flex;
    flex-direction: column;
    /* margin: 20px 0px 20px 0px; */
}

.menu-info {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
}

.menu-views h4 {
    font-weight: 600;
    box-sizing: border-box;
    padding: 2.5px;
    border: 1px solid black;
    width: max-content;
    border-radius: 2.5px;
}

.menu-name {
    width: 10%;
    font-size: 12.5px;
    font-weight: 600;
    /* text-align: right; */
    margin-right: 2.5%;
}

.scan-count {
    display: flex;
    flex-direction: row;
    width: 87.5%;
    align-items: center;
}

.scan-count p {
    font-size: 20px;
    margin-left: 10px;
    font-weight: 400;
    /* color: gray; */
    /* border-bottom: 1px dotted lightgray; */
}

.bar {
    height: 2.5px;
    background-color: black;
}


</style>