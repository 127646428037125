<template>
  <div class="menu-info" @click="redirectToMenu()" :class="{ 'menu-info-minimised': this.show_more_data === false }">
    <div class="basic-info">
      <div class="container" v-lazy-container="{ selector: 'img' }">
        <img class="menu-logo" :data-src="menu.logo.url" v-if="menu.logo.url != null" />
        <div class="menu-logo-blank" v-if="menu.logo.url === null">?</div>
        <div class="name" @click="redirectToOutletCollection()">{{ menu.name }}</div>
      </div>
      <div class="updated">Updated <span>{{ moment(menu.updated_at).fromNow() }}</span></div>
    </div>
    <div class="filler"></div>
    <div class="status-container" v-if="outlet_collection != true">
      <div class="status">
        <div class="status-indicator status-indicator-wide" :class="{ 'live': getMenuStatus() === 'LIVE' }"></div>
        {{ getMenuStatus() }}
      </div>
    </div>
    <div class="show-more-data" v-if="outlet_collection === true" @click.stop="showMoreData()">Show menus</div>
    <div class="more-info" v-if="show_more_data === true">
      <div v-if="is_loading === true" div class="loader">
        <div class="lds-ripple">
          <div></div>
          <div></div>
        </div>
      </div>
      <div v-else>
        <div v-if="outlet_collection === true && menus.length > 0" class="outlets-container">
          <div v-for="menu in menus" :key="menu.id" class="outlet">
            {{ menu.name || menu.id }}
            <div class="outlet-buttons">
              <div class="button view-menu-button" @click.stop="goToOutlet(menu.id)">View menu</div>
              <div class="button" :class="menu.mobile_version_live ? 'live' : 'not-live'"
                @click="toggleLive(menu, menu.mobile_version_live)">
                {{ menu.mobile_version_live ? 'Live' : 'Not live' }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="menu-actions" v-if="show_more_data == true && $store.getters.getUserType === 'admin'">
      {{ $store.getters.getCurrentUser }}
      <button class=" activate-button" @click="toggleAllMenusInOutletCollection(false)">Activate all menus</button>
      <button class="deactivate-button" @click="toggleAllMenusInOutletCollection(true)">Deactivate all menus</button>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/event-bus.js';

export default {
  name: 'MenuInfo',
  props: ['menu', 'outlet_collection', 'item_type'],
  data() {
    return {
      show_more_data: false,
      is_loading: false,
      menus: [],
      publish_response: ''
    }
  },
  methods: {
    showMoreData() {
      this.is_loading = true
      this.show_more_data = !this.show_more_data
      this.$http.plain.get('/app_get_outlets/' + this.menu.custom_url)
        .then(response => {
          this.menus = response.data.menus
          this.is_loading = false
        });
    },
    goToOutlet(id) {
      this.$router.push(`/${id}`)
    },
    getMenuStatus() {
      if (this.menu.mobile_version_live === true) {
        return 'LIVE'
      } else {
        return 'UNPUBLISHED'
      }
    },
    redirectToOutletCollection() {
      if (this.item_type === 'outlet_collection') {
        window.location.replace(`https://${this.menu.custom_url}.menulingua.com`)
      }
    },
    redirectToMenu() {
      EventBus.$emit('clicked', this.signedIn);
      if (this.item_type === 'menu') {
        this.$router.push(`/${this.menu.id}`)
      }
    },
    toggleAllMenusInOutletCollection(status) {
      if (window.confirm(`Are you sure you want to update the status of ${this.menus.length} menus?`)) {
        for (let i = 0; i < this.menus.length; i++) {
          this.toggleLive(this.menus[i], status);
        }
      }
    },
    toggleLive(menu, status) {
      console.log('toggle live firing')
      this.publish_response = ''
      if (status === true) {
        status = 'app_unpublish_menu'
      }
      if (status === false) {
        status = 'app_publish_menu'
      }
      this.$http.secured.post('/' + status + '/' + menu.id)
        .then(response => {
          if (response.data.message === 'You need to upgrade your subscription to make your menu live') {
            this.$router.push({ path: `/pricing` })
            this.$store.commit('setMessage', {
              content: "Purchase a subscription to publish your menu"
            })
          } else {
            this.$store.commit('setMessage', {
              content: `Menu ${menu.name} ${status ? 'unpublished' : 'published'} successfully`
            })
            this.show_more_data = false;
            this.showMoreData();
          }
        })
        .catch(error => this.setError(error, 'Cannot update menu.'))
    }
  }
}

</script>

<style scoped>

.menu-info {
    width: 100%;
    min-height: 100px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid lightgray;
    box-sizing: border-box;
    padding: 20px 2.5% 20px 2.5%;
    cursor: pointer;
    flex-direction: row;
    flex-wrap: wrap;
}

.menu-info-minimised {
    height: 100px !important;
}

.more-info {
    width: 100%;
}

.show-more-data {
    width: 100px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12.5px;
    font-weight: 600;
}

.menu-info:hover {
    background-color: #E5F0D4;
    transition: 0.2s;
}

.menu-logo {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
}

.basic-info {
    display: flex;
    flex-direction: row;
    width: 50%;
    align-items: center;
    /* border: 1px dotted lightgray; */
    justify-content: space-between;
}

.container {
    display: flex;
    flex-direction: row;
    align-items: center;

}

.menu-logo-blank {
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
    border-radius: 50%;
    background-color: lightgray;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 10px;
    font-weight: 600;
}

.name {
    font-size: 15px;
    margin-right: 20px;
    /* width: 20%; */
}

.updated {
    display: flex;
    flex-direction: row;
    font-size: 12.5px;
    align-items: center;
    /* border-top: 1px dotted lightgray; */
    /* border-bottom: 1px dotted lightgray; */
    box-sizing: border-box;
    justify-content: flex-start;
    padding: 5px 0px 0px 0px;
    /* color: gray; */
    width: max-content;
    min-width: 180px;
    /* color: #6D6E70; */
}

.updated span {
    font-weight: 600;
    margin-left: 3px;
}

.status-container {
    display: flex;
    align-items: center;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0.5px;
    /* width: 110px; */
    justify-content: flex-start;
    width: max-content;
}

.status {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: max-content;
    /* border: 1px solid lightgray; */
    background-color: #F1F1F2;
    box-sizing: border-box;
    padding: 5px 10px 5px 10px;
    border-radius: 2.5px;
}   

.status-indicator {
    background-color: #BE1E2D;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-right: 5px;
}

.live {
    background-color: #95BF32;
    color: #51681c
}

.not-live {
  background-color: #d8959c;
  color: #5c2c30;
}

.outlets-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 20px;
    /* justify-content: space-between; */
}

.outlet {
    border: 1px solid lightgray;
    background-color: #F1F1F2;
    color: black;
    display: flex;
    width: 45%;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px 10px 20px;
    border-radius: 5px;
    margin-right: 20px;
    font-size: 12.5px;
    font-weight: 600;
    margin-bottom: 10px;
}

.outlet-buttons {
  width: 25%;
  display: flex;
  flex-direction: column;
}

.button {
  /* border: 1px solid black; */
  margin: 2.5px 0px 2.5px 0px;
  text-align: center;
  border-radius: 2.5px;
}

.view-menu-button {
  border: 1px solid lightgray;
}

.menu-actions {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  width: 100%;
}

.menu-actions button {
  width: 200px;
  margin-right: 10px;
}

.menu-actions button {
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  font: inherit;
  color: inherit;
  text-align: inherit;
  font-size: 12.5px;
  box-sizing: border-box;
  padding: 2.5px 5px 2.5px 5px;
  font-weight: 600;
  text-align: center;
  margin-right: 10px;
  /* Add other properties that you want to reset */
}

.menu-actions .activate-button {
  background-color: #008080;
  /* Teal */
  color: #FFFFFF;
  /* White */
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.menu-actions .activate-button:hover {
  background-color: #005959;
  /* Darker teal on hover */
}

.menu-actions .deactivate-button {
  background-color: #20B2AA;
  /* Lighter teal */
  color: #FFFFFF;
  /* White */
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.menu-actions .deactivate-button:hover {
  background-color: #008080;
  /* Darker teal on hover */
}

@media(max-width: 700px) {

    .menu-info {
        justify-content: space-between;
    }

    .name {
        font-size: 12.5px;
        font-weight: 600;
    }

    .basic-info {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .updated {
        font-size: 10px;
        margin-top: 10px;
        margin-left: 40px;
        border-bottom: 0px;
    }

    .status-indicator {
        width: 10px;
        height: 10px;
    }
}
</style>