<template>

    <div id="price" :class="{ 'half' : price.width === 'half', 'full' : price.width === 'full', 'full' : price_selected[0] === price }">

        <div class="header" :style="{ 'background' : price.top_colour }">

            <h3 v-html="price.name"></h3>

            <div class="details">

                <div class="price-container">

                    <p class="tagline" v-if="price.tagline != null">{{ $homepageTranslations.pages.pricing[price.tagline][$store.getters.getDefaultLanguage] }}</p>

                    <p class="price"> {{ price.price_us }}</p>

                </div>

                <p class="duration">{{ $homepageTranslations.pages.pricing[price.timeline][$store.getters.getDefaultLanguage] }}</p>

            </div>

        </div>

        <div class="content">

            <div v-for="feature in price.features" :key="price.features.indexOf(feature)" class="feature">

                <div class="check-box" v-if="feature.included === true" :style="{ 'background' : price.primary_colour }">

                    <img src="@/assets/check_mark_white.svg" />

                </div>

                <div class="check-box x-box" v-if="feature.included === false" :style="{ 'background' : '#D1D2D4' }">

                    <img src="@/assets/homepage/x_mark_white.svg" />

                </div>

                <p v-html="$homepageTranslations.pages.pricing[feature.name][$store.getters.getDefaultLanguage]"></p>

                <div class="disclaimer-container" v-if="feature.disclaimer != null">

                    <!-- <span class="asterisk" v-if="feature.asterisk === true">*</span> -->

                    <span class="disclaimer" v-if="feature.disclaimer != null">{{ $homepageTranslations.pages.pricing[feature.disclaimer][$store.getters.getDefaultLanguage] }}</span>

                </div>

            </div>

            <div class="filler"></div>

            <div class="select-button" :style="{ 'background' : price.bottom_colour }" @click="goToRoute()" v-if="show_checkout_options != true">

                {{ $homepageTranslations.pages.pricing[price.button_text][$store.getters.getDefaultLanguage]}}

            </div>

        </div>

        <div class="checkout-options" v-if="show_checkout_options === true">

            <p class="close-checkout-button" @click="closeCheckoutOptions()">{{ $homepageTranslations.pages.pricing.checkout_options.close_checkout_options[$store.getters.getDefaultLanguage] }}</p>

            <CheckoutOptions :checkout_options="price.checkout_options" :price_selected="price_selected[0]" :price="price" :user_id="user_id" :user_email="user_email" />

        </div>

    </div>



</template>

<script>
import CheckoutOptions from '@/components/pricing/CheckoutOptions.vue'

export default {
    name: "Price",
    components: {
        CheckoutOptions
    },
    props: ['price', 'price_selected', 'signed_in', 'user_id', 'user_email'],
    data () {
        return {
            show_checkout_options: false
        }
    },
    methods: {
        goToRoute(route) {
            if (this.signed_in != true) {
                this.$router.push('/sign_up')
            } else {
                this.show_checkout_options = true
                this.$emit('price-selected', this.price)
            }
        },
        closeCheckoutOptions() {
            this.show_checkout_options = false
            this.$emit('no-price-selected')
        }
    }
}




</script>

<style scoped>

#price {
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    background-color: white;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
    margin-bottom: 50px;
}

.header {
    color: white;
    box-sizing: border-box;
    padding: 15px;
    border-radius: 10px 10px 0px 0px;  
    display: flex;
    justify-content: space-between;
}

.header h3 {
    font-size: 50px;
    margin: 0px 0px 0px 0px;
}

.content {
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
    border-radius: 0px 0px 10px 10px;
    height: 100%;
}

.check-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    width: 20px !important; 
    height: 20px !important;
    border-radius: 50%;
}

.check-box img {
    width: 10px;
    height: 10px;
}

.feature {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 10px 0px 10px 0px;
    font-family: 'Cabin', sans-serif;
    letter-spacing: 1px;
    align-items: center;
    font-size: 20px;
    color: #3B3B3A;
    flex-wrap: wrap;
}

.feature p {
    margin: 0px 0px 0px 0px;
    max-width: 90%;
    width: max-content;
}

.disclaimer-container {
    margin-top: 25px;
    width: 100%;
}

.disclaimer {
    font-weight: 600;
    color: #919191;
    font-style: italic;
    font-size: 12.5px;
    /* line-height: 5px !important; */
    /* margin-top: 10px; */
}

.select-button {
    width: max-content;
    color: white;
    box-sizing: border-box;
    padding: 20px 60px 20px 60px;
    border-radius: 15px;
    margin: auto;
    font-size: 20px;
    font-weight: 600;
    margin-top: 50px;
    cursor: pointer;
    margin-bottom: 30px;
}

.price-container {
    display: flex;
    flex-direction: row;
    letter-spacing: 1px;
    align-items: flex-end;
    justify-content: flex-end;
}

.details {
    font-family: "Cabin", sans-serif;
}

.tagline {
    text-transform: uppercase;
    margin: 0px 10px 5px 0px;
    font-size: 12.5px;
    letter-spacing: 1.25px;
}

.price {
    margin: 0px 0px 0px 0px;
    font-size: 30px;
}

.duration {
    text-transform: uppercase;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    font-size: 12.5px;
    letter-spacing: 1.25px;
    margin: 10px 0px 0px 0px;
}

.half {
    width: 47.5%;
    /* transition: 0.2s; */
}

.full {
    width: 100%;
    /* transition: 0.2s; */
}

::v-deep .green {
    color: rgb(153, 202, 60);
    font-weight: 800;
}

::v-deep .blue {
    color: rgb(60, 179, 226);
    font-weight: 800;
}

.checkout-options {
    box-sizing: border-box;
    padding: 15px;
}

.close-checkout-button {
    font-family: "Cabin", sans-serif;
    color: #3B3B3A;
    font-size: 12.5px;

}

@media(max-width: 1200px) {
    .header h3 {
        font-size: 40px;
    }
}

@media(max-width: 900px) {
    .half {
        width: 100%;
    }

    .disclaimer-container {
        width: 100%;
    }
}

@media(max-width: 600px) {
    .header h3 {
        font-size: 30px;
    }

    .feature {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .feature p {
        margin: 0px 0px 0px 0px;
        max-width: 80%;
        /* font-size: 15px; */
        width: max-content;
        /* white-space:nowrap; */
        margin-right: 10px;
    }

    .price {
        font-size: 20px;
        font-weight: 600;
    }

    .tagline {
        text-transform: none;
        margin: 0px 5px 3px 0px;
    }

    .duration {
        text-transform: none;
    }
}

</style>