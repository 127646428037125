<template>

  <div class="container" :class="{ 'active-item' : (active === true), 'deactivated-item' : (active != true) }">

    <img src="@/assets/check_mark_white.svg" v-if="active === true" />

    <img src="@/assets/orders/x_button.svg" v-if="active != true" />

  </div>




</template>


<script>

export default {
  name: "SimpleToggleButton",
  props: ["active"]
}



</script>

<style scoped>

.container {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}

.deactivated-item {
  background-color: lightgray;
  border: 1px solid lightgray;
}

.container img {
  width: 7.5px;
  height: 7.5px;
}

.active-item {
  background-color: #8cc63e;
  border: 1px solid #8cc63e;
}

.inner-button {
  width: 12.5px;
  height: 12.5px;
  border-radius: 50%;
  background-color: white;
}

</style>