
const state = () => ({
    step: 1,
    total_steps: 4,
    outlet_type: '',
    outlet_name: '',
    currency: '',
    editing: false,
    selected_languages: [],
    font: '',
    selected_colours: [],
    creatorMenu: false,
    image_only_menu: false
})


const mutations = {
    nextStep (state) { 
    if (state.step < state.total_steps) {
        state.step += 1
    }
    },
    prevStep (state) {
        state.step -= 1
    },
    resetSteps (state) {
        state.step = 1
    },
    setEditing (state) {
        state.editing = true
    },
    setCreatorMenu (state) {
        state.creatorMenu = true
    },
    setNotCreatorMenu (state) {
        state.creatorMenu = false
    },
    setOutletType (state, outletType) {
        state.outlet_type = outletType
    },
    setImageOnlyMenu (state, imageOnlyMenu) {
        state.image_only_menu = imageOnlyMenu
    },
    setOutletName (state, outletName) {
        state.outlet_name = outletName
    },
    setSelectedFont (state, font) {
        state.font = font
    },
    setSelectedColours (state, colour) {
        state.selected_colours.push(colour)
    },
    toggleLanguage (state, language) {
        if (state.selected_languages.includes(language)) {
            state.selected_languages.splice(state.selected_languages.indexOf(language), 1) 
        } else {
            state.selected_languages.push(language)
        }
    },
    selectCurrency (state, currency) {
        if (state.currency === currency) {
            state.currency = ''
        } else {
            state.currency = currency
        }
    }
}

const getters = {
    getStep (state) {
        return state.step
    },
    getTotalSteps (state) {
        return state.total_steps
    },
    getOutletType (state) {
        return state.outlet_type
    },
    getEditing (state) {
        return state.editing
    },
    getOutletName (state) {
        return state.outlet_name
    },
    getSelectedLanguages (state) {
        return state.selected_languages
    },
    getSelectedFont (state) {
        return state.font
    },
    getSelectedColours (state) {
        return state.selected_colours
    },
    getImageOnlyMenu (state) {
        return state.image_only_menu
    },
    getCreatorMenu (state) {
        return state.creatorMenu
    },
    getCurrency (state) {
        return state.currency
    }
}


export default {
    state,
    getters,
    mutations
}