import { render, staticRenderFns } from "./CreateOrderSession.vue?vue&type=template&id=1ffd4878&scoped=true&"
import script from "./CreateOrderSession.vue?vue&type=script&lang=js&"
export * from "./CreateOrderSession.vue?vue&type=script&lang=js&"
import style0 from "./CreateOrderSession.vue?vue&type=style&index=0&id=1ffd4878&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ffd4878",
  null
  
)

export default component.exports