<template>

  <div id="order-session-section-to-print">


    <img class="menu-logo" v-bind:src="menu.logo.url" />



    <div v-if="order_session.language === 'zh'">

      <h1 v-if="order_session.guest_name != 'none'">欢迎光临, {{ order_session.guest_name }}!</h1>

      <h3>扫一扫点餐</h3>

    </div>

    <div v-if="order_session.language === 'ru'">

      <h1 v-if="order_session.guest_name != 'none'">Добро пожаловать, {{ order_session.guest_name }}!</h1>

      <h3>Отсканируйте QR-код, чтобы сделать заказ!</h3>

    </div>

    <div v-if="order_session.language === 'kr'">

      <h1 v-if="order_session.guest_name != 'none'">어서 오십시오, {{ order_session.guest_name }}!</h1>

      <h3>QR코드를 스캔하여 주문하세요!</h3>

    </div>


    <div v-if="order_session.language === 'de'">

      <h1 v-if="order_session.guest_name != 'none'">Willkommen, {{ order_session.guest_name }}!</h1>

      <h3>Scannen Sie den QR-Code, um die Speisekarte anzuzeigen und Ihre Bestellung aufzugeben!</h3>

    </div>

    <div v-if="order_session.language === 'sp'">

      <h1 v-if="order_session.guest_name != 'none'">Bienvenido, {{ order_session.guest_name }}!</h1>

      <h3>Escanee el código QR para ver el menú y hacer su pedido.</h3>

    </div>

    <div v-if="order_session.language === 'fr'">

      <h1 v-if="order_session.guest_name != 'none'">Bienvenue, {{ order_session.guest_name }}!</h1>

      <h3>Scannez le QR code pour commander !</h3>

    </div>

    <div v-if="order_session.language === 'en'">

      <h1 v-if="order_session.guest_name != 'none'">Welcome, {{ order_session.guest_name }}!</h1>

      <h3>Scan the QR code to view the menu and place your order.</h3>

    </div>


    <div class="qr-container">

      <vue-qr
        :text="`https://${$store.getters.getSubdomain}.menulingua.com/${menu.id}/${order_session.language}/${order_session.key}`"
        :size="200"></vue-qr>

    </div>



  </div>


</template>


<script>
import VueQr from 'vue-qr'

export default {
    name: 'OrderSessionPrintout',
    props: ['order_session', 'menu'],
    components: {
        VueQr
    }

}




</script>

<style scoped>

h3 {
    font-weight: 600;
}

h1 {
    font-weight: 600;
}

#order-session-section-to-print {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.menu-logo {
    max-width: 75px;
    max-height: 75px;
}


.qr-container {
    margin-bottom: 100px;
}




</style>