<template>

<div :class="{ 'no-scroll' : (editing_item === true) }">

    <div v-if="is_loading == true" class="loader">
        
        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>

    </div> 
   

    <div v-else>

        <div v-if="menu.mobile_version_live === false && can_edit === false">

            <Deactivated />

        </div>

            <div v-else>

                <div v-if="show_qr_codes === true || show_pdf_generator === true">

                    <QrCode v-if="show_qr_codes === true" :menu="menu" :can_edit="can_edit" v-on:back-to-menu="show_qr_codes = false" />

                    <PdfPrintout v-if="show_pdf_generator === true" :can_edit="can_edit" :menu="menu" :foods="foods" :sold_out_foods="sold_out_foods" :hidden_foods="hidden_foods" :currency_short_form="currency_short_form" :food_tags="food_tags" :languages="languages" v-on:back-to-menu="show_pdf_generator = false" />

                </div>

                <div v-else>

                <div class="sign-up-call-to-action" v-if="menu.user_id === 0 && show_account_call_to_action === true">
                    <h2><span>Create a free account</span> to save and publish your menu</h2>
                    <div class="create-account" @click="createAccount()">
                        Create account
                    </div>
                    <div class="dismiss" @click="show_account_call_to_action = false">
                        Dismiss
                    </div>
                </div>

                <transition name="fade">

                    <div class="languages" v-if="language_selector_shown === true && languages.length > 1" :style="{ 'font-family' : menu.menu_header_font }">

                        <img v-if="menu.logo.url != null && menu.logo.url != ''" :src="menu.logo.url" />

                        <!-- <h2 v-if="menu.name != '' && menu.name != null && menu.show_menu_name === true">{{ menu.name }}</h2> -->
                        
                        <LanguageSelector
                            v-for="language in languages"
                            :language="language"
                            v-on:select-language="selectLanguage(language)"
                            :key="language" />

                    </div>  

                </transition>
                
                <div v-if="menu.images_only === false">

                    <div class="menu-container" @click.self="deselectAll()" @click="logClicked()" :class="{ 'editor' : can_edit, 'menu-container-large-padding-bottom' : can_edit, 'modal-open' : (editing_item === true) }" v-if="show_current_order === false" v-touch:swipe.left="swipeLeftHandlerPaginate" v-touch:swipe.right="swipeRightHandlerPaginate">

                            <Toolbar :menu_id="menu.id" :menu="menu" :can_edit="can_edit" v-on:show-qr-codes="show_qr_codes = true" v-on:add-an-item="addItem()" v-on:show-pdf-generator="show_pdf_generator = true"  v-if="can_edit === true" v-on:generate-pdf="generatePdf()" class="toolbar"/>

                        <div class="menu" :class="{ 'editor-menu' : can_edit }">

                            <div class="header">

                                <MenuHeader :has_language_selector="true" :outlet_collection="outlet_collection" :menu="menu" :language="language" :languages="languages" :show_menu_name="menu.show_menu_name" v-on:show-language-selector="showLanguageSelector()" :can_edit="this.can_edit" />

                            </div>  

                            <div v-if="menu.hide_top_scrollbar === false" id="scrollbar" :style="{ 'background-color' : menu.menu_header_background_color }">

                                <transition name="fade" v-if="major_category_headers.length > 0 || minor_category_headers.length > 0">

                                    <TopScrollbar :foods="foods" :menu="menu" :can_edit="can_edit" :scrollbar_fixed="scrollbar_fixed" v-on:toggle-search-input="toggleSearchInput()" v-on:show-help-box="showHelpBox()" :language="language" :highlighted_item="highlighted_item" :background_color="menu.menu_header_background_color" :font_color="menu.menu_header_font_color" :font="menu.menu_header_font" v-on:scroll-to-category="scrollToCategory" @scroll="onScroll" v-if="scrollbar_fixed === true && adding_item === false && editing_item === false" /> 

                                </transition>

                                <transition name="fade">

                                    <div class="help-response" v-if="show_help_response === true" @click="hideHelpResponse()">

                                        <p>Submitted successfully. Help is on the way! </p>

                                        <div class="close"><img src="../assets/close_new.svg" /></div>

                                    </div>

                                </transition>

                            </div>

                            <transition name="fade">

                                <div v-if="search_bar_toggled === true" class="search-bar" :class="{'fixed-to-top-search-bar' : scrollbar_fixed === false, 'menu-editor-search-bar' : can_edit }">

                                    <img src="@/assets/search.svg" />

                                    <input ref="search" v-model="search_phrase" autofocus />

                                    <img src="@/assets/close_search.svg" @click="closeSearchBar()" />

                                </div>

                            </transition>

                            <div v-if="menu.orderable === true && can_edit === false">

                                <transition name="fade">

                                    <HelpRequest :menu_id="menu.id" :language="language" v-on:close-modal="show_help_box = false" v-on:submit-help-request="submitHelpRequest" v-if="show_help_box === true"  />

                                </transition>

                            </div>

                            <div class="top-buttons" v-if="(can_edit === true && foods.length > 0) && (menu.parent_menu_id === '' || menu.parent_menu_id === null)">

                                <h4>Select</h4>

                                <div class="select-all-button" v-if="can_edit === true && foods.length > 0" @click="selectAll('all')" :class="{ 'select-all-selected' : selected_items.length === foods.length }">
                                    All items
                                </div>

                                <div class="select-all-button" v-if="can_edit === true && food_drink_items.length > 0" @click="selectAll('food_drink')" :class="{ 'select-all-selected' : allSelected(selected_items, food_drink_items) }">
                                    Food and drink items
                                </div>

                                <div class="select-all-button" v-if="can_edit === true && major_category_headers.length > 0" @click="selectAll('major_category_headers')" :class="{ 'select-all-selected' : allSelected(selected_items, major_category_headers)  }">
                                    Major category headers 
                                </div>

                                <div class="select-all-button" v-if="can_edit === true && minor_category_headers.length > 0" @click="selectAll('minor_category_headers')" :class="{ 'select-all-selected' : allSelected(selected_items, minor_category_headers) }">
                                    Minor category headers
                                </div>

                                <div class="select-all-button deselect-button" v-if="can_edit === true && this.selected_items.length > 0" @click="deselectAll()">
                                    Deselect all items
                                </div>

                            </div>

                            <NoItems v-if="can_edit === true && foods.length === 0 && menu.parent_menu_id == null" @click.native="addItem()" />

                            <draggable class="menu-items-container" handle=".handle" v-bind="dragOptions" v-model="foods" @start="drag=true" @end="onDragEnd(foods)" v-if="((menu.parent_menu_id === '' || menu.parent_menu_id === null) || can_edit === false)" >

                                <div class="item-container" v-for="food in filteredFoods" :key="food.id" :sort="food.sort" v-on:click="toggleSelected(food.id)" :class=" {'selected-item' : selected_items.includes(food.id), 'go-back' : swipe_direction === 'back', 'go-forward' : swipe_direction === 'forward' }">

                                    <div class="handle move-button" v-if="can_edit === true" :class="{'anchored' : food.id === (anchored_item) }">
                                        <img src="../assets/drag-handle.svg" />
                                        <div class="button-descriptor">Move item</div>  
                                    </div>

                                    <Item 
                                        :food="food" 
                                        :foods="foods"
                                        :foods_from_connected_outlets="foods_from_connected_outlets"
                                        :menu="menu"
                                        :session_based_ordering="session_based_ordering"
                                        :active_order_session="active_order_session"
                                        :language="language" 
                                        :currency="currency_short_form"
                                        :selected_items="selected_items"
                                        :sold_out_foods="sold_out_foods"
                                        :sold_out_multiple_price_items="sold_out_multiple_price_items"
                                        :hidden_from_order_dashboard="hidden_foods.includes(food.id)"
                                        :food_tags="food_tags"
                                        :can_edit="can_edit"
                                        :languages="languages"
                                        :key="componentKey"
                                        :current_pagination_page="current_pagination_page"
                                        :categories="categories"
                                        :selections="selections[foods.indexOf(food)]"
                                        :selection_groups="selection_groups[foods.indexOf(food)]"
                                        :class="{ 'highlighted-item' : highlighted_item === food.id }"
                                        :random_image_key="'?t=ori'"
                                        v-on:paginate-menu-next-page="paginateMenuNextPage()"
                                        v-on:paginate-menu-prev-page="paginateMenuPrevPage()"
                                    />

                                </div>

                            </draggable>

                            <div v-else class="child-menu">

                                <h5>This is a child menu. To edit its items, edit its parent.</h5>

                                <p @click="goToParentMenu()">Parent menu ID <span>{{ menu.parent_menu_id }}</span></p>

                                <div class="settings-container" v-if="$store.getters.getUserType === 'admin' || $store.getters.getUserId === menu.admin_1 || $store.getters.getUserId === menu.admin_2 || $store.getters.getUserId == menu.admin_3 || $store.getters.getUserId == menu.admin_4 || $store.getters.getUserId == menu.admin_5">
                                  
                                  <Menu :menu="menu" />
                                
                                </div>

                            </div>

                            <transition name="fade">
                                
                                <OrderMini :menu="menu" :currency_short_form="currency_short_form" v-if="this.$store.getters.getOrder.length > 0 && can_edit === false" v-on:show-cart="showCart()" />

                            </transition>

                        </div>

                    </div>

                    <div v-else>
                        <Order :menu="menu" :currency="currency" :language="language" />
                    </div>

                </div>

                <div v-else>

                    <div class="menu-container">

                        <transition name="fade">

                            <div class="languages" v-if="language_selector_shown === true && languages.length > 1" :style="{ 'font-family' : menu.menu_header_font }">

                                <img v-if="menu.logo.url != null && menu.logo.url != ''" :src="menu.logo.url" />

                                <h2 v-if="menu.name != '' && menu.name != null">{{ menu.name }}</h2>

                                <LanguageSelector
                                    v-for="language in languages"
                                    :language="language"
                                    v-on:select-language="selectLanguage(language)"
                                    :key="language" />

                            </div>  

                        </transition>                    

                        <div class="pdf-container" v-touch:swipe.left="swipeLeftHandler" v-touch:swipe.right="swipeRightHandler" :class="{ 'pdf-container-padding-top': can_edit }">

                            <div class="page-scroll">

                                <div class="menu-header-options-container-button" v-if="languages.length > 1" @click="showLanguageSelector()">

                                    <img src="@/assets/language_icon_v3.svg" />

                                </div>

                                <div class="page-scroll-buttons" v-if="pageCount > 1">

                                    <div @click="prevPage()"><img src="@/assets/arrow_backward_circle.svg" /></div>

                                    <div class="page-number-indicator">

                                        <div v-for="page in pageCount" :key="page">

                                            <div class="page" :class="{ 'current-page' : currentPage >= page }"></div>

                                        </div>

                                    </div>

                                    <div @click="nextPage()"><img src="@/assets/arrow_forward_circle.svg" /></div>

                                </div>

                            </div>                        

                            <div v-if="filtered_files.length > 0">

                                <transition name="fade">

                                    <pdf 
                                        :src="filtered_files[filtered_files.length - 1].image.url"
                                        @num-pages="pageCount = $event"
                                        @page-loaded="currentPage = $event"
                                        :page="currentPage"
                                        style="display: inline-block; width: 100%"
                                        ></pdf>

                                </transition>

                            </div>

                            <div v-else>
                                
                                <p class="failure">Failed to load PDF</p>

                            </div>

                            <p @click="editMenu()" class="reupload" v-if="can_edit">Reupload</p>

                        </div>

                        <Toolbar :menu_id="menu.id" :menu="menu" :can_edit="can_edit" v-if="can_edit === true" class="toolbar pdf-toolbar" :add_item="false" :generate_pdf="false" v-on:show-qr-codes="show_qr_codes = true" :user_type="user_type" :access_to_menu_csv_import_and_export="access_to_menu_csv_import_and_export" />

                    </div>

                </div>

            </div>

        </div>

    </div>

    <transition name="slide-fade">

        <ItemOptionsRevised v-if="selected_items.length > 0" :selected_items="selected_items" :foods="foods" v-on:close="selected_items = []" :menu="menu" :languages="languages" :language="language" v-on:item-updated-successfully="updateMenu" v-on:reload-menu="reloadMenu()" v-on:item-deleted="itemDeleted" v-on:clear-selected="clearSelected()" v-on:edit-item="editItem" v-on:set-to-loading="is_loading = true" />

    </transition>

    <transition name="slide-fade">

        <div class="editing-container" v-if="editing_item === true">

            <EditItem :item_target="item_target" :menu="menu" :languages="languages" v-on:close-item-modal="closeItemModal()" v-on:item-added="reloadMenu()" />

        </div>

    </transition>

    <transition name="slide-fade">

        <div class="editing-container" v-if="adding_item === true">

            <NewItem :menu="menu" :languages="languages" v-on:close-item-modal="closeItemModal()" v-on:item-added="reloadMenu()" />

        </div>

    </transition>
 
    


</div>

</template>


<script>
import MenuHeader from '@/components/MenuHeader.vue'
import Item from '@/components/Item.vue'
import draggable from 'vuedraggable'
import ItemOptions from '@/components/ItemOptions.vue'
import NoItems from '@/components/NoItems.vue'
import Toolbar from '@/components/Toolbar.vue'
import Header from '@/components/Header.vue'
import LanguageSelector from '@/components/LanguageSelector.vue'
import Deactivated from '@/components/Deactivated.vue'
import VueHtml2pdf from 'vue-html2pdf'
import CloneItem from '@/components/CloneItem.vue'
import TopScrollbar from '@/components/TopScrollbar.vue'
import OrderMini from '@/components/OrderMini.vue'
import Order from '@/components/Order.vue'
import pdf from 'vue-pdf'
import HelpRequest from '@/components/HelpRequest.vue'
import QrCode from '@/components/QrCode'
import PdfPrintout from '@/components/PdfPrintout'
import ItemOptionsRevised from '@/components/ItemOptionsRevised.vue'
import EditItem from '@/components/EditItem.vue'
import NewItem from '@/components/NewItem.vue'
import Menu from '@/components/orders/Menu.vue'

export default {
    name: 'Show',
    components: {
      MenuHeader,
      Item,
      draggable,
      NoItems,
      Toolbar,
      Header,
      LanguageSelector,
      Deactivated,
      CloneItem,
      TopScrollbar,
      OrderMini,
      Order,
      pdf,
      HelpRequest,
      QrCode,
      PdfPrintout,
      ItemOptionsRevised,
      EditItem,
      NewItem,
      Menu
    },
    data () {
      return {
        show_qr_codes: false,
        show_pdf_generator: false,
        menu: {},
        foods: [],
        foods_from_connected_outlets: [],
        outlet_collection: '',
        sold_out_foods: [],
        hidden_foods: [],
        user_id: 0,
        user_type: '',
        anchored_item: Number,
        languages: [],
        language: '',
        language_selector_shown: false,
        show_more_options: Number,
        currency_short_form: '',
        selected_items: [],
        major_category_headers: [],
        minor_category_headers: [],
        food_drink_items: [],
        food_tags: [],
        fixed_toolbar: Boolean,
        componentKey: 0,
        can_edit: false,
        mobile_version_live: Boolean,
        is_loading: true,
        error: '',
        highlighted_item: Number,
        scrollbar_fixed: Boolean,
        show_current_order: false,
        search_phrase: '',
        files: [],
        filtered_files: [],
        currentPage: 1,
        selection_groups: [],
        selections: [],
        pageCount: 0,
        show_help_box: false,
        show_help_response: false,
        show_account_call_to_action: true,
        session_based_ordering: Boolean,
        active_order_session: Boolean,
        access_to_menu_csv_import_and_export: Boolean,
        url: process.env.VUE_APP_API_URL,
        search_bar_toggled: false,
        editing_item: false,
        adding_item: false,
        item_target: [],
        current_pagination_page: 1,
        categories: [],
        swipe_direction: '',
        sold_out_multiple_price_items: [],
        valid_admin_users: []
      }
    },
    props: ['pdf'],
    beforeCreate() {
        if (this.$store.getters.getUserType != '' && this.$store.getters.getUserId != '') {
          if (this.$store.getters.getUserType === 'admin' || this.$store.getters.getUserId === this.menu.user_id || this.$store.getters.getUserId === this.menu.admin_1 || this.$store.getters.getUserId === this.menu.admin_2 || this.$store.getters.getUserId === this.menu.admin_3 || this.$store.getters.getUserId === this.menu.admin_4 || this.$store.getters.getUserId === this.menu.admin_5 || (this.$store.getters.getLoggedIn === false && this.menu.user_id === 0) || this.valid_admin_users.includes(this.$store.getters.getUserId)) {
                this.can_edit = true
            }
        }
    },
    created () {             
        this.checkForSession();
        this.$store.commit("resetSteps");
        window.addEventListener('scroll', this.handleScroll);
        this.getOrder();
        this.recordView();
        this.getMenu();
    },
    methods: {
        paginateMenuNextPage() {
            this.swipe_direction = 'forward'
            const maxlength = this.categories.length
            if (this.current_pagination_page < maxlength) {
                this.current_pagination_page = this.current_pagination_page + 1
            }   
        },
        paginateMenuPrevPage() {
            this.swipe_direction = 'back'
            const minlength = 1
            if (this.current_pagination_page > minlength) {
                this.current_pagination_page = this.current_pagination_page - 1
            }   
        },
        logClicked() {
            // console.log("I CLICKED ON MENU CONTAINER")
        },
        swipeLeftHandlerPaginate() {
            this.paginateMenuNextPage()
        },
        swipeRightHandlerPaginate() {
            this.paginateMenuPrevPage(); 
        },
        getCategories() {
            if (this.menu.paginate === true) {
                let category_name = ''
                let categories = []
                let counter = -1
                let numberOfCategories = 0
                this.foods.forEach((food) => {
                    if (food.major_category_header === true) {
                        let new_category = {}
                        new_category.id = food.id
                        new_category.foods = []
                        new_category.foods.push(food)
                        categories.push(new_category)
                        counter = counter + 1
                        numberOfCategories = numberOfCategories + 1
                    } else {
                        if (numberOfCategories > 0) {
                            categories[counter].foods.push(food)
                        }
                    }
                })
                if (numberOfCategories == 0) {
                    this.menu.paginate = false 
                }
                this.categories = categories
            }
        },
        checkForSession() {
            if (localStorage.signedIn === "true") {
                this.$http.secured.get('/app_current_user')
                    .then(response => { 
                        this.$store.commit('setLoggedIn', response.data.user)
                        this.$store.commit('setUserType', response.data.user_type)
                        this.$store.commit('setUserEmail', response.data.email)
                        this.$store.commit('setUserCSVAccess', response.data.access_to_menu_csv_import_and_export)
                        this.access_to_menu_csv_import_and_export = response.data.access_to_menu_csv_import_and_export
                        localStorage.signedIn = true
                        this.canEdit();
                    }).catch(error => this.setError(error, 'Something went wrong'))
            }
        },
        editMenu() {
            this.$router.replace(`/${this.menu.id}/edit`);
        },
        isMobile() {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },
        reloadMenu() {
            this.is_loading = true
            this.adding_item = false
            this.editing_item = false
            this.getMenu();
            if (isMobile()) {
                setTimeout(() => {
                    this.getAnchoredItem();
                }, 2000)     
            }
        },
        recordView() {
            if (this.can_edit === false) {
                if (this.$router.currentRoute.params.language != null && this.$router.currentRoute.params.language != '') {
                    this.$http.plain.post(`/app_r/${this.$router.currentRoute.params.menu_id}/${this.$router.currentRoute.params.language}`)
                }
            }
        },
        getOrder() {
            var currentTime = new Date()
            var mostRecentlyAddedOrder = Date.parse(localStorage.ots);
            if ((currentTime - mostRecentlyAddedOrder > 3600000) || (localStorage.ots === null) || (localStorage.ots === undefined)) {
                localStorage.removeItem('order')
            } else {
                if (this.$store.getters.getOrder.length === 0) {
                    if (localStorage.getItem('order') != null) {
                        var order = localStorage.getItem('order');
                        var parsedOrder = JSON.parse(order)
                        for (let i = 0; i < parsedOrder.length; i++) {
                            var item = { 'food': parsedOrder[i].food, 'item_price': parsedOrder[i].item_price, 'item_unit': parsedOrder[i].item_unit, 'selections': parsedOrder[i].selections, 'created_at': parsedOrder[i].created_at}
                            this.$store.commit('addToOrder', item)
                        }
                    }
                }
            }
        },
        showCart() {
            this.$router.push({ path: `/orders/new` });
        },
        redirectToAsiaSubdomain(menuRegion) {
          // Get the current subdomain
          const currentSubdomain = window.location.hostname.split('.')[0];
          console.log(`menuRegion: ${menuRegion}`);
          console.log(`currentSubdomain: ${currentSubdomain}`);

              // Check if the subdomain is not "asia"
          if (menuRegion === 'asia' && currentSubdomain !== 'asia') {
            // Remove the existing subdomain
            const domainParts = window.location.hostname.split('.'); // Split the hostname into parts
            let newHostname;
            if (domainParts.length > 2) {
              domainParts.shift(); // Remove the first part (subdomain)
              newHostname = domainParts.join('.'); // Join the remaining parts back together
            } else {
              newHostname = window.location.hostname; // No subdomain exists, use the current hostname
            }

            // Construct the new URL with "asia" subdomain
            const newUrl = `https://asia.${newHostname}${window.location.pathname}`;

            // Redirect to the new URL
            window.location.href = newUrl;
          }
        },
        handleScroll (event) {

            var target = document.querySelector('#menu-header');
            if (window.scrollY >= target.offsetHeight + 30) {

                this.scrollbar_fixed = true
            } else if (window.scrollY < target.offsetHeight + 30) {

                this.scrollbar_fixed = false
                this.highlighted_item = null
            }
        },
        onScroll ({ target: { scrollTop, clientHeight, scrollHeight }}) {
            // if (scrollTop + clientHeight >= scrollHeight) {
            // }
        },
        getMenu() {
            this.anchored_item = this.$store.getters.getAnchoredItem;
            let id = this.$router.currentRoute.params.menu_id;
            this.$http.plain.get('/app_show_menu_v2/' + id )
                .then(response => { 
                    this.menu = response.data.menu,
                    this.mobile_version_live = response.data.mobile_version_live
                    this.foods = response.data.foods,
                    this.foods_from_connected_outlets = response.data.foods_from_connected_outlets,
                    this.sold_out_foods = response.data.sold_out_foods,
                    this.sold_out_multiple_price_items = response.data.sold_out_multiple_price_items,
                    this.hidden_foods = response.data.hidden_foods,
                    this.languages = response.data.languages,
                    this.currency_short_form = response.data.currency_short_form,
                    this.food_tags = response.data.food_tags,
                    // this.selection_groups = response.data.selection_groups,
                    // this.selections = response.data.selections,
                    this.session_based_ordering = response.data.menu.session_based_ordering,
                    this.outlet_collection = response.data.outlet_collection_name,
                    localStorage.table_number_editable = response.data.table_number_editable,
                      this.files = response.data.files,
                    this.valid_admin_users = response.data.valid_admin_users,
                    this.getFilteredFiles()
                    // this.files = response.data.files.filter(file => file.language === this.$router.currentRoute.params.language);
                    this.getItemTypes()
                    this.canEdit()
                    this.redirectToAsiaSubdomain(response.data.menu.region)
                    this.$store.commit("setMenu", response.data.menu);
                    this.$store.commit("setMenuId", response.data.menu.id);
                    this.$store.commit("setMenuLanguages", response.data.languages);
                    if (this.menu.user_id === 0) {
                        this.$store.commit("setCreatorMenu");
                    } else {
                        this.$store.commit("setNotCreatorMenu");
                    }
                    this.checkForTableNumber();
                    this.getLanguage()
                    if (this.$store.getters.getLoggedIn === false && this.menu.user_id === 0) {
                        this.can_edit = true
                    }
                    
                    if (this.session_based_ordering === true) {
                        this.checkForActiveSession()
                    }
                    this.getCategories();
                    // this.getAnchoredItem()
                    this.is_loading = false
                }).catch(error => this.setError(error, 'Something went wrong'))
        },
        nextPage() {
            if (this.currentPage < this.pageCount) {
                this.currentPage++
            }
        },
        checkForTableNumber() {
            if (new URL(location.href).searchParams.get('table') != null) {
                console.log('TABLE NUMBER EXISTS')
                localStorage.setItem('table_number', new URL(location.href).searchParams.get('table'))
            }
        },
        checkForActiveSession() {
            let id = this.$router.currentRoute.params.menu_id;
            let key = this.$router.currentRoute.params.key || localStorage.order_session_token;
            this.$http.plain.get('/app_check_session/' + '/' + id + '/' + key ) 
            .then(response => { 
                this.active_order_session = response.data.valid_token
                localStorage.table_number = response.data.table_number
                localStorage.pax = response.data.pax
                if (response.data.valid_token === true) {
                    localStorage.order_session_token = response.data.key
                }
            }).catch(error => this.setError(error, 'Something went wrong'))        
        },
        createAccount() {
            // window.location.replace(this.url + `/users/sign_up`)
            this.$router.push('/sign_up')
        },
        swipeLeftHandler() {
            if (this.currentPage < this.pageCount) {
                this.currentPage++
            }        
        },
        swipeRightHandler() {
            if (this.currentPage > 1) {
                this.currentPage--
            }
        },
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--
            }
        },
        getAnchoredItem() {
            if (this.$el.getElementsByClassName("anchored")[0] != null) {
                console.log(this.$el.getElementsByClassName("anchored")[0])
                this.$el.getElementsByClassName("anchored")[0].scrollIntoView({behavior: "auto", block: 'start'});
            }
        },        
        canEdit() {
          if (this.$store.getters.getUserType === 'admin' || this.$store.getters.getUserId === this.menu.user_id || this.$store.getters.getUserId === this.menu.admin_1 || this.$store.getters.getUserId === this.menu.admin_2 || this.$store.getters.getUserId === this.menu.admin_3 || this.$store.getters.getUserId === this.menu.admin_4 || this.$store.getters.getUserId === this.menu.admin_5 || (this.$store.getters.getLoggedIn === false && this.menu.user_id === 0) || this.valid_admin_users.includes(this.$store.getters.getUserId)) {
                this.can_edit = true
            } else {
                this.can_edit = false
            }
        },
        setError (error, text) {
            this.error = (error.response && error.response.data && error.response.data.error) || text
        },
        onDragEnd(foods) {
            for (let i = 0; i < this.foods.length; i++) {
                foods[i].sort = i + 1
                this.$http.secured.post('/app_update_food/' + this.menu.id + '/' + this.foods[i].id, { food: {
                    sort: foods[i].sort
                }}).then(response => { 'THIS WAS SORTED' })
                .catch(error => this.setError(error, 'Something went wrong'))
            }
        },
        newItem() {
            this.$router.push({ path: `/${this.menu.id}/new_item` })
        },
        addItem() {
            // console.log('adding item fired')
            if (localStorage.signedIn === "true") {
                if (this.foods.length > 0) {
                    this.$store.commit("setAnchoredItem", this.foods.slice(-1)[0].id)
                }
                this.adding_item = true;
            } else {
                // window.location.replace(this.url + '/users/sign_up')
                this.$router.push('/sign_up')
            }
        },
        editItem: function (selected_item) {
            this.$store.commit("setAnchoredItem", selected_item);
            this.selected_items = []
            for (let i = 0; i < this.foods.length; i++) {
                if (this.foods[i].id === selected_item) {
                    this.item_target = JSON.stringify(this.foods[i])
                }
            }
            this.editing_item = true
        },
        closeItemModal() {
            this.adding_item = false
            this.editing_item = false
        },
        showMoreOptions(food_id) {
            if (this.show_more_options === food_id) { 
                this.show_more_options = null
            } else {
                this.show_more_options = food_id
            }
        },
        getItemTypes() {
            for (let i = 0; i < this.foods.length; i++) {
                if (this.foods[i].major_category_header === true) {
                    this.major_category_headers.push(this.foods[i].id)
                } else if (this.foods[i].category_header === true && this.foods[i].major_category_header === false) {
                    this.minor_category_headers.push(this.foods[i].id)
                } else if (this.foods[i].category_header === false && this.foods[i].major_category_header === false) {
                    this.food_drink_items.push(this.foods[i].id)
                }
            }
        },
        allSelected(arr1, arr2) {
            if (arr1.length !== arr2.length) return false

            for (var i = 0; i < arr1.length; i++) {
                if (arr1[i] !== arr2[i]) return false
            }
            
            return true;

        },
        selectAll(item_type) {
            this.selected_items = []
            for (let i = 0; i < this.foods.length; i++) {
                if (item_type === 'all') {
                    this.selected_items.push(this.foods[i].id)
                } else if (item_type === 'major_category_headers') {
                    if (this.foods[i].major_category_header === true) {
                        this.selected_items.push(this.foods[i].id)
                    }
                } else if (item_type === 'minor_category_headers') {
                    if (this.foods[i].category_header === true && this.foods[i].major_category_header === false) {
                        this.selected_items.push(this.foods[i].id)
                    }
                } else if (item_type === 'food_drink') {
                    if (this.foods[i].category_header === false && this.foods[i].major_category_header === false) {
                        this.selected_items.push(this.foods[i].id)
                    }
                }
            }
        },
        deselectAll() {
            this.selected_items = [];
        },
        toggleSelected(food_id) {
            if (this.can_edit === true) {
                if (this.selected_items.includes(food_id)) {
                    for( var i = 0; i < this.selected_items.length; i++){ 
                        if ( this.selected_items[i] === food_id) { 
                            this.selected_items.splice(i, 1); 
                        }
                    }
                } else {
                    this.selected_items.push(food_id)
                }
            }
        },
        getPageScrollWidth() {
            var pagecount = this.pageCount 
            return `${ 100 / pagecount}%`
        },
        getLanguage() {
            let languages = this.languages;
            if (this.$router.currentRoute.params.language != 'generatepdf') {
                if (this.$router.currentRoute.params.language != null) {
                    if (languages.includes(this.$router.currentRoute.params.language)) {
                        this.language = this.$router.currentRoute.params.language
                    } else {
                        if (languages.length == 1) {
                            this.language = languages[0]
                            // localStorage.language = this.language
                            this.$router.replace(`/${this.menu.id}/${this.language}`)
                        } else {
                            this.language = languages[0]
                            this.language_selector_shown = true;
                        }                    
                    }
                } else {
                    if (localStorage.language === undefined || localStorage.language === null) {
                        if (languages.length == 1) {
                            this.language = languages[0]
                            // localStorage.language = this.language
                            this.$router.replace(`/${this.menu.id}/${this.language}`)
                        } else {
                            this.language_selector_shown = true;
                        }
                    } else {
                        this.language = localStorage.language
                        this.$router.replace(`/${this.menu.id}/${this.language}`)
                    }
                }
            }
            localStorage.language = this.language
        },
        getSelections(food_id) {
            this.selections.filter(selection => selection.food_id === food_id)
        },
        showLanguageSelector() {
            this.language_selector_shown = true;
        },
        selectLanguage(language) {
            this.language = language;
            localStorage.language = language
            this.$router.replace({ path: `/${this.menu.id}/${language}` })
            this.recordView(this.menu.id)
            this.language_selector_shown = false;
            this.componentKey += 1;
            this.getFilteredFiles();
            // this.recordView();
        },
        isLoading() {
            this.is_loading = true
        },
        updateMenu: function (selected_items) {
            var selected_item_details = JSON.parse(selected_items)
            console.log(selected_items)
            for (let i = 0; i < selected_item_details.length; i++) {
                for (let f = 0; f < this.foods.length; f++) {
                    if (this.foods[f].id === selected_item_details[i].id ) {
                        this.foods[f] = selected_item_details[i]
                        console.log(`SELECTED ITEM: ${this.foods[f]}`)
                    }
                }
            }
            this.componentKey++
            this.adding_item = false
            this.editing_item = false
            this.is_loading = false
        },
        itemDeleted: function (deleted_item) {
            for (let f = 0; f < this.foods.length; f++) {
                if (this.foods[f].id === deleted_item ) {
                    this.foods = this.foods.filter(food => food !== this.foods[f])
                }
            }
            this.componentKey++
            this.is_loading = false
        },
        clearSelected() {
            this.selected_items = []
        },
        scrollToCategory: function (food_id) {  
            console.log(this.categories)
            if (this.menu.paginate === true ) {
                this.categories.forEach((category) => {
                    if (category.id === food_id) {
                        this.current_pagination_page = this.categories.indexOf(category) + 1
                        this.highlighted_item = food_id
                        setTimeout(() => {
                            this.$el.getElementsByClassName("highlighted-item")[0].scrollIntoView({behavior: "smooth", block: 'start'});
                        }, 100) 
                    }
                })
            } else {
                this.highlighted_item = food_id
                setTimeout(() => {
                    this.$el.getElementsByClassName("highlighted-item")[0].scrollIntoView({behavior: "smooth", block: 'start'});
                }, 100) 
            }
        },
        showHelpBox() {
            this.show_help_box = true;
        },
        submitHelpRequest: function(table_number, note) {
            this.show_help_box = false
            var this_table_number = table_number
            this.$http.plain.post(`/app_create_help_request/${this.menu.id}`, { help_request: {
                menu_id: this.menu.id,
                table_number: this_table_number,
                note: note
            }})
            .then(response => { 
                this.show_help_response = true
            })
            .catch(error => this.setError(error, 'Something went wrong'))
        },
        hideHelpResponse() {
            this.show_help_response = false;
        },
        toggleSearchInput() {
            
            this.search_bar_toggled = !this.search_bar_toggled
            if (this.search_bar_toggled === true) {
                this.$nextTick(() => {
                    this.$refs.search.focus();
                })
            }
            
        },
        closeSearchBar() {
            this.search_bar_toggled = false
            this.search_phrase = ''
        },
        getName(food) {
            let language = this.language
            return (food['translation_' + language] || food.name || food.translation_en)
        },
        goToParentMenu() {
            this.$router.replace(`/${this.menu.parent_menu_id}`)
            this.reloadMenu()
        },
        getFilteredFiles() {
            const files = this.files
            const filteredFiles = []
            for (let i = 0; i < files.length; i++) {
                if (files[i].language === this.$router.currentRoute.params.language) {
                    filteredFiles.push(files[i])
                }
            }
            this.filtered_files = filteredFiles
        }
    },
    computed: {    
        dragOptions() {
            return {
                animation: 100,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            }
        },
        filteredFoods() {
            const foods = this.foods
            const filteredFoods = []
            if (this.search_phrase) { 
                for (let i = 0; i < foods.length; i++) {
                    var name = (foods[i]['translation_' + this.language] || foods[i].name || foods[i].translation_en).toLowerCase()
                    if (name.includes(this.search_phrase.toLowerCase())) {
                        filteredFoods.push(foods[i])
                    }   
                }
                return filteredFoods
            } else {
                if (this.menu.paginate === true) {
                    return this.categories[this.current_pagination_page - 1].foods
                } else {
                    return this.foods
                }
                
            }
        }
    }
}



</script>



<style scoped>

.menu-container {
    background-color: #f4f4f4;
    display: flex;
    flex-direction: row;
    justify-content: center;
    box-sizing: border-box;
    padding-bottom: 50px;
    /* max-width: 1100px; */
    width: 100%;
    min-height: 100vh;
    /* overflow: hidden; */
    /* max-width: 1000px; */
}

.menu-container-large-padding-bottom {
    padding-bottom: 250px;
}

.pdf-container {
    width: 100%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
}

.pdf-container-padding-top {
    padding-top: 100px;
}

.page-scroll {
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: space-between; */
    padding: 10px 10px 10px 10px;
    box-sizing: border-box;
}

.page-scroll-buttons {
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 10px 10px 10px;
    box-sizing: border-box;
}

.page-scroll div img {
    width: 20px;
    height: 20px;
    cursor: pointer;
    opacity: 0.2;
}


.page-number-indicator {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* width: 100% !important; */
}

.page {
    width: 5px;
    height: 5px;
    margin: 0px 2.5px 0px 2.5px;
    border: 1px solid lightgray;
    border-radius: 50%;
}

.current-page {
    background-color: lightgray;
}

.menu {
    display: flex;
    /* margin: auto; */
    width: 90%;
    position: relative;
    /* margin-left: 110px; */
    margin-right: 10px;
    max-width: 800px;
    box-shadow: 0 0 8px rgba(200, 200, 200, 0.1);
    /* border: 1px dotted lightgray; */
    flex-direction: column;
}

.header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1000px;
}

.menu-items-container {
    display: flex;
    flex-direction: column;
    position: relative;
}

.item-container {
    display: flex;
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0);
    margin-bottom: -2px;
    /* animation: fadeIn 0.2s ease-in; */
}

.go-back {
    animation: goBack 0.2s ease-in;
}

.go-forward {
    animation: goForward 0.2s ease-in;
}

@keyframes goBack{
  0%{
    opacity: 0;
    transform: translateX(-100%);
  }
  100%{
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes goForward{
  0%{
    opacity: 0;
    transform: translateX(100%);
  }
  100%{
    opacity: 1;
    transform: translateX(0%);
  }
}

.active-container {
    background-color: rgba(177, 214, 241, 0.1);
}

.handle {
    position: absolute;
    height: 25px;
    width: 25px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-content: center;
    box-sizing: border-box;
    padding: 5px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    color: white;
    font-weight: 600;
    cursor: pointer;
    z-index: 50;
    top: -20px;
    z-index: 100;
    left: -12.5px;
}

.handle:hover {
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
}

.more-options {
    position: absolute;
    height: 25px;
    width: 25px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-content: center;
    box-sizing: border-box;
    padding: 5px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    color: white;
    font-weight: 600;
    cursor: pointer;
    z-index: 50;
    top: -20px;
    left: 20px;
    z-index: 6000;
}

.more-options:hover {
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
    cursor: pointer;
}

.more-options-selected {
    background-color: #4794CB;
}

.more-options-selected img {
    filter: brightness(0) invert(1);
}

.edit-item-button {
    position: absolute;
    height: 25px;
    width: 25px;
    background-color: black;
    display: flex;
    justify-content: center;
    align-content: center;
    box-sizing: border-box;
    padding: 5px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    color: white;
    font-weight: 600;
    cursor: pointer;
    z-index: 1000;
    top: -20px;
    left: 55px;
    transition: 0.5s;
    z-index: 6000;
}

.edit-item-button img {
    filter: brightness(0) invert(1);
}

.delete-item-button {
    position: absolute;
    height: 25px;
    width: 25px;
    background-color: #A33B3B;
    display: flex;
    justify-content: center;
    align-content: center;
    box-sizing: border-box;
    padding: 5px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    color: white;
    font-weight: 600;
    cursor: pointer;
    z-index: 6000;
    top: -20px;
    left: 125px;
    transition: 0.5s;
}

.edit-item-button img {
    filter: brightness(0) invert(1);
}

.top-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 25px;
    box-sizing: border-box;
    /* padding-top: 20px; */
    border: 1px solid lightgray;
    padding: 10px;
    margin: 25px 0px 25px 0px;
    border-radius: 5px;
    /* justify-content: space-between; */
}

.top-buttons h4 {
    width: 100%;
    font-weight: 600;
    margin: 0px 0px 5px 0px;
    font-size: 12.5px;
    /* border-bottom: 1px sol */
}

.select-all-button {
    box-sizing: border-box;
    padding: 5px;
    border: 1px solid lightgray;
    border-radius: 5px;
    font-size: 11px;
    font-weight: 600;
    cursor: pointer;
    color: gray;
    margin-right: 10px;
    margin: 10px 10px 0px 0px;
}

.select-all-button:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.select-all-selected {
    background-color: #4794CB;
    color: white;
    border: 1px solid #4794CB;
}

.deselect-button {
    color: #BE1E2D;
    border: 1px solid #BE1E2D;
}

.toolbar {
    width: 100px;
    margin-right: 10px;
    height: max-content;
    display: flex;
    position: fixed;
    left: 0px;
    padding-left: 10px;
    flex-direction: column;
    z-index: 6000;
    /* border: 1px dotted lightgray; */
}

.pdf-toolbar {
    margin-top: 100px;
}

.languages {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0px;
    top: 0px;
    z-index: 10000;
    background-color: white;
}

.languages img {
    max-height: 100px;
    max-width: 100px;
}

.loader {
    height: 100vh;
    width: 100vw;
}

#scrollbar {
    /* height: 50px; */
    position: relative;
    width: 100%;
}

.scrollbar-lowered {
    margin-top: 50px;
}

.loader img {
    width: 100px;
    height: 100px;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 50%;
    background-color: #8CC63E;
    box-shadow: 0 0 32px rgba(100, 100, 100, 0.3);
}

.edit-item-button:hover > .button-descriptor {
    display: flex;
}

.delete-item-button:hover > .button-descriptor {
    display: flex;
}

.more-options:hover > .button-descriptor {
    display: flex;
}

.move-button:hover > .button-descriptor {
    display: flex;
}

canvas {
    width: 1200px;
}

.menu-header-options-container-button {
    background-color: white;
    cursor: pointer;
    background-color: white;
    display: flex;
    flex-direction: row;
    width: 25px; 
    height: 25px;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 2.5px;
    box-shadow: 0 0 8px rgba(100, 100, 100, 0.1);
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    margin: 0px 5px 0px 5px;
}

.help-bell {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #F7931D;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 5px;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
    z-index: 100000;
    position: fixed;
    left: 50%;
    top: 80px;
    transform: translate(-50%, -50%);
    cursor: pointer;
}

.help-bell img {
    max-width: 25px;
    max-height: 25px;
}

.help-response {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: max-content;
    max-width: 90%;
    left: 50%;
    top: 130px;
    background-color: #F7931D;
    color: black;
    border: 1px solid #F7931D;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    box-sizing: border-box;
    padding: 5px 20px 5px 20px;
    background-color: white;
    font-weight: 600;
    font-size: 12.5px;
    transform: translate(-50%, -50%);
    cursor: pointer;
    z-index: 100000;
}

.close {
    width: 10px;
    height: 10px;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.close img {
    max-width: 10px;
    max-height: 10px;
}

.sign-up-call-to-action {
    width: 100%;
    position: fixed;
    bottom: 0;
    background-color: white;
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    /* border-top: 1px solid black; */
    padding: 0px 30px 0px 30px;
    /* height: 50px; */
    z-index: 10000;
    box-sizing: border-box;
    /* padding: 0px; */
    align-items: center;
    justify-content: space-between;
    box-shadow: 8px 0 0 rgba(0, 0, 0, 0.1);
    background-color: #91268F;
    color: white;
    /* height: 30px; */
}

.sign-up-call-to-action h2 {
    font-weight: 400;
    font-size: 20px;
}

.sign-up-call-to-action h2 span {
    font-weight: 600;
}

.create-account {
    font-size: 15px;
    color: white;
    background-color: white;
    display: flex;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    margin: 0px 10px 0px 10px;
    box-sizing: border-box;
    padding: 5px;
    border-radius: 5px;
    color: #91268F;
    height: 35px;
    padding: 5px 10px 5px 10px;
    cursor: pointer;
    white-space: nowrap;
}

.dismiss {
    background-color: #E6E7E8;
    color: black;
    font-size: 12.5px;
    height: 35px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 5px 10px 5px 10px;
    cursor: pointer;
}

.selected-item {
    border: 1px dotted lightcoral !important;
    z-index: 100;
    transition: 0.2s;
}

.failure {
    margin: auto;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
    font-size: 10px;
    font-weight: 600;
}

.menu-version-selector {
    width: max-content;
    border: 1px solid lightgray;
    color: gray;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border-radius: 5px;
    font-size: 12.5px;
    font-weight: 600;
    margin-bottom: 20px;
    cursor: pointer;
}

.search-bar {
    width: 100%;
    background-color: white;
    position: fixed;
    top: 50px;
    left: 50%;
    margin-left: -5px;
    transform: translateX(-50%);
    max-width: 800px;
    z-index: 10000;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.menu-editor-search-bar {
    margin-top: 50px;
}

.fixed-to-top-search-bar {
    top: 0px;
}

.search-bar img {
    width: 20px;
    height: 20px;
    margin: 0px 5px 0px 5px;
    background-color: rgba(0, 0, 0, 0);
    cursor: pointer;
}

.search-bar input {
    width: 100%;
    max-width: 800px;
    font-size: 20px;
    color: gray;
    border: 0px;
    font-family: 'Montserrat', sans-serif;
    height: 50px;
    box-sizing: border-box;
    padding: 0px 20px 0px 20px;
    background-color: rgba(255, 255, 255, 0);
}

.editor {
    margin-top: 75px;
}

.editing-container {
    display: flex;
    position: fixed;
    bottom: 0;
    top: 75px;
    right: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    width: 95%;
    border-radius: 10px 10px 0px 0px;
    z-index: 100000;
    overflow-y: scroll;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
}

.modal-open {
  opacity: 0.5 !important;
  pointer-events: none;
  overflow: hidden;
}

.child-menu {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
}

.child-menu p {
    margin: 0px 0px 10px 0px;
    font-size: 12.5px;
}

.child-menu p span {
    font-weight: 600;
}

.settings-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.reupload {
    border: 1px solid #91268F;
    display: flex;
    color: #91268F;
    box-sizing: border-box;
    padding: 10px 20px 10px 20px;
    border-radius: 5px;
    width: max-content;
    font-weight: 600;
    cursor: pointer;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
}

@media(max-width: 1100px) {
    .editor {
        justify-content: flex-end !important;
    }

    .editor-menu {
        margin-right: 0px !important;
        margin-left: 110px;
    }
}

@media(max-width: 900px) {
    .search-bar {
        width: 90%;
    }
}

@media(max-width: 700px) {
    .editor {
        justify-content: center !important;
    }

    .pdf-container {
        width: 100%;
    }
    
    .editor-menu {
        margin-left: 0px;
    }

    .search-bar {
        width: 100%;
        margin-left: 0px;
    }

    .toolbar {
        width: 100%;
        height: 100px;
        position: fixed;
        bottom: 0;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: white;
        margin: 0px 0px 0px 0px;
        border-top: 1px solid lightgray;
        padding-left: 0px;
    }

    .sign-up-call-to-action {
        display: none;
    }

    .menu {
        margin-left: 0px;
        margin-right: 0px;
    }
}

@media(max-width: 500px) {

    .editor-menu {
        width: 95% !important;
    }

    .menu {
        width: 100%;
    }
}

</style>