<template>

    <div id="pro">

        <h4>Pro</h4>


        <img src="@/assets/features/illo_4.svg" />

        <div class="text-container top">

            <h1 v-html="$homepageTranslations.pages.features.orderable[$store.getters.getDefaultLanguage]"></h1>

            <p v-html="$homepageTranslations.pages.features.orderable_subhead[$store.getters.getDefaultLanguage]"></p>

        </div>

        <div class="text-container">

            <h1 v-html="$homepageTranslations.pages.features.order_notifs[$store.getters.getDefaultLanguage]"></h1>

            <p v-html="$homepageTranslations.pages.features.order_notifs_subhead[$store.getters.getDefaultLanguage]"></p>

        </div>

        <div class="text-container">

            <h1 v-html="$homepageTranslations.pages.features.maintain_menu[$store.getters.getDefaultLanguage]"></h1>

            <p v-html="$homepageTranslations.pages.features.maintain_menu_subhead[$store.getters.getDefaultLanguage]"></p>

        </div>



    </div>





</template>

<script>

export default {
    name: "Pro"
}



</script>

<style scoped>

#pro {
    width: 95%;
    max-width: 1300px;
    margin: auto;
    background: linear-gradient(to bottom, rgb(153, 202, 60), rgb(7, 153, 72));
    box-sizing: border-box;
    padding: 30px;
    color: white;
    border-radius: 30px 100px 30px 30px;
    position: relative;
    margin-top: 150px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

h4 {
    font-family: "Cabin", sans-serif;
    margin: 0px 0px 25px 0px;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 2px;
}

img {
    position: absolute;
    right: -4px;
    top: -150px;
    height: 40vw;
}

.text-container {
    margin-bottom: 70px;
    /* max-width: 70%; */
}

.top {
    width: 50%;
}

h1 {
    font-size: 3.5vw;
    margin: 0px 0px 0px 0px;
    font-weight: 700;
}

p {
    font-family: "Cabin", sans-serif;
    font-size: 20px;
    letter-spacing: 1px;
    line-height: 30px;
}

@media(max-width: 900px) {
    img {
        /* top: -75px;
        transform: translateX(-50%);
        right: 0px; */
        top: -100px;
    }

    #pro {
        border-radius: 30px 30px 30px 30px;
        padding-top: 75px;
    }

    .top {
        width: 100%;
    }

    .top h1 {
        width: 50%;
    }

    .top p {
        width: 70%;
    }

    h1 {
        font-size: 25px;
    }
}

@media(max-width: 500px) {
    .top {
        width: 100%;
    }

    .top h1 {
        width: 100%;
    }

    .top p {
        width: 100%;
    }
}

</style>