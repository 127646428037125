<template>

    <div id="menu-container">

        <h2>{{ menu_name }}</h2>

        <div :class="{ 'menu-item category-header' : menu_item.category_header == true, 'menu-item ordinary-menu-item' : menu_item.category_header == false }" v-for="menu_item in parsedMenuItems()" :key="menu_item.id">

            <h3 v-if="menu_item.category_header === true"> {{ menu_item.translation_en || menu_item.name }} </h3>

            <p v-if="menu_item.category_header === false">{{ menu_item.translation_en || menu_item.name }} </p>

            <div v-if="menu_item.category_header === false" class="availability">

              <div>
                  <MultiplePriceToggleContainer :menu_item="menu_item" :menu_details="menu_details" :currency="currency_short_form" :sold_out_multiple_price_items="sold_out_multiple_price_items" v-if="(getPriceCount(menu_item) > 1) && itemIsAvailable(menu_item.id)" />
                  <NumberFormat :currency="currency_short_form" :number="menu_item.price" v-if="(!(getPriceCount(menu_item) > 1)) && itemIsAvailable(menu_item.id)" /> 
              </div>

              <div class="main-availability-buttons">

                <div class="sold-out-status">
                    
                  <p v-if="itemIsAvailable(menu_item.id)" @click="setItemToSoldOut(menu_item.id)" class="available-item">
                      <img src="@/assets/orders/check_mark_white.svg" />
                      Available
                  </p>
                  
                  <p v-else @click="setItemToAvailable(menu_item.id)" class="sold-out-item">
                      <img src="@/assets/orders/x_button.svg" />
                      Sold out
                  </p>

                </div>

                <div class="visibility-status">

                  <p v-if="itemIsVisible(menu_item)" @click="setItemToHidden(menu_item.id)" class="visible-item">
                      <img src="@/assets/orders/visible.svg" />
                      Visible
                  </p>

                  <p v-else @click="setItemToVisible(menu_item.id)" class="hidden-item">
                      <img src="@/assets/orders/hidden.svg" />
                      Hidden
                  </p>

                </div>

              </div>

            </div>

        </div>

        <div v-if="menu.can_download_qr != false">

          <h1>QR codes</h1>

          <div class="show-qr" @click="show_qr_codes = !show_qr_codes">Show QR codes</div>

          <QrCode :menu="menu" :can_edit="true" :hide_menu_info="true" v-if="show_qr_codes === true" />

      </div>

    </div>


</template>


<script>
import QrCode from '@/components/QrCode'
import MultiplePriceToggleContainer from './MultiplePriceToggleContainer.vue'
import NumberFormat from '../NumberFormat.vue'

export default {
    name: 'Menu',
    components: {
      QrCode,
      MultiplePriceToggleContainer,
      NumberFormat
    },
    props: ['menu'],
    data () {
      return {
          menu_name: '',
          menu_items: {},
          sold_out_items: [],
          hidden_items: [],
          show_qr_codes: false,
          menu_details: {},
          currency_short_form: "",
          sold_out_multiple_price_items: []
      }
    },
    created() {
        if (!localStorage.signedIn) {
            this.$router.replace('/')
        } else {
          let id = this.$router.currentRoute.params.id || this.$router.currentRoute.params.menu_id;
          this.$http.secured.get('/app_get_menu_items/' + id)
              .then(response => { 
                  this.menu_name = response.data.menu_name,
                  this.menu_items = response.data.menu_items,
                  this.sold_out_items = response.data.sold_out_items,
                  this.sold_out_multiple_price_items = response.data.sold_out_multiple_price_items,
                  this.hidden_items = response.data.hidden_items,
                  this.menu_details = response.data.menu,
                  this.currency_short_form = response.data.currency_short_form
              })
              .catch(error => this.setError(error, 'Something went wrong'))
        }
    },
    methods: {
        setError (error, text) {
            this.error = (error.response && error.response.data && error.response.data.error) || text
        },
        getPriceCount(menu_item) {
          let count = 0
          if (menu_item.price != null) {
            count++
          }
          if (menu_item.second_price_number != null) {
            count++
          } 
          if (menu_item.second_price_number != null) {
            count++
          }    
          return count          
        },
        parsedMenuItems() {
          return this.menu_items.filter(food => food.appear_in_order_dashboard_settings == true);
        },
        itemIsAvailable(id) {
            if (this.sold_out_items.includes(id)) {
                return false 
            } else {
                return true
            }
        },
        itemIsVisible(menu_item) {
            if (this.hidden_items.includes(menu_item.id)|| menu_item.hidden == true) {
                return false 
            } else {
                return true
            } 
        },
        setItemToSoldOut(food_id) {
            let menu_id = this.$router.currentRoute.params.id || this.$router.currentRoute.params.menu_id;
            this.$http.secured.post('/app_create_sold_out_item/' + menu_id + '/' + food_id)
            .then(response => {
                this.sold_out_items.push(response.data.food_id)
            })
            .catch(error => this.setError(error, 'Cannot update item status'))
        },
        setItemToAvailable(food_id) {
            let menu_id = this.$router.currentRoute.params.id || this.$router.currentRoute.params.menu_id;
            this.$http.secured.post('/app_remove_sold_out_item/' + menu_id + '/' + food_id)
            .then(response => {
                var index = this.sold_out_items.indexOf(food_id);
                if (index > -1) {
                    this.sold_out_items.splice(index, 1)
                }
            })
            .catch(error => this.setError(error, 'Cannot update item status'))
        },
        setItemToHidden(food_id) {
            let menu_id = this.$router.currentRoute.params.id || this.$router.currentRoute.params.menu_id;
            this.$http.secured.post('/app_create_hidden_item/' + menu_id + '/' + food_id)
            .then(response => {
                this.hidden_items.push(response.data.food_id)
            })
            .catch(error => this.setError(error, 'Cannot update item status'))
        },
        setItemToVisible(food_id) {
            let menu_id = this.$router.currentRoute.params.id || this.$router.currentRoute.params.menu_id;
            this.$http.secured.post('/app_remove_hidden_item/' + menu_id + '/' + food_id)
            .then(response => {
                var index = this.hidden_items.indexOf(food_id);
                if (index > -1) {
                    this.hidden_items.splice(index, 1)
                }
            })
            .catch(error => this.setError(error, 'Cannot update item status'))
        },        
    }
}



</script>


<style scoped>

#menu-container {
    width: 60%;
    border: 1px solid lightgray;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 20px;
    margin-left: 20px;
}

#menu-container h2 {
    font-size: 20px;
    font-weight: 600;
    margin: 0px 0px 20px 0px;
}

.menu-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    min-height: 100px;
    box-sizing: border-box;
    padding: 10px 0px 10px 0px;
    flex-wrap: wrap;
}

.ordinary-menu-item {
    border-bottom: 1px dotted lightgray;
}

.menu-item:last-child {
    border-bottom: 0px;
}

.menu-item h3 {
    font-weight: 600;
    font-size: 17.5px;
}

.menu-item p {
    font-size: 15px;
    width: 100%;
    margin: 0px 0px 15px 0px;
}

.availability {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* border: 1px dotted lightgray; */
}

.availability p {
    font-size: 12.5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 10px;
    font-weight: 600;
    box-sizing: border-box;
    cursor: pointer;
    width: 100px;
    height: 35px;
    letter-spacing: 0.5px;
}

.available-item {
    background-color: #8cc63e;
    color: white;
}

.sold-out-item {
    border: 1px solid lightgray;
    color: gray;
    overflow: hidden;
    white-space: nowrap;
}

.availability p img {
    width: 15px;
    height: 15px;
    margin-right: 5px;
}

.visible-item {
    background-color: #1b75bb;
    color: white;
}

.hidden-item {
    border: 1px solid lightgray;
    color: gray;
}

.qr {
    box-sizing: border-box;
    padding-top: 15px;
    border-top: 1px solid lightgray;
    margin-top: 30px;
}

h1 {
    font-weight: 600;
    font-size: 17.5px;
    margin-top: 100px;
}

.show-qr {
    border: 1px solid #1b75bb;
    color: #1b75bb;
    box-sizing: border-box;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-size: 12.5px;
    font-weight: 600;
    margin-top: 20px;
    cursor: pointer;
}

.show-qr:hover {
    background-color: #1b75bb;
    color: white;
    transition: 0.2s;
}

.sold-out-status {
  margin-right: 10px;
}

@media(max-width: 1000px) {
    #menu-container {
        width: 50%;
    }
}

@media(max-width: 750px) {
    #menu-container {
        width: 100%;
        margin-left: 0px;
    }
}



</style>