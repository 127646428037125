<template>

  <div class="additional-availability">

    <div v-for="price in prices" :key="prices.indexOf(price)" @click="!price.soldOut ? createSoldOutMultiplePriceItem(prices.indexOf(price) + 1) : destroySoldOutMultiplePriceItem(prices.indexOf(price) + 1)">

      <div v-if="(price.price != null)" class="price-container">

        <SimpleToggleButton :active="!price.soldOut" :key="componentKey" />

        <div class="item-name" :class="{ 'sold-out-item-text' : price.soldOut == true }">
          <NumberFormat :currency="currency" :number="price.price" />
        </div>
        <div class="item-unit" v-if="price.unit != ''" :class="{ 'sold-out-item-text' : price.soldOut == true }">
          {{ price.unit }}    
        </div>
      </div>

    </div>

  </div>

</template>

<script>
import NumberFormat from '../NumberFormat.vue';
import SimpleToggleButton from './SimpleToggleButton.vue';


export default {
  name: "MultiplePriceToggleContainer",
  components: {
    NumberFormat,
    SimpleToggleButton
  },
  props: ['menu_item', 'currency', 'sold_out_multiple_price_items'],
  data () {
    return {
      price_one: {},
      price_two: {},
      price_three: {},
      prices: [],
      food: {},
      componentKey: 0,
      soldOutItems: this.sold_out_multiple_price_items
    }
  },
  created() {
    this.food = this.menu_item
    this.getPriceObjects()
  },
  methods: {
    getPriceObjects() {
      let menu_item = this.food
      this.prices = []
      let priceOne = {}
      let priceTwo = {}
      let priceThree = {}
      // let foodsWithSoldOutMultiplePriceItems = []
      let foods

      priceOne.price = menu_item.price
      priceOne.name = "price"
      priceOne.soldOut = menu_item.first_price_sold_out
      priceOne.soldOut = this.soldOutItems.some(obj => obj.food_id === menu_item.id && obj.sold_out_price == 1);
      priceOne.unit = menu_item.unit
      priceTwo.price = menu_item.second_price_number
      priceTwo.name = "second_price_number"
      priceTwo.soldOut = this.soldOutItems.some(obj => obj.food_id === menu_item.id && obj.sold_out_price == 2);
      priceTwo.unit = menu_item.second_unit
      priceThree.price = menu_item.third_price_number
      priceThree.name = "third_price_number"
      priceThree.soldOut = this.soldOutItems.some(obj => obj.food_id === menu_item.id && obj.sold_out_price == 3);
      priceThree.unit = menu_item.third_unit
      this.price_one = priceOne
      this.price_two = priceTwo
      this.price_three = priceThree
      this.prices.push(this.price_one, this.price_two, this.price_three)
      console.log(this.prices)
    },
    createSoldOutMultiplePriceItem(price) {
      const menuId = this.$router.currentRoute.params.id || this.$router.currentRoute.params.menu_id;
      console.log(`MENU ID: ${menuId}`);
      this.$http.secured.post(`/app_create_sold_out_multiple_price_items/${menuId}/${this.menu_item.id}/${price}`)
        .then(response => { 
          this.soldOutItems.push({food_id: this.menu_item.id, sold_out_price: price});
          console.log(`SOLD OUT ITEMS: ${this.soldOutItems}`);
          this.getPriceObjects();
          this.componentKey++;
        })
        .catch(error => this.setError(error, 'Something went wrong')) 
    },
    destroySoldOutMultiplePriceItem(price) {
      const menuId = this.$router.currentRoute.params.id || this.$router.currentRoute.params.menu_id;
      console.log(`MENU ID: ${menuId}`);
      this.$http.secured.post(`/app_destroy_sold_out_multiple_price_items/${menuId}/${this.menu_item.id}/${price}`)
        .then(response => { 
          this.soldOutItems = this.soldOutItems.filter(obj => obj.food_id !== menuId && obj.sold_out_price != price);
          this.getPriceObjects();
          this.componentKey++;
        })
        .catch(error => this.setError(error, 'Something went wrong')) 
    },
    resetFood(food) {
      this.food = food
      console.log(this.food)
    }
  }
}


</script>

<style scoped>

.additional-availability {
  width: 100%;
}


.price-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 15px 0px 15px 0px;
  font-size: 12.5px;
  cursor: pointer;
  /* border: 1px dotted lightgray; */
}

p {
  font-weight: 600;
  margin: 0px 0px 10px 0px;
  font-size: 10px;
}

.item-name {
  margin: 0px 5px 0px 0px;
  overflow: hidden;
  white-space: nowrap;
}

.item-unit {
  /* font-size: 10px; */
}

.toggle-button-container-with-margin {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 50px;
    padding-bottom: 50px;
}

.toggle-button {
    width: 50px;
    height: 20px;
    display: flex;
    align-items: center;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 5px 10px 5px 10px;
    cursor: pointer;
}

.toggle-button span {
    width: 12.5px !important;
    height: 12.5px !important;
    z-index: 1;
    background-color: white;
    border-radius: 50%;
}


.sold-out-item-text {
  opacity: 0.5;
}


</style>