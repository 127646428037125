<template>

    <div id="my-menus">

        <div class="header-container">

            <div class="header-search">
            
                <h1 class="main-header">My menus</h1>

                <div class="search" v-if="menus.length > 0">

                    <img src="@/assets/search.svg" v-if="search_phrase == ''" />

                    <img src="@/assets/close_search.svg" @click="closeSearch()" v-if="search_phrase != ''" />

                    <input ref="search" v-model="search_phrase" :placeholder="'Search menus'" />

                </div>

            </div>

            <div class="filler"></div>

            {{ user }}

            <div class="button" @click="newMenu()" v-if="can_create_menus != false">
                <p class="full-text">Create a menu</p>
                <p class="mobile-text">New +</p>
            </div>

        </div>

        <div class="menus-container" :class="{ 'no-menus-container' : menus.length < 1 }">

            <div v-if="menus.length > 0">

                <div v-for="menu in filteredMenus" :key="menu.id" class="container">

                    <div class="id-info">ID: <span>{{ menu.id }}</span></div>

                    <MenuInfo :menu="menu" :item_type="'menu'" />

                </div>

                <div v-if="menus.length === 25" class="show-more" @click="toggleMaxMenus()">

                    <p>Load all menus</p>

                </div>

                <div class="no-results" v-if="filteredMenus.length === 0 && menus.length > 0">
                    <p>No results</p>
                </div>

            </div>

            <div v-else>

                <p class="none-yet" @click="newMenu()" v-if="can_create_menus != false">No menus yet. <span>Create one!</span></p>

            </div>

        </div>

    </div>




</template>


<script>
import MenuInfo from '@/components/MenuInfo.vue'

export default {
    name: 'MyMenus',
    props: ['menus', 'outlet_collections', 'can_create_menus'],
    components: {
        MenuInfo
    },
    data () {
        return {
            search_phrase: ''
        }
    },
    methods: {
        newMenu() {
            this.$router.push('/new');
        },
        closeSearch() {
            this.search_phrase = ''
        },
        toggleMaxMenus() {
            this.$emit('increase-max-count');
        }
    },
    computed: {
        filteredMenus() {
            const menus = this.menus
            const filteredMenus = []
            if (this.search_phrase != '') { 
                for (let i = 0; i < menus.length; i++) {
                    var name = (menus[i].name).toLowerCase()
                    var restaurant_name = ''
                    if (menus[i].restaurant_name != null) {
                        restaurant_name = (menus[i].restaurant_name).toLowerCase()
                    }
                    if (name.includes(this.search_phrase.toLowerCase()) || (restaurant_name.includes(this.search_phrase.toLowerCase()))) {
                        filteredMenus.push(menus[i])
                    }   
                }
                return filteredMenus
            } else {
                return this.menus
            }
        }
    }
}





</script>

<style scoped>

#my-menus {
    margin-top: 50px;
    position: relative;
    z-index: 1;
}

.header-container {
    width: 95%;
    margin: auto;
    display: flex;
    align-items: center;
    margin-bottom: 50px;
}

.button {
    border: 1px solid #8CC63E;
    display: flex;
    box-sizing: border-box;
    padding: 5px 20px 5px 20px;
    border-radius: 2.5px;
    cursor: pointer;
    background-color: #8CC63E;
    color: white;
}

.button:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    transition: 0.2s;
}

.menus-container {
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 2000px;
    position: relative;
    border-top: 1px solid lightgray;
    background-color: white;
    position: relative;
    z-index: 1;
}


.no-menus-container {
    border-bottom: 0px;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.search {
    width: max-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 250px;
    margin-left: 0px;
    margin-top: 25px;
}

.search img {
    width: 20px;
    height: 20px;
    cursor: pointer;

}

input {
    width: 200px;
    /* height: 50px; */
    border: 0px;
    /* border-bottom: 1px solid lightgray; */
    box-sizing: border-box;
    padding: 10px 10px 10px 10px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
}

.none-yet {
    margin: auto;
    width: max-content;
    position: relative;
    z-index: 2;
    font-size: 30px;
    /* margin-bottom: 50vh; */
    cursor: pointer;
    box-sizing: border-box;
    padding: 0px 0px 30px 0px;
    color: #27A9E1;
    border-bottom: 1px solid #27A9E1;
}

.none-yet span {
    font-weight: 600;
}

.no-results {
    display: flex;
    position: absolute;
    top: 0;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100%;
    /* border: 1px dotted lightgray; */
    height: 50vh;
    font-weight: 600;

}

.show-more {
    width: 100%;
    margin-top: 30px;
    font-weight: 600;
    text-align: center;
    font-weight: 600;
    font-size: 12.5px;
    cursor: pointer;
}

.full-text {
    margin: 0px 0px 0px 0px;
    width: max-content;
}

.mobile-text {
    margin: 0px 0px 0px 0px;
    width: max-content;
    display: none;
}

.container {
    position: relative;
}

@media(max-width: 700px) {
    
    .full-text {
        display: none;
    }

    .mobile-text {
        display: flex;
    }

    .button {
        font-size: 10px;
        font-weight: 600;
    }

    h1 {
        font-size: 22.5px !important;
        font-weight: 600 !important;
    }
    
    .none-yet {
        font-size: 20px;
    }
    
}


</style>