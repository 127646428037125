<template>

    <div id="scrollbar-items" :style="{ 'font-family' : font, 'background-color' : background_color }" :class="{ 'scrollbar-fixed' : scrollbar_fixed === true, 'scrollbar-lowered' : can_edit }">


        <div @click="show_popup_categories = !show_popup_categories" class="show-categories-button" v-if="scrollbar_fixed === true">

            <span :style="{ 'background-color' : font_color, 'border' : `1px solid ${font_color}` }"></span>
            <span :style="{ 'background-color' : font_color, 'border' : `1px solid ${font_color}` }"></span>
            <span :style="{ 'background-color' : font_color, 'border' : `1px solid ${font_color}` }"></span>

        </div>

        <img src="@/assets/search.svg" class="search-icon" @click="toggleSearchInput()" v-if="menu.show_search_bar === true" />

        <img src="@/assets/bell_black.svg" @click="showHelpBox()" v-if="scrollbar_fixed === true && menu.orderable === true" />

        <transition name="slide-fade">

            <div class="popup-categories" v-if="show_popup_categories === true" :style=" { 'background-color' : background_color } ">

                <div class="close" @click="show_popup_categories = false"><p :style=" {'background-color': font_color, 'color' : background_color }">X</p></div>

                <div v-for="food in categoryHeaders()" :key="food.id" class="scrollbar-item" @click="scrollToCategory(food.id)" :style="[highlighted_item === food.id ? { 'background-color': font_color, 'color' : background_color, 'border-radius' : '5px' } : {'background-color': background_color, 'color' : font_color }]">

                        {{ food['translation_' + language] || food.name || food.translation_en }}

                </div>

            </div>

        </transition>


    </div>




</template>

<script>

export default {
    name: 'TopScrollbar',
    props: ['menu', 'foods', 'can_edit', 'language', 'font', 'highlighted_item', 'background_color', 'font_color', 'scrollbar_fixed'],
    data () {
        return {
            show_popup_categories: false
        }
    },
    methods: {
        scrollToCategory(food_id) {
            this.$emit('scroll-to-category', food_id)
            this.show_popup_categories = false;
        },
        showHelpBox() {
            this.$emit('show-help-box');
        },
        toggleSearchInput() {
            this.$emit('toggle-search-input');
        },
        categoryHeaders() {
            let categoryHeaders = []
            this.foods.forEach((food) => {
                if (this.menu.paginate === true) {
                    if (food.major_category_header === true && food.hidden_from_top === false) {
                        categoryHeaders.push(food)
                    }
                } else {
                    if ((food.major_category_header === true || food.category_header === true) && food.hidden_from_top === false) {
                        categoryHeaders.push(food)
                    }
                }
            })
            return categoryHeaders
        }
    }
}


</script>

<style scoped>

#scrollbar-items {
    width: 90%;
    max-width: 800px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* overflow: hidden; */
    white-space: nowrap;
    overflow: scroll;
    height: 50px;
    align-items: center;
    box-sizing: border-box;
    padding: 0px 20px 0px 20px;

}

#scrollbar-items img {
    width: 40px;
    height: 40px;
    background-color: white;
    border-radius: 50%;
    cursor: pointer;
}

.scrollbar-item {
    width: 100%;
    /* min-width: 100px; */
    /* max-width: 100px; */
    /* overflow: hidden; */
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    cursor: pointer;
    margin: 10px 0px 10px 0px;
    /* border: 1px dotted lightgray; */
    box-sizing: border-box;
    padding: 2.5px;
    font-size: 12.5px;
}

.close {
    width: 100%;
    display: flex;
    justify-content: center;
    font-family: 'Montseratt', sans-serif;
    font-weight: 600;
    margin-bottom: 20px;
    font-size: 10px;
    cursor: pointer;
}

.close p {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: 0px 0px 0px 0px;
}

.scrollbar-fixed {
    position: fixed;
    top: 0;
    z-index: 1000000;
    transition: 0.2s;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.scrollbar-lowered {
    margin-top: 50px;
}


.popup-categories {
    display: flex;
    flex-direction: column;
    position: fixed;
    /* position: sticky; */
    bottom: 0;
    left: 0;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 20px;
    /* width: inherit; */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.popup-categories div {
    width: 100%;
}

.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translatey(10px);
  opacity: 0;
}

.show-categories-button {
    width: 20px;
    height: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
}

.show-categories-button span {
    width: 100%;
    border-radius: 1px;
    height: 2px;
}

.search-icon {
    height: 20px !important;
    width: 20px !important;
}

@media(max-width: 700px) {
    .scrollbar-fixed {
        left: 0;
    }

    #scrollbar-items {
        width: 100%;
    }
}


</style>