<template>

    <div id="menu-translation-selector">

        <p class="disclaimer" v-html="$homepageTranslations.pages.pricing.checkout_options.languages_disclaimer[$store.getters.getDefaultLanguage]"></p>

        <div v-for="option in options" :key="option.name_en" @click="selectTranslations(option)" class="option">

            <div class="selector-button" :class=" { 'selector-button-selected' : option.translations === translations }"></div>

            <div class="details" :class=" { 'details-selected' : option.translations === translations }">

                <p class="language" :class=" { 'selected' : option.translations === translations}">{{ option[`name_${$store.getters.getDefaultLanguage}`] }}</p>

                <p class="price" v-if="option.price_usd != 0" :class=" { 'selected' : option.translations === translations}">${{ option.price_usd / 100 }}</p>


            </div>

        </div>



    </div>







</template>

<script>

export default {
    name: "MenuTranslationSelector",
    props: ["number_of_outlets", "translations"],
    data () {
        return {
            options: [
                { 
                    name_en: "No thanks",
                    name_id: "",
                    translations: 0,
                    price_usd: 0
                },
                {
                    name_en: "1 language",
                    name_id: "",
                    stripe_id: "price_1L7wtvG2fH76M3MVWXRmm6cS",
                    price_usd: 5000,
                    translations: 1
                },
                {
                    name_en: "2 languages",
                    name_id: "",
                    price_usd: 10000,
                    stripe_id: "price_1L7wtvG2fH76M3MVWXRmm6cS",
                    translations: 2
                },
                {
                    name_en: "3 languages",
                    name_id: "",
                    price_usd: 15000,
                    stripe_id: "price_1L7wtvG2fH76M3MVWXRmm6cS",
                    translations: 3
                },
                {
                    name_en: "4 languages",
                    name_id: "",
                    price_usd: 20000,
                    stripe_id: "price_1L7wtvG2fH76M3MVWXRmm6cS",
                    translations: 4
                }
            ]
        }
    },
    methods: {
        selectTranslations(option) {
            this.$emit('select-translations', option)
        }
    }
}





</script>

<style scoped>

.disclaimer {
    margin: 0px 0px 0px 0px;
    font-family: "Cabin", sans-serif;
    color: gray;
    letter-spacing: 0.25px;
    font-style: italic;
    margin-bottom: 50px;
}

::v-deep .bold {
    font-weight: 600;
}

.details {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
    /* border: 1px dotted lightgray; */
    justify-content: space-between;
    font-family: "Cabin", sans-serif;
    font-size: 20px;
    letter-spacing: 0.5px;
    color: gray;
    border: 2px solid rgba(0, 0, 0, 0.0);
    box-sizing: border-box;
    padding: 10px;
    border-radius: 10px;
}

.details p {
    margin: 0px 0px 0px 0px;
}

.details-selected {
    border: 2px solid rgb(153, 202, 60);
}

.option {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.selector-button-selected {
    background-color: black;
    border: 2px solid black !important;
}

.selected {
    color: #3B3B3A;
    font-weight: 800;
}


</style>