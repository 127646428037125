<template>

    <div class="step">

        <div :class="{ 'current-step' : step === current_step_name, 'completed-step' : completed_steps.includes(step) }">

            <span>

                <img v-if="completed_steps.includes(step)" src="@/assets/white_check_mark.svg" />

            </span>

        </div>

        <p :class="{ 'bold' : step === current_step_name }">

            {{ step }}

        </p>

    </div>



</template>

<script>

export default {
    name: 'Step',
    props: ['step', 'current_step_name', 'completed_steps'],
    data () {
        return {

        }
    }
}


</script>

<style scoped>

.step {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.step div {
    width: 50px;
    height: 50px;
    border: 2px solid lightgray;
    display: flex;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    background-color: white;
}

.current-step {
    width: 35px;
    height: 35px;
    /* border: 2px dotted #94C13D; */
    background-color: #D1E1AB !important;
    border: 2px solid #D1E1AB !important;
    border-radius: 50%;
    padding: 2.5px;
    box-sizing: border-box;
}

.completed-step {
    width: 35px;
    height: 35px;
    /* border: 2px solid #94C13D; */
    /* background-color: #94C13D; */
    background-color: #078E45 !important;
    border: 2px solid white !important;
    border-radius: 50%;
    box-sizing: border-box;    
    display: flex;
    justify-content: center;
    align-items: center;
}

.completed-step img {
    width: 25px;
    height: 25px;
}

p {
    font-size: 12.5px;
    color: gray;
}

.bold {
    font-weight: 600;
    color: black;
}

@media(max-width: 700px) {
    p {
        font-size: 10px;
    }
}


</style>