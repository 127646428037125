import Vue from 'vue'
import App from './App.vue'
import store from './store'
import VueAxios from 'vue-axios'
import VueRouter from 'vue-router'
import New from '@/components/New'
import EditMenu from '@/components/EditMenu'
import NewItem from '@/components/NewItem'
import EditItem from '@/components/EditItem'
import Show from '@/components/Show'
import NewOrder from '@/components/NewOrder'
import Order from '@/components/Order'
import { upload } from './file-upload.service';
import VueFileAgent from 'vue-file-agent';
import Vue2TouchEvents from 'vue2-touch-events'
import Plans from '@/components/Plans'
import Login from '@/components/Login'
import Menus from '@/components/Menus'
import SignUp from '@/components/SignUp'
import Subscribe from '@/components/Subscribe'
import Dashboard from '@/components/user/Dashboard'
import UserDetails from '@/components/user/UserDetails'
import SubscriptionSettings from '@/components/user/SubscriptionSettings'
import ContactUs from '@/components/user/ContactUs'
import Home from '@/components/home/Home'
import Pricing from '@/components/pricing/Pricing'
import Features from '@/components/features/Features'
import MenuRedirect from '@/components/miscellaneous/MenuRedirect'
import MenuCollectionRedirect from '@/components/miscellaneous/MenuCollectionRedirect'
import SuperMenuCollectionRedirect from '@/components/miscellaneous/SuperMenuCollectionRedirect'
import Orders from '@/components/orders/Orders'
import CurrentActivity from '@/components/orders/CurrentActivity'
import HistoricalOrders from '@/components/orders/HistoricalOrders'
import { EventBus } from './event-bus.js'
import OrderRestaurantView from '@/components/orders/Order'
import MenuSettings from '@/components/orders/MenuSettings'
import moment from 'moment'
import VueTelInput from 'vue-tel-input'
import Analytics from '@/components/orders/Analytics'
import Discounts from '@/components/orders/Discounts'
import AddDiscount from '@/components/orders/AddDiscount'
import Discount from '@/components/orders/Discount'
import OrderSession from '@/components/orders/OrderSession'
import PrintingInstructions from '@/components/orders/PrintingInstructions'
import CreateOrderSession from '@/components/orders/CreateOrderSession'
import CreateServer from '@/components/orders/CreateServer'
import EditServer from '@/components/orders/EditServer'
import CreateTable from '@/components/orders/CreateTable'
import EditTable from '@/components/orders/EditTable'
import MyTables from '@/components/orders/MyTables'
import Importer from '@/components/csv/Importer'
import GlobalSettings from '@/components/global_settings/GlobalSettings'
import AdminUserRelationships from '@/components/user/AdminUserRelationships'
import 'vue-tel-input/dist/vue-tel-input.css'
import VueMeta from 'vue-meta'
import { translations } from './translations/translations.js'
import { homepageTranslations } from './translations/homepageTranslations.js'
import VueLazyload from 'vue-lazyload'
import firebase from './firebase'
import firebaseMessaging from './firebase'

Vue.prototype.moment = moment



import { plainAxiosInstance, securedAxiosInstance, uploadAxiosInstance } from './backend/axios'
import './registerServiceWorker'

Vue.config.productionTip = false

Vue.use(VueRouter);

Vue.use(VueLazyload);

Vue.use(VueFileAgent);

Vue.use(Vue2TouchEvents);

Vue.use(require('vue-cookies'))

if (firebase.messaging.isSupported()) {
  console.log('firebase is supported')
  Vue.prototype.$messaging = firebase.messaging()
} else {
  console.log('firebase is not supported')
}

Vue.use(require('vue-pusher'), {
  api_key: '04617d2b129b1b5a2f05',
  options: {
    cluster: 'ap1',
    encrypted: true,
  }
});

const translationsPlugin = {
  install() {
    Vue.translations = translations
    Vue.prototype.$translations = translations
  }
}

const homepageTranslationsPlugin = {
  install() {
    Vue.homepageTranslations = homepageTranslations
    Vue.prototype.$homepageTranslations = homepageTranslations
  }
}

Vue.use(translationsPlugin)

Vue.use(homepageTranslationsPlugin)

Vue.use(VueMeta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true
})

// Vue.use(TawkMessengerVue, {
//   propertyId : '5d6e1cf477aa790be3321e8c',
//   widgetId : 'default'
// });


Vue.use(VueAxios, {
  secured: securedAxiosInstance,
  plain: plainAxiosInstance,
  upload: uploadAxiosInstance
})

export const router = new VueRouter({
  mode: 'history',
  routes: [
    // ordering system (back office) routes below this line
    { path: "/printing", component: PrintingInstructions },
    { path: "/orders", component: Orders },
    { path: "/current_activity", component: CurrentActivity },
    { path: "/show_order/:id", component: OrderRestaurantView },
    { path: "/menu_settings/:id", component: MenuSettings },
    { path: "/analytics", component: Analytics },
    { path: "/:menu_id/new_discount", component: AddDiscount },
    { path: "/order_sessions/new", component: CreateOrderSession },
    { path: "/order_sessions/:id", component: OrderSession },
    { path: "/servers/:id", component: EditServer },
    { path: "/new_server", component: CreateServer },
    { path: "/tables/:id", component: EditTable },
    { path: "/new_table", component: CreateTable },
    { path: "/tables", component: MyTables },

    // admin user relationships
    { path: "/admin_user_relationships", component: AdminUserRelationships },

    // miscellaneous routes below this line
    { path: "/global_settings", component: GlobalSettings },

    // menu builder routes below this line
    { path: "/new", component: New },
    { path: "/login", component: Login },
    { path: "/sign_up", component: SignUp },
    { path: "/", component: Home },
    { path: "/orders/new", component: NewOrder },
    { path: "/orders/:id", component: Order },
    { path: "/plans", component: Plans },
    { path: "/subscribe", component: Subscribe },
    { path: "/dashboard", component: Dashboard },
    { path: "/account", component: UserDetails },
    { path: "/subscription", component: SubscriptionSettings },
    { path: "/contact", component: ContactUs },
    { path: "/home", component: Home },
    { path: "/menus", component: Menus },
    { path: "/pricing", component: Pricing },
    { path: "/features", component: Features },
    { path: "/:id/edit", component: EditMenu },
    { path: "/csv_import/:menu_id", component: Importer },
    { path: "/super_menu_collections/:super_menu_collection_id", component: SuperMenuCollectionRedirect },
    { path: "/menu_collections/:menu_collection_id", component: MenuCollectionRedirect },
    { path: "/menu_collections/:menu_collection_id/:language", component: MenuCollectionRedirect },
    { path: "/menus/:menu_id/view_mobile", component: MenuRedirect },
    { path: "/menus/:menu_id/view_mobile/:language", component: MenuRedirect },
    { path: "/:menu_id", component: Show },
    { path: "/:menu_id?table=:table_number", component: Show },
    { path: "/:menu_id/:language", component: Show },
    { path: "/:menu_id/:language/:key", component: Show }

  ]
});

new Vue({
  el: '#app',
  router,
  store,
  upload,
  // translations,
  securedAxiosInstance,
  plainAxiosInstance,
  components: { App },
  template: '<App/>'
})
