

<template>

    <div class="order-type-container">
        <div class="header" :style="{ 'background-color' : menu.menu_header_background_color }" @click="backToMenu">
            <img v-bind:src="menu.logo.url" class="logo" />
        </div>

        <div class="order-type-selector">
            
            <div class="order-type" v-if="menu.orderable === true" @click="selectOrderType('in-restaurant')" :class="{ 'selected' : order_type === 'in-restaurant' }">

                <h3 v-if="menu.menu_type === 'restaurant' || menu.menu_type === '' || menu.menu_type === null">{{ this.$translations.in_restaurant[language] || this.$translations.in_restaurant['en'] }}</h3>

                <h3 v-if="menu.menu_type === 'golf course'">On course</h3>

                <h3 v-if="menu.menu_type === 'spa'">At spa</h3>

            </div>

            <div class="order-type" v-if="menu.orders_for_pickup === true" @click="selectOrderType('pickup')" :class="{ 'selected' : order_type === 'pickup' }">

                <h3>{{ this.$translations.pickup[language] || this.$translations.pickup['en'] }}</h3>

            </div>

            <div class="order-type" v-if="menu.orders_for_delivery === true" @click="selectOrderType('delivery')" :class="{ 'selected' : order_type === 'delivery' }">

                <h3>{{ this.$translations.delivery[language] || this.$translations.delivery['en'] }}</h3>

            </div>

            <div class="order-type" v-if="menu.orders_for_in_room === true" @click="selectOrderType('in-room')" :class="{ 'selected' : order_type === 'in-room' }">

                <h3>{{ this.$translations.in_room[language] || this.$translations.in_room['en'] }}</h3>

            </div>            


        </div>






    </div>




</template>


<script>

export default {
    name: 'SelectOrderType',
    props: ['menu', 'language'],
    data () {
        return {
            order_type: ''
        }
    },
    created () {
        this.getDefaultOrderType()
    },
    methods: {
        selectOrderType(type) {
            this.order_type = type
            this.$emit('order-type', type)
        },
        backToMenu() {
            this.$emit('back-to-menu')
        },
        getDefaultOrderType() {
            if (this.menu.orderable === true) {
                this.selectOrderType('in-restaurant')
            } else if (this.menu.orders_for_pickup === true) {
                this.selectOrderType('pickup')
            } else if (this.menu.orders_for_delivery === true) {
                this.selectOrderType('delivery')
            } else if (this.menu.orders_for_in_room === true) {
                this.selectOrderType('in-room')
            }
        }
    }
}



</script>


<style scoped>

.header {
    width: 100%;
    max-width: 700px;
    border: 1px solid lightgray;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo {
    max-width: 100px;
    max-height: 50px;
}

.order-type-selector {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.order-type {
    width: 100%;
    border: 1px solid lightgray;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0px 0px 20px 0px;
    border-radius: 5px;
    cursor: pointer;
}

h3 {
    font-size: 12.5px;
    font-weight: 600;
}

.selected {
    background-color: black;
    border: 1px solid black;
    color: white;
}


</style>