

<template>

    <div class="note-modal">
        <div class="note-modal-form">
            <div class="close" @click="hideNoteModal()"><img src="../assets/close_new.svg" /></div>
            <h1>{{ this.$translations.note[language] || this.$translations.note['en'] }}</h1>
            <h3>{{ name }}</h3>
            <input v-model="new_note" @input="addNote()" maxlength='50' />
            <div class="submit-container">
                <transition name="fade">
                    <div class="submit" v-if="new_note != null" @click="submitNote()" >
                        <img src="@/assets/check_mark_white.svg" />
                    </div>
                </transition>
            </div>
        </div>

    </div>



</template>


<script>

export default {
    name: 'AddNote',
    props: ['selected_item', 'order_row', 'name', 'note', 'translation', 'language'],
    data () {
        return {
            new_note: ''
        }
    },
    created() {
        this.getNote();
    },
    methods: {
        getNote() {
            this.new_note = this.note
        },
        addNote() {
            this.order_row.note = this.new_note
        },
        submitNote() {
            this.$store.commit('addNote', this.order_row)
            this.$emit('close-modal')
        },
        hideNoteModal() {
            this.$emit('close-modal')
        }
    }
}




</script>

<style scoped>

.note-modal {
    width: 100%;
    height: 100%;
    /* margin: auto; */
    position: fixed;
    background-color: white;
    display: flex;
    flex-direction: column;
    /* height: 100vh;
    width: 100vh; */
    top: 0;
    right: 0;
    left: 0;
    box-sizing: border-box;
    z-index: 10000;
}

.note-modal-form {
    margin: auto;
    width: 90%;
    max-width: 500px;
}

input {
    width: 100%;
    height: 50px;
    border: 1px solid lightgray;
    font-family: 'Montserrat', sans-serif;
    font-size: 17.5px;
    border-radius: 2.5px;
    box-shadow: 0 0 8px rgba(100, 100, 100, 0.1);
    box-sizing: border-box;
    padding: 0px 15px 0px 15px;
    margin-bottom: 50px;
}

h1 {
    font-weight: 600;
}

h3 {
    font-weight: 600;
}

.close {
    width: 10px;
    height: 10px;
    margin-top: 0px;
}

.close img {
    max-width: 10px !important;
    max-height: 10px !important;
}

.submit-container {
    width: 100%;
    height: 50px;
}

.submit {
    width: 100%;
    height: 50px;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
}

.submit img {
    max-height: 30px;
    max-width: 100px;
}



</style>