<template>

    <div id="partners">

        <h3 class="gradient-text" v-html="$homepageTranslations.partners.header[$store.getters.getDefaultLanguage]"></h3>

        <p class="subhead" v-html="$homepageTranslations.partners.subhead_one[$store.getters.getDefaultLanguage]"></p>

        <p class="subhead" v-html="$homepageTranslations.partners.subhead_two[$store.getters.getDefaultLanguage]"></p>

        <PartnerIcons />

        <p class="large-subhead top-margin" v-html="$homepageTranslations.partners.subhead_three[$store.getters.getDefaultLanguage]"></p>

        <div class="numbers">

            <div class="number-container">

                <div class="number">22</div>

                <p class="large-subhead allcaps" v-html="$homepageTranslations.partners.cities[$store.getters.getDefaultLanguage]"></p>

            </div>

            <div class="number-container">

                <div class="number">19</div>

                <p class="large-subhead allcaps" v-html="$homepageTranslations.partners.countries[$store.getters.getDefaultLanguage]"></p>

            </div>

        </div>

    </div>






</template>

<script>
import PartnerIcons from '@/components/home/PartnerIcons.vue'

export default {
    name: "Partners",
    components: {
        PartnerIcons
    }
}



</script>

<style scoped>

#partners {
    box-sizing: border-box;
    padding: 0px 5% 0px 5%;
}

h3 {
    font-size: 40px;
    text-align: center;
}

p {
    margin: 0px 0px 0px 0px;
}

.subhead {
    font-family: "Cabin", sans-serif;
    margin: 0px 0px 15px 0px;
    text-transform: uppercase;
    color: #3B3B3B;
    font-weight: 500;
    letter-spacing: 1px;
    text-align: center;
    /* margin-bottom: 50px; */
}

.top-margin {
    margin-top: 100px !important;
}

.large-subhead {
    font-family: "Cabin", sans-serif;
    color: #3B3B3B;
    font-size: 25px;
    text-align: center;
    max-width: 500px;
    margin: auto;
    /* margin-top: 50px; */
    letter-spacing: 1px;
    line-height: 40px;
}

::v-deep .green {
    color: rgb(153, 202, 60);
    font-weight: 800;
}

.number {
    font-size: 60px;
    font-weight: 800;
    text-align: center;
    font-family: "Cabin", sans-serif;
    margin-top: 50px; 
}

.allcaps {
    text-transform: uppercase;
}

.numbers {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.number-container {
    margin: 0px 50px 0px 50px;
}

@media(max-width: 800px) {
    .numbers {
        flex-direction: column;
    }
}

</style>