<template>

    <div id="create-order-session-container">

        <div class="error" v-if="error != ''">{{ error }}</div>

        <h1>Create a new order session</h1>

        <div class="order-session-creation-modal">

            <h4>Select menu</h4>

            <div class="form-selector">

                <div v-for="menu in menusWithSessionBasedOrdering()" :key="menu.id" @click="selectMenu(menu)" :class="{ 'selected-option' : selected_menu === menu }">

                    {{ menu.name }}

                </div>

            </div>

            <div class="form-row" v-if="selected_menu != {}">

                <h4>Enter table number</h4>

                <input v-model="table_number">

            </div>

            <div class="form-row" v-if="selected_menu != {}">

                <h4>Enter number of guests</h4>

                <input type="number" v-model="pax">

            </div>

            <div class="form-row" v-if="selected_menu != {}">

                <h4>Enter guest name</h4>

                <input v-model="guest_name">

            </div>

            <h4 v-if="menu_specific_languages.length > 0">Select language</h4>

            <div class="form-selector" v-if="menu_specific_languages.length > 0">

                <div v-for="language in menu_specific_languages" :key="language[2]" @click="selected_language = language[2]" :class="{ 'selected-option' : selected_language === language[2] }">

                    {{ language[0] }}

                </div>
                
            </div>
            

        </div>

        <div @click="createOrderSession()" class="create-button" v-if="table_number != '' && pax != Number && selected_menu != {} && selected_language != ''">Create order session</div>

    </div>





</template>


<script>

export default {
    name: 'CreateOrderSession',
    data () {
        return {
            menus: [],
            selected_menu: {},
            selected_language: '',
            table_number: '',
            pax: Number,
            guest_name: '',
            menu_specific_languages: [],
            error: '',
            // possible_languages: [],
            possible_languages: [ ['English', 'English', 'en'], ['Spanish', 'Español', 'sp'], ['Chinese', '中文', 'zh'], [ 'French', 'français', 'fr'], ['Russian', 'русский', 'ru'], ['Arabic', 'العربية', 'ar'], ['German', 'Deutsch', 'de'], ['Korean', '한국어', 'kr'], ['Japanese', '日本語', 'jp'], ['Dutch', 'Nederlands', 'nl'], ['Italian', 'Italiano', 'it'], ['Catalan', 'Català', 'ca']  ].sort()
        }
    },
    created() {
        this.scrollToTop();
        this.getMenus();
    },
    methods: {
        selectMenu(menu) {
            this.selected_menu = menu;
            const selectedMenu = menu
            const languages = this.possible_languages
            console.log(languages)
            const menuSpecificLanguages = []
            for (let i = 0; i < languages.length; i++) {
                console.log(languages[i])
                if (selectedMenu[String(languages[i][2])] === true) {
                    menuSpecificLanguages.push(languages[i])
                }
                if (selectedMenu.primary_language === languages[i][0]) {
                    if (!menuSpecificLanguages.includes(languages[i])) {
                        menuSpecificLanguages.push(languages[i])
                    }
                }
            }
            this.menu_specific_languages = menuSpecificLanguages
        },
        createOrderSession() {
            if (this.guest_name === '') {
                this.guest_name = 'none'
            }
            if (this.table_number != '' && this.pax != Number && this.selected_menu != {}) {
                this.$http.secured.post(`/app_create_order_session/${this.selected_menu.id}/${this.table_number}/${this.selected_language}/${this.pax}/${String(this.guest_name)}`)
                    .then(response => { console.log(response), this.$router.replace(`/order_sessions/${response.data.order_session.id}`) })
                    .catch(error => this.setError(error, 'Something went wrong'))
            } else {
                this.error = "All fields but guest name are required."
            }
        },
        getMenus() {
            this.$http.secured.get('/app_get_my_menus')
                .then(response => { this.menus = response.data })
                .catch(error => this.setError(error, 'Something went wrong'))
        },
        menusWithSessionBasedOrdering() {
            return this.menus.filter(menu => menu.session_based_ordering === true);
        },
        scrollToTop() {
            window.scrollTo(0,0);
        }
    }
}




</script>


<style scoped>

#create-order-session-container {
    /* margin-bottom: 50px; */
    margin: auto;
    margin-top: 100px;
    margin-bottom: 50px;
    max-width: 1000px;
    width: 90%;

}

#create-order-session-container h1 {
    font-size: 30px;
    font-weight: 200;
    margin: 0px 0px 40px 0px;
}


.form-selector {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.form-selector h4 {
    width: 100%;
    
}

.form-selector div {
    width: max-content;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid lightgray;
    color: gray;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 2.5px;
    cursor: pointer;
    margin: 5px 5px 5px 0px;
    font-size: 12.5px;
}

.selected-option {
    background-color: #00a19a;
    color: white !important;
    border: 1px solid #00a19a !important;
    transition: 0.2s;
}

.order-session-creation-modal h4 {
    font-weight: 600;
    border-bottom: 1px dotted lightgray;
    width: max-content;
}

.form-row {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 0px 0px 0px 0px;
    /* border-bottom: 1px solid lightgray; */
}

input {
    width: 100%;
    margin-bottom: 20px;
    font-family: 'Montserrat', sans-serif;
    height: 45px;
    font-size: 25px;
    border: 1px solid lightgray;
    font-weight: 200;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 10px;
}

.create-button {
    width: 100%;
    display: flex;
    margin-top: 25px;
    color: white;
    background-color: #00a19a;
    font-size: 15px;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 20px;
    font-weight: 600;
    border-radius: 2.5px;
    cursor: pointer;
}


</style>