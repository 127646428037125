<template>

    <div id="orders-dashboard" class="container">

        <div class="overlay">

            <img src="@/assets/banner-overlay.svg" />

        </div>
        

        <div class="image-container">

            <img src="@/assets/homepage/dashboard_myorders.svg" />

        </div>

        <div class="header-text" @click="goToOrders()">

            <h2>My orders</h2>

            <p>Receive orders and view analytics</p>

        </div>



        <div class="snapshot-container">

            <div class="snapshot">

                <h5>Orders snapshot</h5>

                <p>Orders today: {{ orders.orders_today }}</p>

                <p>Orders this week: {{ orders.orders_this_week }}</p>

                <p>Orders this month: {{ orders.orders_this_month }}</p>

            </div>

            <div class="button" @click="goToOrders()">
                <p>Go to orders</p>
            </div>

        </div>

    </div>



</template>

<script>

export default {
    name: "OrdersDashboard",
    props: ['orders'],
    methods: {
        goToOrders() {
            // window.location.replace('https://orders.menulingua.com')
            this.$router.push('/orders')
        }
    }
}




</script>

<style scoped>

#orders-dashboard {
    width: 95%;
    display: flex;
    flex-direction: row;
    position: relative;
    margin: auto;
    border-radius: 10px;
    margin-bottom: 50px;
    max-height: 350px;
    overflow: hidden;
    box-shadow: 0 0 32px rgba(0, 0, 0, 0.1);
}

.container {
    color: white;
    background-image: linear-gradient(to left, rgb(28, 117, 188), rgb(43, 57, 144));
    box-sizing: border-box;
    padding: 50px 2.5% 0px 2.5%;
}

.header-text {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 300px;
    z-index: 10;
    cursor: pointer;
}

h2 {
    margin: 0px 0px 0px 0px;
    font-weight: 600;
    font-size: 35px;
}

.header-text p {
    font-size: 2vw;
    font-weight: 400;
}

.image-container {
    width: 30%;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    max-height: 300px;
}

.image-container img {
    min-height: 300px;
}

.snapshot-container {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    z-index: 10;
}

.snapshot {
    background-color: white;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 5px;
    color: black;
    width: max-content;
    min-width: 300px;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
}

.snapshot p {
    font-size: 12.5px;
    font-weight: 600;
    margin: 5px 0px 5px 0px;
}

.snapshot h5 {
    font-size: 20px;
    font-weight: 600;
    margin: 0px 0px 20px 0px;
}

.menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 0px 10px 0px;
    cursor: pointer;
    width: 100%;
}

.menu p {
    font-size: 12.5px;
    font-weight: 600;
    margin: 0px 0px 0px 0px;
}


.button {
    display: flex;
    box-sizing: border-box;
    padding: 10px 40px 10px 40px;
    border-radius: 2.5px;
    cursor: pointer;
    background-color: rgb(28, 117, 188);
    color: white;
    width: max-content;
    margin: 25px 0px 25px 0px;
    min-width: 300px;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
}

.button p {
    margin: 0px 0px 0px 0px;
    width: 100%;
    text-align: center;
}

.button:hover {
    box-shadow: 0 0 32px rgba(0, 0, 0, 0.1);
    transition: 0.2s;
}

@media(max-width: 1100px) {
    #orders-dashboard {
        flex-wrap: wrap;
        max-height: 1000px;
    }

    .header-text {
        width: 50%;
        height: auto;
    }

    .image-container {
        width: 100%;
        order: 3;
        height: auto;
    }

    .snapshot-container {
        width: 50%;
        justify-content: flex-end;
    }
}

@media(max-width: 700px) {

    #orders-dashboard {
        /* padding: 100px 10% 0px 10%; */
        width: 95%;
        /* margin: auto; */
        padding: 50px 5% 0px 5%;
        margin: 20px auto 20px auto;
        /* border-radius: 0px; */
    }

    .header-text {
        width: 100%;
        margin: 0px 0px 25px 0px;
        height: auto;
        min-height: 0px;
    }
    .header-text p {
        font-size: 15px;
    }

    .snapshot-container {
        width: 100%;
        align-items: flex-start;
    }

    .snapshot {
        width: 100%;
    }

    .button { 
        width: 100%;
    }
}


</style>