

const state = () => ({
    anchored_item: '',
    menu: {},
    menu_id: Number,
    languages: []
})


const mutations = {
    setAnchoredItem (state, anchoredItem) {
        state.anchored_item = anchoredItem
    },
    setMenu (state, menu) {
        state.menu = menu
        state.menu_id = menu.id
    },
    setMenuId (state, menu_id) {
        state.menu_id = menu_id
    },
    setMenuLanguages (state, languages) {
        state.languages = languages
    }
}


const getters = {
    getAnchoredItem (state) {
        return state.anchored_item
    },
    getMenu (state) {
        return state.menu
    },
    getMenuId (state) {
        return state.menu_id
    },
    getLanguages (state) {
        return state.languages
    }
}


export default {
    state,
    getters,
    mutations
}