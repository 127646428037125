<template>

    <div id="contact-us-referral" @click="goToContactForm()">

        <img class="contact-img" src="@/assets/homepage/contact_icon.svg" />

        <p>Have a question? <span>Contact us</span></p>


    </div>


</template>

<script>

export default {
    name: "ConactUsReferral",
    methods: {
        goToContactForm() {
            this.$router.push('/contact')
        }
    }
}


</script>

<style scoped>


#contact-us-referral {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: auto;
    cursor: pointer;
    box-sizing: border-box;
    padding: 5px 20px 5px 20px;
    border-radius: 5px;
    border: 1px solid lightgray;
    width: max-content;
    margin-top: 100px;
}

img {
    width: 25px;
    height: 25px;
    margin-right: 10px;
}

p {
    font-weight: 600;
    margin: 0px;
}


</style>