
<template>

    <div class="help-request-container">

        <div class="content">

            <div class="close" @click="hideHelpModal()"><img src="../assets/close_new.svg" /></div>

            <div class="help-bell">
                <img src="@/assets/bell.svg" />
            </div>

            <h1>{{ this.$translations.table_number[language] }}</h1>

            <input v-model="table_number" maxlength='50' />

            <h1>{{ this.$translations.note[language] }}</h1>

            <input v-model="note" maxlength='50' />

            <div class="submit-container">
                <transition name="fade">
                    <div class="submit" v-if="table_number != ''" @click="submitHelpRequest(table_number, note)" >
                        <img src="@/assets/check_mark_white.svg" />
                    </div>
                </transition>
            </div>

        </div>

    </div>



</template>

<script>

export default {
    name: 'HelpRequest',
    props: ['menu_id', 'language'],
    data () {
        return {
            table_number: '',
            note: ''
        }
    },
    created() {
        this.checkForOrderSession();
    },
    methods: {
        checkForOrderSession() {
            if (localStorage.table_number != 'null') {
                this.table_number = localStorage.table_number
            }
        },
        hideHelpModal() {
            this.$emit('close-modal')
        },
        submitHelpRequest(table_number, note) {
            this.$emit('submit-help-request', table_number, note)
        }
    }
}



</script>

<style scoped>

.help-request-container {
    position: fixed;
    background-color: white;
    width: 100%;
    height: 100%;
    z-index: 10000000;
    display: flex;
    top: 0;
    left: 0;
    
}

.content {
    max-width: 600px;
    width: 90%;
    margin: 50px auto 50px auto;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

h1 {
    font-weight: 600;
    font-size: 16px;
    margin-top: 25px;
}

.close {
    width: 10px;
    height: 10px;
    margin-top: 0px;
    margin-bottom: 50px;
}

.close img {
    max-width: 10px !important;
    max-height: 10px !important;
}

.help-bell {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #F7931D;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 5px;
    cursor: pointer;
}

.help-bell img {
    max-width: 45px;
    max-height: 45px;
}

.submit-container {
    width: 100%;
    height: 50px;
}

input {
    width: 100%;
    height: 50px;
    border: 1px solid lightgray;
    font-family: 'Montserrat', sans-serif;
    font-size: 17.5px;
    border-radius: 2.5px;
    box-shadow: 0 0 8px rgba(100, 100, 100, 0.1);
    box-sizing: border-box;
    padding: 0px 15px 0px 15px;
    margin-bottom: 0px;
}

.submit {
    width: 100%;
    height: 50px;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 50px;
}

.submit img {
    max-height: 30px;
    max-width: 100px;
}


</style>