<template>

    <div class="steps-container">

        <div class="steps-container-inline" v-for="step in steps" :key="step">

            <Step
                :step = step
                :step_name = step.name 
                :current_step_name = getCurrentStepName()
                :completed_steps = getCompletedSteps()
            />

        </div>

        <div class="line-container">

            <span></span>

        </div>


    </div>




</template>
    
<script>
import Step from '@/components/Step.vue'

export default {
    name: 'Steps',
    components: {
        Step
    },
    props: ['current_step', 'total_steps', 'step', 'steps'],
    data () {
        return {
            // completed_steps: []
        }
    },
    methods: {
        getCurrentStepName() {
            return this.steps[this.current_step - 1]
        },
        getCompletedSteps() {
            var steps = this.steps
            var completed_steps = []
            for (var i = 0; i < steps.length; i++) {
                 if (i < this.current_step - 1 ) {
                    completed_steps.push(steps[i]);
                }
            }
            return completed_steps
        }
    }
}

</script>

<style scoped>

.steps-container {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 50px;
    align-self: flex-end;
    position: relative;
   
}

.steps-container-inline {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 10;
}

.line-container {
    width: 80%;
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    height: 50px;
    z-index: 1;
}

.line-container span {
    width: 80%;
    border-top: 1px dotted lightgray;
}

@media(max-width: 700px) {
    .steps-container {
        width: 100%;
        margin-bottom: 30px;
        flex-grow: 1;
    }
}




</style>