<template>

    <div id="plans-selector">

        <div v-for="option in checkout_options" :key="option.stripe_id" class="checkout-option" @click="chooseOption(option)">

            <div class="selector-button" :class=" { 'selector-button-selected' : option === selected_plan }">


            </div>

            <div class="details" :style="(option === selected_plan) ? `border: 2px solid ${price.primary_colour};` : 'border: 2px solid rbga(0, 0, 0, 0);'">

                <p class="duration" v-html="option[`duration_${$store.getters.getDefaultLanguage}`]" :class=" { 'selected' : option === selected_plan }"></p>

                <p class="price" :class=" { 'selected' : option === selected_plan }"><span :class=" { 'selected' : option === selected_plan }">$</span> {{ (option.price_usd / 100) * number_of_outlets }}</p>


            </div>

        </div>

    </div>

</template>

<script>

export default {
    name: "PlansSelector",
    props: ["checkout_options", "selected_plan", "number_of_outlets", "price"],
    methods: {
        chooseOption(option) {
            this.$emit('choose-option', option)
        },
        getMonthlyRate(plan) {
            let rate = 0
            if (plan.duration_in_months > 1) {
                rate = ((plan.price_usd / 100) / plan.duration_in_months)
            }
            return rate
        }
    }
}




</script>

<style scoped>

#plans-selector {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.checkout-option {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0px 0px 0px 0px;
    cursor: pointer;
    font-family: "Cabin", sans-serif;
    letter-spacing: 0.5px;

}

.checkout-option p {
    margin: 0px 0px 0px 0px;
}

.duration {
    font-size: 12.5px;
    color: #3B3B3A;
    color: gray;
    text-transform: uppercase;
    font-weight: 700;
}

.details {
    border: 2px solid rgba(0, 0, 0, 0.0);
}


.selector-button-selected {
    background-color: black;
    border: 2px solid black !important;
}

.details {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 15px;
    border-radius: 5px;
}

.price {
    font-size: 25px;
    color: #3B3B3A;
    color: gray;
    font-weight: 800;
}

.selected {
    color: #3B3B3A;
}

span {
    color: gray;
}

.details-selected {
    border: 2px solid rgb(153, 202, 60);
}

</style>