<template>

    <div id="home-banner">

        <div class="homepage-overlay" :style="{'background-image': 'url(' + require('@/assets/homepage/pattern_withoutbackgr-dark_resized.png') + ')'}"></div>

        <div class="banner-content">

            <h1 class="main" v-html="$homepageTranslations.banner.header[$store.getters.getDefaultLanguage]"></h1>

            <h2 v-html="$homepageTranslations.banner.subhead[$store.getters.getDefaultLanguage]"></h2>

        </div>

        <div class="filler"></div>

        <img class="banner-image" src="@/assets/homepage/homepage_banner_outlined_2_extended.svg" />

    </div>

</template>

<script>

export default {
    name: "HomeBanner"
}



</script>

<style scoped>

#home-banner {
    position: relative;
    /* height: vw; */
    height: 650px;
    display: flex;
    flex-direction: column;
    /* align-items: space-between; */
    justify-content: space-between;
    margin-top: 125px;
    border-bottom: 1px solid #E6E7E8;
    /* border: 1px dotted lightgray; */
}

.homepage-overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 650px;
    background-repeat: repeat;
    opacity: 0.05;
}

.banner-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 20px 25% 0px 25%;
    align-items: center;
}

.main {
    font-size: 50px;
    text-align: center;
    font-weight: 400;
    color: #3B3B3B;
}

.main ::v-deep .light-green-text {
    font-weight: 700;
    color: #8CC63E;
}

.main ::v-deep .dark-green-text {
    font-weight: 700;
    color: #158A44;
}

h2 {
    text-align: center;
    font-family: "Cabin", sans-serif;
    font-weight: 400;
    max-width: 500px;
    font-size: 20px;
    color: #3B3B3B;
    margin: 0px 0px 0px 0px;
}

.banner-image {
    width: 100%;
    margin: auto;
    max-width: 800px;
    /* margin-top: -80px; */
}

@media(max-width: 1300px) {
    .main {
        font-size: 3.6vw;
    }

    h2 {
        font-size: 1.7vw;
    }

    .banner-content {
        padding: 10px 10% 10px 10%;
    }

    #home-banner {
        height: 650px;
    }

    .homepage-overlay {
        height: 650px;
    }

    .banner-image {
        margin-top: 0px;
    }
}

@media(max-width: 1000px) {
    .main {
        font-size: 40px;
    }

    h2 {
        font-size: 20px;
    }

}


@media(max-width: 600px) {
    .main {
        font-size: 30px;
    }

    h2 {
        font-size: 20px;
    }

    #home-banner {
        height: 500px;
        margin-bottom: 50px;
    }

    .homepage-overlay {
        height: 500px;
    }
}



@media(max-width: 500px) {

    .main {
        font-size: 5.5vw;
        font-weight: 600;
    }

    h2 {
        font-size: 4vw;
    }

    #home-banner {
        height: 100vw;
        margin-bottom: 20px;
    }

    .homepage-overlay {
        height: 320px;
        height: 100vw;
    }
}

</style>