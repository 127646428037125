<template>

    <div id="checkout-options">

        <div class="loader green-loader" v-if="redirecting === true">

            <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>

            <p> {{ $homepageTranslations.pages.pricing.checkout_options.redirecting_to_payment[$store.getters.getDefaultLanguage] }}</p>


        </div>

        <div v-else>

            <div class="form-field">

                <p class="label" v-html="$homepageTranslations.pages.pricing.checkout_options.number_of_outlets[$store.getters.getDefaultLanguage]" :style="{ 'color' : `${price.primary_colour}`}"></p>

                <div class="outlets" @click="number_selector_shown = !number_selector_shown" :style="{ 'border' : `2px solid ${price.primary_colour}`}">

                    <p class="outlet-text" v-if="number_selector_shown === false">{{ number_of_outlets }}<img src="@/assets/checkout/dropdown-arrow.svg" class="dropdown-arrow" v-if="number_selector_shown != true" /></p>

                    <NumberSelectorDropdown :numbers="numbers" v-on:number-selected="updateOutlets" v-if="number_selector_shown === true" :selected="number_of_outlets" />

                </div>

            </div>

            <div class="form-field">

                <p class="label" v-html="$homepageTranslations.pages.pricing.checkout_options.choose_your_plan[$store.getters.getDefaultLanguage]" :style="{ 'color' : `${price.primary_colour}`}"></p>

                <PlansSelector :price="price" :checkout_options="checkout_options" :selected_plan="selected_plan[0]" v-on:choose-option="chooseOption" :number_of_outlets="number_of_outlets" />

            </div>

            <div class="form-field" v-if="price.includes_translation_options === true">

                <p class="label" v-html="$homepageTranslations.pages.pricing.checkout_options.menu_translation[$store.getters.getDefaultLanguage]"></p>

                <MenuTranslationSelector :number_of_outlets="number_of_outlets" v-on:select-translations="selectTranslations" :translations="translations" />

            </div>

            <div class="proceed-to-checkout" v-if="translations != Number && selected_plan != []">

                <GreenCircles :custom_colour="price.primary_colour" />

                <div class="grand-total-container">

                    <p class="grand-total" :style="{ 'color' : price.primary_colour }" v-html="$homepageTranslations.pages.pricing.checkout_options.grand_total[$store.getters.getDefaultLanguage]"></p>

                    <p class="grand-total-price"><span>$</span>{{ getGrandTotal() }}</p>

                </div>

                <div class="proceed-to-checkout-button" @click="buildStripeObject()" :style="{ 'background' : price.bottom_colour }">

                    {{ $homepageTranslations.pages.pricing.checkout_options.proceed_to_checkout[$store.getters.getDefaultLanguage] }}

                </div>   

            </div>

        </div>

    </div>

</template>

<script>
import NumberSelectorDropdown from "@/components/pricing/NumberSelectorDropdown.vue"
import PlansSelector from "@/components/pricing/PlansSelector.vue"
import MenuTranslationSelector from "@/components/pricing/MenuTranslationSelector.vue"
import GreenCircles from "@/components/home/GreenCircles.vue"

export default {
    name: "CheckoutOptions",
    components: {
        NumberSelectorDropdown,
        PlansSelector,
        MenuTranslationSelector,
        GreenCircles
    },
    props: ['checkout_options', 'user_id', 'user_email', 'price'],
    data () {
        return {
            number_of_outlets: 1,
            translations: Number,
            translation_fee: Number,
            selected_plan: [],
            selected_translation_price: [],
            number_selector_shown: false,
            numbers: Array.from(Array(10).keys()),
            line_items: [],
            stripe_object: [],
            go_to_checkout: false,
            redirecting: false
        }
    },
    created() {
        this.getPricingDefaults();
    },
    methods: {
        getPricingDefaults() {
            if (this.price.includes_translation_options === false) {
                this.translations = 0
                this.translation_fee = 0
            }
            if (this.price.checkout_options.length === 1) {
                this.selected_plan.push(this.price.checkout_options[0])
                // this.number_of_outlets = 1
                this.getGrandTotal();
            }
        },
        updateOutlets: function(number) {
            this.number_of_outlets = number
        },
        chooseOption: function(option) {
            this.selected_plan = []
            this.selected_plan.push(option)
        },
        selectTranslations: function(option) {
            this.selected_translation_price = option.stripe_id
            this.translations = option.translations
            this.translation_fee = option.price_usd
        },
        getGrandTotal() {
            return ((this.selected_plan[0].price_usd * this.number_of_outlets) + this.translation_fee) / 100
        },
        buildStripeObject() {
            this.redirecting = true
            const lineItems = []
            const selectedPlan = this.selected_plan[0]
            lineItems.push({
                price: selectedPlan.stripe_id,
                quantity: this.number_of_outlets
            })

            if (this.translations > 0) {
                lineItems.push({
                    price: this.selected_translation_price,
                    quantity: this.translations
                })
            }

            this.$http.secured.post(`/app_create_checkout_session/` , {
                email: this.user_email,
                line_items: lineItems
            })
            .then(response => { 
                console.log(response.data)
                window.location.replace(response.data)
                
            }).catch(error => this.$store.commit('setMessage', { 
                        content: "Failed to redirect to payment page",
                        error: true
                    },
                this.redirecting = false
            ))
        }
    }
}




</script>

<style scoped>

.label {
    /* color: rgb(153, 202, 60); */
    font-weight: 600;
    font-size: 20px;
}

.outlets {
    /* border: 2px solid rgb(153, 202, 60); */
    box-sizing: border-box;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    width: 100%;
}

.outlet-text {
    font-family: "Cabin", sans-serif;
    margin: 0px 0px 0px 0px;
    font-size: 20px;
    font-weight: 600;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

::v-deep .selector-button {
    width: 10px;
    height: 10px;
    margin-right: 10px;
    border: 2px solid gray;
    border-radius: 50%;
}

.dropdown-arrow {
    width: 25px;
    height: 25px;
}

.form-field {
    margin-bottom: 50px;
}

.grand-total-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: "Cabin", sans-serif;
    box-sizing: border-box;
    padding: 0px 10px 0px 10px;
    margin-bottom: 50px;
    
}

.grand-total {
    font-weight: 600;
    color: rgb(7, 153, 72);
    font-size: 25px;
    margin: 0px 0px 0px 0px;
}

.grand-total-price {
    font-size: 30px;
    margin: 0px 0px 0px 0px;
    font-weight: 800;
}

.proceed-to-checkout {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.proceed-to-checkout-button {
    width: max-content;
    align-self: center;
    box-sizing: border-box;
    padding: 25px;
    background: linear-gradient(to bottom, rgb(153, 202, 60), rgb(7, 153, 72));
    color: white;
    font-size: 25px;
    font-weight: 600;
    border-radius: 10px;
    cursor: pointer;
    margin-bottom: 25px;
}

.green-loader {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(to bottom, rgb(153, 202, 60), rgb(7, 153, 72));
    z-index: 10000;
    display: flex;
    flex-direction: column;
}

.green-loader p {
    font-weight: 600;
    font-family: 'Cabin', sans-serif;
    font-size: 25px;
    color: white;
    letter-spacing: 0.5px;
    margin: 0px 0px 0px 0px;
}

.lds-ellipsis div {
    background-color: white;
}

</style>