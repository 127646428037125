<template>

    <div id="pdf">

        <img src="@/assets/features/illo_5.svg" />

        <h4>PDF-ONLY</h4>

        <h1 v-html="$homepageTranslations.pages.features.display_online[$store.getters.getDefaultLanguage]"></h1>

        <p v-html="$homepageTranslations.pages.features.display_online_subhead[$store.getters.getDefaultLanguage]"></p>



    </div>




</template>


<script>

export default {
    name: "PDF"
}


</script>

<style scoped>

#pdf {
    width: 95%;
    max-width: 1300px;
    margin: auto;
    background: linear-gradient(to bottom, rgb(60, 179, 226), rgb(49, 140, 203));
    box-sizing: border-box;
    padding: 30px;
    color: white;
    border-radius: 100px 30px 30px 100px;
    position: relative;
    margin-top: 150px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    padding-left: 35%;
}

h4 {
    font-family: "Cabin", sans-serif;
    margin: 0px 0px 25px 0px;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 2px;
}

img {
    position: absolute;
    left: -10px;
    top: 50%;
    transform: translateY(-50%);
    height: 30vw;
}

p {
    font-family: "Cabin", sans-serif;
    font-size: 20px;
    letter-spacing: 1px;
    line-height: 30px;
}

h1 {
    font-size: 3.5vw;
    margin: 0px 0px 0px 0px;
    font-weight: 700;
}

@media(max-width: 900px) {
    #pdf {
        border-radius: 30px 30px 30px 30px;
    }

    img {
        top: 0%;
        left: 0px;
    }
}

</style>