<template>

    <div @click.prevent="nextStep()">

        <h3>{{ next_step }}</h3>

        <img src="@/assets/arrow_forward_black.svg" />

    </div>


</template>



<script>

export default {
    name: 'NextStep',
    props: ['next_step', 'editing', 'menu'],
    data () {
        return {
            url: process.env.VUE_APP_API_URL,
            loading: false,
            is_editing: Boolean
        }
    },
    created() {
        console.log(process.env.VUE_APP_API_URL)
        console.log(this.menu)
    },
    methods: {
        setError (error, text) {
            this.error = (error.response && error.response.data && error.response.data.error) || text
        },
        nextStep() {
            this.$store.commit("nextStep");
            if (this.next_step === 'Finish') {
                if (this.editing === true) {
                    this.updateMenu();
                } 
                if (this.editing != true) {
                    if (localStorage.csrf === undefined) {
                        this.createProvisionalMenu()
                    } else {
                        this.createMenu();
                    }
                }
            }
        },
        updateMenu() {       
            this.$emit('submitted')
            var urlName = ''
            urlName = 'app_update_menu/' + this.menu.id
            console.log(urlName)
            if (this.$store.getters.getStep === 4) {
                this.$http.secured.post(`/${urlName}`, { menu: {  
                    name: this.$store.getters.getOutletName,
                    restaurant_name: this.$store.getters.getOutletName,
                    menu_type: this.$store.getters.getOutletType,
                    currency: this.$store.getters.getCurrency,
                    menu_builder_v2: true,
                    images_only: this.$store.getters.getImageOnlyMenu,
                    en: this.$store.getters.getSelectedLanguages.includes('en'), 
                    ru: this.$store.getters.getSelectedLanguages.includes('ru'), 
                    jp: this.$store.getters.getSelectedLanguages.includes('jp'), 
                    sp: this.$store.getters.getSelectedLanguages.includes('sp'), 
                    fr: this.$store.getters.getSelectedLanguages.includes('fr'), 
                    ca: this.$store.getters.getSelectedLanguages.includes('ca'), 
                    kr: this.$store.getters.getSelectedLanguages.includes('kr'), 
                    de: this.$store.getters.getSelectedLanguages.includes('de'),   
                    zh: this.$store.getters.getSelectedLanguages.includes('zh'), 
                    nl: this.$store.getters.getSelectedLanguages.includes('nl'),  
                    pt: this.$store.getters.getSelectedLanguages.includes('pt'), 
                    it: this.$store.getters.getSelectedLanguages.includes('it'), 
                    ar: this.$store.getters.getSelectedLanguages.includes('ar'),
                    ina: this.$store.getters.getSelectedLanguages.includes('ina'),
                    gre: this.$store.getters.getSelectedLanguages.includes('gre'),
                    ro: this.$store.getters.getSelectedLanguages.includes('ro')
                } })
                .then(response => {
                    this.$store.commit("resetSteps");
                    this.$router.replace(`/${this.menu.id}`)
                    this.resetMenuOptions();
                })
                .catch(error => this.setError(error, 'Cannot create outlet'))
            } 
        },
        createProvisionalMenu() {
            this.$emit('submitted')
            var urlName = ''
            urlName = 'app_create_provisional_menu'
            console.log(urlName)
            if (this.$store.getters.getStep === 4 && this.$store.getters.getCurrency != '') {
                this.$http.plain.post(`/${urlName}`, { menu: {  
                    name: this.$store.getters.getOutletName,
                    restaurant_name: this.$store.getters.getOutletName,
                    menu_type: this.$store.getters.getOutletType,
                    currency: this.$store.getters.getCurrency,
                    menu_builder_v2: true,
                    images_only: this.$store.getters.getImageOnlyMenu,
                    en: this.$store.getters.getSelectedLanguages.includes('en'), 
                    ru: this.$store.getters.getSelectedLanguages.includes('ru'), 
                    jp: this.$store.getters.getSelectedLanguages.includes('jp'), 
                    sp: this.$store.getters.getSelectedLanguages.includes('sp'), 
                    fr: this.$store.getters.getSelectedLanguages.includes('fr'), 
                    ca: this.$store.getters.getSelectedLanguages.includes('ca'), 
                    kr: this.$store.getters.getSelectedLanguages.includes('kr'), 
                    de: this.$store.getters.getSelectedLanguages.includes('de'),   
                    zh: this.$store.getters.getSelectedLanguages.includes('zh'), 
                    nl: this.$store.getters.getSelectedLanguages.includes('nl'),  
                    pt: this.$store.getters.getSelectedLanguages.includes('pt'), 
                    it: this.$store.getters.getSelectedLanguages.includes('it'), 
                    ar: this.$store.getters.getSelectedLanguages.includes('ar'),
                    ina: this.$store.getters.getSelectedLanguages.includes('ina'),
                    gre: this.$store.getters.getSelectedLanguages.includes('gre')   
                } })
                .then(response => {
                    console.log(response.data)
                    this.$router.replace(`/${response.data.menu_id}`)
                    this.resetMenuOptions();
                    // location.replace(process.env.VUE_APP_API_URL + "/menus/" + response.data.menu_id)
                })
                .catch(error => this.setError(error, 'Cannot create outlet'))
            }
        },
        createMenu() {
            this.$emit('submitted')
            var urlName = ''
            urlName = 'app_create_menu'
            console.log(urlName)
            if (this.$store.getters.getStep === 4) {
                this.$http.secured.post(`/${urlName}`, { menu: {  
                    name: this.$store.getters.getOutletName,
                    restaurant_name: this.$store.getters.getOutletName,
                    menu_type: this.$store.getters.getOutletType,
                    currency: this.$store.getters.getCurrency,
                    menu_builder_v2: true,
                    images_only: this.$store.getters.getImageOnlyMenu,
                    en: this.$store.getters.getSelectedLanguages.includes('en'), 
                    ru: this.$store.getters.getSelectedLanguages.includes('ru'), 
                    jp: this.$store.getters.getSelectedLanguages.includes('jp'), 
                    sp: this.$store.getters.getSelectedLanguages.includes('sp'), 
                    fr: this.$store.getters.getSelectedLanguages.includes('fr'), 
                    ca: this.$store.getters.getSelectedLanguages.includes('ca'), 
                    kr: this.$store.getters.getSelectedLanguages.includes('kr'), 
                    de: this.$store.getters.getSelectedLanguages.includes('de'),   
                    zh: this.$store.getters.getSelectedLanguages.includes('zh'), 
                    nl: this.$store.getters.getSelectedLanguages.includes('nl'),  
                    pt: this.$store.getters.getSelectedLanguages.includes('pt'), 
                    it: this.$store.getters.getSelectedLanguages.includes('it'), 
                    ar: this.$store.getters.getSelectedLanguages.includes('ar'),
                    ina: this.$store.getters.getSelectedLanguages.includes('ina'),
                    gre: this.$store.getters.getSelectedLanguages.includes('gre'),
                    ro: this.$store.getters.getSelectedLanguages.includes('ro')
                } })
                .then(response => {
                    console.log(response.data)
                    this.$router.replace(`/${response.data.menu_id}`)
                    this.resetMenuOptions();
                    // location.replace(process.env.VUE_APP_API_URL + "/menus/" + response.data.menu_id)
                })
                .catch(error => this.setError(error, 'Cannot create outlet'))
            }
        },
        resetMenuOptions() {
            this.$store.commit("setOutletName", '');
            this.$store.commit("setOutletType", '');
            this.$store.commit("selectCurrency", '');
            this.$store.commit("setEditing", false);
        }
    }
}



</script>


<style scoped>

div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #078E45;
    color: white;
    align-items: center;
    border-radius: 10px;
    padding: 0px 20px 0px 20px;
    box-sizing: border-box;
    width: 80%;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

h3 {
    font-size: 15px;
    font-weight: 600;
    /* letter-spacing: 0.5px; */
    margin: 0px 0px 0px 0px;
}

div img {
    filter: invert(99%) sepia(0%) saturate(5593%) hue-rotate(197deg) brightness(115%) contrast(100%);
    width: 50px;
}


@media(max-width: 700px) {
    div {
        width: 100%;
    }
}

</style>