<template>

    <div class="language" @click="$emit('select-language')">

        <h3>{{ nativeLanguageName(language) }}</h3>

    </div>



</template>


<script>

export default {
    name: 'LanguageSelector',
    props: ['language'],
    data () {
        return {

        }
    },
    methods: {
        nativeLanguageName(language) {
            if (language == 'en') {
                return 'English'
            } else if (language == 'ru') {
                return 'русский'
            } else if (language == 'jp') {
                return '日本語'
            } else if (language == 'sp') {
                return 'Español'
            } else if (language == 'fr') {
                return 'français'
            } else if (language == 'ca') {
                return 'Català'
            } else if (language == 'kr') {
                return '한국어'
            } else if (language == 'de') {
                return 'Deutsch'
            } else if (language == 'zh') {
                return '中文'
            } else if (language == 'nl') {
                return 'Nederlands'
            } else if (language == 'pt') {
                return 'Portuguese'
            } else if (language == 'it') {
                return 'Italiano'
            } else if (language == 'ina') {
                return 'Bahasa Indonesia'
            } else if (language == 'ar') {
                return 'العربية'
            } else if (language == 'gre') {
                return 'Ελληνικά'
            } else if (language == 'ro') {
              return 'Română'
            }
        }
    }
}


</script>


<style scoped>

.language {
    width: 100%;
    max-width: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: auto;
    height: 65px;
    border-radius: 10px;
    margin: 10px 0px 10px 0px;
    color: black;
    border-width: 1px;
    border-style: solid;
    /* border-bottom: 1px dotted lightgray; */
    /* box-shadow: 0 0 8px rgba(0, 0, 0, 0.1); */
}

.language:hover {
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
}

.language h3 {
    font-weight: 400;
}

@media(max-width: 600px) {
    .language {
        width: 90%;
    }
}





</style>

