<template>

    <div id="dropdown-navbar" :class=" { 'sticky' : sticky_navbar === true }">

        <div v-for="route in routes" :key="routes.indexOf(route)" @click="goToRoute(route)">

            <p v-html="$homepageTranslations.routes[route.name][$store.getters.getDefaultLanguage]"></p> 

        </div>

    </div>


</template>

<script>

export default {
    name: "DropdownNavbar",
    props: ['sticky_navbar'],
    data () {
        return {
            routes: [
                {
                    name: "sign_in",
                    link: true,
                    alternative_link: "login"
                },
                {
                    name: "create_account",
                    link: true,
                    alternative_link: "sign_up"
                },
                {
                    name: "home",
                    link: true
                },
                {
                    name: "features",
                    link: true
                },
                {
                    name: "try_free_seven_days",
                    link: true,
                    alternative_link: "sign_up"
                },
                {
                    name: "sample_menu",
                    link: true,
                    alternative_link: "/562877"
                },
                {
                    name: "pricing",
                    link: true
                },
                {
                    name: "contact",
                    link: true
                },
                {
                    name: "partners",
                    link: false,
                    section: true,
                    section_name: "partners"
                },
                {
                    name: "additional_services",
                    link: false,
                    section: true,
                    section_name: "additional-services"
                }
            ]
        }
    },
    methods: {
        goToRoute(route) {
            if (route.link === true) {
                // console.log(route.alternative_link)
                if (route.alternative_link === undefined) {
                    this.$router.push(route.name)
                } else {
                    this.$router.push(route.alternative_link)
                }
            }
            if (route.section === true) {
                this.goToSection(route.section_name)
            }
            this.$emit('close-dropdown')
        },
        goToSection(section) {
            this.$router.replace('/')
            this.$nextTick(() => {
                const id = section;
                console.log(section)
                const yOffset = -150; 
                const element = document.getElementById(id);
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
            });
        }
    }
}




</script>

<style scoped>

#dropdown-navbar {
    display: flex;
    flex-direction: column;
    color: white;
    background-color: #3B3B3A;
    position: absolute;
    top: 100px;
    right: 0;
    width: max-content;
    height: max-content;
    z-index: 10000;
    box-sizing: border-box;
    padding: 0px 40px 20px 40px;
    font-weight: 600;
    border-radius: 0px 0px 0px 20px;
}

.sticky {
    top: 85px !important;
    /* height: max-content; */
}

#dropdown-navbar div {
    cursor: pointer;
}

</style>