

const state = () => ({
  message: {
    content: "",
    error: false
  }
})


const mutations = {
  setMessage(state, message) {
    state.message = message
    setTimeout(() => {
      state.message.content = "",
        state.message.error = false
    }, 5000)
  }
}

const getters = {
  getMessage(state) {
    return state.message
  }
}



export default {
  state,
  getters,
  mutations
}