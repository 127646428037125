<template>

    <div id="partner-testimonials">

        <h3 v-html="$homepageTranslations.testimonials.header[$store.getters.getDefaultLanguage]"></h3>

        <div class="arrow-container arrow-container-backward" @click="scrollLeft()">

            <img src="@/assets/homepage/arrow.svg" class="arrow" />

        </div>

        <div class="testimonials-container" id="testimonials-container">

            <Testimonial v-for="testimonial in testimonials" :key="testimonial.name" :testimonial="testimonial" class="testimonial" />

        </div>

        <div class="arrow-container arrow-container-forward" @click="scrollRight()">

            <img src="@/assets/homepage/arrow.svg" class="arrow forward-arrow" />

        </div>

    </div>






</template>

<script>
import Testimonial from '@/components/home/Testimonial.vue'

export default {
    name: "PartnerTestimonials",
    components: {
        Testimonial
    },
    data () {
        return {
            testimonials: [
                {
                    name: "Melida Weber",
                    title_en: "General Manager",
                    title_id: "",
                    tagline_en: "Excellent...",
                    tagline_id: "",
                    content_en: "... from first being introduced to this product until now the service has been flawless.",
                    company: "The edge Luxury Villa Resort",
                    location: "Bali, Indonesia"
                },
                {
                    name: "Juan Alvarez de Ron",
                    title_en: "Beverage Manager",
                    title_id: "",
                    tagline_en: "It’s been very helpful in Covid times...",
                    tagline_id: "",
                    content_en: "... to provide a contactless menu experience. Is easy to manage and the technical support is always willing to help.",
                    company: "Silversands Grenada",
                    location: "Grenada"
                },
                {
                    name: "Maria Jurish Aguinaldo",
                    title_en: "Digital Marketing Director",
                    title_id: "",
                    tagline_en: "The whole team is very quick to respond...",
                    tagline_id: "",
                    content_en: "... and act on different types of requests and queries. Also, the team develops and introduces new features in the platform that are very easy to learn and understand.",
                    company: "The Bistro Group",
                    location: "Manila, the Philippines"
                }

            ]
        }
    },
    methods: {
        scrollRight() {
            const totalDistance = document.getElementById("testimonials-container").clientWidth;
            const container = document.getElementById('testimonials-container');
            const distance = 600;
            console.log(totalDistance)

            container.scrollBy({
                left: (totalDistance),
                behavior: 'smooth'
            });
        },
        scrollLeft() {
            const totalDistance = document.getElementById("testimonials-container").clientWidth;
            const container = document.getElementById('testimonials-container');
            const distance = 600;

            container.scrollBy({
                left: -totalDistance,
                behavior: 'smooth'
            });
        }
    }
}





</script>

<style scoped>

#partner-testimonials {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: auto;
    margin-top: 100px;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
}

.testimonials-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* height: 200px; */
    width: 90%;
    margin: auto;
    overflow: hidden;
    -ms-overflow-style: none;  /* IE and Edge */
    overflow-x: scroll;
    scrollbar-width: none;
    box-sizing: border-box;
    padding: 50px 0px 50px 0px; 
    /* margin-top: 50px; */
}

.testimonials-container::-webkit-scrollbar {
  display: none;
}

h3 {
    font-family: "Cabin", sans-serif;
    font-size: 40px;
    text-align: center;
    color: #128C44;
    width: 100%;
    margin-bottom: 0px;
}

.arrow {
    width: 50px;
    height: 50px;
    cursor: pointer;

}

.forward-arrow {
    transform: rotate(180deg);
}

.arrow-container {
    background-color: #F1F1EF;
    box-sizing: border-box;
    position: absolute;
    cursor: pointer;
}

.arrow-container-backward {
    box-sizing: border-box;
    padding: 40px 10px 40px 50px;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    border-radius: 0 50% 50% 0;  
    top: 50%;
}

.arrow-container-forward {
    box-sizing: border-box;
    padding: 40px 50px 40px 10px;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    border-radius: 50% 0px 0px 50%;
    top: 50%;
}

@media(max-width: 800px) {
    .testimonials-container {
        width: 90%;
    }

    .arrow {
        width: 20px;
        height: 20px;

    }

    .arrow-container-forward {
        padding: 20px 25px 20px 5px;
    }

    .arrow-container-backward {
        padding: 20px 5px 20px 25px;
    }
}

</style>