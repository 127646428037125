

<template>

    <div>

        <div id="price-info-container">
            <p class="price-info price-info-subtotal">
                {{ this.$translations.subtotal[language] || this.$translations.subtotal['en'] }}: <span><NumberFormat :currency="currency" :number="subtotal" /></span>
            </p>
            <p class="price-info tax-service" v-if="menu.service != 0 && order_type != 'pickup' && order_type != 'delivery'">
                {{ this.$translations.service[language] || this.$translations.subtotal['en'] }} {{ menu.service }}%: <span><NumberFormat :currency="currency" :number="calculated_service" /></span>
            </p>
            <p class="price-info tax-service" v-if="menu.tax != 0">
                {{ this.$translations.tax[language] || this.$translations.tax['en'] }} {{ menu.tax }}%:<span><NumberFormat :currency="currency" :number="calculated_tax" /></span>
            </p>
            <p class="price-info tax-service" v-if="menu.delivery_fee != 0 && order_type === 'delivery'">
                {{ this.$translations.delivery_fee[language] || this.$translations.delivery_fee['en'] }}: <span><NumberFormat :currency="currency" :number="menu.delivery_fee" /></span>
            </p>            
            <p class="price-info tax-service" v-if="menu.packaging_fee != 0 && (order_type === 'pickup' || order_type === 'delivery')">
                {{ this.$translations.packaging_fee[language] || this.$translations.packaging_fee['en'] }} {{ menu.packaging_fee }}%: <span><NumberFormat :currency="currency" :number="calculated_packaging_fee" /></span>
            </p>
        </div>

        

        <div class="grand-total-container">
            <p>{{ this.$translations.total[language] || this.$translations.total['en'] }}: <span><NumberFormat :currency="currency" :number="grand_total" /></span></p>
        </div>

    </div>




</template>


<script>
import NumberFormat from '@/components/NumberFormat.vue'

export default {
    name: 'PriceBox',
    components: {
        NumberFormat
    },
    props: ['order_rows', 'menu', 'currency', 'order_type', 'delivery_fee', 'language'],
    data () {
        return {
            subtotal: 0,
            calculated_tax: 0,
            calculated_service: 0,
            calculated_packaging_fee: 0,
            grand_total: 0
        }
    },
    created () {
        this.getSubtotal();
        this.getCalculatedTax();
        this.getCalculatedService();
        this.getPackagingFee();
        this.getGrandTotal();
        
    },
    methods: {
        getSubtotal() {
            var subtotal = 0
            var items = this.order_rows
            for (let i = 0; i < items.length; i++) {
                subtotal = subtotal + items[i].item_price
                for (let s = 0; s < items[i].selections.length; s++) {
                    subtotal = subtotal + Number(items[i].selections[s].additional_fee)
                }
            }
            this.subtotal = subtotal
            this.$emit('subtotal-calculation', subtotal)
        },
        getCalculatedTax() {
            this.calculated_tax = this.subtotal * (this.menu.tax / 100)
        },
        getCalculatedService() {
            if (this.order_type != 'pickup' && this.order_type != 'delivery') {
                if (Number(this.menu.subtotal_deduction) === 0) {
                    this.calculated_service = this.subtotal * (this.menu.service / 100)
                } else {
                    this.calculated_service = (((this.subtotal / (this.menu.subtotal_deduction / 100)) * (this.menu.service / 100)) )
                }    
            } else {
                this.calculated_service = 0
            }      
        },
        getPackagingFee() {
            if (this.order_type === 'pickup') {
                if (Number(this.menu.subtotal_deduction) === 0) {
                    this.calculated_packaging_fee = this.subtotal * (this.menu.packaging_fee / 100)
                } else {
                    this.calculated_packaging_fee = (((this.subtotal / (this.menu.subtotal_deduction / 100)) * (this.menu.packaging_fee / 100)) )
                }
            } else {
                this.calculated_packaing_fee = 0
            }
        },
        getGrandTotal() {
            var sum = this.subtotal + this.calculated_tax + this.calculated_service + this.calculated_packaging_fee
            if (this.order_type === 'delivery') {
                sum = sum + this.delivery_fee
            }
            this.grand_total = sum
        }
    }
}



</script>

<style scoped>

#price-info-container {
    width: 100%;
    max-width: 700px;
    margin-top: 20px;
    box-sizing: border-box;
    padding: 20px;
    border: 1px solid lightgray;
    border-radius: 10px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.price-info {
    font-size: 13px;
    margin: 0px 0px 0px 0px;
    box-sizing: border-box;
    padding: 5px 0px 5px 0px;
    display: flex;
    flex-direction: row;
}

.price-info-subtotal {
    border-bottom: 1px dotted lightgray;
    font-weight: 400;
}

.price-info-subtotal span {
    font-weight: 600;
}

.tax-service {
    font-size: 11.5px;
}

.tax-service span {
    font-weight: 600;
}

.grand-total-container {
    background-color: rgb(60, 60, 59);
    background-color: black;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    color: white;
    font-size: 15px;
    padding: 0px 20px 0px 20px;
    font-weight: 600;
    border: 1px solid rgb(60, 60, 59);
    border-radius: 10px;
    margin-bottom: 20px;
    font-size: 13px;
    
}

.grand-total-container p {
    display: flex;
    flex-direction: row;
    font-weight: 400;
    font-size: 15px;
}

.grand-total-container p span {
    font-weight: 600;
}



</style>