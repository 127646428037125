

<template>

    <div>

        <div v-if="is_loading == true" class="loader">
            
            <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>

        </div> 

        <div v-else>

            <div v-if="promo_images.length > 0">

                <div class="promo-image-container" v-touch:swipe.left="swipeLeftHandler" v-touch:swipe.right="swipeRightHandler">

                    <div>

                        <div class="delete-button" v-if="can_edit" @click="deletePromoImage(filteredPromoImages()[promo_image_counter].id)">Delete</div>
                        
                        <img v-bind:src="filteredPromoImages()[promo_image_counter].image.url" />

                    </div>

                </div>

                <div class="counter-container" v-if="promo_image_max > 1">

                    <div @click="decreasePromoImageCounter()" class="promo-slider-button"><img v-if="promo_image_counter > 0" src="@/assets/arrow_backward_circle.svg" /></div>

                    <div class="slider-container">

                        <div v-for="image in rangeOfImages()" :key="image" class="slider-icon" :class="{ 'slider-icon-selected' : promo_image_counter === image }">
                            
                        </div>

                    </div>

                    <div @click="increasePromoImageCounter()" class="promo-slider-button"><img v-if="promo_image_counter < promo_image_max - 1" src="@/assets/arrow_forward_circle.svg" /></div>

                </div>

            </div>

            <div class="promo-image-uploader" v-if="can_edit">

                <h3 @click="togglePromoImageUploader()">Upload a promo image</h3>

                <image-uploader
                    v-if="can_edit && show_promo_image_uploader === true"
                    :debug="1"
                    :maxWidth="512"
                    :quality="0.7"
                    :autoRotate=true
                    outputFormat="verbose"
                    :preview=false
                    :className="['fileinput', { 'fileinput--loaded' : hasImage }]"
                    :capture="false"
                    accept="video/*,image/*"
                    doNotResize="['gif', 'svg']"
                    @input="setImage">
                </image-uploader>   

        </div>

        </div>

        
    </div>

</template>


<script>
import ImageUploader from 'vue-image-upload-resize'

export default {
    name: 'PromoImage',
    props: ['menu', 'can_edit', 'language'],
    components: {
        ImageUploader
    },
    data() {
        return {
            show_promo_image_uploader: false,
            is_loading: false,
            promo_images: [],
            promo_image_counter: 0,
            promo_image_max: Number,
            languages: String,
            highest_occurring_language: String
        }
    },
    created() {
        this.retrieveMenuPromoImages();
    },
    methods: {
        setError (error, text) {
            this.error = (error.response && error.response.data && error.response.data.error) || text
        },
        togglePromoImageUploader() {
            this.show_promo_image_uploader = !this.show_promo_image_uploader
        },
        retrieveMenuPromoImages() {
            this.$http.plain.get('/app_retrieve_menu_promo_images/' + this.menu.id )
            .then(response => { 
                this.promo_images = response.data
                this.getMenuPromoImageUniqueLanguages();
                this.mostOccurringElement(this.languages)
            }).catch(error => this.setError(error, 'Something went wrong'))  
        },
        deletePromoImage(promo_image_id) {
            if(confirm("Are you sure you want to delete this promo image?")) {
                this.$http.secured.post('/app_delete_promo_image/' + promo_image_id )
                .then(response => { 
                    this.$emit('renew-promo-images')
                }).catch(error => this.setError(error, 'Something went wrong')) 
            }
        },
        getMenuPromoImageUniqueLanguages() {
            const languages = []
            for (let i = 0; i < this.promo_images.length; i++) {
                languages.push(this.promo_images[i].language)
            }
            this.languages = languages
        },
        rangeOfImages() {
            var list = [];
            for (var i = 0; i <= this.promo_image_max - 1; i++) {
                list.push(i);
            }
            return list
        },
        mostOccurringElement(array) {
            var max = array[0],
                counter = {},
                i = array.length,
                element;
            
            while (i--) {
                element = array[i];
                if (!counter[element]) counter[element] = 0;
                counter[element]++;
                if (counter[max] < counter[element]) max = element;
            }
            this.highest_occurring_language = max
            this.promo_image_max = this.languages.filter(x => x == max).length;
        },
        increasePromoImageCounter() {
            if (this.promo_image_counter < (this.promo_image_max - 1)) {
                this.promo_image_counter++
            }
        },
        decreasePromoImageCounter() {
            if (this.promo_image_counter > 0) {
                this.promo_image_counter--
            }            
        },
        filteredPromoImages() {
            const filtered_promo_images = []
            for (let i = 0; i < this.promo_images.length; i++) {
                if (this.promo_images[i].language === this.language) {
                    filtered_promo_images.push(this.promo_images[i])
                }
            }

            console.log(`filtered promo images initial length is ${filtered_promo_images.length}`)

            console.log(`total number of images that should render is ${this.promo_image_max}`)

            if (filtered_promo_images.length < this.promo_image_max) {
                for (let i = filtered_promo_images.length; i < this.promo_images.length; i++) {
                    if (this.promo_images[i].language === 'en') {
                        filtered_promo_images.push(this.promo_images[i])
                    }
                }                
            }

            return filtered_promo_images

        },
        swipeLeftHandler() {
            console.log('swipe handler')
            this.increasePromoImageCounter(); 
        },
        swipeRightHandler() {
            this.decreasePromoImageCounter(); 
        },
        setImage: function (file) {
            this.hasImage = true
            this.image = file
            // this.is_loading = true
            const promoImage = { image: { url: file.dataUrl }, language: this.language}
            this.promo_images.push(promoImage)
            this.promo_image_max = this.promo_image_max + 1
            const data = new FormData();
            Object.entries(file).forEach(([key, value]) => { data.append(key, value) })
            this.show_promo_image_uploader = false
            this.$http.upload.patch('/app_upload_menu_promo_image/' + this.menu.id + '/' + this.language, data
                ).then(response => {
                    console.log(response.data)
                    this.retrieveMenuPromoImages();
                    this.show_promo_image_uploader = false
                    this.is_loading = false
                })
                .catch(error => this.setError(error, 'Cannot update menu.'))
        }
    }
}


</script>

<style scoped>

.promo-image-uploader {
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
}

.promo-image-container {
    width: 100%;
    position: relative;
}

.delete-button {
    position: absolute;
    /* border: 1px solid black; */
    background-color: white;
    font-size: 10px;
    box-sizing: border-box;
    border-radius: 2.5px;
    padding: 2.5px;
    top: 5px;
    left: 5px;
    cursor: pointer;
}

.delete-button:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.promo-image-container div img {
    width: 100%;
    max-width: 100%;
}


.promo-image-uploader h3 {
    border: 1px solid black;
    box-sizing: border-box;
    padding: 2.5px;
    border-radius: 2.5px;
    font-weight: 600;
    font-size: 9px;
    width: max-content;
    cursor: pointer;
}

.counter-container {
    width: 100%;
    box-sizing: border-box;
    padding: 0px 5px 0px 5px;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 20px;
}

.slider-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.slider-icon {
    width: 10px;
    height: 10px;
    border: 1px solid lightgray;
    border-radius: 50%;
    margin: 0px 5px 0px 5px;
}

.slider-icon-selected {
    background-color: black;
}

.promo-slider-button {
    cursor: pointer;
}

.promo-slider-button img {
    width: 15px;
    height: 15px;
}




</style>