<template>


    <div class="plan" :style="{ 'border-color' : background_color }" @click="submit">

        <div class="basics">

            <h3>{{ descriptor }}</h3>

            <h2>${{ price }}</h2>

            <div class="details">

                <p>{{ billing_descriptor }}</p>

                <p class="strong" v-if="translation_count != null">Includes free translation to {{ translation_count }} {{ getPlural() }}</p>

                <!-- <p class="strong" v-if="monthly_price != null">{{ monthly_price }} per month</p> -->

            </div>

        </div>

        <div class="filler"></div>

        <p>Checkout</p>

        <img src="@/assets/arrow_forward_black.svg" />

        <stripe-checkout
            ref="checkoutRef"
            mode="subscription"
            :pk="publishableKey"
            :line-items="lineItems"
            :customer-email="this.user_email"
            :success-url="successURL"
            :cancel-url="cancelURL"
            @loading="v => loading = v"
        />

    </div>




</template>


<script>
import { StripeCheckout } from '@vue-stripe/vue-stripe';


export default {
    name: 'ProPlan',
    props: ['user_id', 'translation_count', 'user_email', 'plan', 'descriptor', 'price', 'background_color', 'billing_descriptor', 'monthly_price'],
    components: {
        StripeCheckout
    },
    data () {
        // this.publishableKey = process.env.STRIPE_PUBLISHABLE_KEY;
        this.publishableKey = 'pk_live_apW3KxpwGGVXi16ozbdhS9ub00AfOVCUDR';
        // this.publishableKey = 'pk_test_rSceP1o2ZpFybSO6y1x4DOAH00njGFjOQ0';
        return {
            loading: false,
            customerEmail: this.user_email,
            lineItems: [
                {
                    price: this.plan, // The id of the recurring price you created in your Stripe dashboard
                    // price: "price_1JLLSDG2fH76M3MV1pCOE9u7",
                    quantity: 1,
                }
            ],
          successURL: `https://${$store.getters.getSubdomain}.menulingua.com/checkout_success`,
          cancelURL: `https://${$store.getters.getSubdomain}.menulingua.com/plans`,
        };
    },
    methods: {
        submit () {
            this.$emit('loading')
            this.$refs.checkoutRef.redirectToCheckout();
        },
        getPlural() {
            if (this.translation_count === 'one') {
                return 'language'
            } else if (this.translation_count === 'two') {
                return 'languages'
            }
        }
    }    
}

</script>


<style scoped>

.plan {
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* height: 75px; */
    box-sizing: border-box;
    padding: 10px 20px 10px 20px;
    border-radius: 10px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    border: 1px solid;
    background-color: white;
    /* border: 1px solid lightgray; */
    cursor: pointer;
}

.plan:hover {
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
    background-color:#E6EED3;
    transition: 0.2s;
}

.plan img {
    width: 75px;
    margin-left: 10px;
}

.plan h2 {
    font-size: 40px;
    margin: 0px 50px 0px 0px;
    width: 150px;
    box-sizing: border-box;
    padding: 2.5px;
    /* border: 1px solid lightgray; */
    background-color: black;
    letter-spacing: 1px;
    color: white;
    display: flex;
    border-radius: 5px;
    justify-content: center;

}

.plan h3 {
    font-size: 20px;
    margin-right: 20px;
    font-weight: 600;
    /* text-transform: uppercase; */
    /* letter-spacing: 0.5px; */
    width: 150px;
}

.details {
    display: flex;
    flex-direction: column;
}

.details p {
    font-size: 13px;
    margin: 2.5px 0px 2.5px 0px;
}

.strong {
    font-weight: 600 !important;
}

.basics {
    display: flex;
    flex-direction: row;
    align-items: center;
}

@media(max-width: 700px) {
    .basics {
        flex-direction: column;
        align-items: flex-start;
    }

    .plan {
        height: 200px;
    }

    .plan h2 {
        margin: 0px 0px 10px 0px;
        width: max-content;
        box-sizing: border-box;
        padding: 5px 10px 5px 10px;
    }

    .plan h3 {
        margin: 0px 0px 10px 0px;
    }
}


</style>