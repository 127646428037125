<template>


    <div id="menu-settings-container">

        <h1>Menu settings: <span>{{ menu_settings.name }}</span></h1>

        <div class="overall-container">
        
            <div class="toggle-container" v-if="access_to_dashboard_order_settings != false">

                <div v-if="this.is_loading === true" class="loader">

                    <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>

                </div>

                <div v-else>                

                    <div class="toggle-button-container">

                        <p>In-restaurant orders</p>

                        <div class="toggle-button"  :class="{ 'activated' : in_restaurant == true, 'deactivated' : in_restaurant == false }" @click="toggleOrdering('in_restaurant')">
                            <span :class="{ 'on' : in_restaurant == true, 'off' : in_restaurant == false }"></span>
                        </div>

                    </div>

                    <div :class="{ 'toggle-button-container toggle-button-container-no-border' : delivery == true, 'toggle-button-container' : delivery == false }">

                        <p>Delivery orders</p>

                        <div class="toggle-button" :class="{ 'activated' : delivery == true, 'deactivated' : delivery == false }" @click="toggleOrdering('delivery')">
                            <span :class="{ 'on' : delivery == true, 'off' : delivery == false }"></span>
                        </div>

                    </div> 

                    <div class="extra-options" id="delivery-options" v-if="delivery == true">

                        <h5>How would you like to receive delivery orders? </h5>

                        <div class="toggle-button-with-selections">
                            <span class="toggle-button-text" :class="{ 'text-activated' : whatsapp_ordering == false }" @click="turnOffWhatsappOrdering()">Dashboard</span>
                            <span class="toggle-button-text" :class="{ 'text-activated' : whatsapp_ordering == true }" @click="turnOnWhatsappOrdering()">WhatsApp</span>
                        </div>

                        <div class="phone-input-container" v-if="whatsapp_ordering == true">

                            <h5>Enter your WhatsApp number</h5>
                            <vue-tel-input v-model="phone_number" v-bind="bindProps" @keyup.native="phoneChanged()"></vue-tel-input>
                            <div v-if="phone_number_saved == false" @click.prevent="updateWhatsappNumber()" class="submit-button" :class="{ 'submit-button-activated' : phone_number.length > 5}">Save number</div>
                            <div v-if="phone_number_saved == true" class="submit-button">Save number</div>
                
                        </div>

                    </div>            

                    <div class="toggle-button-container">

                        <p>Pickup orders</p>

                        <div class="toggle-button" :class="{ 'activated' : pickup == true, 'deactivated' : pickup == false }" @click="toggleOrdering('pickup')">
                            <span :class="{ 'on' : pickup == true, 'off' : pickup == false}"></span>
                        </div>

                    </div>

                    <div class="toggle-button-container">

                        <p>In room orders</p>

                        <div class="toggle-button" :class="{ 'activated' : in_room == true, 'deactivated' : in_room == false }" @click="toggleOrdering('in_room')">
                            <span :class="{ 'on' : in_room == true, 'off' : in_room == false}"></span>
                        </div>

                    </div>

                    <div class="toggle-button-container toggle-button-container-with-margin" v-if="menu_settings.menu_builder_v2 === true">

                        <div class="sessions">

                            <p>Use session-based ordering</p>

                            <span class="descriptor">Session-based ordering allows you to print an individual QR code for each table or guest. Each QR code contains a table/location identifier and a guest name. The code expires in two hours, after which the menu can be viewed, but orders cannot be placed.</span>

                        </div>
                        
                         <div class="toggle-button" :class="{ 'activated' : session_based_ordering == true, 'deactivated' : session_based_ordering == false }" @click="toggleOrdering('session_based')">
                            <span :class="{ 'on' : session_based_ordering == true, 'off' : session_based_ordering == false}"></span>
                        </div>                       
                    </div>



                    <div class="extra-options" id="email-order-notification-options">
   
                        <div class="toggle-button-with-selections">
                            <span class="toggle-button-text" :class="{ 'text-activated' : email_notifs == true }" @click="setEmailNotifs()">Email order notifications</span>
                        </div>

                        <div class="email-input-container" v-if="email_notifs == true">

                            <h5>Enter an email address for order notifications</h5>

                            <input for="email" type="email" placeholder="Enter email here" v-model="email" @change="emailChanged" class="email-input">

                            <div @click.prevent="updateEmailAddress()" class="submit-button" :class="{ 'submit-button-activated' : this.email_saved == false && validEmail() }">Save email</div>

                        </div>
                                      

                    </div>

                </div>

                <Discounts v-bind:menu_id="this.$router.currentRoute.params.id" v-bind:currency="this.currency" />

            </div>

            <Menu :menu="menu_settings" />

        </div>

    </div>

    





</template>

<script>
import { VueTelInput } from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
import Menu from '@/components/orders/Menu.vue'
import Discounts from '@/components/orders/Discounts.vue'

export default {
    name: 'MenuSettings',
    components: {
        VueTelInput,
        Discounts,
        Menu
    },
    data() {
        return {
            menu_settings: [],
            is_loading: true,
            user: '',
            access_to_dashboard_order_settings: Boolean,
            in_restaurant: Boolean,
            delivery: Boolean,
            pickup: Boolean,
            in_room: Boolean,
            whatsapp_ordering: Boolean,
            session_based_ordering: Boolean,
            currency: '',
            logo_url: '',
            email_notifs: Boolean,
            phone_number: null,
            phone_number_saved: true,
            email: '',
            email_saved: true,
            bindProps:{
                mode: 'international'
            }
        }
    },
    created() {
         if (!localStorage.signedIn) {
            this.$router.replace('/')
        } else {
        let id = this.$router.currentRoute.params.id;
        this.$http.secured.get('/app_get_menu_settings/' + id)
            .then(response => { this.menu_settings = response.data.menu,
            this.in_restaurant = response.data.menu.orderable,
            this.delivery = response.data.menu.orders_for_delivery,
            this.pickup = response.data.menu.orders_for_pickup,
            this.in_room = response.data.menu.orders_for_in_room,
            this.session_based_ordering = response.data.menu.session_based_ordering,
            this.user = response.data.user,
            this.access_to_dashboard_order_settings = response.data.access_to_dashboard_order_settings,
            this.whatsapp_ordering = response.data.menu.whatsapp_ordering,
            this.phone_number = response.data.menu.whatsapp_number,
            this.phone_number_saved = true,
            this.email = response.data.menu.order_notification_email,
            this.email_notifs = response.data.menu.email_order_notifications,
            this.is_loading = false })
            .catch(error => this.setError(error, 'Something went wrong'))
        this.$http.secured.get('/app_get_menu_currency/' + id)
            .then(response => {this.currency = response.data })
            .catch(error => this.setError(error, 'Something went wrong'))
        }
    },
    methods: {
        setError (error, text) {
            this.error = (error.response && error.response.data && error.response.data.error) || text
        },
        toggleSwitch(orderType) {
            if (this.orderType == true) {
                this.orderType = false
            } else {
                this.orderType = false
            }
        },
        countryChanged(country) {
            this.country = country.dialCode
        },
        phoneChanged() {
            this.phone_number_saved = false 
            console.log('phoneChanged fired')
        },
        emailChanged() {
            this.email_saved = false
        },
        toggleOrdering(type) {

            if (type == 'in_restaurant') {
                    this.in_restaurant = this.in_restaurant ? false : true;
                }
            if (type == 'delivery') {
                this.delivery = this.delivery ? false : true;
            }
            if (type == 'pickup') {
                this.pickup = this.pickup ? false : true;
            }   
            if (type == 'in_room') {
                this.in_room = this.in_room ? false : true;
            }

            if (type == 'session_based') {
                this.session_based_ordering = this.session_based_ordering ? false : true;
            }

            let id = this.$router.currentRoute.params.id;
            this.$http.secured.post('/app_toggle_ordering/' + id + '/' + `${type}`)
            .then(response => { 
                        
             })
            .catch(error => this.setError(error, 'Something went wrong'))
            },
        turnOnWhatsappOrdering() {
            let id = this.$router.currentRoute.params.id;
            this.$http.secured.post('/app_use_whatsapp_for_delivery/' + id)
            .then(response => { this.whatsapp_ordering = true })
            .catch(error => this.setError(error, 'Something went wrong'))
        },
        turnOffWhatsappOrdering() {
            let id = this.$router.currentRoute.params.id;
            this.$http.secured.post('/app_not_whatsapp_for_delivery/' + id)
            .then(response => { this.whatsapp_ordering = false })
            .catch(error => this.setError(error, 'Something went wrong'))
        },
        toggleEmailNotifs() {
            if (this.email_notifs == false) {
                this.email_notifs = true
            } else {
                this.email_notifs = false
            }
        },
        setEmailNotifs() {
            let id = this.$router.currentRoute.params.id;
            this.$http.secured.post('/app_toggle_email_notifications/' + id)
            .then(response => { this.toggleEmailNotifs() })
            .catch(error => this.setError(error, 'Something went wrong'))
        },
        validEmail() {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(this.email);
        },
        updateEmailAddress() {
            let id = this.$router.currentRoute.params.id;
            this.$http.secured.post('/menus/' + id + '/email_address', { email: this.email })
            .then(response => { this.email_saved = true })
            .catch(error => this.setError(error, 'Cannot update email'))
        },
        updateWhatsappNumber() {
            let id = this.$router.currentRoute.params.id;
            this.$http.secured.patch(`/menus/${id}/whatsapp_number/${this.phone_number}`)
            .then(response => { this.phone_number_saved = true })
            .catch(error => this.setError(error, 'Cannot update number'))
        }
    }
       
}



</script>

<style src="vue-tel-input/dist/vue-tel-input.css"></style>
<style scoped>

#menu-settings-container {
    width: 90%;
    max-width: 1000px;
    margin: auto;
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    /* border: 1px dotted lightgray; */
}

.overall-container {
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    justify-content: space-between;
    align-items: flex-start;
}

#menu-settings-container h1 {
    font-size: 30px;
    font-weight: 200;
    margin: 0px 0px 40px 0px;
}

#menu-settings-container h1 span {
    font-weight: 400;
}

#menu-settings-container h2 {
    font-size: 25px;
    font-weight: 600;
}

.menu-settings-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 40px;
    /* justify-content: center; */
}

.menu-logo {
    width: 100px;
    height: 100px;
    margin-right: 20px;
    object-fit: cover;
    border-radius: 50%;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);

}

.email-input {
    border: 1px solid #bbb;
    border-radius: 3px;
    width: 100%;
    box-sizing: border-box;
    padding: 5px;
    height: 30px;
    /* height:  */
}

.toggle-container {
    display: flex;
    flex-direction: column;
    width: 400px;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid lightgray;
    min-height: 365px;
}

.toggle-button-container {
    box-sizing: border-box;
    padding: 20px 0px 20px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px dotted lightgray;
}

.toggle-button-container-no-border {
    border-bottom: 0px;
}

.toggle-button-container:last-child {
    border-bottom: 0px;
}

.descriptor {
    font-size: 10px;
    line-height: 0.5;
    margin-bottom: 20px;
}

.sessions {
    margin-bottom: 20px;
}

.toggle-button-container-with-margin {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 50px;
    padding-bottom: 50px;
}

.toggle-button {
    width: 100px;
    height: 40px;
    display: flex;
    align-items: center;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 5px 10px 5px 10px;
    cursor: pointer;
}

.toggle-button span {
    width: 25px;
    height: 25px;
    z-index: 1;
    background-color: white;
    border-radius: 50%;
}

.on {
    animation: slide_on 0.5s forwards;
}

.off {
    animation: slide_off 0.5s forwards;
}

.activated {
    animation: fade_to_green 0.5s forwards;
}

.deactivated {
    animation: fade_to_red 0.5s forwards;
}

@keyframes slide_on {
    from { margin-left: 0px; }
    to { margin-left: 50px; }
}

@keyframes slide_off {
    from { margin-left: 100px; }
    to { margin-left: 5px; }
}

@keyframes fade_to_green {
    from {
        background-color: gray; 
    }
    to {
        background-color: #8cc63e;
    }
}

@keyframes fade_to_red {
    from {
        background-color: #8cc63e; 
        
    }
    to {
        background-color: lightgray;
    }
}

.toggle-container p {
    font-size: 14px;
    font-weight: 600;
    margin: 0px 0px 0px 0px;
}

.toggle-container h5 {
    font-weight: 600;
    font-size: 12.5px;
    margin: 10px 0px 5px 0px;
}

.toggle-button-with-selections {
    /* width: 150px; */
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
    box-sizing: border-box;
    /* border-bottom: 1px solid lightgray; */
}

.toggle-button-with-selections span {
    /* border: 1px solid lightgray; */
    box-sizing: border-box;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12.5px;
    font-weight: 600;
    margin: 5px 0px 5px 0px;
    border-radius: 5px;
    cursor: pointer;
}

.toggle-button-text {
    border: 1px solid lightgray;
    color: gray;
    width: 100%;
    height: 40px;
}

.toggle-button-text:hover {
    background-color: lightgray;
    color: white;
    transition: 0.2s;
}

.text-activated {
    border: 1px solid #4394ce;
    color: white;
    background-color: #4394ce;
}

.text-activated:hover {
    border: 1px solid #4394ce;
    color: white;
    background-color: #4394ce;    
}

#delivery-options {
    /* transition:  */
    animation: fadein 0.5s;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.submit-button {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12.5px;
    font-weight: 600;
    border: 1px solid lightgray;
    color: gray;
    border-radius: 5px;
    margin-top: 20px;
    pointer-events: none;
}

.submit-button-activated {
    border: 1px solid #4394ce;
    color: white;
    background-color: #4394ce;   
    cursor: pointer; 
    pointer-events: auto;
}

#email-order-notification-options {
    margin-top: 50px;
}


@media(max-width: 750px) {
    .overall-container {
        flex-direction: column;
    }

    .toggle-container {
        width: 100%;
        margin: auto;
        margin-bottom: 30px;
    }

    #menu-settings-container h1 {
        font-size: 20px;
        font-weight: 200;
        margin: 0px 0px 40px 0px;
    }
}


</style>