<template>

   <div>

        <div v-if="is_loading === true" class="loader">

            <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>

        </div>

        <div v-else>

            <div id="pdf-generator" v-if="can_edit === true">

                <div class="top-container">

                    <div @click="backToMenu()">

                        <BackButton :text="'Back to menu'" />

                    </div>

                </div>        

                    <div class="menu-info">

                        <img class="menu-logo" :src="menu.logo.url" />

                        <div class="menu-info-logo-name">

                            <h3>{{ menu.name }} • Generate PDF </h3>                  

                        </div>

                    </div>            


                <div class="pdf-generator-container">

                    <h3>Choose paper size</h3>

                    <div class="paper-sizes">
                        <p @click="selectPaper('a4')" :class="{ 'selected' : this.paper_size === 'a4' }">A4</p>
                        <p @click="selectPaper('a5')" :class="{ 'selected' : this.paper_size === 'a5' }">A5</p>
                        <p @click="selectPaper('letter')" :class="{ 'selected' : this.paper_size === 'letter' }">Letter</p>
                    </div>

                    <div v-if="languages.length > 1" class="language-select-container">

                        <h3>Select language</h3>
                        
                        <div v-for="language in languages" :key="language" @click="selectLanguage(language)" class="language-select" :class="{ 'selected' : selected_language === language }">

                            {{ nativeLanguageName(language) }}

                        </div>

                    </div>


                    <transition name="fade">
                            
                        <h2 @click="generateMyPdf()" v-if="paper_size != '' && selected_language != ''">Download PDF</h2>

                    </transition>

                    <vue-html2pdf
                            :key="componentKey"
                            :html-to-pdf-options="htmlToPdfOptions"
                            :manual-pagination="true"
                            :pdf-quality="2"
                            ref="pdfcontainer"
                        >
                        
                        <section slot="pdf-content" class="pdf" :class="getPaperSize()">

                                <div id="menu-container">

                                    <MenuHeader :has_language_selector="false" :menu="menu" :language="selected_language" :languages="languages" :show_menu_name="menu.show_menu_name" :can_edit="false" />

                                    <div class="item-container" v-for="food in foods" :key="food.id" :sort="food.sort">
                        
                                            <Item 
                                                :food="food" 
                                                :foods="foods"
                                                :menu="menu"
                                                :random_image_key="'?t=' + Math.random().toString(36).substring(7)"
                                                :language="selected_language" 
                                                :languages="languages"
                                                :currency="currency_short_form"
                                                :food_tags="food_tags"
                                                :can_edit="false"
                                            />       

                                            <div class="html2pdf__page-break" v-if="food.page_break === true"></div>                            

                                    </div>

                                </div>
                            
                            
                        
                        </section>
                    </vue-html2pdf>   

                </div>

            </div>

        </div>

    </div>


</template>

<script>
import Item from '@/components/Item.vue'
import MenuHeader from '@/components/MenuHeader.vue'
import VueHtml2pdf from 'vue-html2pdf'
import Header from '@/components/Header.vue'
import BackButton from '@/components/BackButton.vue'

export default {
    name: 'PdfPrintout',
    components: {
        Item,
        VueHtml2pdf,
        MenuHeader,
        Header,
        BackButton
    },
    props: ['can_edit', 'menu', 'foods', 'sold_out_foods', 'hidden_foods', 'currency_short_form', 'food_tags', 'languages'],
    data () {
        return {
            language_selector_shown: false,
            show_more_options: Number,
            selected_items: [],
            paper_size: '',
            major_category_headers: [],
            minor_category_headers: [],
            food_drink_items: [],
            food_tags: [],
            fixed_toolbar: Boolean,
            componentKey: 0,
            is_loading: false,
            selected_language: '',
            error: '',
            componentKey: 0,
            url: process.env.VUE_APP_API_URL
        }
    },
    created() {
        this.getItemTypes();
        if (this.languages.length === 1) {
            this.selected_language = this.languages[0]
        }
        this.scrollToTop();
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0,0);
        },
        getItemTypes() {
            console.log('foods length: ' + this.foods.length)
            for (let i = 0; i < this.foods.length; i++) {
                if (this.foods[i].major_category_header === true) {
                    this.major_category_headers.push(this.foods[i].id)
                } else if (this.foods[i].category_header === true && this.foods[i].major_category_header === false) {
                    this.minor_category_headers.push(this.foods[i].id)
                } else if (this.foods[i].category_header === false && this.foods[i].major_category_header === false) {
                    this.food_drink_items.push(this.foods[i].id)
                }
            }
        },
        backToMenu() {
            this.$emit('back-to-menu')
        },
        generateMyPdf() {
            this.componentKey++
            this.itemKey++
            this.$nextTick(() => {
                if (this.paper_size != '') {
                    this.componentKey++
                    this.itemKey++
                    console.log(`component key: ${this.componentKey}`)
                    this.$refs.pdfcontainer.generatePdf();
                }
            })
        },
        setError (error, text) {
            this.error = (error.response && error.response.data && error.response.data.error) || text
        },
        selectPaper(paper) {
            this.paper_size = paper
        },
        getPaperSize() {
            if (this.paper_size === 'a4') {
                return 'a4'
            } 
            if (this.paper_size === 'a5') {
                return 'a5'
            } 
            if (this.paper_size === 'letter') {
                return 'letter'
            } 
            if (this.paper_size === '') {
                return 'a4'
            } 
        },
        selectLanguage(language) {
            this.selected_language = language
            this.componentKey++
        },
        nativeLanguageName(language) {
            if (language == 'en') {
                return 'English'
            } else if (language == 'ru') {
                return 'русский'
            } else if (language == 'jp') {
                return '日本語'
            } else if (language == 'sp') {
                return 'Español'
            } else if (language == 'fr') {
                return 'français'
            } else if (language == 'ca') {
                return 'Català'
            } else if (language == 'kr') {
                return '한국어'
            } else if (language == 'de') {
                return 'Deutsch'
            } else if (language == 'zh') {
                return '中文'
            } else if (language == 'nl') {
                return 'Nederlands'
            } else if (language == 'pt') {
                return 'Portuguese'
            } else if (language == 'it') {
                return 'Italiano'
            } else if (language == 'ar') {
                return 'العربية'
            } else if (language == 'ina') {
                return 'Indonesian'
            }
        }
    },
    computed: {
        htmlToPdfOptions() {
            return {
                margin: 0,
                filename: this.menu.name + '.pdf',
                image: {
                    type: "jpeg",
                    quality: 0.95,
                },
                enableLinks: true,
                html2canvas: {
                    scale: 2,
                    useCORS: true,
                    allowTaint: true,
                    scrollX: 0,
                    scrollY: 0,
                    margin: [0, 0, 0, 0]
                },
                jsPDF: { unit: 'in', format: this.paper_size, orientation: 'portrait' }
            }
        }
    }
}


</script>

<style scoped>

#pdf-generator {
    max-width: 1000px;
    width: 90%;
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 50px;
    margin-top: 125px;
}

.pdf-generator-container {
    height: 80vh;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.pdf-generator-container h2 {
    font-weight: 400;
    width: max-content;
    margin: auto;
    cursor: pointer;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0);
    border: 1px solid black;
}

.pdf-generator-container h2:hover {
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.3);
    transition: 0.2s;
    /* border: 1px solid black; */
}

.pdf-generator-container h3 {
    font-size: 15px;
    font-weight: 600;
    width: max-content;
}

#menu-container {
    width: 90%;
    margin: auto;
}

.pdf {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 50px 0px 50px 0px;
    /* width: 80%; */
    /* height: 800px; */
}

.paper-sizes {
    display: flex;
    /* max-width: 500px; */
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
}

.paper-sizes p {
    border: 1px solid black;
    box-sizing: border-box;
    padding: 10px;
    margin: 10px 0px 10px 0px;
    border-radius: 5px;
    cursor: pointer;
    width: 32%;
    height: 70px;
}

.top-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px;
}

.top-container img {
    width: 35px;
    margin: 0px 10px 0px 10px;
}

.top-container p {
    margin: 0px 0px 0px 0px;
    font-size: 12.5px;
    font-weight: 600;
    cursor: pointer;
}

.form-tag {
    display: flex;
    width: max-content;
    border: 1px solid black;
    box-sizing: border-box;
    padding: 2.5px;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    border-radius: 2.5px;
}

.selected {
    background-color: #4794CB;
    border: 1px solid #4794CB !important;
    color: white;
}

.menu-info {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    margin-top: 25px;
    box-sizing: border-box;
    padding-bottom: 10px;
    border-bottom: 25px;
    border-bottom: 1px dotted lightgray;
    width: max-content;
}

.menu-info-logo-name {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
}

.menu-info h3 {
    font-weight: 600;
    margin: 0px 0px 00px 0px;
    font-size: 15px;
}

.menu-logo {
    max-width: 50px;
    max-height: 50px;
    margin-bottom: 10px;
}

.a4 {
    width: 800px;
}

.a5 {
    width: 560px;
}

.item-container {
    page-break-inside: avoid;
}

.loader {
    height: 80vh;
}

.language-select-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.language-select-container h3 {
    width: 100%;
}

.language-select {
    box-sizing: border-box;
    padding: 10px;
    border: 1px solid black;
    border-radius: 2.5px;
    margin-right: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

@media(max-width: 500px) {
    .paper-sizes {
        display: flex;
        /* max-width: 500px; */
        width: 100%;
        flex-direction: column;
        justify-content: flex-start;
    }

    .paper-sizes p {
        border: 1px solid black;
        box-sizing: border-box;
        padding: 10px;
        margin: 10px 0px 10px 0px;
        border-radius: 5px;
        cursor: pointer;
        width: 100%;
        height: 50px;
    }
}


</style>