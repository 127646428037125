<template>

    <div id="account-status">

        <h3>Account status</h3>

        <div class="account-status-container" :class="getStatusClass(current_plan_type)" @click="goToAccount()">

            <p :class="{ 'expired-text' : this.current_plan_type === 'Expired' }">{{ this.current_plan_type }}</p>

            <div class="account-status-icon">

                <img src="../assets/check-mark-white.svg" v-if="current_plan_type != 'Expired'" />

                <img src="../assets/expired.svg" v-if="current_plan_type === 'Expired'" />

            </div>

        </div>

        <div v-if="latest_subscription_status === 'free_trial_seven_days' && current_plan_type != 'Administrator'" class="days-remaining-free-trial" @click="redirectToPlans()">

            <span>{{ days_remaining }}</span> days remaining

        </div>

        <div v-if="latest_subscription_status === 'free_trial_seven_days' && current_plan_type != 'Administrator'" class="days-remaining purchase" @click="redirectToPlans()">

            <span>Upgrade to Pro</span>

        </div>

        <div v-if="subscription_payment_provider != 'midtrans'">

            <div v-if="(latest_subscription_status === 'pro' || latest_subscription_status === 'pdf_only') && cancellation_requested === false && current_plan_type != 'Expired' && admin_or_dashboard_user != true" class="days-remaining">

                Set to auto-renew on {{ moment(this.current_plan_expiry_date).format('LL') }}

            </div>

            <div v-if="(latest_subscription_status === 'pro' || latest_subscription_status === 'pdf_only') && cancellation_requested === true && current_plan_type != 'Expired'" class="days-remaining" @click="redirectToPlans()">

                Your subscription has {{ days_remaining }} days remaining. <span>Click here to extend.</span>

            </div>

        </div>

        <div v-else>

            <div v-if="(latest_subscription_status === 'pro' || latest_subscription_status === 'pdf_only') && current_plan_type != 'Expired'" class="days-remaining" @click="redirectToSubscriptions()">

                Your subscription expires on {{ moment(this.current_plan_expiry_date).format('LL') }}. <span>Click here to extend.</span>

            </div>

        </div>

        <div v-if="current_plan_type === 'Expired'" @click="redirectToPlans()" class="days-remaining">

            Customers can't see your menus. <span>Extend your subscription now.</span>

        </div>
        


    </div>


</template>

<script>

export default {
    name: 'AccountStatus',
    props: ['user_type', 'currentUser', 'email', 'latest_subscription_status', 'current_plan_expiry_date', 'cancellation_requested', 'subscription_payment_provider', 'admin_or_dashboard_user'],
    data () {
        return {
            currently_valid_plan: Boolean,
            current_plan_type: '',
            days_remaining: Number,
            url: process.env.VUE_APP_API_URL
        }
    },
    created() {
        this.getSubscriptionStatus();    
    },
    methods: {
        goToAccount() {
            if (this.latest_subscription_status === 'free_trial_seven_days') {
                this.redirectToPlans();
            } else {
                this.$router.push('/subscription')
            } 
        },
        getSubscriptionStatus() {
            var current_date = new Date()
            var expiry_date = new Date(this.current_plan_expiry_date)
            if (current_date > expiry_date || this.current_plan_expiry_date === null) {
                this.currently_valid_plan = false
            } else {
                this.currently_valid_plan = true
            }
            this.getSubscriptionType();
            
        },
        getSubscriptionType() {
            if (this.latest_subscription_status === 'free_trial_seven_days') {
                if (this.currently_valid_plan === true) {
                    this.current_plan_type = 'Free trial'
                    this.getDaysRemaining();
                } else {
                    this.current_plan_type = 'Expired'
                    this.days_remaining = 0
                }
            }
            if (this.latest_subscription_status === 'pro') {
                if (this.currently_valid_plan === true) {
                    this.current_plan_type = 'Pro'
                    this.getDaysRemaining();
                } else {
                    this.current_plan_type = 'Expired'
                    this.days_remaining = 0
                }
            }
            if (this.latest_subscription_status === 'pdf_only') {
                if (this.currently_valid_plan === true) {
                    this.current_plan_type = 'PDF menu'
                    this.getDaysRemaining();
                } else {
                    this.current_plan_type = 'Expired'
                    this.days_remaining = 0
                }
            }
            if (this.user_type === 'admin') {
                this.current_plan_type = 'Pro'
            }

            if (this.admin_or_dashboard_user === true) {
                this.current_plan_type = 'Administrator'
            }

            if (this.current_plan_expiry_date === null) {
                this.current_plan_type = 'Expired'
                this.days_remaining = 0                
            }

        },
        getDaysRemaining() {
            const oneDay = 24 * 60 * 60 * 1000;
            const firstDate = new Date()
            const secondDate = new Date(this.current_plan_expiry_date)
            this.days_remaining = Math.round(Math.abs((secondDate - firstDate) / oneDay));
        },
        getStatusClass(current_plan_type) {
            if (current_plan_type === 'Pro') {
                return 'pro'
            }
            if (current_plan_type === 'Free trial') {
                return 'free-trial'
            }
            if (current_plan_type === 'PDF menu') {
                return 'pdf-menu'
            }
            if (current_plan_type === 'Expired') {
                return 'expired'
            }
            if (current_plan_type === 'Administrator') {
                return 'administrator'
            }
        },
        redirectToPlans() {
            this.$router.push('/pricing');
        },
        redirectToSubscriptions() {
            this.$router.push('/subscribe')
        }
    }
}




</script>

<style scoped>

#account-status {
    width: 95%;
    display: flex;
    flex-direction: column;
    margin: auto;
    padding-top: 50px;
    position: relative;
    z-index: 1;
}

h3 {
    font-weight: 600;
    font-size: 14px;
}

.account-status-container {
    width: max-content;
    cursor: pointer;
    box-sizing: border-box;
    padding: 10px 20px 10px 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-width: 200px;
    align-items: center;
    background-color: white;
}

.account-status-container p {
    font-weight: 600;
    margin: 0px 0px 0px 0px;
}

.account-status-icon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid white;
}

.account-status-icon img {
    width: 10px;
    height: 10px;
}

.pro {
    border: 1px solid #8CC63E;
    background-color: #8CC63E;
    color: white;
}

.free-trial {
    border: 1px solid #6F3A87;
    background-color: #6F3A87;
    color: white;
}

.expired {
    border: 1px solid #D13737;
}

.expired-text {
    color: #D13737;
}

.days-remaining {
    cursor: pointer;
    margin-top: 20px;
    font-size: 13px;
}

.days-remaining-free-trial {
    margin-top: 20px;
    font-weight: 600;
    font-size: 12.5px;
    background-color: #F1F1F2;
    width: max-content;
    box-sizing: border-box;
    padding: 2.5px 5px 2.5px 5px;
    color: #6F3A87;
    cursor: pointer;
}

.days-remaining-free-trial span {
    font-weight: 600;
}

.days-remaining span {
    font-weight: 600;
}

.pdf-menu {
    background-color: #27A9E1;
    color: white;
}

.purchase {
    font-size: 15px;
    margin: 25px 0px 15px 0px;
    box-sizing: border-box;
    padding: 5px 10px 5px 10px;
    background-color: black;
    background: linear-gradient(to right, rgb(153, 202, 60), rgb(7, 153, 72));
    width: max-content;
    color: white;
    font-family: 'Cabin', sans-serif;
    letter-spacing: 1px;
    border-radius: 2.5px;
    font-weight: 400 !important;
    /* text-transform: uppercase; */
}

.purchase span {
    font-weight: 400;
}

.administrator {
    border: 1px solid black;
    text-transform: uppercase;
    background-color: black;
    color: white;
    font-size: 10px;
    letter-spacing: 1px;
}

</style>