<template>
  <div class="container">
    <h1>Admin User Relationships</h1>
    <h2>Existing records</h2>
    <div class="table">
      <div class="table-header">
        <div class="table-cell">Menu</div>
        <div class="table-cell">User</div>
        <div class="table-cell delete-column">Action</div> <!-- Placeholder for delete button header -->
      </div>
      <div class="table-row" v-for="relationship in relationships" :key="relationship.id">
        <div class="table-cell">{{ relationship.menu.name }} ({{ relationship.menu_id }}) </div>
        <div class="table-cell">{{ relationship.user.email }} ({{ relationship.user_id }})</div>
        <div class="table-cell delete-column">
          <button @click="deleteRelationship(relationship.id)">Delete</button>
        </div>
      </div>
    </div>
    <CreateAdminUserRelationship @relationshipCreated="fetchRelationships" />
    <CurrentOutletCollections :outlet_collections="outlet_collections" />
    <CurrentUsers :users="users" />
  </div>
</template>

<script>
import CreateAdminUserRelationship from './CreateAdminUserRelationship.vue';
import CurrentUsers from './CurrentUsers.vue';
import CurrentOutletCollections from './CurrentOutletCollections.vue';

export default {
  components: {
    CreateAdminUserRelationship,
    CurrentUsers,
    CurrentOutletCollections
  },
  data() {
    return {
      relationships: [],
      users: [],
      outlet_collections: []
    }
  },
  computed: {
    isAdmin() {
      return this.$store.getters.getUserType === 'admin';
    }
  },
  created() {
    this.fetchRelationships();
  },
  methods: {
    fetchRelationships() {
      this.$http.secured.get('/admin_user_relationships')
        .then(response => {
          this.relationships = response.data.admin_user_relationships;
          this.users = response.data.users;
          this.outlet_collections = response.data.outlet_collections;
        })
        .catch(error => {
          console.error(error);
        });
    },
    deleteRelationship(id) {
      this.$http.secured.delete(`/admin_user_relationships/${id}`)
        .then(response => {
          console.log('Admin User Relationship deleted successfully');
          this.$store.commit('setMessage', {
            content: "Admin User Relationship deleted successfully",
            error: false
          });
          this.fetchRelationships(); // Refresh the list after deletion
        })
        .catch(error => {
          console.error(error);
          this.$store.commit('setMessage', {
            content: "Failed to delete Admin User Relationship",
            error: true
          });
        });
    }
  }
}
</script>

<style scoped>
.container {
  width: 90%;
  margin: auto;
  margin-top: 100px;
}

.table {
  display: flex;
  flex-direction: column;
}

.table-header,
.table-row {
  display: flex;
}

.table-header {
  font-size: 12px;
  font-weight: 600;
}

.table-header .table-cell {
  border: none;
}

.table-cell {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
}

.delete-column {
  flex: 0.1;
}
</style>