<template>

    <div class="container">

        <div class="info">

            <img src="../assets/illo_chefcry.svg" />

            <h1>Oh no!</h1>

            

            <h2>It looks like this menu is not active.</h2>

            <p>If this is your restaurant's menu, please log in to the dashboard or contact us at <span>hello@menulingua.com</span>.</p>

        </div>

    </div>




</template>

<script>

export default {
    name: "Deactivated"
}



</script>

<style scoped>


.container {
    width: 100%;
    height: 100%;
    min-height: 80vh;
    background-color: rgb(244, 244, 244);
    padding-top: 50px;
}

.info {
    display: flex;
    flex-direction: column;
    max-width: 700px;
    width: 90%;
    margin: auto;
}

.info img {
    width: 100px;
}

.info h1 {
    font-weight: 400;
    font-size: 50px;
    margin: 20px 0px 20px 0px;
    border: 1px solid lightgray;
    width: max-content;
    box-sizing: border-box;
    padding: 5px;
    border-radius: 10px;
    
}

.info h2 {
    font-weight: 400;
    margin: 10px 0px 10px 0px;
    
}

.info p {
    font-size: 12.5px;
    font-weight: 600;
}

.info p span {
    font-weight: 800;
}


</style>