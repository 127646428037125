<template>

    <div id="cookie-banner" v-if="hide_banner != true">

        <div v-if="show_preferences != true" class="basic">

            <div class="text">

                <p class="heavy">We use cookies to enhance the user experience</p>

                <p class="light">We don’t share your personal information with anyone</p>

                <p class="light">View our <a href="https://dashboard.menulingua.com/privacy_policy">privacy policy</a></p>

            </div>

            <div class="buttons">

                <div class="button green-button" @click="acceptCookies()">

                    Accept all

                </div>

                <div class="button light-button" @click="show_preferences = true">

                    Cookie settings

                </div>

            </div>

        </div>

        <div v-if="show_preferences === true" class="preferences">

            <p class="heavy">

                <img src="@/assets/arrow_black.svg" @click="show_preferences = false" />

                Cookie settings
            </p>

            <div class="cookie-category">

                <div class="category-text">

                    <p class="heavy">
                        Essential cookies
                    </p>

                    <p class="light">
                        These are required to make the site work properly.
                    </p>

                </div>

                <div class="category-buttons">

                    <div class="category-button required">
                        Accept
                    </div>

                </div>

            </div>


            <div class="cookie-category">

                <div class="category-text">

                    <p class="heavy">
                        Analytics cookies
                    </p>

                    <p class="light">
                        These help us understand our users and do not include any personally-identifiable information. We do not sell this information to anyone.
                    </p>

                </div>

                <div class="category-buttons">

                    <div class="category-button" @click="acceptCookies()">
                        Accept
                    </div>

                    <div class="category-button reject-button" @click="acceptFunctionalOnly()">
                        Reject
                    </div>

                </div>

            </div>





        </div>



    </div>



</template>

<script>
import VueCookies from 'vue-cookies'

export default {
    name: "CookieBanner",
    data () {
        return {
            hide_banner: false,
            show_preferences: false
        }
    },
    created() {
        this.getCookiesPreferences();
    },
    methods: {
        getCookiesPreferences() {
            if (this.$cookies.get('functional') === "true") {
                this.hide_banner = true
            }
        },
        acceptCookies() {
            this.$cookies.set("functional","true","3y");
            if (this.$cookies.get('functional-only') === null) {
                this.$cookies.set("statistical","true","3y");
            }
            this.hide_banner = true
        },
        acceptFunctionalOnly() {
            this.$cookies.set("functional","true","3y");
            this.hide_banner = true
        }
    }
}



</script>

<style scoped>

#cookie-banner {
    width: 95%;
    margin: auto;
    background-color: white;
    border: 2px solid #8CC63E;
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: fixed;
    z-index: 1000;
    bottom: 10px;
    left: 50%;
    flex-wrap: wrap;
    transform: translate(-50%, 0);
}

.text {
    width: 70%;
    display: flex;
    flex-direction: column;
}

.buttons {
    display: flex;
    flex-direction: column;
    width: 20%;
}

p {
    margin: 0px 0px 0px 0px;
}

.heavy {
    font-size: 17.5px;
    font-weight: 600;
    margin: 0px 0px 10px 0px;
    display: flex;
    align-items: center;
}

.light {
    font-size: 12.5px;
    margin: 5px 0px 0px 0px;
    font-weight: 600;
}

.light a {
    font-weight: 800;
    cursor: pointer;
}

.button {
    cursor: pointer;
}

.basic {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
}

.green-button {
    box-sizing: border-box;
    padding: 10px 20px 10px 20px;
    background-color: #8CC63E;
    color: white;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 10px;
}

.light-button {
    border: 1px solid #8CC63E;
    padding: 5px 10px 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12.5px;
    font-weight: 600;
}

.cookie-category {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 30px 0px 0px 0px;
    align-items: center;
    /* border: 1px dotted lightgray; */
}

.preferences {
    width: 100%;
}

.category-text {
    width: 80%;
}

.category-buttons {
    display: flex;
    flex-direction: column;
    width: 15%;
}

.category-button {
    width: 100%;
    background-color: #8CC63E;
    border: 2px solid #8CC63E;
    color: white;
    font-size: 12.5px;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    padding: 10px 20px 10px 20px;
    font-weight: 600;
    cursor: pointer;
}

.reject-button {
    border: 2px solid #8CC63E;
    background-color: white;
    box-sizing: border-box;
    color: black;
    margin: 5px 0px 0px 0px;
    cursor: pointer;
}

.required {
    opacity: 0.8;
    pointer-events: none;
}

.heavy img {
    width: 15px;
    height: 15px;
    margin-right: 20px;
    cursor: pointer;
}

@media(max-width: 700px) {
    .text {
        width: 100%;
    }

    .buttons {
        width: 100%;
        margin-top: 25px;
    }

    .category-buttons {
        width: 30%;
    }

    .category-text {
        width: 60%;
    }
}


</style>