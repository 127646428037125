<template>

    <div id="menu-header" :style="{ 'background-color' : menu.menu_header_background_color }">

        <div v-if="is_loading == true" class="loader">
            
            <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>

        </div> 

        <div v-else>

            <div v-if="outlet_collection != null && outlet_collection != ''" @click="redirectToOutletCollection()" class="outlet-collection-redirect">

                <img src="@/assets/arrow_black.svg" />

            </div>

            <div class="logo-container">

                <div class="uploader" v-if="menu.logo.url === null">

                    <p v-if="can_edit" @click="toggleUploader()">Upload a logo for your menu</p>

                    <image-uploader
                        v-if="can_edit && show_uploader === true"
                        :debug="1"
                        :maxWidth="512"
                        :quality="0.7"
                        :autoRotate=false
                        outputFormat="verbose"
                        :preview=false
                        :className="['fileinput', { 'fileinput--loaded' : hasImage }]"
                        :capture="false"
                        accept="video/*,image/*"
                        doNotResize="['gif', 'svg']"
                        @input="setImage"
                        >
                    </image-uploader>   

                </div>

                <div class="logo">

                    <img class="logo-delete" @click="deleteLogo()" src="../assets/garbage_icon.svg" v-if="can_edit && show_logo_options === true && menu.logo.url != null" /> 

                    <img :style="{ 'height' : menu.logo_height + 'px' }" class="logo-img" :class="{ 'selected' : show_logo_options === true }" v-bind:src="menu.logo.url" v-if="menu.logo.url != null" @click="show_logo_options = !show_logo_options" crossOrigin="true" />

                    <div class="resize-icons" v-if="show_logo_options === true && can_edit && menu.logo.url != null">

                        <img class="resize-icon" @click="logoSizeChange('decrease')" src="../assets/minus_button.svg" />

                        <img class="resize-icon" @click="logoSizeChange('increase')" src="../assets/plus_button.svg" />

                    </div>

                </div>

            </div>
            

            <div class="menu-header-text-container">
            
                <h3 :style="{ 'font-size' : menu.app_menu_name_font_size + 'px', 'font-family' : menu.menu_header_font, 'color' : menu.menu_header_font_color }" v-if="show_menu_name === true">{{ menu.name_en || menu.name }}</h3>

                 <PromoImage :menu="menu" :can_edit="can_edit" :language="language" v-on:renew-promo-images="renewPromoImages()" :key="componentKey" />

                <div v-for="lang in languages" v-bind:key="lang" class="special-tag-text" :style=" {'font-family' : menu.menu_header_font, 'color' : menu.menu_header_font_color}">

                    <p v-if="lang === language">{{ menu['special_tag_text_' + lang] }}</p>

                </div>

            </div>

           
            <div class="menu-header-options-container">

                <div class="menu-header-options-container-button" @click="toggleMenuHeaderOptions()"  :class="{ 'toggled' : show_menu_header_options === true }"  v-if="can_edit">
                    
                    <img src="@/assets/settings.svg" />

                    <div class="button-descriptor">Menu header settings</div>

                </div>

                <div class="menu-header-options-container-button" v-if="languages.length > 1 && has_language_selector===true" @click="showLanguageSelector()">

                    <img src="@/assets/language_icon_v3.svg" />

                </div>

                <MenuHeaderOptions :menu="menu" :show_menu_name="show_menu_name" :hide_top_scrollbar="menu.hide_top_scrollbar" :show_search_bar="menu.show_search_bar" v-on:update-background-color="updateBackgroundColor" v-on:toggle-menu-search-bar="toggleMenuSearchBar" v-on:update-font-color="updateFontColor" v-on:toggle-options="toggleMenuHeaderOptions()" v-on:increase-font-size="increaseFontSize()" v-on:decrease-font-size="decreaseFontSize()" v-on:toggle-menu-scrollbar="toggleMenuScrollbar()" v-on:select-menu-header-font="selectMenuHeaderFont" v-on:toggle-menu-name-visibility="toggleMenuNameVisibility()" v-on:toggle-paginate-menu="togglePagination()" v-on:close-modal="show_menu_header_options = false" :languages="languages" :selected_language="language" :can_edit="can_edit" v-if="can_edit && show_menu_header_options === true" class="menu-header-options" />

            </div>

        </div>

        

    </div>




</template>


<script>
import ImageUploader from 'vue-image-upload-resize'
import VueDragResize from 'vue-drag-resize'
import MenuHeaderOptions from '@/components/MenuHeaderOptions.vue'
import PromoImage from '@/components/PromoImage.vue'
    
export default {
    name: 'MenuHeader',
    props: ['menu', 'language', 'can_edit', 'languages', 'show_menu_name', 'has_language_selector', 'outlet_collection'],
    components: {
        ImageUploader,
        VueDragResize,
        MenuHeaderOptions,
        PromoImage
    },
    data () {
        return {
            image: {},
            hasImage: Boolean,
            show_uploader: false,
            logo_height: Number,
            show_logo_options: false,
            show_menu_header_options: false,
            font: '',
            is_loading: false,
            componentKey: 0,
            url: process.env.VUE_APP_API_URL
        }
    },
    created () {
        this.logo_height = this.menu.logo_height
        if (this.menu.menu_header_font === 'default') {
            this.font = 'Montserrat'
        } else {
            this.font = this.menu.menu_header_font
        }
    },
    methods: {
        setError (error, text) {
            this.error = (error.response && error.response.data && error.response.data.error) || text
        },
        toggleUploader() {
            if (this.show_uploader === false) {
                this.show_uploader = true
            } else {
                this.show_uploader = false
            }
        },
        setImage: function (file) {
            this.hasImage = true
            this.image = file
            console.log(file.dataUrl)
            this.menu.logo.url = file.dataUrl
            const data = new FormData();
            Object.entries(file).forEach(([key, value]) => { data.append(key, value) })
            

            this.$http.upload.patch('/app_upload_menu_logo/' + this.menu.id, data
                ).then(response => {
                    console.log(response.data)
                    this.menu.logo.url = response.data.url
                    this.show_uploader = false
                    this.is_loading = false
                })
                .catch(error => this.setError(error, 'Cannot update menu.'))
        },
        renewPromoImages() {
            this.componentKey++
        },
        logoSizeChange(changeType) {
            if (changeType === 'decrease') {
                if (this.menu.logo_height > 40) {
                    this.menu.logo_height = (this.menu.logo_height - 20)
                    this.resize()
                }
            } else if (changeType === 'increase') {
                if (this.menu.logo_height < 300) {
                    this.menu.logo_height = (this.menu.logo_height + 20)
                    this.resize()
                }
            }
        },
        redirectToOutletCollection() {
            if (this.outlet_collection != '') {
                console.log('redirect')
                window.location.replace(`https://${this.outlet_collection}.menulingua.com/${this.language}`)
            }
        },
        resize()  {
            this.$http.secured.post('/app_update_menu/' + this.menu.id, { menu: 
                { 
                    logo_height: this.menu.logo_height
                }}).then(response => {
                    console.log(response.data)
                })
                .catch(error => this.setError(error, 'Cannot update menu.'))
        },
        increaseFontSize() {
            this.menu.app_menu_name_font_size = this.menu.app_menu_name_font_size + 2
            this.$http.secured.post('/app_update_menu/' + this.menu.id, { menu: 
                { 
                    app_menu_name_font_size: this.menu.app_menu_name_font_size
                }}).then(response => {
                    console.log(response.data)
                })
                .catch(error => this.setError(error, 'Cannot update menu.'))             
        },
        decreaseFontSize() {
            this.menu.app_menu_name_font_size = this.menu.app_menu_name_font_size - 2
            this.$http.secured.post('/app_update_menu/' + this.menu.id, { menu: 
                { 
                    app_menu_name_font_size: this.menu.app_menu_name_font_size
                }}).then(response => {
                    console.log(response.data)
                })
                .catch(error => this.setError(error, 'Cannot update menu.'))             
        }, 
        toggleMenuScrollbar() {
            this.menu.hide_top_scrollbar = !this.menu.hide_top_scrollbar
            this.$http.secured.post('/app_update_menu/' + this.menu.id, { menu: 
                { 
                    hide_top_scrollbar: this.menu.hide_top_scrollbar
                }}).then(response => {
                    console.log(response.data)
                })
                .catch(error => this.setError(error, 'Cannot update menu.'))             
        }, 
        togglePagination() {
            this.menu.paginate = !this.menu.paginate
            this.$http.secured.post('/app_update_menu/' + this.menu.id, { menu: 
                { 
                    paginate: this.menu.paginate
                }}).then(response => {
                    console.log(response.data)
                })
                .catch(error => this.setError(error, 'Cannot update menu.'))             
        }, 
        toggleMenuSearchBar() {
            this.menu.show_search_bar = !this.menu.show_search_bar
            this.$http.secured.post('/app_update_menu/' + this.menu.id, { menu: 
                { 
                    show_search_bar: this.menu.show_search_bar
                }}).then(response => {
                    console.log(response.data)
                })
                .catch(error => this.setError(error, 'Cannot update menu.'))    
        },
        toggleMenuNameVisibility() {
            this.show_menu_name = !this.show_menu_name
            this.$http.secured.post('/app_update_menu/' + this.menu.id, { menu: 
                { 
                    show_menu_name: this.show_menu_name
                }}).then(response => {
                    console.log(response.data)
                })
                .catch(error => this.setError(error, 'Cannot update menu.'))  
        }, 
        showLanguageSelector() {
            this.$emit('show-language-selector')
        },
        selectMenuHeaderFont: function (font) {
            this.menu.menu_header_font = font
        },
        updateBackgroundColor(color) {
            this.menu.menu_header_background_color = color
        },
        updateFontColor(color) {
            this.menu.menu_header_font_color = color
        },
        deleteLogo() {
            if(confirm("Are you sure you want to delete this logo?")) {
                this.$http.secured.post('/app_remove_menu_logo/' + this.menu.id).then(response => {
                        console.log('LOGO')
                        this.menu.logo.url = null
                    })
                    .catch(error => this.setError(error, 'Cannot update menu.'))
            }
        },
        toggleMenuHeaderOptions() {
            if (this.show_menu_header_options === false) {
                this.show_menu_header_options = true
            } else {
                this.show_menu_header_options = false
            }
        }
    }
}


</script>

<style scoped>

#menu-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    background-color: white;
}

.menu-header-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.menu-header-text-container h3 {
    font-weight: 600;
}

.logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    max-height: 600px;
}

.logo {
    display: flex;
    position: relative;
    cursor: pointer;
    max-height: 600px;
}

.uploader {
    /* width: 100%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 10px;
    width: 200px;
}

.uploader p {
    margin: 2.5px 0px 5px 0px;
    font-size: 12.5px;
    font-weight: 600;
    padding: 2.5px;
    box-sizing: border-box;
    cursor: pointer;
    text-align: center;
    border-radius: 2.5px;
    background-color: white;
    /* border: 1px solid rgba(0, 0, 0, 0); */
    border: 1px solid black;
}

.uploader p:hover {
    border: 1px solid black;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.logo-img {
    border: 1px solid rgba(0, 0, 0, 0);
    padding: 5px;
}

.logo-delete {
    width: 15px;
    height: 15px;
    position: absolute;
    left: -15px;
    top: 0px;
    background-color: #A33B3B;
    padding: 5px;
    border-radius: 50%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.menu-header-options-container {
    display: flex;
    flex-direction: row;
    /* position: relative; */
    width: 100%;
    /* max-width: 500px; */
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    /* border: 1px dotted lightgray; */
}

.menu-header-options-container p {
    margin: 2.5px 0px 5px 0px;
    font-size: 12.5px;
    font-weight: 600;
    padding: 2.5px 5px 2.5px 5px;
    box-sizing: border-box;
    cursor: pointer;
    text-align: center;
    border-radius: 2.5px;
    /* border: 1px solid rgba(0, 0, 0, 0); */
    border: 1px solid lightgray;
    color: gray;
    width: max-content;
}

.menu-header-options-container-button {
    background-color: white;
    cursor: pointer;
    background-color: white;
    display: flex;
    flex-direction: row;
    width: 25px; 
    height: 25px;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 2.5px;
    box-shadow: 0 0 8px rgba(100, 100, 100, 0.1);
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    margin: 0px 5px 0px 5px;
}

.menu-header-options-container img {
    width: 17.5px;
    height: 17.5px;
}

.toggled {
    background-color: #4794CB !important;
}

.menu-header-options {
    position: absolute;
    top: 100px;
    right: 20px;
    background-color: white;
    box-sizing: border-box;
    padding: 20px;
    margin: auto;
    z-index: 10000;
    margin: auto;
    max-width: 400px;
    width: 90%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.resize-icons {
    position: absolute;
    bottom: -10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* height: 100px; */
    width: 100%;
}

.resize-icon {
    width: 20px;
    height: 20px;
    margin: 0px 10px 0px 10px;
}

.resize-icon:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    transition: 0.2s;
}

.selected {
    border: 1px dotted gray;
    border-radius: 5px;
}

.special-tag-text p {
    font-size: 14px;
    margin: 5px 0px 5px 0px;
    text-align: center;
    box-sizing: border-box;
    padding: 0px 10% 0px 10%;
}

.outlet-collection-redirect {
    /* width: 30px; */
    box-sizing: border-box;
    padding: 5px 20px 5px 20px;
    cursor: pointer;
    width: max-content;
}

.outlet-collection-redirect img {
    width: 30px;
}


.menu-header-options-container-button:hover > .button-descriptor {
    display: flex;
}

@media(max-width: 600px) {
    .menu-header-options {
        top: 50;
        right: 0;
        left: 0;
        max-width: 500px;
        width: 90%;
        z-index: 100000;
    }
}

</style>