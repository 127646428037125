<template>

    <div id="create-server-container">

        <h1 v-if="editing === true">Edit server: {{ server_name }}</h1>

        <h1 v-else>Create server</h1>

        <p class="error">{{ error }}</p>

        <div class="buttons-container" v-if="editing === true">

            <div class="button" :class="{'active' : active === true }" @click="active = !active">

                <img src="@/assets/orders/check_mark_white.svg" v-if="active === true" />

                <img src="@/assets/orders/x_button.svg" v-if="active === false" />

                <p v-if="active === true">Active</p>
                <p v-if="active === false">Deactivated</p>

            </div>

        </div>

        <div class="order-session-creation-modal">

            <div class="form-row">

                <h4>Enter server name</h4>

                <input v-model="server_name" required>

            </div>

            <div class="form-row">

                <h4>Enter email address</h4>

                <input v-model="email" @keyup="checkEmail()" required>

            </div>

            <div @click="submitServer()" class="create-button" v-if="email != '' && validEmail() && server_name != ''">Submit</div>

            <div class="delete-button" v-if="editing === true" @click="deleteServer()">Delete server</div>


        </div>



    </div>


</template>


<script>

export default {
    name: 'CreateServer',
    props: ['editing', 'id'],
    data () {
        return {
            server_name: '',
            email: '',
            error: '',
            active: true,
            deleted: false
        }
    },
    created() {
        this.getMenus();
        this.scrollToTop();
        if (this.editing === true) {
            this.getServer(this.id)
        }
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0,0);
        },
        getMenus() {
            this.$http.secured.get('/app_get_my_menus')
                .then(response => { this.menus = response.data })
                .catch(error => this.setError(error, 'Something went wrong'))
        },
        getServer(id) {
            this.$http.secured.get(`/app_get_server/${id}`)
                .then(response => { this.server_name = response.data.server_name, this.email = response.data.email, this.active = response.data.active })
                .catch(error => this.setError(error, 'Something went wrong'))
        },
        submitServer() {
            if (this.editing === true) {
                this.$http.secured.post(`/app_update_server/${this.id}`, { server: {
                    server_name: this.server_name,
                    email: this.email,
                    active: this.active,
                    deleted: this.deleted
                }})
                    .then(response => { console.log(response), this.$router.replace(`/orders#my-servers`) })
                    .catch(error => this.setError(error, 'Something went wrong'))    
            } else {   
                this.$http.secured.post(`/app_create_server/`, { server: {
                    server_name: this.server_name,
                    email: this.email
                }})
                    .then(response => { console.log(response), this.$router.replace(`/orders#my-servers`) })
                    .catch(error => this.setError(error, 'Something went wrong'))
            }
        },
        deleteServer() {
            if(confirm("Are you sure you want to delete this server?")) {
                (this.deleted = true).then(this.submitServer());
            }
        },
        selectMenu(menu) {
            if (!this.selected_menus.includes(menu.id)) {
                this.selected_menus.push(menu.id)
            } else {
                var index = this.selected_menus.indexOf(menu.id);
                if (index > -1) {
                    this.selected_menus.splice(index, 1)
                }
            }
        },
        validEmail() {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(this.email);
        },
        checkEmail() {
            if (this.validEmail() === false) {
                this.error = 'Please enter a valid email address'
            } else {
                this.error = ''
            }
        }
    }
}


</script>

<style scoped>

#create-server-container {
    margin: auto;
    margin-top: 100px;
    margin-bottom: 50px;
    max-width: 1000px;
    width: 90%;

}

#create-server-container h1 {
    font-size: 30px;
    font-weight: 200;
    margin: 0px 0px 40px 0px;
}


.form-selector {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.form-selector h4 {
    width: 100%;
    
}

.form-selector div {
    width: max-content;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid lightgray;
    color: gray;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 2.5px;
    cursor: pointer;
    margin: 5px 5px 5px 0px;
    font-size: 12.5px;
}

.selected-option {
    background-color: #00a19a;
    color: white !important;
    border: 1px solid #00a19a !important;
    transition: 0.2s;
}

.order-session-creation-modal h4 {
    font-weight: 600;
    border-bottom: 1px dotted lightgray;
    width: max-content;
}

.form-row {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 0px 0px 0px 0px;
    /* border-bottom: 1px solid lightgray; */
}

input {
    width: 100%;
    margin-bottom: 20px;
    font-family: 'Montserrat', sans-serif;
    height: 45px;
    font-size: 25px;
    border: 1px solid lightgray;
    font-weight: 200;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 10px;
}

.create-button {
    width: 100%;
    display: flex;
    margin-top: 25px;
    color: white;
    background-color: #00a19a;
    font-size: 15px;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 20px;
    font-weight: 600;
    border-radius: 2.5px;
    cursor: pointer;
}

.error {
    font-size: 12.5px;
    font-weight: 600;
    color: darkred;
    height: 20px;
    width: 100%;
}

.button {
    width: max-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: lightgray;
    color: gray;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}

.button p {
    margin: 0px 0px 0px 0px;
    font-weight: 600;
}

.button img {
    width: 25px;
    height: 25px;
    margin-right: 10px;
}

.active {
    background-color: #8cc63e;
    color: white;
}

.delete-button {
    margin-top: 50px;
}

</style>