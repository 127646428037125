<template>


  <div id="importer">

    <div class="menu-name" @click="redirectToMenu()">{{ menu.name }}</div>

    <h2>Upload CSV</h2>

    <p class="explainer">To upload a CSV file, first <a @click="exportCSV()">download your menu’s template file</a>. After making changes, export the file to CSV and upload it below.</p>

    <div class="upload-container">

      <input type="file" ref="fileInput" style="display: none" @change="handleFileUpload" />
      <button @click="$refs.fileInput.click()">Choose CSV file</button>

    </div>

    <div class="changes-container" v-if="created_array.length > 0 || updated_array.length > 0">
      
      <div class="items created-items" v-if="created_array.length > 0">
        
        <p class="updated-header">{{ getCreatedText() }}</p>

        <div class="changed-paramater border-bottom" v-for="item in created_array" :key="created_array.indexOf(item)">
        
          <div v-for="(value, key, index) in item" :key="param">

            <div class="new-item-param" v-if="value != null">
              <div class="key-container">
                <p class="new-item-key">{{ key }}</p>
              </div>
              <p class="new-item-value">{{ value }}</p>
            </div>

          </div>
        
        </div>
      
      </div>
      
      <div class="items updated-items" v-if="updated_array.length > 0">
        
        <p class="updated-header">{{ getUpdatedText() }}</p>

        <div v-for="item in updated_array" :key="item.id">

            <div v-for="(value, key, index) in item" :key="key">

              <div class="changed-parameter border-bottom" v-if="valuesDoNotMatch(original_array[updated_array.indexOf(item)][key], value, key)">

                <div class="item-basic-parameters">
                  <p class="item-id">Item ID&nbsp;<span>{{ item.id }}</span></p>
                  <div class="language" v-for="language in itemKeyNames(item)" :key="language.lang">
                    
                    <div class="lang">{{ getLang(language.lang) }}</div>

                    <div class="text">{{ language.text }}</div>
                  
                  </div>

                </div>

                <p class="parameter">{{ key }}</p>

                <div class="changes">

                  <div class="change original">

                    <p class="tag ori">
                      Previous value
                    </p>

                    <p class="value">{{ original_array[updated_array.indexOf(item)][key] }}</p>
                    
                  </div>

                  <img src='@/assets/arrow_forward_black.svg' />

                  <div class="change changed">

                    <p class="tag changed-tag">
                      New value
                    </p>

                    <p class="value">{{ value }}</p>
                    
                  </div>

                </div>
                
              </div>

            </div>

        </div>
      
      </div>
    
    </div>

    <div class="confirm-changes" v-if="pending_changes === true" @click="confirmChanges()">

      <img src="@/assets/checkmark_normal.svg" />
      
      <p>Confirm changes</p>
    
    </div>

    <div class="attributes-container">

      <p>Attributes to include</p>

      <div class="presets">
        <div class="presets-label">
          Presets
        </div>
        <div v-for="preset in user_presets" :key="preset.id" class="preset">
          <img src="@/assets/delete_image.svg" @click="deletePreset(preset)" />
          <div class="preset-selector" @click="togglePreset(preset)" :class="{ 'matched-preset' : selected_attributes == preset.preset_array}">
            {{ preset.name }}
          </div>
        </div>
      </div>
    
      <div v-for="attribute in complete_attributes" :key="attribute" class="attribute" :class="{ 'selected-attribute': selected_attributes.includes(attribute) }" @click="toggleAttribute(attribute)">
    
        {{ attribute }}
  
      </div>

    </div>

    <div class="preset-form" v-if="selected_attributes != complete_attributes">

      <p>Save currently selected fields as preset</p>

      <form @submit.prevent="createPreset()">
        <input type="string" v-model="new_preset_name" placeholder="Preset name" required />
        <button v-if="new_preset_name != ''">Save</button>
      </form>

    </div>

  </div>

</template>


<script>
import Papa from 'papaparse';
export default {
  name: "Importer",
  data() {
    return {
      menu: [],
      created_array: [],
      original_array: [],
      updated_array: [],
      languages_array: [],
      pending_changes: false,
      confirmation: false,
      complete_attributes: [],
      selected_attributes: [],
      new_preset_name: '',
      user_presets: []
    }
  },
  created() {
    this.getMenuName(this.$router.currentRoute.params.menu_id);
    this.getPresets();
  },
  methods: {
    getMenuName(menu_id) {
      this.$http.plain.get('/app_show_menu_v2/' + menu_id )
        .then(response => {
          this.menu = response.data.menu,
          this.complete_attributes = response.data.attributes,
          this.selected_attributes = response.data.attributes
        })
    },
    toggleAttribute(attribute) {
      this.selected_attributes.includes(attribute) ? this.selected_attributes = this.selected_attributes.filter(attr => attr !== attribute) : this.selected_attributes.push(attribute);
      this.getDeselectedItems;
    },
    togglePreset(preset) {
      this.selected_attributes = preset.preset_array
    },
    getPresets() {
      this.$http.secured.get('/app_show_presets').then(response => {
        this.user_presets = response.data
      })
    },
    createPreset() {
      let selectedAttributes = this.selected_attributes
      this.$http.secured.post(`/app_create_preset`, { preset_array: selectedAttributes, name: this.new_preset_name }).then(response => {
        this.getPresets();
        this.new_preset_name = '';
      })
    },
    deletePreset(preset) {
      if (confirm("Are you sure you want to delete this preset?")) {
        this.$http.secured.post(`/app_delete_preset/${preset.id}`).then(response => {
          this.getPresets();
        })
      }
    },
    getDeselectedItems() {
      let combinedArray = this.selected_attributes.concat(this.complete_attributes);
      return combinedArray.filter(function (value, index, array) {
        return array.indexOf(value) === index && array.lastIndexOf(value) === index;
      });
    },
    handleFileUpload() {
    const file = this.$refs.fileInput.files[0];
    const menu_id = this.$router.currentRoute.params.menu_id;
    const reader = new FileReader();
    reader.onload = () => {
      const fileContent = reader.result;
      const deselectedItems = this.getDeselectedItems();
      this.$http.secured.post(`/app_post_rows/${menu_id}/${this.confirmation}`, { fileContent, deselectedItems })
        .then(response => {
          this.original_array = response.data.original_array,
          this.created_array = response.data.created_array,
          this.updated_array = response.data.updated_array,
          this.languages_array = response.data.languages_array,
          this.pending_changes = true,
          this.redirectOnConfirmed();
        })
        .catch(error => {
          console.log(error);
        });
    };
      reader.readAsText(file);
    },
  exportCSV() {
      const menu_id = this.$router.currentRoute.params.menu_id;
      this.$http.secured.get(`/app_export_menu_to_csv/${menu_id}/menu.csv`)
        .then(response => { 
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'menu.csv');
          document.body.appendChild(link);
          link.click();     
        })
        .catch(error => this.setError(error, 'Something went wrong'))  
    },
    confirmChanges() {
      this.confirmation = true;
      this.handleFileUpload();
    },
    redirectOnConfirmed() {
      if (this.confirmation == true) {
        this.$router.replace(`/${this.$router.currentRoute.params.menu_id}`)
      }
    },
    redirectToMenu() {
      this.$router.replace(`/${this.$router.currentRoute.params.menu_id}`)
    },
    getUpdatedText() {
      if (this.updated_array.length > 1) {
        return "Pending updated items"
      } else {
        return "Pending updated item"
      }
    },
    getCreatedText() {
      if (this.created_array.length > 1) {
        return "Items pending for creation"
      } else {
        return "Item pending for creation"
      }     
    },
    valuesDoNotMatch(oldValue, newValue, key) {
      if (!this.selected_attributes.includes(key)) {
        return false
      }
      if (key === 'created_at' || key === 'updated_at') {
        return false
      }
      if (key === 'second_price' && (newValue == 'FALSE' || newValue == 'false')) {
        return false
      }
      if ((!oldValue) && (!newValue)) {
        return false
      }
      if ((oldValue == false && newValue == "FALSE") || (oldValue == false && newValue == "false")) {
        return false
      } else if ((oldValue == true && newValue == "TRUE") || (oldValue == true && newValue == "true")) {
        return false
      } else if (oldValue != newValue) {
        return true
      }
    },
    itemKeyNames(item) {
      const languages = this.languages_array
      let item_names = []
      for (let i = 0; i < languages.length; i++) {
        try {
          if (item[`translation_${languages[i]}`] != null) {
            item_names.push({
            lang: languages[i],
            text: item[`translation_${languages[i]}`]
          })
          }
        } catch {
          item_names.push({})
        }
      }
      return item_names
    },
    getLang(language) {
      if (language === 'ina') {
        return 'ID'
      } else {
        return language
      }
    }
  }

}



</script>

<style scoped>


#importer {
  box-sizing: border-box;
  padding-top: 150px;
  width: 90%;
  margin: auto;
}

h2 {
  font-size: 40px;
  font-weight: 400;
}

.menu-name {
  color: white;
  font-size: 12.5px;
  width: max-content;
  box-sizing: border-box;
  font-weight: 600;
  padding: 5px 20px 5px 20px;
  color: black;
  border: 1px solid black;
}

.explainer {
  width: max-content;
  max-width: 100%;
  /* color: #006838; */
  box-sizing: border-box;
  /* padding: 10px; */
  /* border: 1px solid #006838; */
  /* background: rgba(141, 198, 63, 0.5); */
}

.explainer a {
  font-weight: 600;
  /* color: #006838; */
  cursor: pointer;
}

.upload-container {
  margin-top: 100px;
  margin-bottOm: 100px;
}

.language {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 0px 5px 0px;
}

.item-id {
  box-sizing: border-box;
  padding: 2.5px;
  border: 1px solid black;
  width: max-content;
  font-size: 12.5px;
  margin-bottom: 20px;
  margin-left: 40px;
  margin-top: 0px;
}

.item-id span {
  font-weight: 600;
}

.lang {
  background-color: black;
  box-sizing: border-box;
  padding: 2.5px;
  color: white;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  margin-right: 20px;
  width: 20px;
  text-align: center;
}

.parameter {
  font-weight: 600;
  box-sizing: border-box;
  width: max-content;
  font-size: 17.5px;
  margin: 50px 0px 20px 40px;

}

.tag {
  width: max-content;
  box-sizing: border-box;
  padding: 2.5px;
  border: 1px solid black;
  font-size: 10px;
  margin: 0px 0px 0px 0px;
}

.changes {
  width: 100%;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  padding-left: 40px;
  /* align-items: center; */
}

.changes img {
  width: 2.5%;
  margin-right: 3.25%;
  margin-left: 3.25%;
  margin-top: 15px;
  opacity: 0.7;
}

.change {
  width: 40%;
  /* border: 1px dotted lightgray; */
}

.original {
  box-sizing: border-box;
  padding: 10px;
  background: rgba(150, 150, 150, 0.1);
}

.changed {
  box-sizing: border-box;
  padding: 10px;
  /* background: rgba(0, 0, 0, 0.1); */
  background: rgba(141, 198, 63, 0.3)
}

.border-bottom {
  /* border-bottom: 1px solid lightgray; */
  border: 0.5px solid lightgray;
  margin-bottom: 25px;
  box-sizing: border-box;
  padding: 25px;
  /* background: #F1F2F2; */
} 

.confirm-changes {
  position: fixed;
  background: #39B54A;
  color: white;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  left: 0;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  box-sizing: border-box;
  padding: 20px;
  font-weight: 600;
}

.confirm-changes img {
  width: 20px;
  height: 20px;
  margin-right: 20px;
  filter: brightness(0) invert(1);
}

.updated-header {
  font-size: 25px;
  font-weight:400;
}

.new-item-param {
  display: flex;
  flex-direction: row;
  margin: 5px 0px 5px 0px;
  align-items: center;
}

.new-item-key {
  font-size: 10px;
  box-sizing: border-box;
  padding: 1px 5px 1px 5px;
  border: 1px solid black;
  display: flex;
  align-items: center;
  margin: 0px 10px 0px 0px;
  width: max-content;
}

.new-item-value {
  width: 75%;
}

.new-item-param p {
  margin: 0px 0px 0px 0px;
}

.key-container {
  width: 200px;
}

.created-items {
  margin-bottom: 50px;
}

.menu-name {
  cursor: pointer;
}

button {
  /* background-color: #27AAE1; */
  padding: 10px 20px 10px 20px;
  background: rgba(39, 170, 225, 0.5);
  border: 1px solid rgba(39, 170, 225, 1);
  font-size: 20px;
  color: rgba(39, 170, 225, 1);
  color: #276175;
  font-weight: 600;
  /* color: white; */
  font-family: 'Montserrat';
  cursor: pointer;
}

.attributes-container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  border-top: 1px solid lightgray;
}

.attributes-container p {
  width: 100%;
  font-weight: 600;
}

.attribute {
  width: max-content;
  margin: 5px 5px 5px 0px;
  cursor: pointer;
  box-sizing: border-box;
  padding: 2.5px 5px 2.5px 5px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  background-color: white;
  color: dimgray;
}

.selected-attribute {
  background-color: rgba(141, 198, 63, 0.5);
  color: #006838;
}

.presets {
  width: 100%;
  margin-bottom: 25px;
}

.preset {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.presets img {
  width: 10px;
  height: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.presets-label {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 20px;
}

.preset-selector {
  cursor: pointer;
  font-size: 12.5px;
  margin: 5px 0px 5px 0px;
}

.presets-button {
  font-weight: 600;
  font-size: 13px;
  cursor: pointer;
}

.preset-form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.preset-form p {
  font-size: 13px;
  margin-top: 50px;
  font-weight: 600;
}

.preset-form form {
  display: flex;
  flex-direction: column;
}

.matched-preset {
  font-weight: 600;
}

.preset-form form input {
  width: 100%;
  border-bottom: 1px solid lightgray;
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  font-size: 15px;
  height: 40px;
  margin: 10px 0px 30px 0px;
  font-family: 'Montserrat', sans-serif;
}


@media(max-width: 750px) {
  .new-item-value {
    font-size: 10px;
  }
}

</style>