
const homepageTranslations = {
    call_to_action: {
        en: "Start your 7-day free trial today!",
        id: ""
    },
    sign_up: {
        en: "Create account",
        id: ""
    },
    features_text: {
        en: "Features",
        id: ""
    },
    navbar_pricing: {
        en: "Pricing",
        id: ""
    },
    contact_us_navbar: {
        en: "Contact",
        id: ""
    },
    contact_navbar: {
        en: "Contact us",
        id: ""
    },
    sign_in: {
        en: "Sign in",
        id: ""
    },
    try_free_seven_days: {
        en: "Try free for 7 days",
        id: ""
    },
    no_credit_card_required: {
        en: "No credit card required",
        id: ""
    },
    home: {
        en: "Home",
        id: ""
    },
    routes: {
        sign_in: {
            en: "Sign in",
            id: ""
        },
        create_account: {
            en: "Create account",
            id: ""
        },
        home: {
            en: "Home",
            id: ""
        },
        features: {
            en: "Features",
            id: ""
        },
        try_free_seven_days: {
            en: "Try free for 7 days",
            id: ""
        },
        sample_menu: {
            en: "Sample menu",
            id: ""
        },
        pricing: {
            en: "Pricing",
            id: ""
        },
        contact: {
            en: "Contact us",
            id: ""
        },
        partners: {
            en: "Our partners",
            id: ""
        },
        additional_services: {
            en: "Additional services",
            id: ""
        }
    },
    banner: {
        header: {
            en: `The ultimate <span class="light-green-text">digital menu</span><br> & <span class='dark-green-text'>ordering system</span><br>for your F&B business`,
            id: ""
        },
        subhead: {
            en: "One that matches your branding, is easy to maintain, and helps speed up your order-taking process.",
            id: ""
        }
    },
    features: {
        header: {
            en: "Features",
            id: ""
        },
        list: {
            mobile_optimised: {
                en: "Mobile optimised",
                id: ""
            },
            multi_language: {
                en: "Multi-language support<span class='asterisk'>*</span>",
                id: ""
            },
            admin_accounts: {
                en: "Mutliple admin accounts",
                id: ""
            },
            orderable_menu: {
                en: "Orderable menu",
                id: ""
            },
            order_dashboard: {
                en: "Order dashboard",
                id: ""
            },
            push_notifications: {
                en: "Push notifications",
                id: ""
            },
            bluetooth_printing: {
                en: "Multi-language support<span class='asterisk'>*</span>",
                id: ""
            },
        },
        additional_charges_may_apply: {
            en: "additional charges may apply",
            id: ""
        },
        details: {
            en: "Details",
            id: ""
        }
    },
    sample_menu: {
        header: {
            en: "Sample menu",
            id: ""
        },
        scan_or_tap: {
            en: "Scan or tap on the QR code below",
            id: ""
        }
    },
    pricing: {
        header: {
            en: "Pricing",
            id: ""
        },
        starting_from: {
            en: "Starting from US$<span class='super-bold'>21</span> per month, our flexible subscription is offered in multiple options and time length to suit your business needs.",
            id: ""
        }
    },
    contact_us: {
        header: {
            en: "Contact us",
            id: ""
        },
        subhead: {
            en: "Get in touch to learn more",
            id: ""
        },
        leave_a_message: {
            en: "Leave a message",
            id: ""
        }
    },
    beautiful_menus: {
        header: {
            en: "Build <span class='heavy blue'>beautiful</span> menus.",
            id: ""
        },
        header_two: {
            en: "Build <span class='heavy red'>dynamic</span> menus.",
            id: ""
        }
    },
    partners: {
        header: {
            en: "Our partners",
            id: ""
        },
        subhead_one: {
            en: "Restaurants, cafés, bars, hotels or clubs..",
            id: ""
        },
        subhead_two: {
            en: "Our system is adaptable wherever you serve your guests.",
            id: ""
        },
        subhead_three: {
            en: "We currently improve guests' <span class='green'>order-taking experience</span> and <span class='green'>waitstaff efficiency</span> in",
            id: ""
        },
        cities: {
            en: "<span class='green'>Cities</span>",
            id: ""
        },
        countries: {
            en: "<span class='green'>Countries</span>",
            id: ""
        }
    },
    testimonials: {
        header: {
            en: "Partner testimonials",
            id: ""
        }
    },
    additional_services: {
        header: {
            en: "Additional services",
            id: ""
        },
        menu_translation: {
            en: "Menu translation",
            id: ""
        },
        menu_design: {
            en: "Menu design",
            id: ""
        },
        marketing_design: {
            en: "Marketing design",
            id: ""
        },
        custom_menu_site: {
            en: "Custom menu site",
            id: ""
        }
    },
    footer: {
        social_media: {
            en: "Follow us on social media",
            id: ""
        }
    },
    miscellaneous: {
        create_menu: {
            en: "Create a menu",
            id: ""
        },
        with_our_menu_builder: {
            en: "with our menu builder",
            id: ""
        },
        send_menu: {
            en: "Send us your menu",
            id: ""
        },
        build_for_you: {
            en: "We'll build it for you",
            id: ""
        },
        details: {
            en: "Details",
            id: ""
        }
    },
    pages: {
        pricing:{
            header: {
                en: "Pricing",
                id: ""
            },
            subhead_intl: {
                en: "Starting from US$21 per month, our flexible subscription is offered in multiple options and time length to suit your business needs.",
                id: ""
            },
            subhead_indo: {
                en: "Starting from Rp360.000,00 per month, our flexible subscription is offered in multiple options and time length to suit your business needs.",
                id: ""
            },
            starts_from: {
                en: "Starts from",
                id: ""
            },
            per_month: {
                en: "Per month per outlet",
                id: ""
            },
            per_year: {
                en: "Per year per outlet",
                id: ""
            },
            custom_menu: {
                en: "Build your custom menu & QR code",
                id: ""
            },
            match_design: {
                en: "Match design & style with your branding",
                id: ""
            },
            orderable_menu: {
                en: "Orderable menu",
                id: ""
            },
            unlimited_orders: {
                en: "Accept unlimited orders",
                id: ""
            },
            detailed_analytics: {
                en: "Detailed analytics",
                id: ""
            },
            multiple_admin_accounts: {
                en: "Multiple admin accounts",
                id: ""
            },
            push_notifications: {
                en: "Push notifications",
                id: ""
            },
            bluetooth_printing: {
                en: "Bluetooth printing",
                id: ""
            },
            upload_pdf: {
                en: "Upload your existing PDF menu in seconds",
                id: ""
            },
            custom_qr_code: {
                en: "Custom QR code",
                id: ""
            },
            general_analytics: {
                en: "General analytics",
                id: ""
            },
            content_editing: {
                en: "Content editing",
                id: ""
            },
            additional_charges_apply: {
                en: "Note: An additional one-time fee of $5 is required to set up Bluetooth printing",
                id: ""
            },
            select: {
                en: "Select",
                id: ""
            },
            valid_one_outlet: {
                en: "Valid for 1 (one) outlet",
                id: ""
            },
            try_features: {
                en: "Try <span class='green'>Pro</span> and <span class='blue'>PDF-Only</span> features to decide which plan suits your business best.",
                id: ""
            },
            sign_up: {
                en: "Create account",
                id: ""
            },
            checkout_options: {
                number_of_outlets: {
                    en: "Number of outlets",
                    id: ""
                },
                choose_your_plan: {
                    en: "Choose your plan",
                    id: ""
                },
                menu_translation: {
                    en: "Professional menu translation",
                    id: ""
                },
                languages_disclaimer: {
                    en: "<span class='bold'>We use professional (human) translators</span>. Limited to <span class='bold'>1,000 words</span> per language",
                    id: ""
                },
                grand_total: {
                    en: "Grand total",
                    id: ""
                },
                proceed_to_checkout: {
                    en: "Proceed to checkout",
                    id: ""
                },
                redirecting_to_payment: {
                    en: "Redirecting to payment page",
                    id: ""
                },
                close_checkout_options: {
                    en: "Close",
                    id: ""
                }
            }
        },
        features: {
            header: {
                en: "Managing your menu has never been easier.",
                id: ""
            },
            subhead: {
                en: "Create and update your menu wherever and whenever you’re connected to the internet.",
                id: ""
            },
            digitally_accessible: {
                en: "Make your menu digitally accessible to your guests.",
                id: ""
            },
            own_device: {
                en: "Customers use their own device to scan the code and view your menu.",
                id: ""
            },
            multiple_languages: {
                en: "Present your menu in multiple languages.",
                id: ""
            },
            translation: {
                en: "Input the translation you already have into our system, or employ our human-translation service that is reliable and accurate to context.",
                id: ""
            },
            teamwork: {
                en: "Bring teamwork to the table.",
                id: ""
            },
            admin_members: {
                en: "Give access to up to 5 (five) members of your team to manage and make changes to your menu.",
                id: ""
            },
            orderable: {
                en: "Build a customized menu that is orderable.",
                id: ""
            },
            orderable_subhead: {
                en: "Digitize your order-taking experience, minimize human error and let your waitstaff focus on and attend to other aspects of the service that your guests love and appreciate.",
                id: ""
            },
            order_notifs: {
                en: "Accept orders and notifications on multiple devices.",
                id: ""
            },
            order_notifs_subhead: {
                en: "Track incoming orders on various devices in use at your outlet.<br> Accessible on desktop browsers, mobile browsers, and available as PWA for iOS and Android.",
                id: ""
            },
            maintain_menu: {
                en: "Maintain your menu with efficiency.",
                id: ""
            },
            maintain_menu_subhead: {
                en: "Reduce paper usage by turning multiple pages of print menus into a compact QR code, and update your item availability, prices, photos, and content as often as you need without reprinting.",
                id: ""
            },
            display_online: {
                en: "Display your PDF menu online.",
                id: ""
            },
            display_online_subhead: {
                en: "Upload the PDF file of your menu for instant viewing.",
                id: ""
            }
        }
    }
}





export { homepageTranslations }