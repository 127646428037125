<template>






</template>

<script>

export default {
    name: "SuperMenuCollectionRedirect",
    beforeCreate() {
        this.$http.secured.get(`/app_get_super_menu_collection_url/${this.$router.currentRoute.params.super_menu_collection_id}`)
            .then(response => { 
                window.location.replace(response.data.url)
            }).catch(error => this.setError(error, 'Something went wrong'))       
    }
}




</script>