<template>

    <div id="number-selector-dropdown">


        <div v-for="number in numbers" :key="numbers.indexOf(number)" @click="select(number + 1)" class="selector" :class="{ 'selected' : selected === (number + 1) }">
            
            {{ number + 1 }}

        </div>  



    </div>


</template>

<script>

export default {
    name: "NumberSelectorDropdown",
    props: ['numbers', 'selected'],
    methods: {
        select(number) {
            this.$emit('number-selected', number)
        }
    }
}


</script>

<style scoped>

.selector {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 0px 10px 0px;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    font-family: "Cabin", sans-serif;
    font-weight: 400;
    /* border: 1px dotted lightgray; */
    /* margin: 0px 0px 20px 0px !important; */
}

.selector:hover {
    background-color: rgb(153, 202, 60);
    transition: 0.2s;
}

.selected {
    /* font-weight: 800 !important; */
}

</style>