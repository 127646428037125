<template>

    <div> {{ this.$props.currency }} {{ numberFormat(number, currency) }}</div>

</template>

<script>

export default {
    name: 'NumberFormat',
    props: ['number', 'currency'],
    methods: {
        numberFormat(number, currency) {
            if (number != null) {
                if (this.currency == 'OMR') {
                  return Number(number).toFixed(3)
                } else if (this.currency == 'lei') {
                  return Number(number).toLocaleString('de-DE', { minimumFractionDigits: 2 })
                } else if (this.currency == 'Rp') {
                  return Number(number).toLocaleString()
                } else {
                  if (number % 1 == 0) {
                      return Number(number).toLocaleString()
                  } else {
                      return Number(number).toFixed(2).toLocaleString()
                  }
                }
            } else {
                return "--"
            }
        }
    }
}


</script>

<style scoped>

div {
    margin-left: 2.5px;
    margin-right: 2.5px;
}


</style>