<template>

    <div class="add-discount-form-container">

        <h1>Add a discount</h1>

        <form action="" @submit.prevent="addDiscount">
            <div>

                <p class="form-identifier">Specify type</p>
                <div class="toggle-button-with-selections">
                    <span class="toggle-button-text" :class="{ 'text-activated' : discount_type == 'percentage_discount' }" @click="setToPercentageDiscount()">Percentage discount</span>
                    <span class="toggle-button-text" :class="{ 'text-activated' : discount_type == 'fixed_discount' }" @click="setToFixedDiscount()">Fixed discount</span>
                </div>

                <p class="form-identifier">Minimum spend required?</p>
                <div class="toggle-button-with-selections">
                    <span class="toggle-button-text" :class="{ 'text-activated' : minimum_spend_required == true }" @click="setToMinimumSpendRequired()">Yes</span>
                    <span class="toggle-button-text" :class="{ 'text-activated' : minimum_spend_required == false }" @click="setToMinimumSpendNotRequired()">No</span>
                </div>

                <div v-if="minimum_spend_required == true">
                    <p class="form-identifier">Minimum spend amount</p>
                    <input class="input"
                        autofocus autocomplete="off"
                        name="minimum_spend_amount"
                        type="number"
                        placeholder="Enter amount"
                        v-model="newDiscount.minimum_spend_amount" />   
                </div>


                <div v-if="discount_type == 'percentage_discount'">
                    <p class="form-identifier">Percent discount amount</p>
                    <input class="input"
                        autofocus autocomplete="off"
                        name="percentage"
                        type="number"
                        placeholder="Enter percent off"
                        v-model="newDiscount.percentage_discount" />    
                </div>        

                <div v-if="discount_type == 'fixed_discount'">
                    <p class="form-identifier">Fixed discount amount</p>
                    <input class="input"
                        autofocus autocomplete="off"
                        name="percentage"
                        type="number"
                        placeholder="Enter discount amount"
                        v-model="newDiscount.fixed_amount_discount" />    
                </div>

            </div>

            <input type="submit" value="Create Discount" />
        </form>


    </div>

</template>


<script>

export default {
    name: 'AddDiscount',
    data () {
        return {
            user_type: '',
            user_id: 0,
            error: '',
            discount_type: 'percentage_discount',
            active: true,
            minimum_spend_required: true,
            newDiscount: {},
            menu_id: 0
        }
    },
    created() {
        if (localStorage.signedIn) {
            this.menu_id = this.$router.currentRoute.params.menu_id;
            this.$http.secured.get('/app_current_user')
                .then(response => { 
                    this.user_type = response.data.user_type, this.user_id = response.data.user, this.checkForAdmin() })
                .catch(error => this.setError(error, 'Something went wrong'))
        } else {
            this.$router.replace('/')
        }

    },
    methods: {
        setError (error, text) {
            this.error = (error.response && error.response.data && error.response.data.error) || text
        },
        addDiscount () {
            const value = this.newDiscount
            if (!value) {
                return
            }
            this.$http.secured.post("/app_create_discount/", { discount: { active: this.active, menu_id: this.menu_id, discount_type: this.discount_type, minimum_spend_required: this.minimum_spend_required, minimum_spend_amount: this.newDiscount.minimum_spend_amount, percentage_discount: this.newDiscount.percentage_discount, fixed_amount_discount: this.newDiscount.fixed_amount_discount } })
                .then(response => {
                    console.log(response.data)
                    this.$router.replace('/menu_settings/' + this.menu_id)
                })
                .catch(error => this.setError(error, 'Cannot create discount.'))
        },
        setToPercentageDiscount() {
            this.discount_type = 'percentage_discount'
        },
        setToFixedDiscount() {
            this.discount_type = 'fixed_discount'
        },
        setToMinimumSpendRequired() {
            this.minimum_spend_required = true
        },
        setToMinimumSpendNotRequired() {
            this.minimum_spend_required = false
        }
    }

}


</script>

<style scoped>

.add-discount-form-container {
    max-width: 600px;
    margin: auto;
    width: 90%;
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    box-sizing: border-box;
    padding: 30px;
    border: 1px solid lightgray;
    border-radius: 10px;
}

.input {
    width: 100%;
    border-bottom: 1px solid lightgray;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    font-size: 15px;
    height: 40px;
    margin: 20px 0px 20px 0px;
    font-family: 'Montserrat', sans-serif;
}

h1 {
    font-size: 20px;
    font-weight: 400;
}

input[type="submit"] {
    background-image: linear-gradient(to right, rgb(153, 202, 60), rgb(7, 153, 72));
    display: flex;
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    justify-content: center;
    border: 0px;
    height: 40px;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    margin-top: 50px;
}

.toggle-button-with-selections {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 10px;
    box-sizing: border-box;
}

.toggle-button-with-selections span {
    /* border: 1px solid lightgray; */
    box-sizing: border-box;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12.5px;
    font-weight: 600;
    margin: 5px 0px 5px 0px;
    border-radius: 5px;
    cursor: pointer;
    width: 49%;
}

.toggle-button-text {
    border: 1px solid lightgray;
    color: gray;
    width: 100%;
    height: 40px;
}

.toggle-button-text:hover {
    background-color: lightgray;
    color: white;
    transition: 0.2s;
}

.text-activated {
    border: 1px solid #4394ce;
    color: white;
    background-color: #4394ce;
}

.text-activated:hover {
    border: 1px solid #4394ce;
    color: white;
    background-color: #4394ce;    
}

.form-identifier {
    font-weight: 600;
    margin: 20px 0px 10px 0px;
    font-size: 12.5px;
}

.text-red {
    color: red;
    font-size: 12.5px;
}

</style>