
const state = () => ({
  default_language: '',
})


const mutations = {
  setDefaultLanguage(state, default_language) {
    state.default_language = default_language
  }
}

const getters = {
  getDefaultLanguage(state) {
    return state.default_language
  }
}

export default {
  state,
  getters,
  mutations
}