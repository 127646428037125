<template>

    <div id="contact-us">

        <h3 v-html="$homepageTranslations.contact_us.header[$store.getters.getDefaultLanguage]" class="gradient-text"></h3>

        <p v-html="$homepageTranslations.contact_us.subhead[$store.getters.getDefaultLanguage]" class="subhead"></p>

        <a class="option green" @click="$router.push('/contact')">

            <p>{{ $homepageTranslations.contact_us.leave_a_message[$store.getters.getDefaultLanguage] }}</p>

        </a>

        <a href="mailto:hello@menulingua.com" class="option purple">

            <img src="@/assets/homepage/email_icon.svg" />

            <p>hello@menulingua.com</p>

        </a>

        <a href="https://wa.me/62811979419" class="option light-green">

            <img src="@/assets/homepage/whatsapp.svg" />

            +62 811-979-419

        </a>

        <a href="https://telegram.me/menulingua" class="option blue">

            <img src="@/assets/homepage/telegram-logo.svg" />

            @menulingua

        </a>



    </div>




</template>


<script>

export default {
    name: "ContactUs"
}





</script>

<style scoped>

h3 {
    text-align: center;
    font-size: 40px;
}

.subhead {
    font-family: "Cabin", sans-serif;
    margin: 7.5px 0px 7.5px 0px;
    text-transform: uppercase;
    color: #3B3B3B;
    font-weight: 500;
    letter-spacing: 1px;
    text-align: center;
    margin-bottom: 50px;
}

.option {
    margin: 20px auto 20px auto;
    width: max-content;
    min-width: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    box-sizing: border-box;
    padding: 10px;
    cursor: pointer;
    border-radius: 20px;
    font-weight: 600;
}

.option p {
    margin: 0px 0px 0px 0px;
}

.option img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.green {
    border: 2px solid #94BF3D;
    color: #94BF3D;
}

.purple {
    border: 2px solid rgb(105, 70, 141);
}

.purple p {
    background: -webkit-linear-gradient(left, rgb(105, 70, 141), rgb(67, 42, 106));
    background: -o-linear-gradient(right, rgb(105, 70, 141), rgb(67, 42, 106));
    background: -moz-linear-gradient(right, rgb(105, 70, 141), rgb(67, 42, 106));
    background: linear-gradient(to right, rgb(105, 70, 141), rgb(67, 42, 106));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.light-green {
    border: 2px solid #7AD06D;
    color: #7AD06D;
}

.blue {
    border: 2px solid #23A8DD;
    color: #23A8DD;
}

@media(max-width: 600px) {
    .option {
        width: 95%;
        min-width: auto;
    }
}


</style>