<template>

    <div class="font-selector">
        
        <div v-if="close_modal === true" class="close-modal" @click.stop="$emit('close-modal')">
            <img src="../assets/close.svg" />
            Close
        </div>



        <h2 v-if="close_modal === true">Choose a font</h2>

        <p v-if="selected_items != null">This change will apply to all selected items ({{ selected_items.length }})</p>

        <div class="fonts">

            <div v-for="font in fonts" :key="font">

                <div :style="{ 'font-family' : font }" @click.stop="selectFont(font), $emit('select-font', font)" class="font-selector-box" :class="{ 'selected' : selected === font }">

                    {{ font }}

                </div>

            </div>

        </div>

    </div>

</template>


<script>

export default {
    name: 'FontSelector',
    props: ['font_selector_type', 'selected', 'selected_items', 'close_modal'],
    data() {
        return {
            fonts: ['Montserrat', 'Roboto', 'Merriweather', 'Ubuntu', 'Inconsolata', 'Overpass', 'Source Serif Pro', 'Open Sans', 'Economica', 'Permanent Marker', 'Sacramento', 'Josefin Slab', 'Zilla Slab']
        }
    },
    methods: {
        selectFont(font) {
            this.$store.commit('setSelectedFont', font)
        }
    }
}


</script>


<style scoped>

.fonts {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
}

.font-selector-box {
    display: flex;
    height: 30px;
    width: 100px;
    font-size: 12px;
    align-items: center;
    background-color: white;
    box-sizing: border-box;
    padding: 2.5px;
    margin-bottom: 10px;
    border: 1px solid lightgray;
    border-radius: 5px;
    justify-content: center;
    cursor: pointer;
    /* margin: 5px 5px 5px 5px; */
}

.font-selector h3 {
    font-size: 15px;
    font-weight: 600;
}

.selected {
    background-color: #4794CB;
    color: white;
    border: 1px solid #4794CB;
}



</style>