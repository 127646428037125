<template>

    <div id="colour-palette">

        <div class="top-buttons">

            <div class="back-button" @click="exit()" v-if="colour_picker_shown === false">
                <img src="@/assets/closedialogue.svg" />
            </div>

            <div class="back-button" @click="back()" v-if="colour_picker_shown === true">
                <img src="@/assets/closedialogue.svg" />
            </div>

            <h3 v-if="colour_picker_shown === false">Colour palette</h3>

            <h3 v-if="colour_picker_shown === true">{{ target_name }}</h3>

        </div>

        <div class="colour-container" @click="showColourPicker('background_colour')" v-if="colour_picker_shown === false">

            <p>Background colour</p>

            <div class="colour-box" :style="{ 'background-color' : getBackgroundColour() }"></div>

        </div>

        <div class="colour-container" @click="showColourPicker('item_name_font_colour')" v-if="colour_picker_shown === false">

            <p>Primary text</p>

            <div class="colour-box" :style="{ 'background-color' : getItemNameColour() }">

            </div>

        </div>

        <div class="colour-container" @click="showColourPicker('item_description_font_colour')" v-if="colour_picker_shown === false">

            <p>Secondary text</p>

            <div class="colour-box" :style="{ 'background-color' : getItemPriceColour() }">

            </div>

        </div>

        <div class="picker-container" v-if="colour_picker_shown === true">

            <slider-picker  v-model="colors" v-if="colour_picker_shown === true"></slider-picker>

            <div class="flex-pickers">

                <material-picker v-model="colors" />

                <compact-picker v-model="colors" />

            </div>

            <!-- {{ this.selected_item_details }} -->

            <div class="save-button" @click="saveColour()">
                <img src="@/assets/save.svg" />
                <p>Save</p>
            </div>

        </div>

    </div>


</template>

<script>
import { Slider } from 'vue-color'
import { Material } from 'vue-color'
import { Compact } from 'vue-color'

export default {
    name: 'ColourPalette',
    props: ['selected_item_details', 'selected_items', 'menu'],
    components: {
        'slider-picker': Slider,
        'material-picker' : Material,
        'compact-picker' : Compact
    },
    data () {
        return {
            colour_picker_shown: false,
            items: [],
            preset_colours: [],
            colors: '',
            target: '',
            target_name: '',
            selected_colour: ''
        }
    },
    created() {
        // this.getPresetColours();
        // this.getSelectedItemDetails();
    },
    methods: {
        exit() {
            this.$emit('return-to-buttons');
        },
        back() {
            this.colour_picker_shown = false;
        },
        // getSelectedItemDetails() {
        //     var items = []

        //     for (let i = 0; i < this.selected_item_details.length; i++ ) {
        //         items_object = this.selected_item_details[i]
        //         items.push(this.selected_item_details[i])
        //         console.log(items)
        //     }
        //     this.items = items
        //     // console.log(`SELECTED ITEMS IN SAVE COLOUR: ${this.items}`)
        // },
        updateColourHex() {
            this.colors = this.colors
        },
        saveColour() {
            var target = this.target
            // console.log(`SELECTED ITEMS IN SAVE COLOUR: ${JSON.stringify(this.selected_item_details)}`)
            for (let i = 0; i < this.selected_item_details.length; i++) {
                if (target === 'background_colour') {
                    this.$http.secured.post('/app_update_food/' + this.menu.id + '/' + this.selected_item_details[i].id, { food: 
                        { 
                            background_colour: this.colors.hex || this.colors
                             }}).then(response => { 
                                this.selected_item_details[i].background_colour = this.colors.hex || this.colors
                                this.$emit('items-updated', JSON.stringify(this.selected_item_details)) 
                                }).catch(error => this.setError(error, 'Cannot update food.'))
                }
                if (target === 'item_name_font_colour') {
                    this.$http.secured.post('/app_update_food/' + this.menu.id + '/' + this.selected_item_details[i].id, { food: 
                        { item_name_font_colour: this.colors.hex || this.colors }}).then(response => { 
                            this.selected_item_details[i].item_name_font_colour = this.colors.hex || this.colors
                            this.$emit('items-updated', JSON.stringify(this.selected_item_details)) }).catch(error => this.setError(error, 'Cannot update food.'))
                }
                if (target === 'item_description_font_colour') {
                    this.$http.secured.post('/app_update_food/' + this.menu.id + '/' + this.selected_item_details[i].id, { food: 
                        { item_description_font_colour: this.colors.hex || this.colors }}).then(response => { 
                            this.selected_item_details[i].item_description_font_colour = this.colors.hex || this.colors
                            this.$emit('items-updated', JSON.stringify(this.selected_item_details)) }).catch(error => this.setError(error, 'Cannot update food.'))
                }
            }
        },
        showColourPicker(colour) {
            this.target = colour
            this.colour_picker_shown = true
            this.selected_colour = this.selected_item_details[0][colour]
            if (colour === 'background_colour') {
                this.colors = this.getBackgroundColour();
                this.target_name = 'Background'
            }
            if (colour === 'item_name_font_colour') {
                this.colors = this.getItemNameColour();
                this.target_name = 'Primary text'
            }
            if (colour === 'item_description_font_colour') {
                this.colors = this.getItemPriceColour();
                this.target_name = 'Secondary text'
            }
        },
        getBackgroundColour() {
            if (this.selected_item_details[0].background_colour === 'default' || this.selected_item_details[0].background_colour === null) {
                return '#FFFFFF'
            } else {
                return this.selected_item_details[0].background_colour
            }
        },
        getItemNameColour() {
            if (this.selected_item_details[0].item_name_font_colour === 'default' || this.selected_item_details[0].item_name_font_colour === null) {
                return '#000000'
            } else {
                return this.selected_item_details[0].item_name_font_colour
            }            
        },
        getItemPriceColour() {
            if (this.selected_item_details[0].item_description_font_colour === 'default' || this.selected_item_details[0].item_description_font_colour === null) {
                return '#000000'
            } else {
                return this.selected_item_details[0].item_description_font_colour
            }            
        }
    }
}



</script>

<style scoped>

#colour-palette {
    box-sizing: border-box;
    padding: 0px 10px 0px 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    color: #2F2F2E;
}

.top-buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

h3 {
    font-size: 20px;
    width: max-content;
    font-weight: 400;
}

.back-button {
    width: max-content;
    cursor: pointer;
}

.back-button img {
    width: 20px;
    height: 20px;
}

.colour-container {
    display: flex;
    flex-direction: column;
    width: 33%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-sizing: border-box;
    padding: 20px 0px 20px 0px;
}

.colour-container:hover {
    background-color: #B3CBE7;
    transition: 0.2s;
}

.colour-container p {
    font-weight: 600;
    font-size: 12.5px;
    text-align: center;
    margin: 0px 0px 20px 0px;
}

.colour-box {
    width: 30px;
    height: 30px;
    border: 1px solid black;
    border-radius: 2.5px;
}

.picker-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.save-button {
    width: max-content;
    box-sizing: border-box;
    padding: 10px 50px 10px 50px;
    border: 1px solid #98BD3C;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 2.5px;
}

.save-button p {
    color: #98BD3C;
    font-weight: 600;
    margin: 0px 0px 0px 0px;
    text-transform: uppercase;
    margin-left: 5px;
}

.save-button:hover {
    background-color: #B3CBE7;
    transition: 0.2s;
}

.save-button img {
    width: 25px;
    height: 25px;
}

.flex-pickers {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    /* width: 100%; */
}

/deep/ .vc-slider {
    margin-bottom: 10px;
}

/deep/ .vc-material {
    box-shadow: 0px 0px 0px;
    margin-right: 20px;
    font-family: 'Montserrat', sans-serif;
    padding: 0px;
}

/deep/ .vc-material .vc-input__input {
    color: #2F2F2E;
}

/deep/ .vc-compact {
    box-shadow: 0px 0px 0px;
    display: flex;
    align-items: center;
    margin-left: 5px;
    padding: 0px;
}

@media(max-width: 600px) {
    /deep/ .vc-slider {
        margin: auto;
        width: 90%;
        margin-bottom: 20px;
        padding: 0px;
    }
}

@media(max-width: 400px) {
    .flex-pickers {
        flex-direction: column;
        align-items: flex-start;
        width: 90%;
        margin: auto;
    }
    
    /deep/ .vc-compact {
        box-shadow: 0px 0px 0px;
        display: flex;
        align-items: center;
        margin-left: 0px;
        margin-top: 20px;
        padding: 0px;
    }


}

</style>