<template>

    <div id="home-footer">

        <div class="homepage-overlay" :style="{'background-image': 'url(' + require('@/assets/homepage/pattern_withoutbackgr-dark_resized.png') + ')'}"></div>

        <img class="logo" src="@/assets/logo/menulingua_logo_v2.svg" />

        <div class="top-links" v-if="signed_in === false">

            <p class="gradient-text" v-html="$homepageTranslations.sign_in[$store.getters.getDefaultLanguage]" @click="$router.push('/login')"></p>

            <p class="gradient-text" v-html="$homepageTranslations.try_free_seven_days[$store.getters.getDefaultLanguage]" @click="$router.push('/sign_up')"></p>

        </div>

        <div class="links">

            <p class="gradient-text" v-html="$homepageTranslations.home[$store.getters.getDefaultLanguage]"></p>

            <p class="gradient-text" v-html="$homepageTranslations.features_text[$store.getters.getDefaultLanguage]" @click="$router.push('/features')"></p>

            <p class="gradient-text" v-html="$homepageTranslations.navbar_pricing[$store.getters.getDefaultLanguage]" @click="$router.push('/pricing')"></p>

            <p class="gradient-text" v-html="$homepageTranslations.contact_navbar[$store.getters.getDefaultLanguage]" @click="$router.push('/contact')"></p>

        </div>

        <div class="social-media">

            <p class="social-media-text" v-html="$homepageTranslations.footer.social_media[$store.getters.getDefaultLanguage]"></p>

            <div class="social-media-icons">

                <a href="https://facebook.com/menulingua" target="_blank">

                    <img src="@/assets/homepage/facebook.svg" />

                </a>

                <a href="https://instagram.com/menulingua" target="_blank">

                    <img src="@/assets/homepage/instagram.svg" />

                </a>

            </div>

        </div>

        <div class="bottom-links">

            <p class="bottom-link gradient-text">© Menulingua LLC 2019</p>

            <div class="bottom-links-across gradient-text">

                <p class="bottom-link gradient-text" @click="goTo('privacy-policy')">Privacy Policy</p>

                <p class="bottom-link">•</p>

                <p class="bottom-link gradient-text" @click="goTo('refund-policy')">Refund Policy</p>

                <p class="bottom-link">•</p>

                <p class="bottom-link gradient-text" @click="goTo('terms-of-use')">Terms of Use</p>

            </div>

        </div>

    </div>




</template>

<script>

export default {
    name: "HomeFooter",
    props: ['signed_in'],
    methods: {
        goTo(target) {
            if (target === 'privacy-policy') {
                window.location.replace('https://menulingua.com/privacy_policy')
            }
            if (target === 'refund-policy') {
                window.location.replace('https://menulingua.com/refund_policy')
            }
            if (target === 'terms-of-use') {
                window.location.replace('https://menulingua.com/terms_of_use')
            }
        },
        goToSection(section) {
            this.$router.replace('/')
            this.$nextTick(() => {
                const id = section;
                console.log(section)
                const yOffset = -150; 
                const element = document.getElementById(id);
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
            });
        }
    }
}





</script>

<style scoped>

#home-footer {
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 50px 5% 50px 5%;
}

.logo {
    width: 300px;
    margin: auto;
}

.homepage-overlay {
    position: absolute;
    top: 0;
    width: 100%;
    margin-left: -5%;
    min-height: 600px;
    box-sizing: border-box;
    background-repeat: repeat;
    opacity: 0.05;
}

.top-links {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-top: 50px;
    z-index: 1000;
}

.links {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    margin-top: 50px;
    z-index: 1000;
}

.links p, .top-links p {
    font-weight: 700;
    font-size: 20px;
    margin: 0px 0px 20px 0px;
    cursor: pointer;
}

.social-media {
    display: flex;
    align-items: center;
    flex-direction: column;
    /* justify-content: center; */
    margin-top: 50px;
    z-index: 1000;
}

.social-media p {
    font-family: "Cabin", sans-serif;
    font-size: 20px;
    letter-spacing: 0.5px;
    color: #3B3B3B;
}

.social-media-icons {
    display: flex;
    flex-direction: row;
    align-items: center;

}

.social-media-icons img {
    width: 35px;
    height: 35px;
    margin: 0px 20px 0px 20px;
    cursor: pointer;
}

.bottom-links {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-family: "Cabin", sans-serif;
    font-size: 17.5px;
    font-weight: 600;
    letter-spacing: 0.5px;
    margin-top: 50px;
    z-index: 1000;
}

.bottom-links-across {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.bottom-link {
    margin: 0px 0px 0px 20px;
    cursor: pointer;
}

@media(max-width: 800px) {
    .top-links {
        align-items: flex-start;
    }

    .logo {
        margin-left: 0px;
        width: 200px;
        margin-bottom: 50px;
    }

    .links {
        flex-direction: column;
        width: 100%;
        align-items: flex-start;
        margin-top: 0px;
    }

    .social-media {
        align-items: flex-start;
        margin-top: 0px;
    }

    .social-media-icons img {
        margin: 0px 40px 0px 0px;
    }

    .bottom-links {
        flex-direction: column;
        width: 100%;
        align-items: flex-start;
    }

    .bottom-link {
        margin: 0px 20px 0px 0px;
    }

    .bottom-links-across {
        margin-top: 25px;
    }

    .homepage-overlay {
        min-height: 700px;
    }
}

</style>