<template>

  <NewItem :editing="true" :item_target="item_target" :menu="menu" :languages="languages" v-on:item-added="itemAdded()" v-on:close-item-modal="closeModal()" />

</template>


<script>
import NewItem from '@/components/NewItem.vue'

export default {
    name: 'EditItem',
    props: ['item_target', 'menu', 'languages'],
    components: {
        NewItem
    },
    data () {
        return {

        }
    },
    methods: {
        closeModal() {
            this.$emit('close-item-modal')
        },
        itemAdded() {
            this.$emit('item-added')
        }
    }
}




</script>


<style scoped>









</style>