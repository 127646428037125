<template>

    <div id="green-circles">

        <div v-if="custom_colour === undefined" class="green-circles-container">

            <div v-for="circle in circles" :key="circles.indexOf(circle)" class="circle" :style=" { 'background-color' : colours[circles.indexOf(circle)]}"></div>

        </div>

        <div v-else class="green-circles-container">

            <div v-for="circle in circles" :key="circles.indexOf(circle)" class="circle" :style=" { 'background-color' : custom_colour }"></div>

        </div>

    </div>







</template>

<script>

export default {
    name: "GreenCircles",
    props: ['custom_colour'],
    data () {
        return {
            circles: Array.from(Array(10).keys()),
            colours: [
                "#92BE3D",
                "#84B940",
                "#74B343",
                "#63AD44",
                "#56A845",
                "#44A246",
                "#389C46",
                "#2A9846",
                "#1F9245",
                "#128C44"
            ]
        }
    }
}




</script>

<style scoped>

#green-circles {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* justify-content: space-between; */
    width: 95%;
    max-width: 700px;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
}

.circle {
    width: 11px !important;
    height: 11px !important;
    border-radius: 50%;
    background-color: black;
    margin: 0px 10px 0px 10px;
    background-color: #8CC63E;
    filter: brightness(1);
}

.green-circles-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}


</style>