<template>

<div class="soldout">
  <!-- Generator: Adobe Illustrator 26.4.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 65.9 20" style="enable-background:new 0 0 65.9 20;" xml:space="preserve">
  <path class="st0" d="M6.3,12.5v0.8c0,0.9,0.3,1.4,1,1.4c0.7,0,0.9-0.7,0.9-1.5c0-1.3-0.3-1.7-1.6-2.8s-2-1.7-2-3.6
    c0-1.6,0.5-3.3,2.7-3.3c2.3,0,2.6,1.6,2.6,3v0.7H8V6.6c0-0.9-0.1-1.4-0.8-1.4c-0.6,0-0.8,0.5-0.8,1.4c0,0.9,0.2,1.3,1.3,2.1
    c1.8,1.4,2.4,2.2,2.4,4.2c0,1.8-0.6,3.4-2.9,3.4c-2.3,0-2.8-1.5-2.8-3.1v-0.7L6.3,12.5z" :fill="getColour()"/>
  <path class="st0" d="M18.3,7.1v5.8c0,2.4-1,3.5-3.1,3.5c-2,0-2.9-1.1-2.9-3.5V7.1c0-2.4,1.2-3.4,3-3.4C17.2,3.6,18.3,4.7,18.3,7.1z
    M14.3,6.9v6.2c0,1,0.4,1.5,1.1,1.5c0.7,0,1.1-0.4,1.1-1.5V6.9c0-1-0.3-1.5-1.1-1.5C14.6,5.4,14.3,5.9,14.3,6.9L14.3,6.9z" :fill="getColour()"/>
  <path class="st0" d="M20.8,3.8h1.9v10.6h3.1l-0.2,1.7h-4.8V3.8z" :fill="getColour()"/>
  <path class="st0" d="M27.7,3.8h2.6c2.6,0,3.3,1.4,3.3,3.6v4.7c0,1.9-0.3,4-3.3,4h-2.6L27.7,3.8z M29.6,14.5h0.6
    c1.3,0,1.4-0.8,1.4-2.1V7.3c0-1.1-0.2-1.9-1.5-1.9h-0.6V14.5z" :fill="getColour()"/>
  <path class="st0" d="M45.4,7.1v5.8c0,2.4-1,3.5-3.1,3.5c-2,0-2.9-1.1-2.9-3.5V7.1c0-2.4,1.2-3.4,3-3.4C44.3,3.6,45.4,4.7,45.4,7.1z
    M41.4,6.9v6.2c0,1,0.4,1.5,1.1,1.5c0.7,0,1.1-0.4,1.1-1.5V6.9c0-1-0.3-1.5-1.1-1.5C41.7,5.4,41.4,5.9,41.4,6.9z" :fill="getColour()"/>
  <path class="st0" d="M49.8,3.8v9.4c0,0.9,0.3,1.4,1,1.4c0.8,0,1-0.6,1-1.4V3.8h1.9v9.3c0,1.9-0.9,3.2-2.9,3.2c-2,0-2.9-1.1-2.9-3.2
    V3.8H49.8z" :fill="getColour()"/>
  <path class="st0" d="M57.6,5.6h-2V3.8h5.9v1.8h-2v10.6h-1.9V5.6z" :fill="getColour()"/>
  <path class="st0" d="M61,20H4.9C2.2,20,0,17.8,0,15V4.9C0,2.2,2.2,0,4.9,0H61c2.7,0,4.9,2.2,4.9,4.9V15C65.9,17.8,63.7,20,61,20z
    M4.9,1.3c-2,0-3.6,1.6-3.6,3.6V15c0,2,1.6,3.6,3.6,3.6H61c2,0,3.6-1.6,3.6-3.6V4.9c0-2-1.6-3.6-3.6-3.6H4.9z" :fill="getColour()"/>
  </svg>
</div>





</template>


<script>

export default {
  name: "SoldOut",
  props: ['colour'],
  methods: {
    getColour() {
      if (this.colour === 'default') {
        return '#000000'
      } else {
        return this.colour
      }
    }
  }
}




</script>

<style scoped>

.soldout {
  width: 60px;
  height: 15px;

}


</style>