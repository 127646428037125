<template>

    <div>
        <h2>Choose languages</h2>

        <!-- {{ menu }} -->

        <div class="languages-container">
            
            <Language v-for="language in languages" :key="language[2]" :language = language  v-on:toggle-language="toggleLanguage(language[2])" />

        </div>

        <div class="step-container" >

            <transition name="fade">

                <NextStep v-if="can_proceed == true" :next_step="'Next Step'" />

            </transition>

        </div>

    </div>




</template>
    
<script>
import Language from '@/components/Language.vue'
import NextStep from '@/components/NextStep.vue'

export default {
    name: 'StepThree',
    components: {
        Language,
        NextStep
    },
    props: ['menu'],
    data () {
        return {
            languages: [ ['English', 'English', 'en'], ['Spanish', 'Español', 'sp'], ['Chinese', '中文', 'zh'], [ 'French', 'français', 'fr'], ['Russian', 'русский', 'ru'], ['Arabic', 'العربية', 'ar'], ['German', 'Deutsch', 'de'], ['Korean', '한국어', 'kr'], ['Japanese', '日本語', 'jp'], ['Dutch', 'Nederlands', 'nl'], ['Italian', 'Italiano', 'it'], ['Catalan', 'Català', 'ca'], ['Indonesian', 'Bahasa Indonesia', 'ina'], ['Greek', 'Ελληνικά', 'gre'], ['Romanian', 'Română', 'ro']  ].sort(),
            can_proceed: false
        }
    },
    created () {
        this.$store.getters.getSelectedLanguages
        this.canProceed()
    },
    methods: {
        toggleLanguage(language) {
            this.$store.commit("toggleLanguage", language)
            this.canProceed()
        },
        canProceed() {
            if (this.$store.getters.getSelectedLanguages.length > 0) {
                this.can_proceed = true
            } else {
                this.can_proceed = false
            }
        }
    }



}

</script>

<style scoped>

.languages-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}





</style>