<template>

    <div class="status-container" :style="{ 'background-color' : getBackgroundColour() }">
        
        <div class="status-info">

            <div class="checkbox-container">
                <div class="checkbox">

                    <img src="@/assets/check_mark_white.svg" />

                </div>  
            </div>

            <div class="text">
                
                <h1 v-if="order_status === 'confirmed'">{{ this.$translations.confirmed[language] }}</h1>
                <h1 v-if="order_status === 'submitted'">{{ this.$translations.submitted[language] }}</h1>
                <h1 v-if="order_status === 'otw'">{{ this.$translations.on_the_way[language] }}</h1>
                <p v-if="order_status === 'confirmed'">{{ this.$translations.restaurant_has_acknowledged_your_order[language] }}</p>
                <p v-if="order_status === 'submitted'">{{ this.$translations.order_submitted_successfully[language] }}</p>
                <p v-if="order_status === 'otw'">{{ this.$translations.on_the_way_to_you[language] }}</p>

            </div>

        </div>
    </div>



</template>


<script>

export default {
    name: 'OrderStatus',
    props: ['order_id', 'language'],
    data () {
        return {
            order_status: ''
        }
    },
    created() {
        this.getOrderStatus();
    },
    mounted() {
        if (this.order_status != 'otw') {
            this.checkOrderStatus();
        }
    },
    methods: {
        getOrderStatus() {
            this.$http.plain.get("/app_get_order_status/" + this.order_id)
                .then(response => { this.order_status = response.data.order_status
            })
            .catch(error => this.setError(error, 'Something went wrong'))
        },
        checkOrderStatus: function() {
            if (this.order_status != 'confirmed') {
                var self = this;
                var interval = setInterval(function() {
                    console.log('I just requested order status')
                    self.getOrderStatus();
                }, 5000);
            } else {
                clearInterval(interval);
            }
        },
        getBackgroundColour() {
            if (this.order_status === 'submitted') {
                return '#27A9E1'
            } else if (this.order_status === 'confirmed') {
                return '#8CC63E'
            } else if (this.order_status === 'otw') {
                return '#672D91'
            }
        }
    }
}




</script>

<style scoped>

.status-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 125px;
    margin-bottom: -15px;
    padding: 0px;
}

.status-info {
    width: 90%;
    max-width: max-content;
    height: 110px;
    display: flex;
    flex-direction: row;
    /* margin: auto; */
    margin-left: auto;
    margin-right: auto;
    color: white;
    /* border: 1px dotted lightgray; */
}

.checkbox-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 110px; */
    height: 100%;
    box-sizing: border-box;
    padding: 15px;
    /* margin-right: 25px;    */
}

.checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border: 1px solid white;
    border-radius: 50%;
}

.checkbox img {
    width: 17.5px;
    height: 17.5px;
    /* width: 80px;
    height: 80px;
    border: 1px solid white;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 50%; */
}

.text {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.text h1 {
    font-size: 20px;
    font-weight: 600;
    margin: 0px 0px 10px 0px;
}

.text p {
    margin: 0px 0px 0px 0px;
    font-weight: 600;
}


@media(max-width: 700px) {
    .text h1 {
        font-size: 15px;
        margin: 0px 0px 5px 0px;
    }
    .text p {
        font-size: 12.5px;
    }

    .status-info {
        margin-left: 0;
        margin-right: 0;
    }
}

@media(max-width: 500px) {
    .checkbox-container {
        /* justify-content: flex-end; */
    }
    .checkbox {
        height: 30px;
        width: 30px;
    }
    .checkbox img {
        width: 15px;
        height: 15px;
    }
    
}




</style>