<template>

    <div class='footer-icon'>
        Powered by
        <img src='@/assets/logo/menulingua_logo_v2.svg' />
    </div>

</template>

<script>

export default {
    name: 'Footer'
}


</script>

<style scoped>

.footer-icon {
    height: 200px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 0px 30px 0px 30px;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 2px;
    text-transform: uppercase;
    /* border-top: 1px solid lightgray; */
    background-color: white;
    position: relative;
    z-index: 1;
    margin-top: 50px;
}

.footer-icon img {
    width: 200px;
    margin-left: 5px;
}

@media(max-width: 500px) {
    .footer-icon {
        font-size: 8px;
    }

    .footer-icon img {
        width: 100px;
    }
}


</style>