
const state = () => ({
  subdomain: process.env.VUE_APP_SUBDOMAIN_URL,
})

const getters = {
  getSubdomain(state) {
    return state.subdomain
  },
  getTest(state) {
    return state.test
  }
}

export default {
  state,
  getters,
}