import Vue from 'vue'
import Vuex from 'vuex'
import newmenu from './modules/newmenu'
import editmenu from './modules/editmenu'
import currentuser from './modules/currentuser'
import order from './modules/order'
import message from './modules/message'
import defaultlanguage from './modules/defaultlanguage'
import homepage from './modules/homepage'
import subdomain from './modules/subdomain'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    newmenu: newmenu,
    editmenu: editmenu,
    currentuser: currentuser,
    order: order,
    message: message,
    defaultlanguage: defaultlanguage,
    homepage: homepage,
    subdomain: subdomain
  }
})
