<template>

    <div id="menus-dashboard" class="container">

        <div class="overlay">

            <img src="@/assets/banner-overlay.svg" />

        </div>

        <div class="image-container">

            <img src="@/assets/homepage/menubuilder2.svg" />

        </div>

        <div class="header-text" @click="goTo('/menus')">

            <h2>My menus</h2>

            <p>Create, view and edit your menus</p>

        </div>

        <div class="snapshot-container">

            <div class="snapshot">

                <h5>Menus snapshot</h5>

                <div v-if="menus.length > 0">

                    <div v-for="menu in menus" :key="menu.id" class="menu" @click="goToMenu(menu.id)">

                        <div class="status">

                            <div class="status-indicator" :class="{ 'live' : menu.mobile_version_live === true }"></div>

                        </div>

                        <p>{{ menu.name }} </p>

                    </div>

                </div>

                <div v-else>

                    No menus yet

                </div>

            </div>

            <div class="button" @click="newMenu()" v-if="can_create_menus != false">
                <p>Create a menu</p>
            </div>

        </div>

    </div>

</template>


<script>

export default {
    name: "MenusDashboard",
    props: ['menus', 'can_create_menus'],
    methods: {
        newMenu() {
            this.$router.push('/new');
        },
        goToMenu(id) {
            this.$router.push(`/${id}`)
        },
        goTo(link){
            this.$router.push(link)
        }
    }
}



</script>

<style scoped>

#menus-dashboard {
    width: 95%;
    display: flex;
    flex-direction: row;
    position: relative;
    margin: auto;
    border-radius: 10px;
    margin-bottom: 50px;
    max-height: 350px;
    box-sizing: border-box;
    box-shadow: 0 0 32px rgba(0, 0, 0, 0.1);
}

.container {
    color: white;
    background-image: linear-gradient(to left, rgb(153, 202, 60), rgb(7, 153, 72));
    box-sizing: border-box;
    padding: 50px 2.5% 0px 2.5%;
}

.header-text {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 300px;
    z-index: 10;
    cursor: pointer;
}

h2 {
    margin: 0px 0px 0px 0px;
    font-weight: 600;
    font-size: 35px;
}

.header-text p {
    font-size: 2vw;
    font-weight: 400;
}

.image-container {
    width: 30%;
    display: flex;
    height: 100%;
    min-height: 300px;
    overflow: hidden;
    max-height: 300px;
}

.image-container img {
    min-height: 300px;
}

.snapshot-container {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    z-index: 10;
}

.snapshot {
    background-color: white;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 5px;
    color: black;
    width: max-content;
    min-width: 300px;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
}

.snapshot h5 {
    font-size: 20px;
    font-weight: 600;
    margin: 0px 0px 20px 0px;
}

.menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 0px 10px 0px;
    cursor: pointer;
    width: 100%;
}

.menu p {
    font-size: 12.5px;
    font-weight: 600;
    margin: 0px 0px 0px 0px;
}


.button {
    display: flex;
    box-sizing: border-box;
    padding: 10px 40px 10px 40px;
    border-radius: 2.5px;
    cursor: pointer;
    background-color: #8CC63E;
    color: white;
    width: max-content;
    margin: 25px 0px 25px 0px;
    min-width: 300px;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
}

.button p {
    margin: 0px 0px 0px 0px;
    width: 100%;
    text-align: center;
}

.button:hover {
    box-shadow: 0 0 32px rgba(0, 0, 0, 0.1);
    transition: 0.2s;
}

.status-indicator {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #BE1E2D;
    box-sizing: border-box;
    margin-right: 10px;
}

.live {
    background-color: #95BF32;
}

.status {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.status p {
    margin: 0px 0px 0px 0px;
}

@media(max-width: 1100px) {
    #menus-dashboard {
        flex-wrap: wrap;
        max-height: 1000px;
    }

    .header-text {
        width: 50%;
        height: auto;
    }

    .image-container {
        width: 100%;
        order: 3;
        height: auto;
        justify-content: center;
    }

    .snapshot-container {
        width: 50%;
        justify-content: flex-end;
    }

}

@media(max-width: 700px) {

    #menus-dashboard {
        padding: 50px 5% 0px 5%;
        margin: 20px auto 20px auto;
        /* border-radius: 0px; */
        width: 95%;
    }

    .header-text {
        width: 100%;
        margin: 0px 0px 25px 0px;
        height: auto;
        min-height: 0px;
    }
    .header-text p {
        font-size: 15px;
    }

    .snapshot-container {
        width: 100%;
        align-items: flex-start;
    }

    .snapshot {
        width: 100%;
    }

    .button { 
        width: 100%;
    }
}



</style>