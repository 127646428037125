<template>

    <div @click="$emit('select-currency')" :class="{ 'selected-currency' : this.$store.getters.getCurrency === currency}">

        <span :class="{ 'selected-currency-span' : this.$store.getters.getCurrency === currency}">

            <img v-if="this.$store.getters.getCurrency === currency" src="@/assets/white_check_mark.svg" />

        </span>

        <p>{{ currency }}</p>

    </div>



</template>

<script>

export default {
    name: 'Currency',
    props: ['currency']
}


</script>

<style scoped>

div {
    width: 32%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 5px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    margin: 5px 0px 5px 0px;
    justify-content: flex-start;
    height: 40px;
    margin-right: 1.33%;
}

span {
    border: 1px solid lightgray;
    width: 12.5px;
    height: 12.5px;
    border-radius: 50%;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

span img {
    width: 7.5px;
    height: 7.5px;
}

div:hover {
    cursor: pointer;
}

div p {
    font-size: 12.5px;
    font-weight: 600;
    margin: 0px 0px 0px 0px;
}

.selected-currency {
    background-color: #4794CB;
    /* border: 1px solid #4794CB; */
    color: white;
}

.selected-currency-span {
    background-color: #4794CB;;
    border: 1px solid white;
}

@media(max-width: 700px) {
    div {
        width: 100%;
        border: 1px solid lightgray;
        box-shadow: 0 0 0;
        margin: 10px 0px 10px 0px;
    }

    .selected-currency {
        border: 1px solid #4794CB;
    }
}




</style>