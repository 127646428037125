<template>

    <div class="message" :class="{ 'error-message' : $store.getters.getMessage.error === true }">

        {{ $store.getters.getMessage.content }}

    </div>






</template>

<script>

export default {
    name: "Message"
}





</script>


<style scoped>

.message {
    position: fixed;
    z-index: 10000;
    background-color: #8CC63E;
    color: black;
    top: 100px;
    box-sizing: border-box;
    padding: 10px 50px 10px 50px;
    left: 50%;
    border-radius: 5px;
    transform: translate(-50%);
    text-align: center;
    width: 90%;
    width: max-content;
    max-width: 400px;
    transition: 0.5s;
    font-weight: 600;
}

.error-message {
    background-color: #f39200;
    color: black;
}



</style>