<template>

  <div id="sample-menu">

    <h3 v-html="$homepageTranslations.sample_menu.header[$store.getters.getDefaultLanguage]" class="gradient-text"></h3>

    <p v-html="$homepageTranslations.sample_menu.scan_or_tap[$store.getters.getDefaultLanguage]" class="subhead"></p>

    <div class="container" @click="openSampleMenu()">

      <vue-qr :text="`https://${$store.getters.getSubdomain}.menulingua.com/562877`" :size="300"></vue-qr>

    </div>



  </div>



</template>

<script>
import VueQr from 'vue-qr'

export default {
  name: "SampleMenu",
  components: {
      VueQr
  },
  methods: {
      openSampleMenu() {
          this.$router.push('/562877');
      }
  }
}




</script>

<style scoped>

h3 {
    text-align: center;
    font-size: 40px;
}

.subhead {
    font-family: "Cabin", sans-serif;
    margin: 7.5px 0px 7.5px 0px;
    text-transform: uppercase;
    color: #3B3B3B;
    font-weight: 500;
    letter-spacing: 1px;
    text-align: center;
}

.container {
    display: flex;
    width: 200px;
    height: 200px;
    margin: 50px auto 50px auto;
    align-items: center;
    justify-content: center;
}

.container img {
    width: 200px;
    height: 200px;
    cursor: pointer;
}

</style>