

const state = () => ({
    dropdown_shown: Boolean
})


const mutations = {
    setDropdownShown (state, dropdown_shown) {
        state.dropdown_shown = dropdown_shown
    }
}


const getters = {
    getDropdownShown(state) {
        return state.dropdown_shown
    }
}


export default {
    state,
    getters,
    mutations
}