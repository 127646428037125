<template>

    <div id="try-free-seven-days">

        <h3 v-html="$homepageTranslations.try_free_seven_days[$store.getters.getDefaultLanguage]" class="gradient-text"></h3>

        <p class="subhead" v-html="$homepageTranslations.no_credit_card_required[$store.getters.getDefaultLanguage]"></p>

        <div class="graphics">

            <div class="icon-container green">

                <img class="menu" src="@/assets/homepage/menu_create_icon_only.png" />

                <div class="container-content">

                    <div class="button light-green-border" @click="$router.push('/new')">
                        {{ $homepageTranslations.miscellaneous.create_menu[$store.getters.getDefaultLanguage] }}
                    </div>

                    <p class="small-subhead">{{ $homepageTranslations.miscellaneous.with_our_menu_builder[$store.getters.getDefaultLanguage] }} </p>

                </div>

            </div>

            <div class="icon-container green-gradient">

                <div class="container-content">

                    <div class="button dark-green-border" @click="goToUploadPage()">
                        {{ $homepageTranslations.miscellaneous.send_menu[$store.getters.getDefaultLanguage] }}
                    </div>

                    <p class="small-subhead">{{ $homepageTranslations.miscellaneous.build_for_you[$store.getters.getDefaultLanguage] }} </p>

                </div>

                <img class="menu" src="@/assets/homepage/menu_send.png" />

            </div>

        </div>

    </div>




</template>


<script>

export default {
    name: "TryFreeSevenDays",
    methods: {
        goToUploadPage() {
            window.location.replace('https://dashboard.menulingua.com/upload_my_menu')
        }
    }
}







</script>

<style scoped>

h3 {
    font-size: 40px;
    text-align: center;
}

.subhead {
    font-family: "Cabin", sans-serif;
    margin: 7.5px 0px 7.5px 0px;
    text-transform: uppercase;
    color: #3B3B3B;
    font-weight: 500;
    letter-spacing: 1px;
    text-align: center;
}

.small-subhead {
    font-family: "Cabin", sans-serif;
    margin: 7.5px 0px 0px 0px;
    /* text-transform: uppercase; */
    color: white;
    font-weight: 400;
    letter-spacing: 0.5px;
    text-align: center; 
}

.graphics {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 90%;
    /* max-width: 1000px; */
    /* border: 1px dotted lightgray; */
    margin: 50px auto 50px auto;
}

.icon-container {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 15vw;
}

.green img {
    margin-left: -5px;
}

.green {
    background-color: #63AA37;
    border-radius: 10vw 0 0 10vw;
    box-sizing: border-box;
}

.green-gradient {
    background: linear-gradient(to right, rgb(39, 137, 85), rgb(199, 218, 143));
    border-radius: 0 10vw 10vw 0;
    justify-content: flex-end;
}

.green-gradient img {
    margin-right: -10px;
}

.container-content {
    display: flex;
    flex-direction: column;
    /* border: 1px dotted lightgray; */
    flex-grow: 1;
    align-items: center;
    justify-content: center;
}

.button { 
    width: max-content;
    background-color: white;
    box-sizing: border-box;
    padding: 20px 20px 20px 20px;
    border-radius: 20px;
    font-weight: 600;
    cursor: pointer;
}

.light-green-border {
    border: 5px solid #94BF3D;
    color: #94BF3D;
}

.dark-green-border {
    border: 5px solid #158A44;
    color: #158A44;
}

@media(max-width: 900px) {
    .graphics {
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .icon-container {
        width: 80%;
        height: 27.5vw;
    }

    .green {
        align-self: flex-end;
        margin-left: auto;
        margin-bottom: 50px;
    }

    .green img {
        margin-left: -3.2vw;
    }
}

@media(max-width: 600px) {
    
    .button {
        font-size: 12.5px;
    }

    h3 {
        font-size: 30px;
        width: 90%;
    }
}

</style>