<template>

    <div id="features">

        <h3 v-html="$homepageTranslations.features.header[$store.getters.getDefaultLanguage]" class="gradient-text"></h3>

        <div v-for="feature in features" :key="feature.en">
            <p v-html="feature[$store.getters.getDefaultLanguage]"></p>
        </div>

        <div class="additional-charges-disclaimer">
            <span class="asterisk">*</span> {{ $homepageTranslations.features.additional_charges_may_apply[$store.getters.getDefaultLanguage] }}
        </div>

        <div class="details" @click="$router.push('/features')">
            {{ $homepageTranslations.features.details[$store.getters.getDefaultLanguage] }}
        </div>

    </div>

</template>

<script>

export default {
    name: "Features",
    data () {
        return {
            features: this.$homepageTranslations.features.list
        }
    }
}


</script>

<style scoped>

#features {
    display: flex;
    flex-direction: column;
    align-items: center;
}

h3 {
    font-size: 40px;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

p {
    font-family: "Cabin", sans-serif;
    margin: 7.5px 0px 7.5px 0px;
    text-transform: uppercase;
    color: #3B3B3B;
    font-weight: 500;
    letter-spacing: 1px;
}

p ::v-deep .asterisk {
    color: #F9BC56;
}

.additional-charges-disclaimer {
    margin-top: 25px;
}

.additional-charges-disclaimer .asterisk {
    color: #F9BC56;
}

.additional-charges-disclaimer {
    font-weight: 600;
    color: #919191;
    font-style: italic;
    font-size: 12.5px;
}

.details {
    color: #919191;
    margin-top: 45px;
    font-weight: 600;
    box-sizing: border-box;
    padding: 2.5px 0px 2.5px 0px;
    border-bottom: 1px solid #919191;
    cursor: pointer;
}

</style>