<template>

    <div id="subscription-container">

        <h2>Subscribe</h2>

        <div class="subscriptions">

            <div v-for="subscription in subscriptions" :key="subscription.price" class="subscription" @click="redirectToPayment(subscription)">

                <h3>{{ subscription.name }}</h3>

                <p>IDR {{ subscription.price }}</p>

            </div>

        </div>

    </div>





</template>


<script>

export default {
    name: 'Subscribe',
    data () {
        return {
            url: process.env.VUE_APP_API_URL,
            subscriptions: [ 
                { 
                    name: 'One month',
                    duration: 'one_month',
                    price: '360.000',
                    url_key: '1bulan'
                },
                {
                    name: 'Three months',
                    duration: 'three_months',
                    price: '972.000',
                    url_key: '3bulan'
                },
                {
                    name: 'Six months',
                    duration: 'six_months',
                    price: '1.836.000',
                    url_key: '6bulan'
                },
                {
                    name: 'One year',
                    duration: 'one_year',
                    price: '3.240.000',
                    url_key: '1tahun'
                }
            ]
        }
    },
    methods: {
        redirectToPayment(subscription) {
            if (localStorage.signedIn === "true") {
            this.$http.secured.get('/app_current_user')
                .then(response => { 
                    window.location.replace(this.url + `/subscribe/${subscription.url_key}/${response.data.user}`)
                }).catch(error => this.setError(error, 'Something went wrong'))
            } else {
                 this.$router.push('/sign_up')
            }
        }
    }
}




</script>


<style scoped>


#subscription-container {
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
    margin-top: 100px;
}

.subscriptions {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    border: 1px dotted lightgray;
}

.subscription {
    display: flex;
    flex-direction: column;
    width: 49%;
    margin-right: 0.5%;
    margin-left: 0.5%;
    cursor: pointer;
    /* border: 1px dotted lightgray; */
}


</style>