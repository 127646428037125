

<template>

    <div class="overall-container">

        <div class="loader" v-if="is_loading === true">

            <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>

        </div>

        <div v-else>

            <div class="plans-container" v-if="show_plans_modal === false">

                <div class="plans-header">

                    <h1>Simple, transparent pricing</h1>

                    <h2>Choose a plan to suit your needs.</h2>

                </div>

                <div class="plans-description-container">

                    <div class="pro-plan" @click="showPlansModal()">

                        <MenulinguaPro :user_id="user_id" :user_email="user_email" />

                    </div>

                    <div class="pdf-only-plan">

                        <PdfOnlyMenuPlan :user_id="user_id" :user_email="user_email" v-on:loading="setToIsLoading()" />

                    </div>

                </div>

                <div class="terms">
                    <h3>Our payments are securely processed by Stripe.</h3>
                    <p>All Menulingua pro plans are refundable within 7 days of purchase.</p>
                </div>

                <ContactUsReferral />

            </div>

            <transition name="fade">

                    <div class="pro-plans-modal" v-if="show_plans_modal === true">

                        <div class="pro-plans">

                            <img @click="show_plans_modal = false" src="../assets/close_new.svg" />

                            <h2>Thank you for choosing Menulingua Pro!</h2>

                            <div class="plan-descriptor-top">
                                <h2>Menulingua Pro</h2>
                                <h4>Price per outlet, in USD</h4>
                            </div>

                            <ProPlan v-on:loading="setToIsLoading()" :user_id="user_id" :user_email="user_email" :plan="'price_1JLLSqG2fH76M3MVqUE810SS'" :descriptor="'Monthly'" :billing_descriptor="'Billed monthly'" :price="21" :background_color="'#D1E1AB'" />

                            <ProPlan v-on:loading="setToIsLoading()" :user_id="user_id" :user_email="user_email" :plan="'price_1JLLkkG2fH76M3MV1e0h9W9b'" :descriptor="'3 months'" :billing_descriptor="'Billed every three months'" :monthly_price="'$18.33'" :price="55" :background_color="'#BCD587'" />

                            <ProPlan v-on:loading="setToIsLoading()" :user_id="user_id" :user_email="user_email" :plan="'price_1JV9kVG2fH76M3MVDpnl6bNG'" :descriptor="'6 months'" :price="110" :billing_descriptor="'Billed every six months'" :translation_count="'one'" :monthly_price="'$18.33'" :background_color="'#A8CA60'" />

                            <ProPlan v-on:loading="setToIsLoading()" :user_id="user_id" :user_email="user_email" :plan="'price_1JV9jfG2fH76M3MVBcFDbNUB'" :descriptor="'1 year'" :price="220" :billing_descriptor="'Billed annually'" :monthly_price="'$18.33'" :translation_count="'two'" :background_color="'#94C03D'" />

                        </div>

                    </div>

                </transition>    

            </div>

    </div>


</template>


<script>

import PdfOnlyMenuPlan from '@/components/PdfOnlyMenuPlan.vue'
import MenulinguaPro from '@/components/MenulinguaPro.vue'
import ProPlan from '@/components/ProPlan.vue'
import ContactUsReferral from '@/components/user/ContactUsReferral.vue'

export default {
    name: 'Plans',
    components: {
        PdfOnlyMenuPlan,
        MenulinguaPro,
        ProPlan,
        ContactUsReferral
    },
    data () {
        return {
            user_id: Number,
            user_email: '',
            show_plans_modal: false,
            is_loading: false
            // user_email: ''
        }
    },
    created () {
            // this.user_id =  this.$store.getters.getUserId
            // this.user_email = this.$store.getters.getUserEmail
            // this.$http.plain.post('/check_for_login')
            // .then(response => {
            //         localStorage.token = response.data.access
            //         localStorage.csrf = response.data.csrf
            //         localStorage.refresh = response.data.refresh
            //         console.log(response.data.access)
            //         if (response.data.access != null) {
            //             this.checkForSession();
            //         }
            //     }).catch(error => 'Didnt work')   
                
            // this.getLocation();
        this.getUser();
        
    },
    methods: {
        getUser() {
            if (localStorage.signedIn) {
            this.$http.secured.get('/app_current_user')
                .then(response => { 
                    this.user_email = response.data.email
                })
                .catch(error => this.setError(error, 'Something went wrong'))
            } else {
                this.$router.replace('/login')
            }
        },

        // getLocation() {
        //     this.$http.plain.get('/app_get_user_location')
        //         .then(response => { 
        //             console.log(response.data)
        //         }).catch(error => this.setError(error, 'Something went wrong'))            
        // },
        // checkForSession() {
        //     this.$http.secured.get('/app_current_user')
        //         .then(response => { 
        //             this.$store.commit('setLoggedIn', response.data.user)
        //             this.$store.commit('setUserType', response.data.user_type)
        //             this.$store.commit('setUserEmail', response.data.email)
        //             this.user_id = response.data.user;
        //             this.user_email = response.data.email;
                    
        //             console.log(response.data)
        //             this.is_loading = false;
        //         }).catch(error => this.setError(error, 'Something went wrong'))
        // },
        setToIsLoading() {
            this.is_loading = true;
        },
        showPlansModal() {
            window.scrollTo(0,0);
            this.show_plans_modal = true;
        }
    }
}

</script>


<style scoped>

.loader {
    min-height: 80vh;
}

.plans-container {
    width: 90%;
    max-width: 1200px;
    margin: auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 70px;
    margin-top: 75px;
}

.plans-header {
    margin-bottom: 50px;
    margin-top: 20px;
}

.plans-header h2 {
    font-size: 17.5px;
}

.plans-container h1 {
    font-weight: 400;
}

.plans-description-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.pro-plan {
    width: 60%;
    cursor: pointer;
    display: flex;
    /* box-shadow: 0 0 16px rgba(0, 0, 0, 0.1); */
    z-index: 1;
    /* background-color: white; */
}


.pdf-only-plan {
    width: 41%;
    cursor: pointer;
    display: flex;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: -1%;
    z-index: 0;
}

.terms {
    margin-top: 20px;
}

.terms h3 {
    font-weight: 600; 
}

.terms p {
    margin: 0px 0px 0px 0px;
}

.pro-plans-modal {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    /* min-height: 100%; */
    /* background-color: #FAFBFB; */
    /* position: fixed; */
    z-index: 50;
    top: 0;
    left: 0;
    box-sizing: border-box;
    overflow-y: scroll;
    margin-bottom: 100px;
    /* padding: 20%; */
}

.pro-plans {
    width: 90%;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 20px;
}

.pro-plans h2 {
    font-size: 30px;
}

.pro-plans img {
    width: 15px;
    height: 15px;
}


.plan-descriptor-top {
    background-color: white;
    color: black;
    border: 1px solid lightgray;
    width: max-content;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
}

.plan-descriptor-top h2 {
    margin: 0px 0px 5px 0px;
    font-size: 17.5px;
}

.plan-descriptor-top h4 {
    margin: 0px 0px 0px 0px;
    font-weight: 600;
    font-size: 13px;
}

.overall-container {
    background-color: #F5F5F6;
    box-sizing: border-box;
    padding-bottom: 50px;
    padding-top: 75px;
    /* margin-bottom: -50px; */
}

@media(max-width: 850px) {

    .plans-header {
        margin-bottom: 10px;
    }
    .pro-plan {
        width: 100%;
        margin-bottom: 0px;
    }

    .pdf-only-plan {
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .pro-plans h2 {
        font-size: 20px;
    }

    .plan-descriptor-top h2 {
        font-size: 15px;
    }

    .plan-descriptor-top h4 {
        font-size: 12.5px;
    }
}



</style>