

<template>

    <div class="plan-container" @click="submit">

        <div class="plan-descriptor-top">
            <h2>PDF-Only Plan</h2>
            <h4>Price per outlet, in USD</h4>
        </div>

        <p><img src="@/assets/check-mark.svg" />Upload PDF of your menu in seconds</p>

        <p><img src="@/assets/check-mark.svg" />Custom QR code</p>

        <p><img src="@/assets/check-mark.svg" />Analytics</p>

        <p><img src="@/assets/check-mark.svg" />Multi-language support</p>

        <p><img src="@/assets/check-mark.svg" />Email customer support</p>

        <div class="filler"></div>


        <div class="pricing-box">
            <h3>$5</h3>
            <h5>per year</h5>
        </div>

        <div class="get-button">
            <h6>Get Menulingua PDF</h6>
        </div>







        
        <stripe-checkout
            ref="checkoutRef"
            mode="subscription"
            :pk="publishableKey"
            :line-items="lineItems"
            :customer-email="this.user_email"
            :success-url="successURL"
            :cancel-url="cancelURL"
            @loading="v => loading = v"
        />

        <!-- <button @click="submit">Subscribe!</button> -->

    </div>



</template>


<script>
import { StripeCheckout } from '@vue-stripe/vue-stripe';

export default {
    name: 'PdfOnlyMenuPlan',
    props: ['user_id', 'user_email'],
    components: {
        StripeCheckout
    },
    data () {
        // this.publishableKey = process.env.STRIPE_PUBLISHABLE_KEY;
        this.publishableKey = 'pk_live_apW3KxpwGGVXi16ozbdhS9ub00AfOVCUDR';
        return {
            loading: false,
            customerEmail: this.user_email,
            lineItems: [
                {
                    price: 'price_1JLKxTG2fH76M3MVH3tGzyvJ', // The id of the recurring price you created in your Stripe dashboard
                    quantity: 1,
                }
            ],
          successURL: `https://${process.env.VUE_SUBDOMAIN}.menulingua.com/checkout_success`,
          cancelURL: `https://${process.env.VUE_SUBDOMAIN}.menulingua.com/plans`,
        };
    },
    methods: {
        submit () {
            this.$emit('loading')
        // You will be redirected to Stripe's secure checkout page
            this.$refs.checkoutRef.redirectToCheckout();
        },

    }    
}




</script>


<style scoped>

.plan-container {
    width: 100%;
    border: 1px solid lightgray;
    background-color: white;
    /* box-shadow: 0 0 16px rgba(0, 0, 0, 0.1); */
    box-sizing: border-box;
    padding: 30px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    /* background-color: white; */
}

.plan-container:hover {
    transition: 0.5s;
    background-color: #D6E7F7;
}

.plan-descriptor-top {
    background-color: black;
    color: white;
    width: max-content;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
}

.plan-descriptor-top h2 {
    margin: 0px 0px 5px 0px;
}

.plan-descriptor-top h4 {
    margin: 0px 0px 0px 0px;
    font-weight: 600;
    font-size: 13px;
}

.plan-container p {
    font-weight: 600;
    margin: 10px 0px 10px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.plan-container p img {
    width: 15px;
    height: 15px;
    margin-right: 10px;
}

.subset {
    margin-left: 30px;
}

.subset p {
    font-size: 12.5px;
    margin: 0px 0px 10px 0px;
    display: flex;
    flex-direction: row;
}

.subset p img {
    width: 10px;
    height: 10px;
}

.pricing-box {
    background-color: #27A9E1;
    display: flex;
    flex-direction: row;
    height: 80px;
    margin-top: 20px;
    /* align-items: center; */
    box-sizing: border-box;
    padding: 10px 20px 10px 20px;
    border-radius: 5px;
    color: white;
}

.pricing-box h3 {
    font-size: 50px;
    margin: 0px 10px 0px 0px;
    display: flex;
    /* align-self: flex-end; */
    /* align-items: center; */
    height: 50px;
}

.pricing-box h5 {
    font-weight: 600;
    font-size: 20px;
    margin: 0px 0px 0px 0px;
    /* align-self: flex-end; */
    height: 50px;
    display: flex;
    align-items: flex-end;
}

.get-button {
    margin-top: 20px;
    width: 100%;
    border: 2px solid #27A9E1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    box-sizing: border-box;
    padding: 5px;
    border-radius: 5px;
}

.get-button h6 {
    margin: 0px 0px 0px 0px;
    font-weight: 600;
    font-size: 20px;
}

.filler {
    display: flex;
    flex-grow: 1;
}

@media(max-width: 1000px) {
    .get-button h6 {
        font-size: 15px;
    }
}


@media(max-width: 850px) {

    .plans-header {
        margin-bottom: 10px;
    }
    .pro-plan {
        width: 100%;
        margin-bottom: 0px;
    }

    .plan-container {
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    }
}


</style>