<template>

    <div id="analytics-container">

        <h1>Analytics</h1>

        <div class="admin-button" v-if="user_type === 'admin'" @click="show_admin_data = !show_admin_data">
            <p v-if="show_admin_data === false">Show admin data</p>
            <p v-if="show_admin_data === true">Hide admin data</p>
        </div>

        <div class="admin-data" v-if="show_admin_data === true && user_type === 'admin'">

            <NewAdminData v-if="user_type === 'admin'" v-on:show-user-data="showUserData" />

        </div>



        <div class="views-graph-container">

            <h2>Menu views</h2>

            <div v-if="user_type === 'admin'">
                
                <input @change="updateUserId()" v-model="viewed_user" placeholder="Enter a user ID">

            </div>

            <div class="timeframe-buttons">

                <div @click="setTimeFrame(7)" :class="[{ 'selected' : timeframe == 7 }, 'timeframe-button']">

                    Past 7 days

                </div>  

                <div @click="setTimeFrame(14)" :class="[{ 'selected' : timeframe == 14 }, 'timeframe-button']">

                    Past 14 days

                </div>    

                <div @click="setTimeFrame(30)" :class="[{ 'selected' : timeframe == 30}, 'timeframe-button']">

                    Past 30 days

                </div>                  

            </div>

            <div v-if="is_loading === true" class="loader">
                
                <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>

            </div>   

            <div v-else>

                <div v-if="zero_scans_in_timeframe == true" class="no-views">

                    No views yet

                 </div>

                <div class="graph-body"> 

                    <div v-for="date in dates" :key="date" :style="getWidth()" class="graph-column">

                        <div class="count">
                            <span>{{ datestamp(date) }}</span>
                            <p> {{ filteredScans(date).length }} views</p>
                        </div>

                        <div v-for="scan in filteredScans(date)" :key="scan.id" :class="[ getScanStyle(), scan.language]">

                            <div class="extra-info">
                            
                                <p id="lang"><img src="@/assets/orders/language_icon.svg" />{{ longForm(scan.language) }}</p>

                                <p>{{ timestamp(scan.created_at) }}</p>

                            </div>

                        </div>

                    </div>

                </div>

                <div class="x_axis">

                    <div v-for="date in dates" :key="date" :style="getWidth()" :class="[{ 'thirty-days' : timeframe == 30, 'fourteen-days' : timeframe == 14, 'seven-days' : timeframe == 7 }, 'date-text', getEvenOddDate() ]">

                        {{ datestamp(date) }}
                        
                    </div>   

                </div>

                <div class="totals-container">

                    <div class="quick-numbers">

                        <div class="total">

                            <p>Views in past {{ timeframe }} days</p>

                            <h4>{{ total_scans.toLocaleString() }}</h4>
                        
                        </div>

                        <div class="total">

                            <p>Views per day in past {{ timeframe }} days</p>

                            <h4>{{ viewsPerDay() }}</h4>

                        </div>

                        <div class="total">

                            <p>Views all time</p>

                            <h4>{{ scans_all_time.toLocaleString() }}</h4>

                        </div>                                                 

                    </div>

                    <div class="languages">

                        <h5>Languages</h5>

                        <div class="no-lang-data" v-if="zero_scans_in_timeframe == true">

                            No data

                        </div>

                        <div class="unique-languages">

                            <div v-for="language in getLanguages()" :key="language" class="unique-language">
                                
                                <div class="lang-name-container">
                                    <h4>{{ longForm(language) }}</h4>
                                </div>

                                <div class="lang-bar-container">
                                    <div :class="['bar', language]" :style="getPercentWidth(language)"></div>
                                    <div class="lang-percent">{{ langPercent(language) }}%</div>
                                </div>

                            </div>
                        
                        </div>

                    </div>

                </div>                

            </div>

        </div>

        <OrdersAnalytics v-bind:user="current_user" :key="componentKey" />

    </div>



</template>


<script>
import moment from 'moment'
import OrdersAnalytics from '@/components/orders/OrdersAnalytics.vue'
import AdminData from '@/components/orders/AdminData.vue'
import NewAdminData from '@/components/orders/NewAdminData.vue'

export default {
    name: 'Analytics',
    components : {
        OrdersAnalytics,
        NewAdminData
    },
    data() {
        return {
            timeframe: 14,
            scans: [],
            dates: [],
            menus: 'all',
            start_date: [],
            total_scans: 0,
            average_per_day: 0,
            end_date: [],
            scans_all_time: 0,
            zero_scans_in_timeframe: false,
            is_loading: true,
            current_user: 0,
            max_per_day: 0,
            user_type: '',
            viewed_user: '',
            componentKey: 0,
            show_admin_data: true
        }
    },
    created() {
        if (!localStorage.signedIn) {
            this.$router.replace('/')
        } else {
            this.getCurrentUser();
            this.getEvenOddDate();        
        }
    },
    mounted () {

    },
    methods: {
        getData() {
            let timeframe = this.timeframe;
            console.log(`THIS IS A REQUEST FOR DATA ${this.current_user}`);
            this.$http.secured.get('/app_analytics/' + timeframe + '/' + this.current_user )
                .then(response => { 
                    this.scans = response.data.menu_views,
                    this.total_scans = response.data.total_scans,
                    this.dates = response.data.dates,
                    this.scans_all_time = response.data.scans_all_time,
                    this.getDates();
                    this.checkScanCount(this.total_scans),
                    this.getMaxScans();
                    this.componentKey++
                    this.is_loading = false;
                }).catch(error => this.setError(error, 'Something went wrong'))
        },
        showUserData: function (order_recipient_id) {
            console.log(`USERID: ${order_recipient_id}`)
            this.viewed_user = order_recipient_id
            this.updateUserId();
        },
        getCurrentUser() {
            this.$http.secured.get('/app_current_user')
            .then(response => { 
                this.current_user = response.data.user,
                this.user_type = response.data.user_type
                this.getData();
            }).catch(error => this.setError(error, 'Something went wrong'))
        },
        updateUserId() {
            this.is_loading = true
            this.current_user = parseInt(this.viewed_user)
            if (this.viewed_user == '') {
                this.current_user = 0
            }
            this.getData();
        },
        filteredScans(date_in_question) {
            var refined_date = moment(date_in_question).format('MMM D');
            return this.scans.filter((e) => moment(e.created_at).format('MMM D') === refined_date)
        },
        setError (error, text) {
            this.error = (error.response && error.response.data && error.response.data.error) || text
        },
        getDates(startDate, endDate) {
            var today = new Date();
            endDate = moment().calendar(); 
            startDate = moment().subtract(this.timeframe, 'days').calendar();
            this.start_date = startDate;
            this.end_date = endDate;
        },
        checkScanCount() {
            if (this.total_scans === 0) {
                this.zero_scans_in_timeframe = true
            } else {
                this.zero_scans_in_timeframe = false
            }
        },
        getWidth() {
            var percent = (100 / this.timeframe)
            return { 
                width: percent + '%'
            }
        },
        setTimeFrame(timeframe) {
            this.is_loading = true;
            this.timeframe = timeframe
            this.getData();
            this.getWidth();
            this.checkScanCount();
        },
        viewsPerDay() {
            var number = this.total_scans / this.timeframe
            return number.toFixed(2)
        },
        timestamp(scan_placed) {
            return moment(scan_placed).format('LT');
        },
        datestamp(scan_placed) {
            return moment(scan_placed).format('MMM D');
        },
        getEvenOddDate() {
            var time = new Date().getDate();
            if (time % 2 != 0) {
                return 'odd'
            } else  {
                return 'even'
            }
        },
        getLanguages() {
            return this.scans.map(scan => scan.language)
                .filter((value, index, self) => self.indexOf(value) === index)
        },
        getMostCommonLanguage() {
            var scans = this.scans.map(scan => scan.language)
            function mode(arr){
                return arr.sort((a,b) =>
                    arr.filter(v => v===a).length
                    - arr.filter(v => v===b).length
                ).pop();
            }
            return mode(scans)
        },
        languageCount(lang) {
            return this.scans.filter((scan) => scan.language === lang).length
        },
        langPercent(lang) {
            return ((this.languageCount(lang) / this.total_scans) * 100).toFixed(2)
        },
        getPercentWidth(lang) {
            var percent = ((this.languageCount(lang) / this.languageCount(this.getMostCommonLanguage()) * 100))
            return { 
                width: percent + '%'
            }
        },
        getMaxScans() {
            var scans = []
            var dates = this.dates
            for (let i = 0; i < dates.length; ++i) {
                scans.push(this.filteredScans(dates[i]).length)
            }
            this.max_per_day = Math.max(...scans)
        },
        getScanStyle() {
            var max = this.max_per_day
            if ((max >= 25) && (max <= 75)) {
                return 'scan scan-small'
            } 
            
            if ((max >= 75) && (max <= 400)) {
                return 'scan scan-smaller'
            } 

            if (max >= 300) {
                return 'scan scan-smallest'
            } 
            
            if (max <= 25) {
                return 'scan'
            }
        },
        longForm(language) {
            if (language == 'en') {
                return 'English'
            }
            if (language == 'zh') {
                return 'Chinese'
            }
            if (language == 'de') {
                return 'German'
            }
            if (language == 'sp') {
                return 'Spanish'
            }
            if (language == 'pt') {
                return 'Portuguese'
            }    
            if (language == 'jp') {
                return 'Japanese'
            }     
            if (language == 'kr') {
                return 'Korean'
            }      
            if (language == 'ru') {
                return 'Russian'
            }   
            if (language == 'nl') {
                return 'Dutch'
            }    
            if (language == 'ar') {
                return 'Arabic'
            }  
            if (language == 'fr') {
                return 'French'
            }   
            if (language == 'ca') {
                return 'Catalan'
            }  
            if (language == 'it') {
                return 'Italian'
            }     
            if (language == 'ina') {
                return 'Indonesian'
            }                                                   
        }
    }
}



</script>


<style scoped>

#analytics-container {
    margin: auto;
    width: 90%;
    display: flex;
    flex-direction: column;
    margin-top: 150px;
}

.views-graph-container {
    margin: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
}

#analytics-container h1 {
    font-size: 30px;
    font-weight: 200;
    margin: 0px 0px 40px 0px;
}

.views-graph-container h2 {
    font-weight: 600;
    font-size: 13px;
    box-sizing: border-box;
    border: 1px solid gray;
    padding: 5px;
    display: inline-flex;
    width: 95px;
    border-radius: 5px;
    margin-bottom: 20px;
}

.graph-column:hover {
    background-color: #D8E7F4;
    /* transition: 0.2s; */
}

.scan {
    width: 10px;
    height: 10px;
    background-color: black;
    border-radius: 50%;
    margin: 2.5px 0px 2.5px 0px;
    position: relative;
    cursor: pointer;
}

.scan-small {
    width: 10px;
    height: 5px;
    border-radius: 0%;
    margin: 1px 0px 0px 0px;
}

.scan-smaller {
    width: 10px;
    height: 1px;
    border-radius: 0%;
    margin: 0.5px 0px 0px 0px;
}

.scan-smallest {
    width: 10px;
    height: 0.5px;
    border-radius: 0%;
    margin: 0px 0px 0px 0px;
}

.selected {
    border: 1px solid #4394ce;
    color: white;
    background-color: #4394ce;
}

.extra-info {
    position: absolute;
    display: flex;
    flex-direction: column;
    left: 15px;
    top: -7.5px;
    /* width: 50px; */
    border: 1px solid lightgray;
    background-color: white;
    border-radius: 5px;
    display: none;
    padding: 5px;
    z-index: 100;
}

.scan:hover {
    background-color: lightgray;
}

.scan .extra-info {
    position: absolute;
    z-index: 50;
}

.scan:hover .extra-info {
    position: absolute;
    display: flex;
    background-color: white;
}

.extra-info p {
    font-size: 10px;
    margin: 0px;
    display: flex;
    align-items: center;
}

#lang {
    font-weight: 600 !important;
    font-size: 12.5px !important;
    margin-bottom: 2.5px;
}

#lang img {
    width: 10px;
    height: 10px;
    margin-right: 2.5px;
}

.count {
    font-weight: 600;
    text-align: center;
    white-space: nowrap;
    box-sizing: border-box;
    padding: 5px;
    border: 1px solid gray;
    visibility: hidden;
    margin-bottom: 2.5px;
    border-radius: 5px;
    background-color: white;
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 1;
    bottom: 30px;
    top: -52.5px;
    height: 45px;
    /* margin-bottom: 10px; */
}

.count span {
    font-size: 10px;
    color: black;
    font-weight: 400;
    text-transform: uppercase;
}

.count p {
    margin: 0px 0px 0px 0px;
    font-size: 14px;
    color: black;
}

.graph-column:hover .count {
    visibility: visible;
}

.totals-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    margin-top: 50px;
    justify-content: space-evenly;
}

input {
    width: 100%;
    margin-bottom: 20px;
    font-family: 'Montserrat', sans-serif;
    height: 45px;
    font-size: 25px;
    border: 1px solid lightgray;
    font-weight: 200;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 10px;
}

.quick-numbers {
    width: 49%;
    display: flex;
    flex-direction: column;
}

.total {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid lightgray;
    border-radius: 5px;
    align-items: center;
    max-height: 75px;
}

.total p {
    font-size: 12.5px;
    font-weight: 600;
    margin: 0px 0px 0px 0px;
}

.total h4 {
    font-size: 20px;
    font-weight: 400;
    margin: 0px 0px 0px 0px;
}

.languages { 
    width: 48%;
    box-sizing: border-box;
    padding: 10px;
    border: 1px solid lightgray;
    border-radius: 5px;
}

.languages h5 {
    font-weight: 600;
    margin: 0px 0px 25px 0px;
    font-size: 17.5px;
}

.unique-languages {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* flex-wrap: wrap; */
    margin-top: 10px;
    /* justify-content: space-between; */
}

.unique-language {
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    margin-right: 5px;
    font-size: 13px;
    width: 80px;
    margin-bottom: 5px;
    /* color: gray; */
    font-weight: 600;
    width: 100%;
    margin-bottom: 10px;
    align-items: center;
}

.unique-language h4 {
    font-weight: 600;
    margin: 0px 0px 5px 0px;
    width: 70px !important;
}

.unique-language p {
    font-weight: 400;
    margin: 0px 0px 0px 0px;
    white-space: nowrap;
}

.colour-code {
    width: 10px !important;
    height: 10px !important;
    border-radius: 50%;
    margin-right: 2.5px;
}

.bar {
    height: 10px;
}

.loader {
    height: 300px;
}

.lang-name-container {
    width: 20%;
}

.lang-bar-container {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.lang-bar-container p {
    width: 15px;
    height: 15px;
    color: white;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    padding: 2.5px;
    align-items: center;
    border-radius: 50%;
    font-size: 10px;
    font-weight: 600;
    margin-left: -5px;
}

.lang-percent {
    box-sizing: border-box;
    padding: 2.5px;
    border-radius: 5px;
    margin-left: 5px;
    font-size: 11px;
    color: gray;
}

.admin-data {
    margin-top: 50px;
}

.no-views {
    width: 100%;
    display: flex;
    justify-content: center;
    /* min-height: 200px; */
    /* margin-bottom: -200px; */
    align-items: center;
    position: absolute;
    width: 90%;
    height: 200px;
    /* max-width: 1000px; */
    margin: auto;
}

.no-lang-data {
    font-size: 12.5px;
    font-weight: 400;
    margin: 0px 0px 0px 0px;
}

/* language colours  */
::v-deep.en {
    background-color: #00a19a;
}

::v-deep.de {
    background-color: #673548;
}

::v-deep.it {
    background-color: #0d193d;
}

::v-deep.ar {
    background-color: #618082;
}

::v-deep.nl {
    background-color: #808432;
}

::v-deep.kr {
    background-color: #008d36;
}

::v-deep.pt {
    background-color: #d37abe;
}

::v-deep.jp {
    background-color: #B69269;
}

::v-deep.ca {
    background-color: #ffde00;
}

::v-deep.sp {
    background-color: #008d36;
}

::v-deep.fr {
    background-color: #eb5847;
}

::v-deep.ru {
    background-color: #3c4491;
}

::v-deep.zh {
    background-color: #95c11e;
}

::v-deep.ina {
    background-color: #C85147;
}

::v-deep .timeframe-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    font-size: 12.5px;
    font-weight: 600;
    color: gray;
}

::v-deep .timeframe-button {
    border: 1px solid lightgray;
    box-sizing: border-box;
    padding: 5px;
    border-radius: 5px;
    margin-right: 10px;
    cursor: pointer;
    width: 95px;
    text-align: center;
    white-space: nowrap;
}

::v-deep .x_axis {
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid lightgray;
    box-sizing: border-box;
    padding-top: 5px;
    /* padding-right: 5px; */
}

::v-deep .x_axis div {
    width: auto;
    display: inline-flex;
    font-size: 10px;
    font-weight: 600;
    text-align: center;
    justify-content: center;
    white-space: nowrap;
}

::v-deep .thirty-days {
    visibility: hidden;
}

::v-deep .thirty-days:nth-child(3n) {
    visibility: visible;
    margin-left: 0px;
}

::v-deep .graph-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 50px;
}

::v-deep .graph-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: auto;
    min-height: 200px;
    justify-content: flex-end;
}

.admin-button {
    /* background-color: #00a19a; */
    border: 1px solid #00a19a;
    color: #00a19a;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12.5px;
    font-weight: 600;
    border-radius: 2.5px;
    cursor: pointer;
    /* color: white; */
    max-width: max-content;
    /* margin-bottom: 100px; */
    padding: 0px 20px 0px 20px;
}

::v-deep .timeframe-buttons {
    margin-bottom: 10px;
}

::v-deep .timeframe-button {
    width: 150px;
}

@media(max-width: 900px) {
    .totals-container {
        flex-direction: column;
    }

    .quick-numbers {
        width: 100%;
    }

    .total {
        width: 100%;
        margin: 10px 0px 10px 0px;
    }

    .languages {
        width: 100%;
        margin: 10px 0px 10px 0px;
    }

    .languages h5 {
        font-size: 15px;
    }
}

@media(max-width: 600px) {
    .scan {
        border-radius: 0px;
        margin: 0 0 0 0;
    }
}

@media(max-width: 600px) {
    ::v-deep .fourteen-days {
        visibility: hidden;
    }

    ::v-deep .fourteen-days:nth-child(2n) {
        visibility: visible;
    }

    ::v-deep .timeframe-button {
        font-size: 10px;
    }

    ::v-deep .thirty-days {
        visibility: hidden;
    }

    ::v-deep .thirty-days:nth-child(3n) {
        visibility: hidden;
    }

    ::v-deep .thirty-days:nth-child(5n) {
        visibility: visible;
    }

    ::v-deep .graph-column {
        min-height: 100px;
    }
}




</style>