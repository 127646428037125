

<template>

    <div class="plan-container">
        <div class="plan-descriptor-top">
            <h2>Menulingua Pro</h2>
            <h4>Price per outlet, in USD</h4>
        </div>


        <p><img src="@/assets/check-mark.svg" />Build your custom menu + QR code</p>

        <p><img src="@/assets/check-mark.svg" />Match your branding</p>

        <div class="subset">

            <p><img src="@/assets/check-mark.svg" />Custom colours</p>

            <p><img src="@/assets/check-mark.svg" />Custom fonts</p>

            <p><img src="@/assets/check-mark.svg" />Match design & style of your printed menus</p>

        </div>

        <p><img src="@/assets/check-mark.svg" />Accept orders</p>

        <p><img src="@/assets/check-mark.svg" />Payments integration</p>

        <p><img src="@/assets/check-mark.svg" />Unlimited visits & unlimited orders</p>

        <p><img src="@/assets/check-mark.svg" />Detailed analytics</p>

        <p><img src="@/assets/check-mark.svg" />Email, WhatsApp & Telegram customer support</p>

        <div class="pricing-box">
            <h3>$21</h3>
            <h5>per month</h5>
        </div>

        <div class="get-button">
            <h6>Get Menulingua Pro</h6>
        </div>



    </div>



</template>


<script>

export default {
    name: "MenulinguaPro"
}




</script>

<style scoped>

.plan-container {
    width: 100%;
    border: 1px solid #94C03D;
    /* box-shadow: 0 0 16px rgba(0, 0, 0, 0.1); */
    box-sizing: border-box;
    padding: 30px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
}

.plan-container:hover {
    transition: 0.5s;
    background-color: #E6EED3;
}

.plan-descriptor-top {
    background-color: black;
    color: white;
    width: max-content;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
}

.plan-descriptor-top h2 {
    margin: 0px 0px 5px 0px;
}

.plan-descriptor-top h4 {
    margin: 0px 0px 0px 0px;
    font-weight: 600;
    font-size: 13px;
}

.plan-container p {
    font-weight: 600;
    margin: 10px 0px 10px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.plan-container p img {
    width: 15px;
    height: 15px;
    margin-right: 10px;
}

.subset {
    margin-left: 30px;
}

.subset p {
    font-size: 12.5px;
    margin: 0px 0px 10px 0px;
    display: flex;
    flex-direction: row;
}

.subset p img {
    width: 10px;
    height: 10px;
}

.pricing-box {
    background-color: #94C03D;
    display: flex;
    flex-direction: row;
    height: 80px;
    margin-top: 20px;
    /* align-items: center; */
    box-sizing: border-box;
    padding: 10px 20px 10px 20px;
    border-radius: 5px;
    color: white;
}

.pricing-box h3 {
    font-size: 50px;
    margin: 0px 10px 0px 0px;
    display: flex;
    /* align-self: flex-end; */
    /* align-items: center; */
    height: 50px;
}

.pricing-box h5 {
    font-weight: 600;
    font-size: 20px;
    margin: 0px 0px 0px 0px;
    /* align-self: flex-end; */
    height: 50px;
    display: flex;
    align-items: flex-end;
}

.get-button {
    margin-top: 20px;
    width: 100%;
    border: 2px solid #94C03D;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    box-sizing: border-box;
    padding: 5px;
    border-radius: 5px;
}

.get-button h6 {
    margin: 0px 0px 0px 0px;
    font-weight: 600;
    font-size: 20px;
}

@media(max-width: 1000px) {
    .get-button h6 {
        font-size: 15px;
    }
}


</style>