<template>

<div>

    <Header v-if="this.$store.getters.getLoggedIn != true" />

    <div v-if="is_loading == true" class="loader">
            
        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>

    </div> 

    <div v-else> 

        <div id="new-menu">

            <div class="form-container">

                <div class="back-button" @click="prevStep()" v-if="currentStep() > 1">
                    
                    <img src="@/assets/arrow_black.svg" />

                </div>

                <h4 v-if="editing === false">Create a menu</h4>

                <router-link :to="`/${menu.id}`" v-if="editing === true" class="form-tag">

                    Editing menu: {{ menu.name }}
                        
                </router-link>
                
                <div id="step-one" v-if="currentStep() === 1">

                    <StepOne :key="componentKey" :editing="editing" />

                </div>

                <div id="step-two" v-if="currentStep() === 2">

                    <StepTwo :outlet_type = this.$store.getters.getOutletType :editing="editing" />

                </div>

                <div id="step-three" v-if="currentStep() === 3">

                    <StepThree :editing="editing" :menu="menu" />

                </div>

                <div id="step-four" v-if="currentStep() === 4">

                    <StepFour :editing="editing" :menu="menu" />

                </div>   


                <Steps 
                    :current_step = currentStep()
                    :total_steps = totalSteps()
                    :steps = steps
                    :step = getStep()
                />

            </div>

            <div class="sidebar">

                <Sidebar 
                    :current_step = currentStep()
                    :total_steps = totalSteps() 
                />

            </div>

        </div>

    </div>

</div>


</template>

<script>
import StepOne from '@/components/StepOne.vue'
import StepTwo from '@/components/StepTwo.vue'
import StepThree from '@/components/StepThree.vue'
import StepFour from '@/components/StepFour.vue'
import Steps from '@/components/Steps.vue'
import Sidebar from '@/components/Sidebar.vue'
import Header from '@/components/Header.vue'

export default {
    name: 'New',
    components: {
        StepOne,
        StepTwo,
        StepThree,
        StepFour,
        Sidebar,
        Steps,
        Header
    },
    props: ['editing'],
    data () {
        return {
            steps: ['Outlet type', 'Outlet details', 'Languages', 'Final details' ],
            is_editing: false,
            user_id: 0,
            signed_in: false,
            user_type: '',
            languages: [],
            menu: {},
            componentKey: 0,
            is_loading: true
        }
    },
    created () {
        this.checkForSession()
        if (this.$props.editing === true) {
            this.editing = true
            if (localStorage.signedIn) {
            this.$http.secured.get('/app_current_user')
                .then(response => { this.user_type = response.data.user_type, this.user_id = response.data.user })
                .catch(error => this.setError(error, 'Something went wrong'))
            }    
            this.getMenu();
        } else {
            this.editing = false
            this.$store.commit("setEditing", false);
            this.is_loading = false;
        }
        this.currentStep();
        
        console.log(process.env.VUE_APP_API_URL)
    },
    mounted() {
        this.$nextTick(() => {
            this.scrollToTop();
        });
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0,0);
            console.log('scroll to top fired')
        },       
        setError (error, text) {
            this.error = (error.response && error.response.data && error.response.data.error) || text
        },
        checkForSession() {
            this.$http.plain.post('/check_for_login')
            .then(response => {
                localStorage.token = response.data.access
                localStorage.csrf = response.data.csrf
                localStorage.refresh = response.data.refresh
                localStorage.signedIn = true
                }).catch(error => 'Failed')
        },
        currentStep() {
            return this.$store.getters.getStep;
            // return 4
        },
        totalSteps() {
            return this.$store.getters.getTotalSteps;
        },
        getStep() {
            return this.steps[(this.currentStep() - 1)]
        },
        prevStep() {
            this.$store.commit("prevStep");
        },
        getMenu() {
            let id = this.$router.currentRoute.params.id;
            console.log(id)
            this.$http.secured.get('/app_edit_menu/' + id )
                .then(response => { 
                    this.menu = response.data.menu
                    this.$store.commit("setOutletType", response.data.menu.menu_type);
                    this.$store.commit("setOutletName", response.data.menu.name);
                    this.languages = response.data.languages;
                    this.$store.commit("setImageOnlyMenu", response.data.menu.images_only);
                    this.languages.forEach((language) => {
                        this.$store.commit("toggleLanguage", language)
                    });
                    this.$store.commit("selectCurrency", response.data.menu.currency);
                    this.componentKey += 1;
                    this.is_loading = false;
                }).catch(error => this.setError(error, 'Something went wrong'))            
        },
        canEdit() {
          if (this.user_type == 'admin' || this.user_id == this.menu.user_id || this.user_id == this.menu.admin_1 || this.user_id == this.menu.admin_2 || this.user_id == this.menu.admin_3 || this.user_id == this.menu.admin_4 || this.user_id == this.menu.admin_5 || this.menu.valid_admin_users.includes(this.user_id)) {
                return true
            } else {
                // this.$router.replace('/')
            }
        },
        
    },
    computed: {

    }
}


</script>

<style scoped>

#new-menu {
    width: 100%;
    min-height: 100%;
    min-height: 100vh;
    margin: auto;
    display: flex;
    flex-direction: row;
    margin-top: 75px;
    /* margin-top: 20px; */
}

h4 {
    font-size: 13px;
    font-weight: 600;
    margin-top: 35px;   
}

.form-tag {
    font-size: 13px;
    font-weight: 600; 
    margin-top: 35px;   
}

.form-container {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    max-width: 1000px;
    min-height: 80vh;
    box-sizing: border-box;
    padding-left: 10%;
    z-index: 100;
    margin-right: -10%;
    margin-top: 30px;
}

.sidebar {
    flex-grow: 1;
    min-height: 100%;
    max-width: 50%;
    /* display: flex; */
}

.back-button {
    display: flex;
    flex-direction: row;
    font-size: 10px;
    border: 1px dotted lightgray;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    box-sizing: border-box;
    padding: 2.5px;
    cursor: pointer;
    margin-top: 20px;
    margin-bottom: 20px;
    max-height: 20px;
    max-width: 20px;
}

.back-button img {
    max-width: 15px;
    max-height: 15px;
    height: 15px;
    width: 15px;
}

.back-button:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.header-icon {
    align-self: flex-start;
    margin-bottom: 25px;
    margin-top: 25px;
}

.header-icon img {
    height: 30px;
}

.loader {
    height: 80vh;
}

@media(min-width: 2000px) {
    .sidebar {
        max-width: 100%;
        margin-left: -2.5%;
    }
    .form-container {
        margin-right: 0%;
    }
}

@media(max-width: 700px) {
    #new-menu {
        flex-direction: column;
        /* min-height: 100vh; */
        /* justify-content: space-between; */
    }

    .form-container {
        /* margin: 20px 20px 20px 20px; */
        padding: 0px;
        /* min-height: 100vh; */
        width: 90%;
        margin: auto;
        flex-grow: 1;
        margin-top: 50px;
    }

    .sidebar {
        flex-grow: 1;
        width: 100%;
        max-width: 100%;
    }
}



</style>