<template>

    <div class="main-cont" :class="{ 'main-cont-small-margin' : hide_menu_info === true }">

            <div id="qr-code-container" v-if="can_edit === true">

                <div class="top-container" v-if="hide_menu_info != true">

                    <div @click="backToMenu()">

                        <BackButton :text="'Back to menu'" />

                    </div>

                </div>

                <div class="header" v-if="hide_menu_info != true">
                    
                    <img src="@/assets/qr_icon.svg" />

                    <h2>Generate QR code</h2>

                </div>

                <div class="menu-info" v-if="hide_menu_info != true">

                    <img class="menu-logo" :src="menu.logo.url" />

                    <div class="menu-info-logo-name">

                        <h3>{{ menu.name }}</h3>

                        <div class="menu-status">
                            
                            <!-- <p>Menu status: </p> -->
                            <h5 v-if="menu.mobile_version_live === true" class="live-menu">Live</h5>
                            <h5 v-if="menu.mobile_version_live === false" class="unpublished-menu">Unpublished</h5>

                        </div>                    

                    </div>

                </div>

                <div class="response-container" v-if="hide_menu_info != true">
                    <transition name="fade">
                        <div class="response" v-if="publish_response != ''">{{ publish_response }}</div>
                    </transition>
                </div>

                <div class="toggle-status-button" :class="{ 'publish' : menu.mobile_version_live === false, 'unpublish' : menu.mobile_version_live === true }" @click="toggleMenuStatus()" v-if="hide_menu_info != true">

                    <div v-if="button_loading === true" class="button-loader">

                        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>

                    </div>

                    <div v-else>
                        <p v-if="menu.mobile_version_live === true">Unpublish menu</p>
                        <p v-if="menu.mobile_version_live === false && menu.user_id != 0">Publish menu</p>
                        <p v-if="menu.mobile_version_live === false && menu.user_id === 0">Create an account to publish your menu</p>
                    </div>
                </div>

                <div class="qr-options" :class="{ 'qr-options-flex-column' : hide_menu_info === true }">

                    <div class="qr-option-container" :class="{ 'narrow-qr-option' : hide_menu_info === true }">

                        <p>Classic</p>

                        <div class="qr-option">

                            <div>

                                <vue-qr :text="qrText(menu.id)" :size="400"></vue-qr>

                            </div>

                        </div>

                        <vue-html2pdf
                            :html-to-pdf-options="htmlToPdfOptions"
                            :manual-pagination="true"
                            ref="option1">
                            
                            <section slot="pdf-content" class="pdf">
                                                                
                                    <img class="menu-logo menu-logo-print" :src="menu.logo.url + '?t=' + Math.random().toString(36).substring(7)" v-if="menu.logo.url != '' && menu.logo.url != null" crossOrigin="true"/>
                                    <h1 :style="{ 'font-family' : menu.menu_header_font }" v-if="menu.show_menu_name === true">{{ menu.name }}</h1>
                                    <ScanToSeeMenuText :menu="menu" />
                                    <div class="qr-box">
                                        <vue-qr :text="qrText(menu.id)" class="qr" :size="400"></vue-qr>
                                    </div>
                                    <PrintFooter />
                                    <div class="html2pdf__page-break"></div>
                            
                            </section>
                        </vue-html2pdf>

                        <div class="download-button" @click="generateQrCardOption1()">Download QR code</div>

                    </div>

                    <div class="qr-option-container" v-if="menu.logo.url != '' && menu.logo.url != null && darkEnough()" :class="{ 'narrow-qr-option' : hide_menu_info === true }">

                        <p>Branded</p>

                        <div class="qr-option">

                            <div>

                                <vue-qr :logoSrc="menu.logo.url + '?t=' + Math.random().toString(36).substring(7)" :logoScale="0.2" :colorDark="menu.menu_header_background_color" :text="qrText(menu.id)" :size="400" v-if="darkEnough()" :correctLevel="3"></vue-qr>

                            </div>

                        </div>

                        <vue-html2pdf
                            :html-to-pdf-options="htmlToPdfOptions"
                            :manual-pagination="true"
                            ref="option2">
                            
                            <section slot="pdf-content" class="pdf">

                                    <img class="menu-logo menu-logo-print" :src="menu.logo.url + '?t=' + Math.random().toString(36).substring(7)" v-if="menu.logo.url != '' && menu.logo.url != null" crossOrigin="true" />
                                    <h1 :style="{ 'font-family' : menu.menu_header_font }" v-if="menu.show_menu_name === true">{{ menu.name }}</h1>
                                    <ScanToSeeMenuText :menu="menu" />
                                    <div class="qr-box">
                                        <vue-qr :logoSrc="menu.logo.url + '?t=' + Math.random().toString(36).substring(7)" :colorDark="menu.menu_header_background_color" :text="qrText(menu.id)" :size="400" v-if="darkEnough()" :correctLevel="3"></vue-qr>
                                    </div>
                                    <PrintFooter />
                                    <div class="html2pdf__page-break"></div>
                            
                            </section>
                        </vue-html2pdf>                    

                        <div class="download-button" @click="generateQrCardOption2()">Download QR code</div>

                    </div>

                    <div class="qr-option-container" v-if="darkEnough()" :class="{ 'narrow-qr-option' : hide_menu_info === true }">

                        <p>With colour</p>

                        <div class="qr-option">

                            <div>

                                <vue-qr :colorDark="menu.menu_header_background_color" :text="qrText(menu.id)" :size="400" v-if="darkEnough()" :correctLevel="0"></vue-qr>

                            </div>

                        </div>

                        <vue-html2pdf
                            :html-to-pdf-options="htmlToPdfOptions"
                            :manual-pagination="true"
                            ref="option3">
                            
                            <section slot="pdf-content" class="pdf">

                                    <img class="menu-logo menu-logo-print" :src="menu.logo.url + '?t=' + Math.random().toString(36).substring(7)" v-if="menu.logo.url != '' && menu.logo.url != null" crossOrigin="true"/>
                                    <h1 :style="{ 'font-family' : menu.menu_header_font }" v-if="menu.show_menu_name === true">{{ menu.name }}</h1>
                                    <ScanToSeeMenuText :menu="menu" />
                                    <div class="qr-box">
                                        <vue-qr :colorDark="menu.menu_header_background_color" :text="qrText(menu.id)" :size="400" v-if="darkEnough()" :correctLevel="0"></vue-qr>
                                    </div>
                                    <PrintFooter />
                                    <div class="html2pdf__page-break"></div>
                            
                            </section>
                        </vue-html2pdf>                        

                        <div class="download-button" @click="generateQrCardOption3()">Download QR code</div>

                    </div>

                    <div class="qr-option-container" v-if="menu.logo.url != '' && menu.logo.url != null" :class="{ 'narrow-qr-option' : hide_menu_info === true }">

                        <p>With logo</p>

                        <div class="qr-option">

                            <div>

                                <vue-qr :logoSrc="menu.logo.url + '?t=' + Math.random().toString(36).substring(7)" :logoScale="0.2" :logoMargin="0" :text="qrText(menu.id)" :size="400" :correctLevel="0"></vue-qr>

                            </div>

                        </div>

                        <vue-html2pdf
                            :html-to-pdf-options="htmlToPdfOptions"
                            :manual-pagination="true"
                            ref="option4">
                            
                            <section slot="pdf-content" class="pdf">

                                    <img class="menu-logo menu-logo-print" :src="menu.logo.url + '?t=' + Math.random().toString(36).substring(7)" v-if="menu.logo.url != '' && menu.logo.url != null" crossOrigin="true" />
                                    <h1 :style="{ 'font-family' : menu.menu_header_font }" v-if="menu.show_menu_name === true">{{ menu.name }}</h1>
                                    <ScanToSeeMenuText :menu="menu" />
                                    <div class="qr-box">
                                        <vue-qr :logoSrc="menu.logo.url + '?t=' + Math.random().toString(36).substring(7)" :logoScale="0.2" :logoMargin="0" :text="qrText(menu.id)" :size="400" :correctLevel="0"></vue-qr>
                                    </div>
                                    <PrintFooter />
                                    <div class="html2pdf__page-break"></div>
                            
                            </section>
                        </vue-html2pdf>                        

                        <div class="download-button" @click="generateQrCardOption4()">Download QR code</div>

                    </div>  

                    <div class="qr-option-container" :class="{ 'narrow-qr-option' : hide_menu_info === true }">

                        <p>Black background</p>

                        <div class="qr-option">

                            <div>

                                <vue-qr backgroundColor="#000000" :correctLevel="3" :text="qrText(menu.id)" :size="400"></vue-qr>

                            </div>

                        </div>

                        <vue-html2pdf
                            :html-to-pdf-options="htmlToPdfOptions"
                            :manual-pagination="true"
                            ref="option5">
                            
                            <section slot="pdf-content" class="pdf">

                                    <img class="menu-logo menu-logo-print" :src="menu.logo.url + '?t=' + Math.random().toString(36).substring(7)" v-if="menu.logo.url != '' && menu.logo.url != null" crossOrigin="true" />
                                    <h1 :style="{ 'font-family' : menu.menu_header_font }" v-if="menu.show_menu_name === true">{{ menu.name }}</h1>
                                    <ScanToSeeMenuText :menu="menu" />
                                    <div class="qr-box">
                                        <vue-qr backgroundColor="#000000" :correctLevel="3" :text="qrText(menu.id)" :size="400"></vue-qr>
                                    </div>
                                    <PrintFooter />
                                    <div class="html2pdf__page-break"></div>
                            
                            </section>
                        </vue-html2pdf>                        

                        <div class="download-button" @click="generateQrCardOption5()">Download QR code</div>

                    </div>         

                    <div class="qr-option-container" :class="{ 'narrow-qr-option' : hide_menu_info === true }">

                        <p>Grey background</p>

                        <div class="qr-option">

                            <div>

                                <vue-qr backgroundColor="#939393" :correctLevel="3" :text="qrText(menu.id)" :size="400"></vue-qr>

                            </div>

                        </div>

                        <vue-html2pdf
                            :html-to-pdf-options="htmlToPdfOptions"
                            :manual-pagination="true"
                            ref="option6">
                            
                            <section slot="pdf-content" class="pdf">

                                    <img class="menu-logo menu-logo-print" :src="menu.logo.url + '?t=' + Math.random().toString(36).substring(7)" v-if="menu.logo.url != '' && menu.logo.url != null" crossOrigin="true"/>
                                    <h1 :style="{ 'font-family' : menu.menu_header_font }" v-if="menu.show_menu_name === true">{{ menu.name }}</h1>
                                    <ScanToSeeMenuText :menu="menu" />
                                    <div class="qr-box">
                                        <vue-qr backgroundColor="#939393" :correctLevel="3" :text="qrText(menu.id)" :size="400"></vue-qr>
                                    </div>
                                    <PrintFooter />
                                    <div class="html2pdf__page-break"></div>
                            
                            </section>
                        </vue-html2pdf>                        

                        <div class="download-button" @click="generateQrCardOption6()">Download QR code</div>

                    </div>                   


                </div>

            </div>

    </div>


</template>
    
<script>
import Header from '@/components/Header.vue'
import VueQr from 'vue-qr'
import VueHtml2pdf from 'vue-html2pdf'
import SampleQr from '@/components/SampleQr.vue'
import PrintFooter from '@/components/PrintFooter.vue'
import ScanToSeeMenuText from '@/components/ScanToSeeMenuText.vue'
import BackButton from '@/components/BackButton.vue'

export default {
    name: 'QrCode',
    components: {
        Header,
        VueQr,
        VueHtml2pdf,
        SampleQr,
        PrintFooter,
        ScanToSeeMenuText,
        BackButton
    },
    props: ['menu', 'can_edit', 'hide_menu_info'],
    data () {
        return {
            signed_in: false,
            user_type: '',
            output: null,
            publish_response: '',
            is_loading: false,
            error: '',
            button_loading: false,
            image_counter: 0,
            url: process.env.VUE_APP_API_URL
        }
    },
    created() {
      if (this.hide_menu_info != true) {
        this.scrollToTop();
      }
    },
    methods: {
        backToMenu() {
            this.$emit('back-to-menu')
      },
      qrText(menuId) {
          const hostname = window.location.hostname;
        const parts = hostname.split('.');
        let subdomain = ''
          
          // Assuming the subdomain is the first part of the hostname
          if (parts.length > 2) {
            if (parts[0] ==='asia') {
              subdomain = 'asia'
            }
          } else {
            subdomain = ''
          }
        console.log(`SUBDOMAIN: ${subdomain}`)
        if (subdomain === 'asia') {
          return `https://asia.menulingua.com/${menuId}`
        } else {
          return `https://menus.menulingua.com/${menuId}`
        }
        },
        scrollToTop() {
            window.scrollTo(0,0);
        },
        getLogoUrl() {
            return this.menu.logo.url + '?t=' + new Date().getTime()
        },
        setError (error, text) {
            this.error = (error.response && error.response.data && error.response.data.error) || text
        },
        toggleMenuStatus()  {
            if (this.menu.user_id === 0) {
                // window.location.replace(this.url + `/users/sign_up`)
                this.$router.push('/sign_up')
            }
            this.button_loading = true
            this.publish_response = ''
            var status = ''
            if (this.menu.mobile_version_live === true) {
                status = 'app_unpublish_menu'
            }
            if (this.menu.mobile_version_live === false) {
                status = 'app_publish_menu'
            }
            this.$http.secured.post('/' + status + '/' + this.menu.id
                ).then(response => {
                    this.button_loading = false
                    this.menu.mobile_version_live = response.data.menu_status 
                    this.publish_response = response.data.message
                    if (response.data.message === 'You need to upgrade your subscription to make your menu live') {
                        this.$router.push({ path: `/pricing` })
                        this.$store.commit('setMessage', { 
                                content: "Purchase a subscription to publish your menu"
                            }
                        )
                    }
                })
                .catch(error => this.setError(error, 'Cannot update menu.'))
        },
        darkEnough() {
            var c = this.menu.menu_header_background_color.substring(1);      // strip #
            var rgb = parseInt(c, 16);   // convert rrggbb to decimal
            var r = (rgb >> 16) & 0xff;  // extract red
            var g = (rgb >>  8) & 0xff;  // extract green
            var b = (rgb >>  0) & 0xff;  // extract blue

            var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
    
            if (luma < 151 && luma > 20) {
                return true
            } else {
                return false
            }
        },
        generateQrCardOption1 () {
            this.$refs.option1.generatePdf()
        },
        generateQrCardOption2 () {
            this.$refs.option2.generatePdf()
        },
        generateQrCardOption3 () {
            this.$refs.option3.generatePdf()
        },
        generateQrCardOption4 () {
            this.$refs.option4.generatePdf()
        },
        generateQrCardOption5 () {
            this.$refs.option5.generatePdf()
        },
        generateQrCardOption6 () {
            this.$refs.option6.generatePdf()
        }
    },
    computed: {
        htmlToPdfOptions() {
            return {
                margin: 0,
                filename: this.menu.name + '.pdf',
                image: {
                    type: "jpeg",
                    quality: 0.95,
                },
                enableLinks: true,
                html2canvas: {
                    scale: 2,
                    // proxy: 'https://localhost:3000',
                    useCORS: true,
                    allowTaint: true,
                    scrollX: 0,
                    scrollY: 0
                },
                jsPDF: { unit: 'in', format: 'a5', orientation: 'portrait' }
            }
        }
    }
}


</script>

<style scoped>

.main-cont {
    min-height: 80vh;
    margin-top: 125px;
}

.main-cont-small-margin {
  min-height: 100px;
  margin-top: 0px;
}

#qr-code-container {
    width: 90%;
    max-width: 1000px;
    margin: auto;
    margin-top: 35px;
    display: flex;
    flex-direction: column;
}

h2 {
    margin: 0px 0px 0px 0px;
    font-weight: 600;
    font-size: 10px;
    letter-spacing: 0.25px;
    text-transform: uppercase;
}

.top-container {
    display: flex;
    flex-direction: row;
    align-items: center
}

.top-container img {
    width: 35px;
    margin: 0px 10px 0px 10px;
}

.top-container p {
    margin: 0px 0px 0px 0px;
    font-size: 12.5px;
    font-weight: 600;
}

.form-tag {
    display: flex;
    width: max-content;
    border: 1px solid black;
    box-sizing: border-box;
    padding: 2.5px;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    border-radius: 2.5px;
}

.header {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 25px;
    align-items: center;
}

.header img {
    width: 25px;
    height: 25px;
    margin-right: 20px;
}

.menu-info {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
    box-sizing: border-box;
    padding-bottom: 20px;
    border-bottom: 1px dotted lightgray;
    width: max-content;
}

.menu-info-logo-name {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
}

.menu-info h3 {
    font-weight: 600;
    margin: 0px 0px 20px 0px;
    font-size: 15px;
}

.menu-logo {
    max-width: 100px;
    max-height: 100px;
    margin-bottom: 10px;
}

.menu-logo-print {
    margin-right: 0px;
    max-height: 125px !important;
    margin-bottom: 10px;
}

.qr-box {
    margin-top: 50px;
    box-sizing: border-box;
    padding: 10px;
    border: 1px solid lightgray;
    border-radius: 10px;
}

.menu-status {
    display: flex;
    flex-direction: row;
    align-items: center
}

.menu-status p {
    font-size: 12.5px;
    font-weight: 600;
    margin: 0px 5px 0px 0px;
}

.menu-status h5 {
    font-size: 12.5px;
    font-weight: 600;
    margin: 0px 0px 0px 0px;
    box-sizing: border-box;
    padding: 5px;
    /* border: 1px solid black; */
    border-radius: 2.5px;
}

.live-menu {
    border: 1px solid #8CC63E;
    background-color: #8CC63E;
    color: white;
}

.unpublished-menu {
    border: 1px solid #BE1E2D;
    background-color: #BE1E2D;
    color: white;
}

.toggle-status-button {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 15px;
    border-radius: 10px;
    margin-top: 50px;
    cursor: pointer;
}

.toggle-status-button:hover {
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
}

.toggle-status-button p {
    font-size: 20px;
    font-weight: 600;
    margin: 0px 0px 0px 0px;
}

.publish {
    border: 1px solid #006738;
    color: #006738;
}

.unpublish {
    border: 1px solid #BE1E2D;
    color: #BE1E2D;
}

.qr-options {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 50px;
}

.qr-option-container {
    display: flex;
    flex-direction: column;
    width: 32%;
    align-items: center;
    margin: 0px 0px 50px 0px;
}

.qr-option-container p {
    font-weight: 600;
    font-size: 12.5px;
    margin: 0px 0px 10px 0px;
}

.qr-option {
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
    background-color: white;
    box-shadow: 0 0 16px rgba(100, 100, 100, 0.1);
    display: flex;
    justify-content: center;
    border-radius: 10px;
}

.qr-option div img {
    width: 200px;
    height: 200px;
}

.download-button {
    width: 80%;
    margin: auto;
    border: 1px solid black;
    box-sizing: border-box;
    padding: 10px;
    margin-top: 25px;
    font-weight: 600;
    font-size: 12.5px;
    display: flex;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    /* padding */
}

.download-button:hover {
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
}

.pdf {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 582px;
}

.pdf h2 {
    font-size: 20px;
}

.pdf img {
    max-width: 200px;
    max-height: 200px;
    margin-top: 10px;
}

.qr-card-footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.qr-card-footer img {
    width: 50px;
    height: 50px;
}

.response-container {
    width: 100%;
    display: flex;
    height: 20px;
    justify-content: center;
}

.response {
    font-size: 12.5px;
    font-weight: 600;
    border-bottom: 1px solid black;
}

.loader {
    height: 80vh;
}

.narrow-qr-option {
  width: 100%;
}

.qr-options-flex-column {
  display: flex;
  flex-direction: column;
}


@media(max-width: 750px) {
    .qr-option-container {
        width: 100%;
    }

    .download-button {
        width: 100%;
    }

    .menu-info {
        flex-direction: column;
        margin-bottom: 25px;
    }
}


</style>