

const state = () => ({
    logged_in: false,
    current_user: Number,
    user_type: '',
    user_email: '',
    access_to_menu_csv_import_and_export: Boolean
})


const mutations = {
    setLoggedIn (state, current_user) {
        state.logged_in = true,
        state.current_user = current_user
    },
    setUserType (state, user_type) {
        state.user_type = user_type
    },
    setUserEmail (state, user_email) {
        state.user_email = ''
    },
    setLoggedOut (state) {
        state.logged_in = false,
        state.current_user = null,
        state.user_type = ''
    },
    setUserCSVAccess (state, access_to_menu_csv_import_and_export) {
      state.access_to_menu_csv_import_and_export = access_to_menu_csv_import_and_export
    }
}


const getters = {
    getLoggedIn (state) {
        return state.logged_in
    },
    getUserId (state) {
        return state.current_user
    },
    getUserType (state) {
        return state.user_type
    },
    getUserEmail (state) {
        return state.user_email
    },
    getUserCSVAccess (state) {
      return state.access_to_menu_csv_import_and_export
    }
}


export default {
    state,
    getters,
    mutations
}