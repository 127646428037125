<template>

  <div id="container">
    
    <div v-if="user_type == 'admin'">

      <h1>Global Settings</h1>

      <h3>Telegram</h3>
      
      <div class="status">
        <p>Order notifications</p>
        <div class="status-button" :class="{ 'active' : order_notifications_active }" @click="toggleSetting('order-notifications')">
          Active
        </div>
        <div class="status-button" :class="{ 'active': !order_notifications_active }" @click="toggleSetting('order-notifications')">
          Inactive
        </div>
      </div>

      <div class="status">
        <p>Help request notifications</p>
        <div class="status-button" :class="{ 'active': help_requests_active }" @click="toggleSetting('help-requests-active')">
          Active
        </div>
        <div class="status-button" :class="{ 'active': !help_requests_active }" @click="toggleSetting('help-requests-active')">
          Inactive
        </div>
      </div>
      <div class="settings-input status">
        <p>Telegram token</p>
        <input v-model="telegram_token" />
      </div>

      <div class="save" @click="saveSettings()">
        Save settings
      </div>
    </div>
  </div>


</template>

<script>

export default {
  name: 'GlobalSettings',
  data() {
    return {
      help_requests_active: Boolean,
      order_notifications_active: Boolean,
      telegram_token: String,
      user_type: String
    }
  },
  created() {
    this.checkCurrentUser();
  },
  methods: {
    checkCurrentUser() { 
      this.$http.secured.get('/app_current_user')
      .then(response => { this.user_type = response.data.user_type }, this.retrieveSettings())
      .catch(error => this.setError(error, 'Something went wrong'))
    },
    retrieveSettings() {
      this.$http.secured.get('/app_retrieve_settings').then(response => {
        this.help_requests_active = response.data.telegram_settings.help_requests_active,
        this.order_notifications_active = response.data.telegram_settings.order_notifications_active,
        this.telegram_token = response.data.telegram_settings.token
      })
    },
    saveSettings() {
      this.$http.secured.post('/app_save_settings', {
          telegram_token: this.telegram_token,
          order_notifications_active: this.order_notifications_active,
          help_requests_active: this.help_requests_active
        }).then(response => {
          this.$store.commit('setMessage', {
            content: "Settings saved."
          })
          this.retrieveSettings();
      }).catch(error => this.$store.commit('setMessage', {
        content: "Failed to save settings",
        error: true
      }))
    },
    toggleSetting(setting) {
      if (setting == 'order-notifications') {
        this.order_notifications_active = !this.order_notifications_active
      }
      if (setting == 'help-requests-active') {
        this.help_requests_active = !this.help_requests_active
      }
    }
  }
}


</script>

<style scoped>

#container {
  min-height: 100vh;
  box-sizing: border-box;
  padding-top: 100px;
  width: 90%;
  margin: auto;
}

.status {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 100px;
}

.status-button {
  width: 49%;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 10px 0px 10px 0px;
  border-radius: 5px;
  font-size: 12.5px;
  color: gray;
  letter-spacing: 0.25px;
}

.status p {
  width: 100%;
  font-size: 12.5px;
  font-weight: 600;
}

.active {
  background-color: black;
  color: white;
  font-weight: 400;
  font-weight: 600;
}

.settings-input input {
  width: 100%;
  border: 0px;
  border-bottom: 1px solid lightgray;
  font-family: 'Montserrat', sans-serif;
  /* color: gray; */
  font-size: 20px;
  box-sizing: border-box;
  padding: 10px 0px 10px 0px;
}

.save {
  width: max-content;
  align-self: center;
  box-sizing: border-box;
  padding: 25px;
  background: linear-gradient(to bottom, rgb(153, 202, 60), rgb(7, 153, 72));
  color: white;
  font-size: 25px;
  font-weight: 600;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 25px;
}



</style>