<template>

    <div @click="$emit('toggle-language')" :class="{ 'selected-language' : this.$store.getters.getSelectedLanguages.includes(language[2])}">

        <p>{{ language[0] }}</p>
        <p v-if="language[0] != language[1]">{{ language[1] }}</p>

    </div>




</template>

<script>

export default {
    name: 'Language',
    props: ['language']
}


</script>

<style scoped>

div {
    width: 32%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    margin: 10px 0px 10px 0px;
}

div:hover {
    background-color: #B3CBE7;
    transition: 0.2s;
}

div p {
    font-weight: 600;
    font-size: 12.5px;
    margin: 2.5px 0px 2.5px 0px;
}

.selected-language {
    background-color: #4794CB;
    color: white;
}

.selected-language:hover {
    background-color: #4794CB;
}


@media(max-width: 700px) {
    div {
        width: 47%;
        box-shadow: 0 0 0;
        border: 1px solid lightgray;
    }

    .selected-language {
        border: 1px solid #4794CB;
    }
}



</style>