<template>


  <div id="dashboard">

    <div class="overlay">

      <img src="@/assets/banner-overlay.svg" />

    </div>

    <AccountStatus :user_type="user_type" :admin_or_dashboard_user="admin_or_dashboard_user" :currentUser="currentUser"
      :email="email" :latest_subscription_status="latest_subscription_status"
      :current_plan_expiry_date="current_plan_expiry_date" :cancellation_requested="cancellation_requested"
      :subscription_payment_provider="subscription_payment_provider" :key="componentKey" />

    <div class="account-info" @click="goToAccountInfo()">
      <img src="@/assets/account_icon.svg" />
      <p>{{ email }}</p>
      <div class="admin-user" v-if="user_type === 'admin'">Admin</div>
    </div>

    <div class="link-to-admin-settings account-info" v-if="user_type === 'admin'" @click="goToGlobalSettings()">Global
      settings</div>
    <div class="link-to-admin-settings account-info" v-if="user_type === 'admin'" @click="goToAdminUserRelationships()">
      Admin User Relationships</div>



    <h1>My dashboard</h1>


    <MenusDashboard :menus="menus" :can_create_menus="can_create_menus" />

    <OrdersDashboard :orders="orders" />





  </div>








</template>




<script>
import AccountStatus from '@/components/AccountStatus.vue'
import MenusDashboard from '@/components/user/MenusDashboard.vue'
import OrdersDashboard from '@/components/user/OrdersDashboard.vue'

export default {
    name: "Dashboard",
    components: {
        AccountStatus,
        MenusDashboard,
        OrdersDashboard
    },
    data () {
        return {
            url: process.env.VUE_APP_API_URL,
            user_type: '',
            currentUser: '',
            admin_or_dashboard_user: Boolean,
            email: '',
            latest_subscription_status: '',
            current_plan_expiry_date: '',
            cancellation_requested: Boolean,
            componentKey: 0,
            subscription_payment_provider: '',
            can_create_menus: Boolean,
            admin_outlet_collections_selected: false,
            menus: [],
            count: 3,
            orders: []
        }
    },
    created() {
        this.getUserInfo();
        this.scrollToTop();
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0,0);
        },
        getUserInfo() {
            if (localStorage.signedIn) {
                this.$http.secured.get('/app_current_user')
                    .then(response => { 
                        this.currentUser = response.data.user,
                        this.user_type = response.data.user_type,
                        this.email = response.data.email,
                        this.admin_or_dashboard_user = response.data.admin_or_dashboard_user,
                        this.latest_subscription_status = response.data.latest_subscription_status,
                        this.current_plan_expiry_date = response.data.current_plan_expiry_date,
                        this.cancellation_requested = response.data.cancellation_requested,
                        this.subscription_payment_provider = response.data.subscription_payment_provider,
                        this.can_create_menus = response.data.can_create_menus,
                        this.$store.commit('setLoggedIn', response.data.user)
                        this.$store.commit('setUserType', response.data.user_type)
                        this.$store.commit('setUserEmail', response.data.email)
                        this.componentKey++
                        this.getMenus();
                        this.getOrders();
                    })
                .catch(error => this.setError(error, 'Something went wrong'))
            } else {
                this.$router.replace('/login')
            }
        },
        getMenus() {
            this.$http.secured.get(`/app_retrieve_menus/${this.count}`)
            .then(response => { 
                this.menus = response.data.menus
                if (response.data.outlet_collections != null) {
                    this.outlet_collections = response.data.outlet_collections
                }
                this.is_loading = false
            }).catch(error => this.setError(error, 'Something went wrong'))
        },
        getOrders() {
            this.$http.secured.get('/app_get_order_recent_history')
                .then(response => { 
                    this.orders = response.data
                }).catch(error => this.setError(error, 'Something went wrong'))
        },
        goToAccountInfo() {
          this.$router.push('/account');
      },
      goToGlobalSettings() {
        this.$router.push('/global_settings');
      },
      goToAdminUserRelationships() {
        this.$router.push('/admin_user_relationships');
      }
    }
}





</script>

<style scoped>

#dashboard {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    position: relative;
    flex-direction: column;
    margin-top: 75px;
}


h1 {
    font-size: 35px;
    font-weight: 400;
    margin: 50px auto 50px auto;
    width: 95%;
}

.link-to-admin-settings {
  font-size: 12.5px;
  cursor: pointer;
  font-weight: 600;
  color: blueviolet;
  margin-bottom: 10px;
}

</style>