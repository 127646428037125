<template>

    <router-link class='footer-icon' to="/">
        Powered by
        <img class="icon" src='@/assets/menulingua_logo_final_small.png' />
    </router-link>

</template>

<script>

export default {
    name: 'Footer'
}


</script>

<style scoped>

.footer-icon {
    /* height: 100px; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 0px 30px 0px 30px;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 2px;
    text-transform: uppercase;
    border-top: 1px solid lightgray;
    margin-top: 100px;
    padding-top: 50px;
}

.icon {
    margin-left: 20px;
    width: 200px;
    /* width: 200px; */
    /* height: auto; */
    /* height: 75px !important; */
    /* width: auto; */
    /* width: 100px;
    height: 100px;
    margin-left: 5px; */
}


</style>