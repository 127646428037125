
// const BASE_URL = 'http://localhost:3001';



function upload(formData) {
    this.$http.upload.patch('/app_upload_food_image/', + 0, formData
        // get data
        .then(x => x.data))
        // add url field

}

export { upload }