<template>

  <div @click="goBack()">

    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="15" height="15">
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" />
    </svg>

  </div>





</template>

<script>

export default {
  name: "BackButton",
  props: ['currentUser', 'componentKey'],
  methods: {
    goBack() {
        this.$router.go(-1)
      }
  }
}




</script>

<style scoped>

div {
  /* background-color: black; */
  background-color: #8CC63E;
  background-color: white;
  border: 2px solid #8CC63E;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 10000;
  cursor: pointer;
}

@media(max-width: 700px) {
  div {
    display: none;
  }
}

</style>