<template>

    <div id="pricing-details">

        <div class="primary">

            <Price :price="prices[0]" :price_selected="price_selected" :signed_in="signed_in" :user_id="user_id" :user_email="user_email" v-on:price-selected="priceSelected" v-on:no-price-selected="noPriceSelected()" v-if="price_selected[0] === prices[0] || price_selected.length === 0" />

            <Price :price="prices[1]" :price_selected="price_selected" :signed_in="signed_in" :user_id="user_id" :user_email="user_email" v-on:price-selected="priceSelected" v-on:no-price-selected="noPriceSelected()" v-if="price_selected[0] === prices[1] || price_selected.length === 0" />

        </div>

        <div class="secondary" v-if="signed_in != true">

            <Price :price="prices[2]" :signed_in="signed_in" />

        </div>

    </div>




</template>


<script>
import Price from '@/components/pricing/Price.vue'

export default {
    name: "PricingDetails",
    props: ['signed_in', 'user_id', 'user_email'],
    components: {
        Price
    },
    data () {
        return {
            price_selected: [],
            prices: [
                {
                    name: "Pro",
                    price_us: "US$21",
                    price_id: "Rp360.000,00",
                    width: 'half',
                    tagline: "starts_from",
                    top_gradient: true,
                    bottom_gradient: true,
                    primary_colour: "rgb(153, 202, 60)",
                    top_colour: "linear-gradient(to right, rgb(153, 202, 60), rgb(7, 153, 72))",
                    bottom_colour: "linear-gradient(to bottom, rgb(153, 202, 60), rgb(7, 153, 72))",
                    timeline: "per_month",
                    includes_translation_options: true,
                    button_text: "select",
                    checkout_options: [
                        {
                            stripe_id: 'price_1JLLSqG2fH76M3MVqUE810SS',
                            duration_en: '1 month',
                            duration_in_months: 1,
                            unit_price_us: '$21',
                            unit_price_id: 'Rp360.000.000,00',
                            price_usd: 2100
                        },
                        {
                            stripe_id: 'price_1JLLkkG2fH76M3MV1e0h9W9b',
                            duration_en: '3 months',
                            duration_in_months: 3,
                            unit_price_us: '$55',
                            price_usd: 5500
                        },
                        {
                            stripe_id: 'price_1JV9kVG2fH76M3MVDpnl6bNG',
                            duration_en: '6 months',
                            duration_in_months: 6,
                            unit_price_us: '$110',
                            unit_price_id: 'Rp360.000.000,00',
                            price_usd: 11000
                        },
                        {
                            stripe_id: 'price_1JV9jfG2fH76M3MVBcFDbNUB',
                            duration_en: '1 year',
                            duration_in_months: 12,
                            unit_price_us: '$220',
                            price_usd: 22000
                        }
                    ],
                    features: [
                        {
                            included: true,
                            name: "custom_menu"
                        },
                        {
                            included: true,
                            name: "match_design"
                        },
                        {
                            included: true,
                            name: "orderable_menu"
                        },
                        {
                            included: true,
                            name: "unlimited_orders"
                        },
                        {
                            included: true,
                            name: "detailed_analytics"
                        },
                        {
                            included: true,
                            name: "multiple_admin_accounts"
                        },
                        {
                            included: true,
                            name: "push_notifications"
                        },
                        {
                            included: true,
                            name: "bluetooth_printing",
                            asterisk: true,
                            disclaimer: "additional_charges_apply"
                        }
                    ]
                },
                {
                    name: "PDF-Only",
                    price_us: "US$5",
                    price_id: "Rp72.000,00",
                    width: 'half',
                    top_gradient: true,
                    bottom_gradient: true,
                    primary_colour: "rgb(60, 179, 226)",
                    top_colour: "linear-gradient(to right, rgb(60, 179, 226), rgb(49, 140, 203))",
                    bottom_colour: "linear-gradient(to right, rgb(60, 179, 226), rgb(49, 140, 203))",
                    timeline: "per_year",  
                    includes_translation_options: false,
                    button_text: "select",
                    checkout_options: [
                        {
                            stripe_id: 'price_1JLKxTG2fH76M3MVH3tGzyvJ',
                            duration_en: '1 year',
                            duration_in_months: 1,
                            unit_price_us: '$5',
                            unit_price_id: 'Rp72.000,00',
                            price_usd: 500
                        }
                    ],
                    features: [
                        {
                            included: true,
                            name: "upload_pdf"
                        },
                        {
                            included: true,
                            name: "custom_qr_code"
                        },
                        {
                            included: true,
                            name: "general_analytics"
                        },
                        {
                            included: false,
                            name: "orderable_menu"
                        },
                        {
                            included: false,
                            name: "content_editing"
                        }
                    ]                 
                },
                {
                    name: "Free trial",
                    price_us: "7 days",
                    price_id: "7 hari",
                    width: 'full',
                    top_colour: "linear-gradient(to right, rgb(7, 153, 72), rgb(7, 153, 72))",
                    timeline: "valid_one_outlet",
                    button_text: "sign_up",
                    route: "/sign_up",
                    bottom_colour: "linear-gradient(to right, rgb(7, 153, 72), rgb(7, 153, 72))",
                    features: [
                        {
                            text_only: true,
                            name: "try_features"
                        }
                    ]
                }
            ]
        }
    },
    methods: {
        priceSelected: function(price) {
            this.price_selected = []
            console.log(`The price we have selected: ${price}`)
            this.price_selected.push(price)
        },
        noPriceSelected() {
            this.price_selected = []
        }
    }
}




</script>

<style scoped>

#pricing-details {
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: -150px;
}

.primary {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* margin-bottom: 50px; */
    flex-wrap: wrap;
}

.secondary {
    width: 100%;
}

</style>