<template>

    <div>
        <h2>{{ outlet_type }} details</h2>

        <p>{{ outlet_type }} name</p>

        <input class="input"
            autofocus autocomplete="off"
            maxlength='50'
            name="outlet[name]"
            placeholder="Add a name for this venue"
            v-model="outlet_name"
            @input="saveName"
            @keyup.enter="nextStep" />  

        <h2>Menu type</h2>

        <div class="menu-type-selector">

            <div class="menu-type" :class="{ 'radio-button-selected' : image_only_menu === false }" @click="setToProMenu()">

                <h3>Custom-built menu</h3>

                <div class="description">
                    <div><img src="@/assets/check-mark.svg" />Match your branding</div>
                    <div><img src="@/assets/check-mark.svg" />Supports ordering</div>
                    <div><img src="@/assets/check-mark.svg" />Instant updates</div>
                </div>

                <div class="price">
                    <div><span>$21/month</span> after free trial</div>
                </div>                     

            </div>

            <div class="menu-type" :class="{ 'radio-button-selected' : image_only_menu === true }" @click="setToImageOnly()">
                
                <h3>PDF menu</h3>

                <div class="description">
                    <div><img src="@/assets/check-mark.svg" />Upload your own PDF version of your menu</div>
                    <div><img src="@/assets/check-mark.svg" />Supports multiple languages</div>
                    <div><img src="@/assets/x-black.svg" />Does not support ordering</div>
                </div>

                <div class="price">
                    <div><span>$5/year</span> after free trial</div>
                </div>                

            </div>




        </div>

        <div class="step-container" >

            <transition name="fade">

                <NextStep v-if="can_proceed == true" :next_step="'Next Step'" />

            </transition>

        </div>






    </div>




</template>
    
<script>
import NextStep from '@/components/NextStep.vue'

export default {
    name: 'StepTwo',
    components: {
        NextStep
    },
    props: ['outlet_type'],
    data () {
        return {
            can_proceed: false,
            outlet_name: '',
            image_only_menu: false
        }
    },
    created() {
        this.outlet_name = this.$store.getters.getOutletName
        if (this.$store.getters.getImageOnlyMenu != Boolean) {
            this.image_only_menu = this.$store.getters.getImageOnlyMenu
        } else {
            this.image_only_menu = false;
        }
        localStorage.current_step = 2
        if (this.outlet_name != '' && this.outlet_name.length > 3) {
            this.can_proceed = true
        }
    },
    methods: {
        saveName: function(event)  {
            if (event.target.value.length > 3) {
                this.$store.commit("setOutletName", event.target.value);
                this.$store.commit("setImageOnlyMenu", this.image_only_menu);
                this.can_proceed = true
            } else {
                this.can_proceed = false
            }
        },
        setToImageOnly() {
            this.image_only_menu = true;
            this.$store.commit("setImageOnlyMenu", true);
        },
        setToProMenu() {
            this.image_only_menu = false;
            this.$store.commit("setImageOnlyMenu", false);
        },
        nextStep: function() {
            this.$store.commit("nextStep");
        }
    }
}

</script>

<style scoped>

h2::first-letter {
    text-transform: capitalize;
}

p {
    margin: 50px 0px 5px 0px;
    font-size: 12.5px;
    font-weight: 600;
}

p::first-letter {
    text-transform: capitalize;
}

h3 {
    font-weight: 600;
    font-size: 20px;
    margin: 0px 0px 20px 0px;
}

input {
    width: 100%;
    height: 50px;
    border: 0px;
    font-family: 'Montserrat', sans-serif;
    font-size: 17.5px;
    border-radius: 2.5px;
    box-shadow: 0 0 8px rgba(100, 100, 100, 0.1);
    box-sizing: border-box;
    padding: 0px 15px 0px 15px;
}

.menu-type-selector {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.menu-type {
    display: flex;
    flex-direction: column;
    width: 49%;
    background-color: white;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    padding: 20px;
    border-radius: 10px;
    cursor: pointer;
}

.description {
    display: flex;
    flex-direction: column;
}

.description div {
    display: flex;
    align-items: center;
    flex-direction: row; 
    font-size: 13px;
    font-weight: 600;
    margin: 5px 0px 5px 0px;
}

.description img {
    width: 15px;
    height: 15px;
    margin-right: 5px;
}

.price {
    margin-top: 20px;
    font-size: 12px;
    border: 1px solid lightgray;
    box-sizing: border-box;
    padding: 5px;
    border-radius: 2.5px;
    width: max-content;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    /* letter-spacing: 0.5px; */
}

.price span {
    font-weight: 800;
}

.radio-button-selected {
    background-color: #94C13D;
    background-color: #4794CB;
    border: 1px solid #4794CB !important;
    /* border: 1px solid #078E45; */
    /* background-image: linear-gradient(to right, rgb(153, 202, 60), rgb(7, 153, 72)); */
    color: white;
    /* transition: 0.2s; */
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
}

.radio-button-selected img {
    filter: invert(99%) sepia(0%) saturate(5593%) hue-rotate(197deg) brightness(115%) contrast(100%);
    transition: 0.2s;
}

@media(max-width: 700px) {
    input {
        border-bottom: 1px solid lightgray;
        border-radius: 0px;
        box-shadow: 0 0 0;
    }

    .menu-type {
        width: 100%;
        margin: 10px 0px 10px 0px;
        box-shadow: 0 0 0;
        border: 1px solid lightgray;
    }
}





</style>