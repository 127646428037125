<template>

  <div class="item-new-cont"
    :class="{'hidden-item' : food.hidden === true || hidden_from_order_dashboard == true, 'hidden-can-edit' : ((food.hidden === true || hidden_from_order_dashboard == true) && can_edit === true) }">

    <div v-if="is_loading == true" class="loader">

      <div class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>

    </div>

    <div v-else>

      <div class="item"
        :style="{ 'min-height' : '50px', 'background-color': background_color, 'border-bottom' : getBorderBottom() }">

        <div v-lazy-container="{ selector: 'img' }" class="item-content-container"
          :class="{ 'item-content-container-flex-column' : food.category_header === true || food.major_category_header === true, 'item-content-container-centre-aligned' : food.centre_content === true }"
          id="content-box">

          <div class="item-image-container" v-if="food.image.url != null && image_hidden === false"
            :class="{ 'full-width-container' : (food.category_header === true || food.major_category_header === true), 'full-width-image-container' : this.full_size_image === true }"
            @click="toggleFullSizeImage()">

            <img
              :class="{ 'food-image' : food.category_header === false && food.major_category_header === false, 'food-image-full-size' : full_size_image === true }"
              :style="{ 'height' : image_height }" :data-src="food.image.url"
              v-if="food.image.url != null && image_hidden === false" />

          </div>

          <div class="item-text-container"
            :class="{ 'item-text-container-overlay' : (food.category_header === true || food.major_category_header === true) && food.image.url != null && image_hidden === false, 'item-with-image-name' : food.image.url != null && image_hidden === false, 'centre-align' : (food.centre_content === true && !(menu.paginate === true && food.major_category_header === true)) }">

            <SpecialTag v-if="food.special_tag_for_item != null && food.special_tag_for_item != ''"
              :special_tag_for_item="food.special_tag_for_item" :background_color="item_name_color"
              :text_color="background_color" :font="food.item_name_font" />

            <div class="item-name"
              :class="{ 'align-flex-start' : menu.paginate === true, 'item-name-centered' : food.centre_content === true }">

              <div class="back-arrow" v-if="(menu.paginate === true) && (food.major_category_header === true)"
                :class="{ 'hidden' : current_pagination_page === 1 }" @click.stop="changePage('prev')">
                <DynamicallyColouredArrow :currentColour="item_name_color" />
              </div>

              <h4
                :style="{ 'font-size' : item_name_font_size, 'color' : item_name_color, 'font-family' : item_name_font }"
                :class="{ 'centered' : menu.paginate === true && food.major_category_header === true }">

                {{ name }}

              </h4>

              <div class="next-arrow" v-if="(menu.paginate === true) && (food.major_category_header === true)"
                :class="{ 'hidden' : current_pagination_page === categories.length }" @click.stop="changePage('next')">
                <DynamicallyColouredArrow :currentColour="item_name_color" />
              </div>

              <ItemPrice :can_edit="can_edit" :food="food" :font_family="item_name_font" :color=item_name_color
                :item_price="food.price" :second_price="food.second_price_number" :third_price="food.third_price_number"
                :unit="food.unit || food.custom_unit" :second_unit="food.second_unit" :third_unit="food.third_unit"
                :price_position="price_position" :currency="currency"
                v-if="food.category_header === false && food.major_category_header === false && food.price != null && food.price != 0 && price_position === 'default'"
                v-on:click="toggleFontSelector('price')" />

            </div>

            <p class="hidden-tag"
              v-if="(food.hidden === true || hidden_from_order_dashboard === true) & can_edit === true">This item is
              hidden</p>

            <FoodTags :food="food" />

            <div class="description"
              :style="{ 'color' : item_description_color, 'font-family' : item_description_font }"
              v-if="description != '' && description != null">

              <h5 :style="{ 'font-size': item_description_font_size, 'width': menu.paginate ? '100%' : null }"
                :class="{ 'centered-description': food.centre_content === true, 'paginate-centered': menu.paginate }">{{
                description }}</h5>

              <div class="options-container">

              </div>

            </div>

            <div v-if="this.$store.getters.getUserType === 'admin'" class="updated-info">
              <img :data-src="require(`../assets/new-update-icon.svg`)" />
              {{ food.last_edited_by }} <span>{{ moment(food.updated_at).fromNow() }}</span>
            </div>

            <div v-if="orderable()">

              <OrderButton v-if="food.orderable === true && can_edit === false && item_type === 'item'" :food="food"
                :name="name" :currency="currency" :menu="menu" :foods="foods" :languages="languages"
                :language="language" :selection_groups="selection_groups" :selections="selections"
                :item_name_font="item_name_font" :sold_out_multiple_price_items="sold_out_multiple_price_items" />

            </div>

            <div v-if="soldOut() === true" class="sold-out-container">
              <SoldOut :colour="food.item_name_font_colour" />
            </div>

            <ItemPrice :can_edit="can_edit" :food="food" :font_family="item_name_font" :color=item_name_color
              :item_price="food.price" :second_price="food.second_price_number" :third_price="food.third_price_number"
              :unit="food.unit" :second_unit="food.second_unit" :third_unit="food.third_unit"
              :price_position="price_position" :currency="currency"
              v-if="food.category_header === false && food.major_category_header === false && food.price != null && food.price != 0 && price_position === 'align_left'"
              @click.stop="toggleFontSelector('price')" />

          </div>

        </div>

      </div>

    </div>

  </div>


</template>

<script>
import VueDragResize from 'vue-drag-resize'
import ItemPrice from '@/components/ItemPrice.vue'
import ItemOptions from '@/components/ItemOptions.vue'
import FontSelector from '@/components/FontSelector.vue'
import ImageUploader from 'vue-image-upload-resize'
import SpecialTag from '@/components/SpecialTag.vue'
import FoodTags from '@/components/FoodTags.vue'
import OrderButton from '@/components/OrderButton.vue'
import SoldOut from '@/components/miscellaneous/SoldOut.vue'
import DynamicallyColouredArrow from '@/components/dynamic_svgs/DynamicallyColouredArrow.vue'


export default {
    name: 'Item',
    props: ['food', 'selections', 'session_based_ordering', 'active_order_session', 'sold_out_multiple_price_items', 'selection_groups', 'menu', 'foods', 'language', 'languages', 'can_edit', 'active_component', 'currency', 'selected_items', 'food_tags', 'sold_out_foods', 'hidden_from_order_dashboard', 'random_image_key', 'current_pagination_page', 'categories'],
    components: {
        VueDragResize,
        ItemPrice,
        ItemOptions,
        FontSelector,
        ImageUploader,
        SpecialTag,
        FoodTags,
        OrderButton,
        SoldOut,
        DynamicallyColouredArrow
    },
    data () {
        return {
            food_id: this.food.id,
            menu_id: this.menu.id,
            edit_item_selected: false,
            name: '',
            description: '',
            item_type: '',
            hasImage: false,
            image: {},
            item_name_font_size: '',
            item_name_color: '',
            item_description_color: '',
            item_description_font_size: '',
            prevent_active: Boolean,
            background_color: '',
            color_picker_toggled: false,
            price_position: String,
            show_item_name_options: false,
            show_item_description_options: false,
            show_background_color_options: false,
            item_name_font: String,
            item_description_font: String,
            price_font: String,
            show_item_name_font_selector: false,
            show_item_description_font_selector: false,
            show_item_price_font_selector: false,
            border_bottom: this.food.stroke_bottom,
            image_selector: false,
            image_height: '',
            image_hidden: false,
            full_size_image: false,
            is_loading: false,
            container_height: 100,
            url: process.env.VUE_APP_API_URL,
            componentKey: 0
        }
    },
    created() {
        this.preventActive();
        this.getNameAndDescription();
        this.getItemType();
        this.getBackgroundColor();
        this.getFontSize();
        this.getItemNameColor();
        this.getPricePosition();
        this.getFonts();
        this.getBorderBottom();
        this.getImageHeight();
        this.getImageVisibility();
        this.getItemDescriptionColor();
        
    },
    methods: {
        changePage(direction) {
            if (direction === 'next') {
                this.$emit('paginate-menu-next-page')
            }
            if (direction === 'prev') {
                this.$emit('paginate-menu-prev-page')
            }
        },
        setError (error, text) {
            this.error = (error.response && error.response.data && error.response.data.error) || text
        },
        setImage: function (file) {
            this.hasImage = true
            this.image = file
            const data = new FormData();
            this.is_loading = true
            Object.entries(file).forEach(([key, value]) => { data.append(key, value) })

            this.$http.upload.patch('/app_upload_food_image/' + this.food_id, data
                ).then(response => {
                    this.food.image.url = response.data.url
                    this.image_selector = false
                    this.is_loading = false
                    this.getImageHeight();
                })
                .catch(error => this.setError(error, 'Cannot update food.'))
        },
        toggleFullSizeImage() {
            if (this.item_type === 'item') {
                this.full_size_image = !this.full_size_image
            }
        },
        toggleImageSelector(food_id) {
            if (this.image_selector === false) {
                this.image_selector = true 
            } else {
                this.image_selector = false
            }
        },
        orderable() {
            if (this.menu.session_based_ordering === true) {
                if (this.active_order_session === true) {
                    return true
                }
            } else {
                if ((this.menu.orderable === true || this.menu.orders_for_delivery === true || this.menu.orders_for_pickup === true || this.menu.orders_for_in_room == true) && !this.sold_out_foods.includes(this.food.id)) {
                    return true
                }
            }   
        },
        soldOut() {
          if (this.sold_out_foods != null && this.sold_out_foods.includes(this.food.id)) {
            return true
          }
        },
        toggleFontSelector(font_selector_type) {
            this.show_item_name_options = false
            if (this.can_edit) {
                if (font_selector_type === 'item_name') {
                    if (this.show_item_name_font_selector === true) {
                        this.show_item_name_font_selector = false
                    } else {
                        this.show_item_name_font_selector = true
                        this.show_item_description_font_selector = false
                        this.show_item_price_font_selector = false
                    }
                }

                if (font_selector_type === 'item_description') {
                    if (this.show_item_description_font_selector === true) {
                        this.show_item_description_font_selector = false
                    } else {
                        this.show_item_description_font_selector = true
                        this.show_item_name_font_selector = false
                        this.show_item_price_font_selector = false
                    }
                }
                
                if (font_selector_type === 'price') {
                    if (this.show_item_price_font_selector === true) {
                        this.show_item_price_font_selector = false
                    } else {
                        this.show_item_price_font_selector = true
                        this.show_item_description_font_selector = false
                        this.show_item_name_font_selector = false                        
                    }
                }            
            }
        },
        selectItemNameFont: function (font) {
            for (let i = 0; i < this.selected_items.length; i++) {
                this.$http.secured.post('/app_update_food/' + this.menu_id + '/' + this.selected_items[i], { food: 
                    { 
                        item_name_font: font
                    }}).then(response => {
                        this.item_name_font = font
                        this.$root.$emit('selected-items-change-item-name-font', font);
                    })
                    .catch(error => this.setError(error, 'Cannot update food.'))
            }
        },
        addPageBreak() {
            for (let i = 0; i < this.selected_items.length; i++) {
                this.$http.secured.post('/app_update_food/' + this.menu_id + '/' + this.selected_items[i], { food: 
                    { 
                        page_break: true
                    }}).then(response => {
                        this.food.page_break = true
                    })
                    .catch(error => this.setError(error, 'Cannot update food.'))
            }
        },
        removePageBreak() {
            for (let i = 0; i < this.selected_items.length; i++) {
                this.$http.secured.post('/app_update_food/' + this.menu_id + '/' + this.selected_items[i], { food: 
                    { 
                        page_break: false
                    }}).then(response => {
                        this.food.page_break = false
                    })
                    .catch(error => this.setError(error, 'Cannot update food.'))
            }
        },
        selectItemDescriptionFont: function (font) {
            for (let i = 0; i < this.selected_items.length; i++) {
                this.$http.secured.post('/app_update_food/' + this.menu_id + '/' + this.selected_items[i], { food: 
                    { 
                        item_description_font: font
                    }}).then(response => {
                        this.item_description_font = font
                        this.$root.$emit('selected-items-change-item-description-font', font);
                        // show_item_description_font_selector = false
                    })
                    .catch(error => this.setError(error, 'Cannot update food.'))
            }
        },
        selectPriceFont: function (font) {
            for (let i = 0; i < this.selected_items.length; i++) {
                this.$http.secured.post('/app_update_food/' + this.menu_id + '/' + this.selected_items[i], { food: 
                    { 
                        price_font: font
                    }}).then(response => {
                        this.price_font = font
                        this.$root.$emit('selected-items-change-price-font', font);
                        // show_item_price_font_selector = false
                    })
                    .catch(error => this.setError(error, 'Cannot update food.'))
            }
        },                
      getBackgroundColour() {
        if (this.food.background_colour === 'default' || this.food.background_colour === null || this.food.background_colour === undefined || this.food.background_colour == '') {
              this.background_colour = '#FFFFFF'
            } else {
              this.background_colour = this.food.background_colour
            }
        },
        getItemType() {
            if (this.food.major_category_header === true) {
                this.item_type = 'major-category-header'
            } else if (this.food.category_header === true) {
                this.item_type = 'minor-category-header'
                // console.log('minor cat header found')
            } else {
                this.item_type = 'item'
            }
        },
        getImageVisibility() {
            if (this.food.image_hidden === true) {
                this.image_hidden = true
            } else {
                this.image_hidden = false
            }
        },
        hideImage() {
            if (this.image_hidden === true) {
                this.image_hidden = false
            } else {
                this.image_hidden = true
            }
            this.$http.secured.post('/app_update_food/' + this.menu_id + '/' + this.food.id, { food: 
                { 
                    image_hidden: this.image_hidden
                }}).then(response => {

                })
                .catch(error => this.setError(error, 'Cannot update food.'))
        },
        preventActive() {
            if (this.can_edit === true) {
                this.prevent_active = false
            } else {
                this.prevent_active = true
            }
        },
        getFonts() {
            if (this.food.item_name_font === 'default') {
                this.item_name_font = 'Montserrat'
            } else {
                this.item_name_font = this.food.item_name_font
            }

            if (this.food.item_description_font === 'default') {
                this.item_description_font = 'Montserrat'
            } else {
                this.item_description_font = this.food.item_description_font
            }
            
            if (this.food.price_font === 'default') {
                this.price_font = 'Montserrat'
            } else {
                this.price_font = this.food.price_font
            }
        },
        getImageHeight() {
            if (this.food.category_header === true || this.food.major_category_header === true) {
                    this.image_height = String((Number(this.food.container_height)) + 'px')
            } else {
                if (this.food.container_height === 'default') {
                    this.image_height = '100px'
                } else {
                    if (this.food.container_height < 100) {
                        this.image_height = String((Number(this.food.container_height)) + 'px')
                    } else {
                        this.image_height = String('100px')
                    }
                }  
            }         
        },
        getBorderBottom() {
            if (this.border_bottom === true) {
                return '1px solid lightgray'
            } else {
                return '0px'
            }
        },
        toggleBorderBottom() {
            if (this.border_bottom === true) {
                this.border_bottom = false
            } else {
                this.border_bottom = true
            }
            for (let i = 0; i < this.selected_items.length; i++) {
                this.$http.secured.post('/app_update_food/' + this.menu_id + '/' + this.selected_items[i], { food: 
                    { 
                        stroke_bottom: this.border_bottom
                    }}).then(response => {
                        this.$root.$emit('selected-items-change-border-bottom', this.border_bottom);
                    })
                    .catch(error => this.setError(error, 'Cannot update food.'))
            }            
        },
        getPricePosition() {
            if (this.food.price_position == "default") {
                this.price_position = "default"
            } else {
                this.price_position = "align_left"
            }
        },
        setPricePosition: function (position) {
            // console.log('price position ' + position)
            for (let i = 0; i < this.selected_items.length; i++) {
                this.$http.secured.post('/app_update_food/' + this.menu_id + '/' + this.selected_items[i], { food: 
                    { 
                        price_position: position
                    }}).then(response => {
                        this.price_position = position
                        this.$root.$emit('selected-items-change-price-position', position);
                    })
                    .catch(error => this.setError(error, 'Cannot update food.'))
            }
        },
        getHeight(food) {
            var height = 0
            if (food.container_height === "default") {
                height = 100
            } else {
                height = food.container_height
            }
            if (this.can_edit === false) {
                if (this.menu.orderable === true || this.menu.orders_for_pickup === true || this.menu.orders_for_delivery === true || this.menu.orders_for_in_room === true) {
                    if (food.orderable === true) {
                        height = parseInt(height) + parseInt(22)
                        return parseInt(height)
                    } else {
                        return Number(height)
                    }
                } else {
                    return Number(height)
                }
            } else {
                return Number(height)
            }
        },
        getNameAndDescription() {
            this.name = this.food['translation_' + this.language] || this.food.name || this.food.translation_en
            this.description = this.food['description_' + this.language] || this.food.description || this.food.description_en     
        },
        resize(food)  {
            this.$http.secured.post('/app_update_food/' + this.menu_id + '/' + this.food_id, { food: 
                { 
                    container_height: food.height 
                }}).then(response => {
                    // console.log(response.data)
                    this.food.container_height = food.height
                    this.getImageHeight();
                })
                .catch(error => this.setError(error, 'Cannot update food.'))
        },
        toggleItemNameOptions() {
            if (this.show_item_name_options === false) {
                this.show_item_name_options = true
                this.show_item_description_font_selector = false
                this.show_item_name_font_selector = false
                this.show_item_price_font_selector = false
            } else {
                this.show_item_name_options = false
            }
        },
        toggleBackgroundColorOptions() {
            // console.log('background options clicked')
            if (this.show_background_color_options === false) {
                this.show_background_color_options = true
            } else {
                this.show_background_color_options = false
            }
        },
        increaseItemNameFontSize() {
            var startingFontSize = this.item_name_font_size.replace(/\D/g,'');
            var newFontSize = (Number(startingFontSize) + 2)
            this.item_name_font_size = String(newFontSize + 'px')
            // console.log(newFontSize)
            for (let i = 0; i < this.selected_items.length; i++) {
                this.$http.secured.post('/app_update_food/' + this.menu_id + '/' + this.selected_items[i], { food: 
                    { 
                        item_name_font_size: this.item_name_font_size
                    }}).then(response => {
                        // console.log(response.data)
                        this.$root.$emit('selected-items-change-font-size', this.item_name_font_size);
                    })
                    .catch(error => this.setError(error, 'Cannot update food.'))
            }
        },
        decreaseItemNameFontSize() {
            var startingFontSize = this.item_name_font_size.replace(/\D/g,'');
            var newFontSize = (Number(startingFontSize) - 2)
            this.item_name_font_size = String(newFontSize + 'px')
            for (let i = 0; i < this.selected_items.length; i++) {
                this.$http.secured.post('/app_update_food/' + this.menu_id + '/' + this.selected_items[i], { food: 
                    { 
                        item_name_font_size: this.item_name_font_size
                    }}).then(response => {
                        this.$root.$emit('selected-items-change-font-size', this.item_name_font_size);
                    })
                    .catch(error => this.setError(error, 'Cannot update food.'))
            }
        }, 
        getItemNameColor() {
            this.item_name_color = this.food.item_name_font_colour
        },
        getItemDescriptionColor() {
            if (this.food.item_description_font_colour === 'default') {
                this.item_description_color = '#000000'
            } else {
                this.item_description_color = this.food.item_description_font_colour
            }
        },
        getBackgroundColor() {
            if (this.food.background_colour === 'default' || this.food.background_colour === null || this.food.background_colour === undefined || this.food.background_colour == '') {
                this.background_color = '#FFFFFF'
            } else {
                this.background_color = this.food.background_colour
            }
        },
        setItemBackgroundColor: function (color) {
            for (let i = 0; i < this.selected_items.length; i++) {
                this.$http.secured.post('/app_update_food/' + this.menu_id + '/' + this.selected_items[i], { food: 
                    { 
                        background_colour: color
                    }}).then(response => {
                        this.background_color = color
                        this.$root.$emit('selected-items-change-background-color', color);
                        if (localStorage.colour != undefined) {
                            var storedColours = JSON.parse(localStorage.colour)
                        } else {
                            var storedColours = []
                        }             
                        storedColours.indexOf(color) === -1 ? storedColours.push(color) : console.log("This item already exists");
                        localStorage.setItem("colour", JSON.stringify(storedColours));
                    })
                    .catch(error => this.setError(error, 'Cannot update food.'))
            }
        },
        setColour() {
            // console.log('color')
        },
        getFontSize() {
            if (this.food.item_name_font_size === 'default') {
                if (this.item_type === 'major-category-header') {
                    this.item_name_font_size = '25px'
                } else if (this.item_type === 'minor-category-header') {
                    this.item_name_font_size = '20px'
                } else {
                    this.item_name_font_size = '15px'
                }
            } else {
                this.item_name_font_size = this.food.item_name_font_size
            }
            
            if (this.food.item_description_font_size === 'default') {
                this.item_description_font_size = '12.5px'
            } else {
                this.item_description_font_size = this.food.item_description_font_size
            }

        }
    }
}


</script>

<style scoped>

.updated-info {
    background-color: rgba(39, 169, 225, 0.7);
    color: white;
    font-size: 9px;
    box-sizing: border-box;
    padding: 2.5px 5px 2.5px 5px;
    position: absolute;
    top: 2.5px;
    right: 2.5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 600;
    border-radius: 2.5px;
}

.updated-info span {
    margin-left: 2.5px;
    color: #F8ED31;
}

.updated-info img {
    width: 10px;
    height: 10px;
    margin-right: 5px;
}

h4 {
    font-weight: 600;
    width: 100%;
    display: flex;
    flex-direction: row;
    width: max-content;
    margin-right: 5px;
    margin-bottom: 0px;
    margin: 0px 0px 0px 0px;
}

.centered {
    width: 100%;
    justify-content: center !important;
    /* justify-content: space-evenly; */
}

.item-content-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100% !important;
}

.item-content-container-flex-column {
    flex-direction: column;
}

.item {
    position: relative;
    width: 100% !important;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 20px;
    cursor: pointer;
    height: min-content !important;
    margin-bottom: 0px;
}

.item-unpadded {
    padding: 0px;
}

.item:hover {
    background-color: rgba(177, 214, 241, 0.1);
    transition: 0.1s;
}

.food-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.food-image-full-size {
    height: auto !important;
    max-height: 1000px;
}

.full-width-image-container {
    background-color: rgba(255, 255, 255, 0.8);
    width: 100% !important;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 10000000;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    

}

.full-width-image-container img {
    width: 90%;
    max-width: 600px;
    margin: auto;
    height: auto !important;
}

.description {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 0px 10px 0px;
    min-height: 25px;
}

.description h5 {
    margin: 0px 0px 0px 0px;
    font-weight: 400;
    font-size: 13px;
}

.item-image-container {
    /* margin-right: 10px; */
    /* width: 15%; */
    width: 30%;
    margin-right: 2.5%;
    /* border: 1px dotted lightgray; */
}

.full-width {
    width: 100%;
}

.full-width-container {
    width: 100% !important;
    height: max-content;
}

.full-width-container img {
    width: 100%;
    max-width: 500px;
    max-height: 200px;
    object-fit: cover;
    position: relative;

}

.item-text-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.item-text-container-overlay {
    position: absolute;
    left: 30px;
}

.item-text-container-overlay h4 {
    /* color: white; */
    text-shadow: 0 0 16px rgba(0, 0, 0, 1);
}

.item-text-container-overlay h5 {
    /* color: white; */
    text-shadow: 0 0 16px rgba(0, 0, 0, 1);    
}

.item-name {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
}

.item-name-centered {
    max-width: 100%;
    text-align: center;
    margin: auto;
    margin-right: 0px;
    margin-left: 0px;
    justify-content: center;
}

.align-flex-start {
    align-items: center;
}

.item-with-image-name {
    max-width: 78.5%;
}

.hidden {
    opacity: 0;
}

.item-name h4 {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding-right: 20px;
    /* width: 70%; */
    /* max-width: 90%; */
}


#drag {
    width: 20px;
    height: 20px;
    background-color: gray;
}

.vdr.active {
    z-index: 1 !important;
}

.item-new-cont {
    width: 100% !important;
    min-height: max-content !important;
}

.options-container {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 10px;
}

.more-options {
    position: relative;
    /* position: absolute; */
    height: 20px !important;
    width: 20px !important;
    margin-left: 10px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-content: center;
    box-sizing: border-box;
    padding: 5px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    color: white;
    font-weight: 600;
    cursor: pointer;
    /* z-index: 1000; */
}

.more-options:hover {
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
    cursor: pointer;
}

.more-options-selected {
    background-color: #4794CB;
}

.has-selections {
    background-color: #4794CB;
}

.more-options-selected img {
    filter: brightness(0) invert(1);
}


.color-picker-container {
    position: absolute;
    right: 5px;
    z-index: 100;
    height: 100%;
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-self: flex-end;

}

.color-picker {
    box-sizing: border-box;
    padding: 2.5px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    /* border: 1px solid lightgray; */
    background-color: white;
    width: 20px;
    height: 20px;
    max-width: 20px;
    max-height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    /* z-index: 8000; */
}

.color-picker img {
    max-height: 15px !important;
    max-width: 15px !important;;
    min-height: 15px !important;;
    min-width: 15px !important;;
}
.border-picker {
    margin-top: 10px;
}

.border-picker-selected {
    background-color: #4794CB;
}

.border-picker-selected img {
    filter: brightness(0) invert(1);
}

.image-uploader {
    height: 20px;
    display: flex;
    align-items: center;
    /* margin-left: 10px; */
}

.more-options:hover > .button-descriptor {
    display: flex;
    z-index: 100000;
    position: absolute;
}

.color-picker:hover > .button-descriptor {
    display: flex;
    z-index: 100000;
    position: absolute;
}

.hidden-tag {
    /* position: absolute; */
    background-color: black;
    color: white;
    box-sizing: border-box;
    padding: 2.5px;
    width: max-content;
    font-size: 12.5px;
    border-radius: 2.5px;
}

.hidden-item {
    display: none;
}

.hidden-can-edit {
    opacity: 0.3;
    display: block;
    width: 100% !important;
}

.next-arrow {
    width: 25px;
    height: 25px;
}

.back-arrow {
    width: 25px;
    height: 25px;
    transform: rotate(180deg);
}

.centre-align {
    flex-direction: column;
    align-items: center;
}

.centered-description {
    text-align: center
}

.item-content-container-centre-aligned {
        flex-direction: column;
    align-items: center;
}

.sold-out-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.paginate-centered {
  text-align: center;
}

@media(max-width: 500px) {
    /* .food-image {
        max-width: 125px;
        min-width: 125px;
    } */
    .item-image-container {
        width: 30%;
        /* min-width: 100px; */
    }

}

@media(max-width: 400px) {
    /* .food-image {
        max-width: 100px;
        min-width: 100px;
    } */

    

    .content-container {
        /* height: max-content !important; */
        padding: 5px 0px 10px 0px;
    }
}

</style>