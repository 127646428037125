

<template>

    <div>

        <div v-if="uploading === true" class="loader loader-fixed">
            
            
            <transition name="fade">

                <div class="loader-with-message">

                    <p>Uploading PDF ...</p>

                    <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>

                </div>

            </transition>

                

        </div> 

        <div class="uploader">

            <div class="lang-specific">

                <h3>Menu file ({{ languageLongForm(language) }})</h3>

                <p>Max 10 MB</p>

                <form enctype="multipart/form-data" class="menu-uploader">
                    <input id="upload" type="file" name="file[]" @change="previewFiles">
                    <transition name="fade">
                        <div v-if="result === 'uploaded'"><img src="@/assets/check-mark.svg" /></div>  
                    </transition>
                </form>

                        
            
            </div>

        </div>

    </div>


</template>


<script>
// import ImageUploader from 'vue-image-upload-resize'

  export default {
    name: 'ImageMenuUploader',
    props: ['editing', 'menu_id', 'language'],
    data(){
      return {
            files: [],
            result: '',
            uploading: false
      };
    },
    created() {
        
    },
    methods: {
        previewFiles(event) {
            console.log(event.target.files);
            this.files = event.target.files
            this.uploadFiles()
        },
        uploadFiles() {
            this.uploading = true;
            var picData = new FormData();
            picData.append('userFiles', this.files[0])
            console.log(picData)
            if (localStorage.signedIn === "true" && this.menu_id != 0) {
                this.$http.upload.post(`/app_upload_custom_image/${this.menu_id}/${this.language}`, picData).then(response => {
                    this.result = 'uploaded';
                    console.log('uploaded from uploader')
                    this.uploading = false
                    this.$emit('can-proceed');
                })
            } else {
                 this.$http.upload.post(`/app_upload_provisional_custom_image/${this.language}`, picData).then(response => {
                    this.result = 'uploaded';
                    console.log('uploaded from uploader')
                    this.uploading = false
                    this.$emit('can-proceed');
                })               
            }
        },
        languageLongForm(language) {
            if (language == 'en') {
                return 'English'
            } else if (language == 'ru') {
                return 'Russian'
            } else if (language == 'jp') {
                return 'Japanese'
            } else if (language == 'sp') {
                return 'Spanish'
            } else if (language == 'fr') {
                return 'French'
            } else if (language == 'ca') {
                return 'Catalan'
            } else if (language == 'kr') {
                return 'Korean'
            } else if (language == 'de') {
                return 'German'
            } else if (language == 'zh') {
                return 'Chinese'
            } else if (language == 'nl') {
                return 'Dutch'
            } else if (language == 'pt') {
                return 'Portuguese'
            } else if (language == 'it') {
                return 'Italian'
            } else if (language == 'ar') {
                return 'Arabic'
            } else if (language == 'ina') {
                return 'Indonesian'
            }
        }
    }
  }


</script>

<style scoped>



h3 {
    font-weight: 600;
    font-size: 15px;
    margin: 10px 0px 10px 0px;
}

p {
    font-size: 10px;
    font-weight: 600;
}

.lang-specific {
    margin: 0px 0px 30px 0px;
}

.menu-uploader {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.menu-uploader img {
    width: 15px;
    height: 15px;
}

.loader-fixed {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100000;
    background-color: white;
}
  
  
  
</style>