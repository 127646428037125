<template>

    <div>

        <img src="../assets/first_item.svg" />
        <h3>Click here to add your first item</h3>



    </div>



</template>


<script>

export default {
    name: 'NoItems'
}

</script>

<style scoped>

div {
    background-color: white;
    margin: auto;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 80vh;
    cursor: pointer;
}

div img {
    width: 30px;
    height: 30px;
}

h3 {
    font-weight: 400;
    margin: 10px 0px 0px 0px;
    font-size: 15px;
}



</style>