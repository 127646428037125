<template>

    <div id="home">

        <HomeNavbar :sticky_navbar="scrolled_from_top" v-if="signed_in != true" />

        <HomeBanner />

        <Features />

        <GreenCircles />

        <TryFreeSevenDays />

        <SampleMenu />

        <Pricing />

        <ContactUs />

        <BeautifulMenus />

        <Partners />

        <PartnerTestimonials />

        <AdditionalServices />

        <HomeFooter :signed_in="signed_in" />
            

    </div>




</template>

<script>
import HomeNavbar from '@/components/home/HomeNavbar.vue'
import HomeBanner from '@/components/home/HomeBanner.vue'
import Features from '@/components/home/Features.vue'
import GreenCircles from '@/components/home/GreenCircles.vue'
import TryFreeSevenDays from '@/components/home/TryFreeSevenDays.vue'
import SampleMenu from '@/components/home/SampleMenu.vue'
import Pricing from '@/components/home/Pricing.vue'
import ContactUs from '@/components/home/ContactUs.vue'
import BeautifulMenus from '@/components/home/BeautifulMenus.vue'
import Partners from '@/components/home/Partners.vue'
import PartnerTestimonials from '@/components/home/PartnerTestimonials.vue'
import AdditionalServices from '@/components/home/AdditionalServices.vue'
import HomeFooter from '@/components/home/HomeFooter.vue'




export default {
    name: "Home",
    metaInfo: {
        title: "Menulingua | Digital Menus | Ordering System | QR Code Menus",
        titleTemplate: null,
        meta: [
            {
                name: 'description',
                content: "Build your digital menu for your restaurant, golf course or spa. Edit your QR code menu anytime and match your design & branding. Set up in just 1 minute",
                keywords: 'digital menu, qr code menu, restaurant menu, translation, culinary, tourism'
            }
        ],
        htmlAttrs: {
        lang: 'en'
        },
        link: [
            {rel: 'canonical', href: 'https://menulingua.com'}
        ]
    },
    components: {
        HomeNavbar,
        HomeBanner,
        Features,
        GreenCircles,
        TryFreeSevenDays,
        SampleMenu,
        Pricing,
        ContactUs,
        BeautifulMenus,
        Partners,
        PartnerTestimonials,
        AdditionalServices,
        HomeFooter
    },
    data () {
        return {
            show_screen: false,
            scrolled_from_top: false,
            signed_in: Boolean
        }
    },
    created() {
        this.getLanguage();
        window.addEventListener('scroll', this.handleScroll);
        this.checkSignedIn();
    },
    methods: {
        handleScroll (event) {
            var target = document.querySelector('#home-navbar');
            if (window.scrollY >= target.offsetHeight + 0) {
                this.scrolled_from_top = true
            } else if (window.scrollY < target.offsetHeight + 0) {
                this.scrolled_from_top = false
            }
        },
        checkSignedIn() {
            if (localStorage.signedIn) {
                this.signed_in = true
            } else {
                this.signed_in = false
            }
        },
        getLanguage() {
            if (localStorage.default_language === undefined) {
                this.$store.commit('setDefaultLanguage', 'en')
                localStorage.setItem('default_language', 'en')
            } else {
                this.$store.commit('setDefaultLanguage', localStorage.default_language)
            }
        }
    }

}





</script>

<style scoped>

#home {
    height: max-content;
    display: flex;
    flex-direction: column;
}

.home-screen {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 1000;
    display: flex;
    align-items: stretch;
    background-color: rgba(0, 0, 0, 0.3);
}



</style>