<template>

  <div id="order-info-container">

    <div v-if="this.is_loading === true" class="loader">


      <div class="submitted-confirmation">

        <img src="@/assets/checkmark_normal.svg" />

      </div>

    </div>

    <div v-else>

      <OrderStatus :order_id="order_id" :language="language" />

      <div class="order-header">

        <h1>

          <span v-if="order_type === 'in-restaurant'">

            #{{ order_id }} {{ this.$translations.in_restaurant[language] || this.$translations.in_restaurant['en'] }}

          </span>

          <span v-if="order_type === 'pickup'">

            #{{ order_id }} {{ this.$translations.pickup[language] || this.$translations.pickup['en'] }}

          </span>

          <span v-if="order_type === 'delivery'">

            #{{ order_id }} {{ this.$translations.delivery[language] || this.$translations.delivery['en'] }}

          </span>

          <span v-if="order_type === 'in-room'">

            #{{ order_id }} {{ this.$translations.in_room[language] || this.$translations.in_room['en'] }}

          </span>

          <a @click="navigateToMenuSettings(order_info.menu_id)" class="menu-box">

            <div v-if="order_info.menu_restaurant_name != null && order_info.menu_restaurant_name != ''">
              <img class="menu-logo" v-bind:src="order_info.menu_logo.url" />
              {{ order_info.menu_restaurant_name }}
            </div>

            <div v-else>
              <img class="menu-logo" v-bind:src="order_info.menu_logo.url" />
              {{ order_info.menu_name }}
            </div>

          </a>

        </h1>

        <p><span>{{ moment(created_at).format('LT') }} {{moment(created_at).format('YYYY-MM-DD')}} </span> </p>

        <div class="order-header-details">

          <div v-if="order_type === 'in-restaurant'">

            <p>
              <img v-if="outlet_type === 'restaurant' || outlet_type === ''" src="@/assets/table.svg" />

              <img v-if="outlet_type === 'golf course' || outlet_type === ''" src="@/assets/hole_number.svg" />

              <img v-if="outlet_type === 'spa' || outlet_type === ''" src="@/assets/location.svg" />

              <span>{{ table_number }}</span>

            </p>

            <p><img src="@/assets/pax.svg" />{{ this.$translations.guests[language] || this.$translations.guests['en']
              }}: <span>{{ pax }}</span> </p>

          </div>

          <div v-if="order_type === 'delivery'">

            <p><img src="@/assets/icon_recipient.svg" />{{ this.$translations.name[language] ||
              this.$translations.name['en'] }}:<span>{{ this.order_info.order_data.name }}</span></p>

            <p><img src="@/assets/location.svg" />{{ this.$translations.address[language] ||
              this.$translations.address['en'] }}:<span>{{ this.order_info.order_data.physical_address }}</span></p>

            <p><img src="@/assets/phone_new.svg" />{{ this.$translations.phone_number[language] ||
              this.$translations.phone_number['en'] }}:<span>{{ this.order_info.order_data.phone_number }}</span></p>

          </div>

          <div v-if="order_type === 'pickup'">

            <p><img src="@/assets/icon_recipient.svg" />{{ this.$translations.name[language] ||
              this.$translations.name['en'] }}:<span>{{ this.order_info.order_data.name }}</span></p>

            <p><img src="@/assets/phone_new.svg" />{{ this.$translations.phone_number[language] ||
              this.$translations.phone_number['en'] }}:<span>{{ this.order_info.order_data.phone_number }}</span></p>

          </div>

          <div v-if="order_type === 'in-room'">

            <p><img src="@/assets/room_number.svg" />{{ this.$translations.room_number[language] ||
              this.$translations.room_number['en'] }}:<span>{{ this.order_info.order_data.room_number }}</span></p>

            <p><img src="@/assets/icon_recipient.svg" />{{ this.$translations.last_name[language] ||
              this.$translations.last_name['en'] }}:<span>{{ this.order_info.order_data.last_name }}</span></p>

          </div>

          <div v-if="order_info.order_data.payment_type === 'stripe'">

            <p class="paid-tag">Paid</p>

            <p class="payment-id-tag">Payment ID: <span>{{ this.order_info.order_data.payment_intent }}</span></p>

          </div>

        </div>

      </div>

      <!-- {{ order_info }} -->

      <div class="order-rows-container">

        <div class="headers">

          <p>{{ this.$translations.item[language] || this.$translations.item['en'] }}</p>

          <p>{{ this.$translations.price[language] || this.$translations.price['en'] }}</p>

          <p>{{ this.$translations.quantity[language] || this.$translations.quantity['en'] }}</p>

        </div>

        <div class="order-row" v-for="order_row in order_rows" :key="order_row.id">

          <div class="order-row-basic-info">

            <span v-if="order_row.bundle_item === true" class="bundle-item">Bundle</span>

            <p class="item-name">{{ getItemName(order_row.food_id) }}</p>

            <p v-if="order_row.item_unit !== null && order_row.item_unit != 'none'" class="item-selections">{{
              order_row.item_unit }}</p>

            <!-- <p v-if="checkIfBasePriceDoesNotMatch(order_row.item_price, order_row.final_price)" class="item-base-price"><NumberFormat :number="order_row.item_price" :currency="currency" /></p> -->

            <div class="item-selections-container" v-if="order_row.selections != null && order_row.selections != ''">

              <div v-for="selection in order_row.selections" :key="selection" class="selection">

                <div>
                  <p>{{ getSelectionName(Number(selection)) }}</p>
                  <p v-if="getSelectionPrice(Number(selection)) != null && getSelectionPrice(Number(selection)) != 0">+
                    <NumberFormat :number="getSelectionPrice(Number(selection))" :currency="currency" />
                  </p>

                </div>

              </div>

            </div>



            <p v-if="order_row.item_note.length > 0" class="item-selections"><img src="@/assets/note_icon.svg" />{{
              order_row.item_note[0].toString() }}</p>

          </div>

          <p v-if="order_row.item_price !== null" class="item-price">
            <NumberFormat :number="order_row.item_price" :currency="currency" />
          </p>

          <p class="item-quantity">{{ order_row.item_quantity }}</p>

        </div>

      </div>

      <div v-if="subtotal != grand_total" class="order-rows-container price-info-container">

        <p class="price-info price-info-subtotal">{{ this.$translations.subtotal[language] ||
          this.$translations.subtotal['en'] }}:
          <NumberFormat :number="subtotal" :currency="currency" />
        </p>

        <p v-if="discount_amount != 0 && discount_amount != null" class="price-info">Discount:
          <NumberFormat :number="discount_amount" :currency="currency" />
        </p>

        <p v-if="calculated_order_tax != 0" class="price-info">{{ this.$translations.tax[language] ||
          this.$translations.tax['en'] }} {{ tax }}%:
          <NumberFormat :number="calculated_order_tax" :currency="currency" />
        </p>

        <p v-if="calculated_order_service != 0" class="price-info">{{ this.$translations.service[language] ||
          this.$translations.service['en'] }} {{ service }}%:
          <NumberFormat :number="calculated_order_service" :currency="currency" />
        </p>

        <p v-if="calculated_order_packaging_fee != 0" class="price-info">{{ packaging }}% {{
          this.$translations.packaging_fee[language] || this.$translations.packaging_fee['en'] }}:
          <NumberFormat :number="calculated_order_packaging_fee" :currency="currency" />
        </p>

        <p v-if="delivery_fee != 0 && order_type === 'delivery'" class="price-info"> {{
          this.$translations.delivery_fee[language] || this.$translations.delivery_fee['en'] }}:
          <NumberFormat :number="delivery_fee" :currency="currency" />
        </p>

      </div>

      <div class="order-rows-container grand-total-container">

        <p>{{ this.$translations.total[language] || this.$translations.total['en'] }}:
          <NumberFormat :number="grand_total" :currency="currency" />
        </p>

      </div>

    </div>

  </div>





</template>


<script>
// import moment from 'moment'
import NumberFormat from '@/components/NumberFormat.vue'
import OrderStatus from '@/components/OrderStatus.vue'

export default {
    name: 'Order',
    components: {
        NumberFormat,
        OrderStatus
    },
    data () {
        return {
            order_info: {},
            is_loading: true,
            order_id: '',
            order_type: '',
            created_at: '',
            table_number: '',
            pax: '',
            tax: 0,
            calculated_order_tax: 0,
            service: 0,
            calculated_order_service: 0,
            packaging: 0,
            calculated_order_packaging_fee: 0,
            subtotal: 0,
            delivery_fee: 0,
            grand_total: 0,
            order_open: Boolean,
            currency: '',
            order_rows: [],
            discount_amount: 0,
            language: '',
            selections: [],
            languages: [],
            url: process.env.VUE_APP_API_URL,
            foods: [],
            foods_from_connected_outlets: [],
            outlet_type: ''
        }
    },
    beforeCreate() {
        localStorage.removeItem('pending_order_id')
    },
    created() {
        this.scrollToTop();
        this.language = localStorage.language
        this.$store.commit('clearOrder') 
        localStorage.removeItem('order')
        // if (this.foods_from_connected_outlets.length > 0) {
        // }
    },
    mounted () {
            let id = this.$router.currentRoute.params.id;
            this.$http.plain.get("/app_order/" + id)
            .then(response => { this.order_info = response.data,
                this.foods = response.data.foods,
                this.foods_from_connected_outlets = response.data.foods_from_connected_outlets,
                this.order_id = response.data.order_data.id,
                this.order_type = response.data.order_data.order_type,
                this.created_at = response.data.order_data.created_at,
                this.table_number = response.data.order_data.table_number,
                this.pax = response.data.order_data.pax,
                this.currency = response.data.currency,
                this.tax = response.data.order_data.tax,
                this.calculated_order_tax = response.data.order_data.calculated_order_tax,
                this.service = response.data.order_data.service,
                this.calculated_order_service = response.data.order_data.calculated_order_service,
                this.packaging = response.data.order_data.packaging_fee,
                this.calculated_order_packaging_fee = response.data.order_data.calculated_order_packaging_fee,
                this.subtotal = response.data.order_data.price,
                this.grand_total = response.data.order_data.grand_total,
                this.delivery_fee = response.data.order_data.delivery_fee,
                this.order_open = response.data.order_data.open,
                this.order_rows = response.data.order_rows,
                this.discount_amount = response.data.order_data.discount,
                this.outlet_type = response.data.outlet_type,
                this.selections = response.data.selections,
                this.order_rows = this.findUnique(this.order_rows),
                this.languages = response.data.languages
                localStorage.removeItem('order_pending');
                this.combineConnectedFoods()
                this.is_loading = false
            })
            .catch(error => this.setError(error, 'Something went wrong'))
    },
    methods: {
        setError (error, text) {
            this.error = (error.response && error.response.data && error.response.data.error) || text
        },
        navigateToMenuSettings(menuId) {
          this.$router.push('/menu_settings/' + menuId);
        },
        scrollToTop() {
            window.scrollTo(0,0);
        },
        timestamp(orderPlaced) {
            return moment(orderPlaced).format('LLL');
        },
        checkIfBasePriceDoesNotMatch(basePrice, computedPrice) {
            if (basePrice != computedPrice && basePrice != null) {
                return true
            } else {
                return false
            }
        },
        combineConnectedFoods() {
            const foodsFromConnectedOutlets = this.foods_from_connected_outlets
            for (let i = 0; i < foodsFromConnectedOutlets.length; i++) {
                this.foods.push(foodsFromConnectedOutlets[i])
            }
        },
        getItemName(order_row_id) {
            // const foods = this.foods
            // this.foods.push(this.foods_from_connected_outlets)
            const result = this.foods.filter(food => food.id == order_row_id);
            return result[0]['translation_' + this.language] || result[0]['translation_en'] || result[0]['name'] || result[0]['translation_' + this.languages[0]]


            // return food
        },        
        numberFormat(number) {
            if (this.currency === 'OMR') {
                return Number(number).toFixed(3)
            } else if (this.currency === 'Rp') {
                return Number(number).toLocaleString()
            } else {
                return Number(number).toFixed(2)
            }
        },
        orderId() {
            return this.$router.currentRoute.params.id
        },
        findUnique(array) {
            const result = [];
            const map = new Map();
            for (const item of array) {
                let quantity = 0
                let food_id = item.food_id
                let unit = item.unit
                let note = []
                for (let i = 0; i < array.length; i++) {
                    if ((array[i].food_id === item.food_id) && (array[i].selections === item.selections) && (array[i].unit === item.unit)) quantity++, note.push(array[i].note)
                }
                note = note.filter(n => n)
                if(!map.has(item.food_id + item.selections + item.unit)) {
                    map.set((item.food_id + item.selections + item.unit), true); // set any value to Map
                    result.push({
                        food_id: item.food_id,
                        item_name: item.item_name,
                        item_quantity: quantity,
                        selections: item.selections.split(","),
                        item_selection_name: item.selection_name,
                        item_price: item.item_price,
                        item_base_price: item.base_price,
                        item_unit: item.unit,
                        bundle_item: item.bundle_item,
                        item_note: note
                    });
                }
            }
            return result
        },
        checkOrderStatus() {
            if (this.order_open === true) {
                this.order_open = false
            } else {
                this.order_open = true
            }
        },
        getSelectionName(selectionId) {
            var selection = this.selections.filter(selection => selection.id == selectionId)
            return selection[0][`name_${this.language}`] || selection[0][`name`] || selection[0][`name_en`]
        },
        getSelectionPrice(selectionId) {
           var selection = this.selections.filter(selection => selection.id == selectionId)
            return Number(selection[0].additional_fee)           
        }


    }
}



</script>

<style scoped>

.loader {
    height: 80vh;
}

#order-info-container {
    /* max-width: 1200px;
    width: 90%; */
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    /* margin-top: 50px; */
}

.delete-button {
    margin-top: 50px;
}

.order-header {
    display: flex;
    flex-direction: column;
    /* width: 100%; */
    background-color: white;
    border: 1px solid lightgray;
    /* box-shadow: 8px 0px 0px 0px rgba(0, 0, 0, 0.1); */
    box-sizing: border-box;
    padding: 20px;
    margin: auto;
    border-radius: 10px;
    margin-bottom: 25px;
    max-width: 1200px;
    width: 90%;
}

.order-header h1 {
    font-size: 25px;
    font-weight: 600;
    margin: 0px 0px 10px 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.order-header h1 span {
    margin: 5px 5px 5px 0px;
}

.order-header h1 div {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid lightgray;
    font-size: 12.5px;
    font-weight: 600;
    align-self: flex-end;
    /* margin-left: 10px; */
    box-sizing: border-box;
    padding: 2.5px 5px 2.5px 5px;
    border-radius: 5px;
    cursor: pointer;
    height: 35px;
    /* margin: 10px 0px 10px 0px; */
}

.order-header h1 div:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    transition: 0.2s;
}

.order-header h1 div img {
    max-height: 25px;
    max-width: 25px;
    margin-right: 5px;
}

.order-header p {
    font-size: 13px;
    margin: 15px 0px 0px 0px;
    display: flex;
    align-items: center;
}

.order-header p span {
    font-weight: 600;
    margin-left: 5px;
}

.order-header-details {
    display: flex;
    flex-direction: column;
}

.order-header-details p {
    margin-right: 10px;
}

.order-header-details img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.order-rows-container {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    width: 90%;
    border: 1px solid lightgray;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 10px;
    margin: auto;
    margin-bottom: 25px;
}

.headers {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    justify-content: space-between;
}

.headers p {
    font-size: 11px;
    text-transform: uppercase;
    margin: 0px 0px 0px 0px;
    font-weight: 400;
    display: flex;
    /* border: 1px dotted lightgray; */
}

.headers p:first-child {
    width: 60%;
}

.headers p:nth-child(2), .headers p:nth-child(3) {
    width: 20%;
    justify-content: center;
}

.order-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0px 0px 20px 0px;
}

.item-price, .item-quantity {
    width: 20%;
    margin-top: 0px;
    /* border: 1px dotted lightgray; */
    display: flex;
    justify-content: center;
    font-size: 13px;
    font-weight: 600;
}

.order-row-basic-info {
    display: flex;
    flex-direction: column;
    width: 60%;
    box-sizing: border-box;
    padding-right: 10%;
}

.item-name {
    font-size: 15px;
    margin: 0px 0px 5px 0px;
    font-weight: 600;
}

.item-base-price {
    font-size: 12px;
    margin: 5px 0px 5px 0px;
}

.item-base-price span {
    font-weight: 600;
}

.item-selections {
    font-size: 12px;
    margin: 5px 0px 5px 0px;
    display: flex;
    /* align-items: center; */
}

.item-selections img {
    width: 15px;
    height: 15px;
    margin-right: 5px;
}

.price-info-container {
    align-items: flex-end;
}

.price-info-container p {
    display: flex;
    flex-direction: row;
}

.price-info-container p div {
    font-weight: 600;
}

.price-info {
    font-size: 13px;
    margin: 0px 0px 0px 0px;
    box-sizing: border-box;
    padding: 5px 0px 5px 0px;
}

.price-info-subtotal {
    border-bottom: 1px dotted lightgray;
    font-weight: 600;
}

.grand-total-container {
    background-color: black;
    align-items: flex-end;
    color: white;
    font-size: 15px;
    font-weight: 600;
    border: 1px solid rgb(60, 60, 59);
}

.grand-total-container p {
    display: flex;
    flex-direction: row;
    font-weight: 400;

}

.grand-total-container p div {
    font-weight: 600;
}

.order-status {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* border: 1px solid lightgray; */
    /* height: 100px; */
    box-sizing: border-box;
    padding: 15px;
    align-items: center;
    cursor: pointer;
    border-radius: 10px;
}

.order-status h5 {
    font-weight: 600;
    margin: 0px 0px 5px 0px;
    font-size: 17.5px;
}

.order-status p {
    font-weight: 400;
    margin: 0px 0px 0px 0px;
    font-size: 12.5px;
}

.open {
    background-color: #fbaf3f;
    /* color: white; */
}

.closed {
    background-color: lightgray;
    color: gray;
}

.bundle-item {
    border: 1px solid lightgray;
    color: black;
    font-size: 10px;
    font-weight: 600;
    box-sizing: border-box;
    padding: 2.5px;
    border-radius: 2.5px;
    width: 52.5px;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.paid-tag {
    border: 1px solid gray;
    box-sizing: border-box;
    padding: 5px;
    border-radius: 2.5px;
    font-weight: 600;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.item-selections-container {
    display: flex;
    flex-direction: column;
    font-size: 11px;
    font-weight: 600;
}

.selection {
    display: flex;
    flex-direction: column;
    margin: 0px 0px 5px 0px;
}

.selection div {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.selection div p {
    margin: 0px 5px 0px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
}

.submitted-confirmation {
    width: 75px;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    border-radius: 50%;
    box-sizing: border-box;
    padding: 25px;
    animation-name: bounceIn;
    animation-duration: 1000ms;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

.submitted-confirmation img {
    max-width: 50px;
    max-height: 50px;
}

@keyframes bounceIn{
  0%{
    opacity: 0;
    transform: scale(0.3) translate3d(0,0,0);
  }
  50%{
    opacity: 0.9;
    transform: scale(1.1);
  }
  80%{
    opacity: 1;
    transform: scale(0.89);
  }
  100%{
    opacity: 1;
    transform: scale(1) translate3d(0,0,0);
  }
}

@media(max-width: 800px) {

    .order-row-basic-info {
        width: 40%;
    }

    .item-price {
        width: 30%;
    }

    .item-quantity {
        width: 30%;
    }

    .headers p {
        font-size: 9px;
    }

    .item-name {
        font-size: 14px;
    }

    .item-price {
        font-size: 14px;
    }

    .item-quantity {
        font-size: 11px;
        align-items: flex-start;
    }

    .order-button {
        width: 15px;
        height: 15px;
    }

    .item-note {
        font-size: 11px;
    }

    .headers p:first-child {
        width: 40%;
    }

    .headers p:nth-child(2), .headers p:nth-child(3) {
        width: 30%;
        justify-content: center;
    }
}

@media(max-width: 600px) {
    .order-header h1 {
        flex-direction: column-reverse;
        align-items: flex-start;
    }

    .menu-box {
        margin-bottom: 10px;
    }

    .order-header h1 div {
        border: 0px;
    }
}

</style>