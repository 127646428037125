<template>
  <form @submit.prevent="createAdminUserRelationship" class="create-admin-relationship-form">
    <div class="form-group">
      <label for="outlet_collection_id">Outlet Collection ID:</label>
      <input id="outlet_collection_id" v-model="outlet_collection_id" required>
    </div>
    <div class="form-group">
      <label for="user_id">User ID:</label>
      <input id="user_id" v-model="user_id" required>
    </div>
    <button type="submit">Create Admin User Relationship</button>
  </form>
</template>

<script>
export default {
  data() {
    return {
      outlet_collection_id: '',
      user_id: ''
    }
  },
  methods: {
    createAdminUserRelationship() {
      const url = `/admin_user_relationships/${this.outlet_collection_id}/${this.user_id}`;
      this.$http.secured.post(url)
        .then(response => {
          console.log('Admin User Relationship created successfully');
          this.$store.commit('setMessage', {
            content: "Admin User Relationship created successfully",
            error: false
          });
          this.$emit('relationshipCreated');
          // You might want to do something with the response here
        })
        .catch(error => {
          console.error(error);
          this.$store.commit('setMessage', {
            content: "Failed to create Admin User Relationship. Valid outlet collection ID and user ID are required.",
            error: true
          });
        });
    }
  }
}
</script>

<style scoped>
.create-admin-relationship-form {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.form-group input {
  width: 100%;
  margin-top: 5px;
}
</style>