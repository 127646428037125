<template>
  <div class="container">
    <h1>Current Outlet Collections</h1>
    <table>
      <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="collection in outlet_collections" :key="collection.id">
          <td>{{ collection.id }}</td>
          <td>{{ collection.name }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    outlet_collections: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>
.container {
  width: 100%;
  margin: auto;
  margin-top: 100px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

table th,
table td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: left;
}
</style>