<template>

    <div id="home-navbar">

        <div class="account-refer">

            <p v-html="$homepageTranslations.call_to_action[$store.getters.getDefaultLanguage]"></p>

            <p v-html="$homepageTranslations.sign_up[$store.getters.getDefaultLanguage]" @click="redirectTo('sign_up')" class="sign-up-button"></p>

        </div>

        <div class="container" :class=" { 'sticky' : sticky_navbar === true, 'transparent' : transparent_navbar === true }">

            <img src="@/assets/homepage/menulingua_logo_v2-01.svg" class="logo" :class="{ 'small-logo' : sticky_navbar === true }" @click="$router.push('/')" />

            <div class="filler"></div>

            <div class="quicklinks">

                <p @click="redirectTo('features')" v-html="$homepageTranslations.features_text[$store.getters.getDefaultLanguage]" :class="{ 'selected' : getPath('/features') }"></p>

                <p @click="redirectTo('pricing')" v-html="$homepageTranslations.navbar_pricing[$store.getters.getDefaultLanguage]" :class="{ 'selected' : getPath('/pricing') }"></p>

                <p @click="redirectTo('contact')" v-html="$homepageTranslations.contact_us_navbar[$store.getters.getDefaultLanguage]" :class="{ 'selected' : getPath('/contact') }"></p>

            </div>

            <div class="sign-in-button-container" @click="redirectTo('/login')">

                <img src="@/assets/homepage/sign-in-icon.svg" />

                <p v-html="$homepageTranslations.sign_in[$store.getters.getDefaultLanguage]"></p>

            </div>

            <div class="dropdown-button" :class="{ 'dropdown-button-toggled' : (show_mobile_dropdown === true) }" @click="showMobileDropdown()">

                <span></span>

                <span></span>

                <span></span>

            </div>

        </div>

        <DropdownNavbar v-if="show_mobile_dropdown === true" v-on:close-dropdown="showMobileDropdown()" :sticky_navbar="sticky_navbar" />

    </div>

</template>

<script>
import DropdownNavbar from '@/components/home/DropdownNavbar.vue'

export default {
    name: "HomeNavbar",
    components: {
        DropdownNavbar
    },
    props: ["sticky_navbar", "transparent_navbar"],
    data () {
        return {
            show_mobile_dropdown: false
        }
    },
    methods: {
        redirectTo(route) {
            this.$router.push(route)
        },
        showMobileDropdown() {
            this.show_mobile_dropdown = !this.show_mobile_dropdown
            this.$store.commit('setDropdownShown', this.show_mobile_dropdown);
        },
        getPath(path) {
            if (this.$router.currentRoute.path === path) {
                return true
            } else {
                return false
            }
        }
    }
}





</script>

<style scoped>

#home-navbar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100000;
}

.container {
    background-color: #3B3B3A;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 0px 5% 0px 5%;
    position: relative;
    height: 70px;
    transition: 0.2s;
}

.sticky {
    height: 50px;
    transition: 0.2s;
    background-color: #3B3B3A !important;
}

p {
    margin-top: 0px;
    margin-bottom: 0px;
}

.account-refer {
    font-family: "Cabin", sans-serif;
    background-color: #158A44;
    color: white !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1.5px;
    box-sizing: border-box;
    padding: 10px 0px 10px 0px;
    height: 35px;
    font-size: 14px;
}

.sign-up-button {
    text-transform: none;
    box-sizing: border-box;
    padding: 1.5px 0px 1.5px 0px;
    border-bottom: 1px solid white;
    cursor: pointer;
    margin-left: 15px;
}

.logo {
    width: 30%;
    max-width: 300px;
    transition: 0.2s;
    cursor: pointer;
    /* min-height: 100px; */
}

.quicklinks {
    font-family: "Montserrat", sans-serif;
    display: flex;
    flex-direction: row;
    color: white;
    font-weight: 600;
    align-items: center;
}

.quicklinks p {
    cursor: pointer;
    margin-right: 40px;
    box-sizing: border-box;
    padding: 2.5px 0px 2.5px 0px;
}

.sign-in-button-container {
    background-color: white;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    padding: 5px 10px 5px 10px;
    border-radius: 2.5px;
    cursor: pointer;
}

.sign-in-button-container img {
    width: 15px;
    height: 15px;
    margin-right: 5px;
}

.sign-in-button-container p {
    font-weight: 600;
    font-size: 12.5px;
}

.dropdown-button {
    height: 25px;
    width: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 40px;
    cursor: pointer;
}

.dropdown-button span {
    background-color: #8CC63E;
    width: 100%;
    height: 4px;
    border-radius: 2.5px;
}

.dropdown-button-toggled span {
    background-color: #959695;
}

.small-logo {
    width: 200px;
    transition: 0.2s;
}

.transparent {
    background-color: rgba(0, 0, 0, 0);
}

.selected {
    /* border-bottom: 1px solid white; */
    font-weight: 800;
}

@media(max-width: 800px) {
    .quicklinks {
        display: none;
    }

    .logo {
        width: 200px;
    }

    .container {
        padding: 5px 5% 5px 2.5%;
    }

    .account-refer p {
        font-size: 10px;
    }
}

@media(max-width: 400px) {
    .logo {
        width: 150px;
    }

}

</style>