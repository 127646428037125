
<template>

    <div id="order-info-container">

        <div v-if="is_loading == true">

            <transition name="fade">

                <div class="loader">
                    
                    <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>

                    <!-- <Submitting /> -->

                </div> 

            </transition>

        </div>

        <div v-if="is_submitting == true">

            <transition name="fade">

                <div class="loader">
                    
                    <Submitting />

                </div> 

            </transition>

        </div>

        <div v-else>

            <div class="back-button" @click="backToMenu()">

                <img src="@/assets/arrow_black.svg" />

            </div>
            

            <SelectOrderType :language="language" :menu="menu" v-on:order-type="selectOrderType" v-on:back-to-menu="backToMenu()" />


            <div class="order-rows-container">
                    
                <div class="headers">

                    <p>{{ this.$translations.item[language] || this.$translations.item['en'] }}</p>

                    <p>{{ this.$translations.price[language] || this.$translations.price['en'] }}</p>

                    <p>{{ this.$translations.quantity[language] || this.$translations.quantity['en']  }}</p>

                </div>

                <div class="order-row" v-for="order_row in findUnique(order_rows)" :key="order_row.id">

                    <div class="order-row-basic-info">

                        <p class="item-name">{{ getItemName(order_row.food) }}</p>

                        <p v-if="order_row.item_price != order_row.final_price" class="item-original-price"><NumberFormat :number="order_row.item_price" :currency="currency" /></p>

                        <p v-if="order_row.item_unit !== null && order_row.item_unit != 'none'" class="item-selections">{{ order_row.item_unit }}</p>

                        <div v-for="selection in order_row.selections" :key="selection.id">
                            
                            <p v-if="order_row.selections !== null" class="item-selections">
                                {{ selection[`name_${language}`] || selection[`name`] || selection[`name_en`] }} 
                                <span v-if="Number(selection.additional_fee) != null && Number(selection.additional_fee) != 0">
                                    +<NumberFormat :number="Number(selection.additional_fee)" :currency="currency" />
                                </span>
                            </p>

                        </div>

                        <div class="item-note" @click="show_note_input = true, selected_item = order_row" :class="{ 'no-note' : order_row.note == null || order_row.note == '' }">
                            <img src='@/assets/note_icon.svg' />
                            {{ order_row.note }}
                        </div>

                        <transition name="fade">

                            <AddNote v-if="show_note_input === true && JSON.stringify(selected_item) === JSON.stringify(order_row)" :order_row="order_row" :language="language" :note="order_row.note" :name="getItemName(order_row.food)" v-on:close-modal="hideNoteInput()" />

                        </transition>

                    </div>

                    <p v-if="order_row.item_price !== null" class="item-price"><NumberFormat :number="order_row.final_price" :currency="currency" /></p>
                    
                    <div class="item-quantity">
                        <div class="order-button" :style="{ 'border-color' : 'black' }" @click="removeFromOrder(order_row, order_row.item_unit, order_row.selections)">
                            -
                        </div>

                        <div>{{ order_row.item_quantity }}</div>

                        <div class="order-button" :style="{ 'border-color' : 'black' }" @click="addToOrder(order_row.food, order_row.item_price, order_row.item_unit, menu.id, order_row.selections)">
                            +
                        </div>
                        

                    </div>

                </div>

            </div>

            <PriceBox :order_rows="order_rows" :menu="menu" :currency="currency" :language="language" :delivery_fee="menu.delivery_fee" :order_type="order_type" :key="componentKey" v-on:subtotal-calculation="getSubtotal" />
 
            <OrderUserInfo :order_type="order_type" :language="language" :currency="currency" :order_rows="order_rows" :menu="menu" :subtotal="subtotal" v-on:submit-order="isSubmitting()" v-on:resubmitting="isSubmitting()" />
 
        </div>    
    
    </div>


</template>

<script>
import NumberFormat from '@/components/NumberFormat.vue'
import SelectOrderType from '@/components/SelectOrderType.vue'
import PriceBox from '@/components/PriceBox.vue'
import AddNote from '@/components/AddNote.vue'
import OrderUserInfo from '@/components/OrderUserInfo.vue'
import Submitting from '@/components/Submitting.vue'

export default {
    name: 'NewOrder',
    components: {
        NumberFormat,
        SelectOrderType,
        PriceBox,
        AddNote,
        OrderUserInfo,
        Submitting
    },
    data () {
        return {
            order_rows: [],
            language: '',
            foods: [],
            currency: '',
            menu: [],
            order_type: '',
            componentKey: 0,
            selected_item: {},
            show_note_input: false,
            subtotal: Number,
            is_loading: false,
            is_submitting: false
        }
    },
    // beforeCreate() {
    //     this.checkForPendingOrder()
    // },
    created() {
        this.scrollToTop();
        this.getOrder();
        this.language = localStorage.language
        this.currency = localStorage.currency
        this.getFoods();
        this.getMenu();
        this.getSubtotal
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0,0);
        },
        isLoading() {
            this.is_loading = true
        },
        isSubmitting() {
            this.is_loading = false
            this.is_submitting = true
        },
        getSubtotal: function(subtotal) {
            this.subtotal = subtotal
        },
        getFoods() {
            if (this.$store.getters.getFoods.length === 0) {
                var foods = localStorage.getItem('foods');
                var parsedFoods = JSON.parse(foods)
                var foods_array = []
                // retrieve foods from last viewed menu
                for (let i = 0; i < parsedFoods.length; i++) {
                    foods_array.push(parsedFoods[i])
                }
                this.$store.commit('setFoods', foods_array)
            }
            this.foods = this.$store.getters.getFoods;
        },
        getMenu() {
            this.menu = JSON.parse(localStorage.menu)
        },
        getOrder() {
            if (this.$store.getters.getOrder.length === 0) {
                if (localStorage.getItem('order') === null) {
                    if (localStorage.menu != null) {
                        var menu = JSON.parse(localStorage.menu)
                        this.$router.replace(`/${menu.id}`)
                    } else {
                        window.location.replace('https://menulingua.com')
                    }
                }
                var order = localStorage.getItem('order');
                var parsedOrder = JSON.parse(order)
                for (let i = 0; i < parsedOrder.length; i++) {
                    var item = { 'food': parsedOrder[i].food, 'item_price': parsedOrder[i].item_price, 'item_unit': parsedOrder[i].item_unit, 'menu': parsedOrder[i].menu, 'note': parsedOrder[i].note, 'selections': parsedOrder[i].selections }
                    this.$store.commit('addToOrder', item)
                }
            }
            this.order_rows = this.$store.getters.getOrder;
        },
        selectOrderType: function(type) {
            this.order_type = type
            this.componentKey++
        },
        findUnique(array) {
            const result = [];
            const map = new Map();
            for (const item of array) {
                let quantity = 0
                let food = item.food
                let item_unit = item.item_unit
                let selections = item.selections
                let selections_prices = 0
                for (let i = 0; i < selections.length; i++) {
                    selections_prices = selections_prices + Number(selections[i].additional_fee)
                }
                let final_price = item.item_price + selections_prices
                for (let i = 0; i < array.length; i++) {
                    if ((array[i].food === item.food) && (array[i].item_unit === item.item_unit) && (JSON.stringify(array[i].selections)==JSON.stringify(item.selections))) quantity++;
                }
                
                // note = note.filter(n => n)
                if(!map.has(item.food.id + item.item_unit + JSON.stringify(item.selections))) {
                    map.set((item.food.id + item.item_unit + JSON.stringify(item.selections)), true); // set any value to Map
                    result.push({
                        food: item.food,
                        item_quantity: quantity,
                        selections: selections,
                        item_price: item.item_price,
                        final_price: final_price,
                        note: item.note,
                        item_unit: item.item_unit,
                    });
                }
            }
            return result
        },
        getItemName(food) {
            return food['translation_' + this.language] || food['name'] || food['translation_en']
        },
        addToOrder(food, item_price, item_unit, menu, selections) {
            var item = { 'food': food, 'item_price': item_price, 'item_unit': item_unit, 'menu': menu, 'selections': selections }
            this.$store.commit('addToOrder', item)
            this.$store.commit('setFoods', this.foods)
            this.show_price_selector = false
            this.selected_price = Number;
            this.selected_unit = '';
            localStorage.setItem('menu', JSON.stringify(this.menu));
            this.componentKey += 1;
        },
        removeFromOrder(food, unit, selections) {
            this.$store.commit('removeFromCheckoutPage', food, unit, selections)
            this.componentKey += 1;
            if (this.$store.getters.getOrder.length === 0) {
                this.$router.push({ path: `/${this.menu.id}/${this.language}` })
            }
        },
        hideNoteInput() {
            this.show_note_input = false
        },
        backToMenu() {
            this.$router.push({ path: `/${this.menu.id}/${this.language}` })
        }
    }
}


</script>

<style scoped>

#order {
    min-height: 80vh;
}

#order-info-container {
    max-width: 700px;
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.order-rows-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid lightgray;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 25px;
}

.order-row-basic-info {
    display: flex;
    flex-direction: column;
    width: 60%;
    box-sizing: border-box;
    padding-right: 10%;
}

.item-name {
    font-size: 15px;
    margin: 0px 0px 5px 0px;
    font-weight: 600;
}

.item-selections {
    font-size: 12px;
    margin: 0px 0px 5px 0px;
    display: flex;
    white-space: nowrap;
    /* align-items: center; */
}

.item-selections span {
    display: flex;
    align-items: center;
    margin-left: 5px;

}


.headers {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    justify-content: space-between;
}

.headers p {
    font-size: 11px;
    text-transform: uppercase;
    margin: 0px 0px 0px 0px;
    font-weight: 400;
    display: flex;
    /* border: 1px dotted lightgray; */
}

.headers p:first-child {
    width: 60%;
}

.headers p:nth-child(2), .headers p:nth-child(3) {
    width: 20%;
    justify-content: center;
}

.order-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0px 0px 20px 0px;
}

.item-price {
    width: 20%;
    margin-top: 0px;
    /* border: 1px dotted lightgray; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 13px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;

}

.item-quantity {
    width: 20%;
    margin-top: 0px;
    height: 20px;
    /* border: 1px dotted lightgray; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 13px;
    font-weight: 600; 
    align-items: center;
}



.quantity-box {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100px;
    align-items: center;
    justify-content: space-between;
}

.order-button {
    border-width: 1px;
    border-style: solid;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    /* align-self: flex-end; */
    border-radius: 2.5px;
    cursor: pointer;
    /* stroke: 1px  */
}

.back-button {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    /* border: 1px dotted lightgray; */
}

.back-button img {
    width: 30px;
}

.item-note {
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    font-size: 12px;
}

.item-note img {
    max-height: 15px;
    max-width: 15px;
    margin-right: 5px;
}

.no-note {
    opacity: 0.3;
}

.loader {
    min-height: 80vh;
}

.item-original-price {
    font-size: 11px;
    font-weight: 600;
    color: gray;
    margin: 0px 0px 10px 0px;
}

@media(max-width: 800px) {

    .order-row-basic-info {
        width: 40%;
    }

    .item-price {
        width: 30%;
    }

    .item-quantity {
        width: 30%;
    }

    .headers p {
        font-size: 9px;
    }

    .item-name {
        font-size: 13px;
    }

    .item-price {
        font-size: 13px;
    }

    .item-quantity {
        font-size: 11px;
        align-items: flex-start;
    }

    .order-button {
        width: 15px;
        height: 15px;
    }

    .item-note {
        font-size: 11px;
    }

    .headers p:first-child {
        width: 40%;
    }

    .headers p:nth-child(2), .headers p:nth-child(3) {
        width: 30%;
        justify-content: center;
    }
}


</style>