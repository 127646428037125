<template>

   <div class="discounts">

        <h1>Discounts</h1>

        <Discount
            v-for="discount in discounts"
            :discount="discount"
            :active="discount.active"
            v-on:activate-discount="activateDiscount(discount)"
            v-on:deactivate-discount="deactivateDiscount(discount)"
            v-on:delete-discount="deleteDiscount(discount)"
            v-bind:currency="currency"
            :key="discount.id">
        </Discount>

        <router-link :to="{ path: '/' + this.$router.currentRoute.params.id + '/new_discount' }" class="create">Create a discount</router-link> 

    </div>

</template>

<script>
import Discount from '@/components/orders/Discount'

export default {
    name: 'Discounts',
    props: ['currency'],
    components: {
        Discount
    },
    data () {
        return {
            discounts: []
        }
    },
    created() {
        this.getDiscounts()
    },
    methods: {
        getDiscounts() {
            let menu_id = this.$router.currentRoute.params.id;
            this.$http.secured.get('/app_get_discounts/' + menu_id)
                .then(response => { 
                    this.discounts = response.data.discounts
                })
                .catch(error => this.setError(error, 'Something went wrong'))            
        },
        activateDiscount(discount) {
            this.$http.secured.post('/discounts/' + discount.id + '/activate') 
             .then(response => { 
                discount.active = true
            })
            .catch(error => this.setError(error, 'Something went wrong'))
        },
        deactivateDiscount(discount) {
            this.$http.secured.post('/discounts/' + discount.id + '/deactivate') 
             .then(response => { 
                discount.active = false
            })
            .catch(error => this.setError(error, 'Something went wrong'))
        },
        deleteDiscount(discount) {
           this.$http.secured.delete('/discounts/' + discount.id + '/destroy') 
            .then(response => { 
                this.discounts = this.discounts.filter((e)=>e.id !== discount.id )
            })
            .catch(error => this.setError(error, 'Something went wrong'))
        }
    }
}


</script>

<style scoped>

.discounts {
    box-sizing: border-box;
    padding-top: 15px;
    border-top: 1px solid lightgray;
    margin-top: 30px;
}

h1 {
    font-weight: 600;
    font-size: 17.5px;
}

.create {
    border: 1px solid #1b75bb;
    color: #1b75bb;
    box-sizing: border-box;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-size: 12.5px;
    font-weight: 600;
    margin-top: 20px;
}

.create:hover {
    background-color: #1b75bb;
    color: white;
    transition: 0.2s;
}

</style>