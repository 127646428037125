<template>

    <div class="back-button-standard">

        <img src="@/assets/arrow_black.svg" />

        {{ text }}



    </div>



</template>


<script>

export default {
    name: 'BackButton',
    props: ['text']
}



</script>

<style scoped>

.back-button-standard {
    background-color: rgba(140, 198, 62, 0.5);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 10px 40px 10px 40px;
    cursor: pointer;
    font-size: 13.5px;
    font-weight: 600;
    border-radius: 5px;
    margin-bottom: 20px;
}

.back-button-standard img {
    width: 30px;
    height: 30px;
    margin-right: 30px;
}


</style>