<template>

    <div id="beautiful-menus">

        <h3 v-html="$homepageTranslations.beautiful_menus.header[$store.getters.getDefaultLanguage]" v-if="counter === 0"></h3>

        <h3 v-html="$homepageTranslations.beautiful_menus.header_two[$store.getters.getDefaultLanguage]" v-if="counter === 1"></h3>

        <Menu :name="menus[counter].name" :location="menus[counter].location" :logo="menus[counter].logo" :image="menus[counter].image" />

    </div>



</template>


<script>
import Menu from '@/components/home/menus/Menu.vue'

export default {
    name: "BeautifulMenus",
    components: {
        Menu
    },
    data () {
        return {
            counter: 0,
            menus: [
                {
                    name: "The Shore at <span class='bold'>Hilton Bali Resort</span>",
                    location: "Bali, Indonesia",
                    logo: "hilton_logo.png",
                    image: "hilton_qr_menu.png"
                },
                {
                    name: "<span class='bold'>Mama Lou's</span> Italian Kitchen",
                    location: "Manila, Philippines",
                    logo: "mamalous_logo.png",
                    image: "mamalous_qr_menu.png"
                }
            ]
        }
    },
    created() {
        this.iterate();
    },
    methods: {
        iterate() {
            let counter = this.counter
            setTimeout(() => {
                console.log('iterate')
                if (counter < 1) {
                    this.counter++
                } else {
                    this.counter = 0
                }
                this.iterate();
            }, 5000)    
        }
    }
}




</script>

<style scoped>

#beautiful-menus {
    background-color: #FAFBFB;
    display: flex;
    flex-direction: column;
    margin-top: 100px;
}

h3 {
    font-weight: 400;
    text-align: center;
    font-size: 40px;
    transition: 0.2s;
    animation: fadeIn 0.5s ease-in;
}

@keyframes fadeIn {
    0% {opacity: 0;}
    100% { opacity: 1;}
}

::v-deep .heavy {
    font-weight: 700;
}

::v-deep .blue {
    color: #454D73;
}

::v-deep .red {
    color: rgb(148, 26, 29);
}

@media(max-width: 600px) {
    h3 {
        font-size: 30px;
    }
}

</style>