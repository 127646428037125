<template>
    
    
    <div id="menus">

        <div v-if="is_loading === true" div class="loader">

            <div class="lds-ripple"><div></div><div></div></div>

        </div>

        <div v-else>

            <div class="overlay">

                <img src="@/assets/banner-overlay.svg" />

            </div>

            <AccountStatus :user_type="user_type" :admin_or_dashboard_user="admin_or_dashboard_user" :currentUser="currentUser" :email="email" :latest_subscription_status="latest_subscription_status" :current_plan_expiry_date="current_plan_expiry_date" :cancellation_requested="cancellation_requested" :subscription_payment_provider="subscription_payment_provider" :key="componentKey" />

            <div class="account-info" @click="goToAccountInfo()">
                <img src="@/assets/account_icon.svg"/>
                <p>{{ email }}</p>
                <div class="admin-user" v-if="user_type === 'admin'">Admin</div>
            </div>


            <div class="selector-container">

                <div class="selector" :class="{ 'selector-selected' : menus_selected === true }" @click="select('menus')">My menus</div>

                <div class="selector" :class="{ 'selector-selected' : outlet_collections_selected === true }" @click="select('outlet collections')" v-if="outlet_collections.length > 0 && outlet_collections != null">My outlet collections</div>

                <div class="selector" :class="{ 'selector-selected' : admin_menus_selected === true }" @click="select('admin_menus')" v-if="user_type === 'admin'">Menus • Admin</div>

                <div class="selector" :class="{ 'selector-selected' : admin_outlet_collections_selected === true }" @click="select('admin_outlet_collections')" v-if="user_type === 'admin'">Outlet Collections • Admin</div>

            </div>


            <MyMenus :menus="menus" v-if="menus_selected === true || admin_menus_selected === true" :user_type="user_type" :url="url" v-on:increase-max-count="increaseMaxCount()" :can_create_menus="can_create_menus" />

            <MyOutletCollections :outlet_collections="outlet_collections" v-if="outlet_collections_selected === true || admin_outlet_collections_selected === true" :user_type="user_type" :url="url" />

        </div>

        <ContactUsReferral />
        
    </div>





</template>

<script>
import AccountStatus from '@/components/AccountStatus.vue'
import MyMenus from '@/components/MyMenus.vue'
import MyOutletCollections from '@/components/MyOutletCollections.vue'
import ContactUsReferral from '@/components/user/ContactUsReferral.vue'

export default {
    name: "Menus",
    components: {
        AccountStatus,
        MyMenus,
        MyOutletCollections,
        ContactUsReferral
    },
    data () {
        return {
            url: process.env.VUE_APP_API_URL,
            user_type: '',
            currentUser: '',
            email: '',
            admin_or_dashboard_user: Boolean,
            latest_subscription_status: '',
            current_plan_expiry_date: '',
            cancellation_requested: Boolean,
            componentKey: 0,
            menus_selected: false,
            outlet_collections_selected: false,
            admin_menus_selected: false,
            subscription_payment_provider: '',
            admin_outlet_collections_selected: false,
            can_create_menus: Boolean,
            menus: [],
            outlet_collections: [],
            count: 25,
            is_loading: true
        }
    },
    created() {
        this.scrollToTop();
        if (localStorage.signedIn) {
            this.$http.secured.get('/app_current_user')
                .then(response => { 
                    this.currentUser = response.data.user,
                    this.user_type = response.data.user_type,
                    this.email = response.data.email,
                    this.admin_or_dashboard_user = response.data.admin_or_dashboard_user,
                    this.latest_subscription_status = response.data.latest_subscription_status,
                    this.current_plan_expiry_date = response.data.current_plan_expiry_date,
                    this.cancellation_requested = response.data.cancellation_requested,
                    this.subscription_payment_provider = response.data.subscription_payment_provider,
                    this.can_create_menus = response.data.can_create_menus,
                    this.$store.commit('setLoggedIn', response.data.user)
                    this.$store.commit('setUserType', response.data.user_type)
                    this.$store.commit('setUserEmail', response.data.email)
                    this.componentKey++
                    this.getSelected();
                    this.getMenus();
                })
            .catch(error => this.setError(error, 'Something went wrong'))
        } else {
            this.$router.replace('/login')
        }
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0,0);
        },
        getSelected() {
            if (localStorage.selected != undefined) {
                this.select(localStorage.selected)
            } else {
                this.menus_selected = true
            }
        },
        getMenus() {
            this.$http.secured.get(`/app_retrieve_menus/${this.count}`)
            .then(response => { 
                this.menus = response.data.menus
                if (response.data.outlet_collections != null) {
                    this.outlet_collections = response.data.outlet_collections
                }
                this.is_loading = false
            }).catch(error => this.setError(error, 'Something went wrong'))
        },
        goToAccountInfo() {
            this.$router.push('/account')
        },
        increaseMaxCount() {
            this.count = 1000
            this.is_loading = true
            if (this.menus_selected === true) {
                this.$http.secured.get(`/app_retrieve_menus/${this.count}`)
                .then(response => { 
                    this.menus = response.data.menus
                    if (response.data.outlet_collections != null) {
                        this.outlet_collections = response.data.outlet_collections
                    }
                    this.is_loading = false
                }).catch(error => this.setError(error, 'Something went wrong'))
            } 
            if (this.admin_menus_selected === true) {
                this.$http.secured.get(`/app_retrieve_menus_a/${this.count}`)
                .then(response => { 
                    this.menus = response.data.menus
                    this.outlet_collections = response.data.outlet_collections
                    this.is_loading = false
                }).catch(error => this.setError(error, 'Something went wrong'))
            }
        },
        goToDashboard() {
            window.location.replace(this.url)
        },
        select(selector) {
            if (selector === 'menus') {
                localStorage.selected = 'menus'
                if (this.admin_menus_selected === true) {
                    this.getMenus();
                }
                this.menus_selected = true
                this.outlet_collections_selected = false
                this.admin_menus_selected = false
                this.admin_outlet_collections_selected = false
                
            } 
            if (selector === 'outlet collections') {
                localStorage.selected = 'outlet collections'
                this.outlet_collections_selected = true
                this.menus_selected = false
                this.admin_menus_selected = false
                this.admin_outlet_collections_selected = false
            }
            if (selector === 'admin_menus') {
                localStorage.selected = 'admin_menus'
                this.is_loading = true
                this.admin_menus_selected = true
                this.menus_selected = false
                this.outlet_collections_selected = false
                this.admin_outlet_collections_selected = false
                this.$http.secured.get(`/app_retrieve_menus_a/${this.count}`)
                .then(response => { 
                    this.menus = response.data.menus
                    // this.outlet_collections = response.data.outlet_collections
                    this.is_loading = false
                }).catch(error => this.setError(error, 'Something went wrong'))                
            }
            if (selector === 'admin_outlet_collections') {
                localStorage.selected = 'admin_outlet_collections'
                this.is_loading = true
                this.admin_menus_selected = false
                this.menus_selected = false
                this.outlet_collections_selected = false
                this.admin_outlet_collections_selected = true
                this.$http.secured.get('/app_retrieve_outlet_collections_a')
                .then(response => { 
                    this.outlet_collections = response.data.outlet_collections
                    this.is_loading = false
                }).catch(error => this.setError(error, 'Something went wrong'))                
            }
        }
    }
}


</script>

<style scoped>

#menus {
    width: 100%;
    min-height: 80vh;
    box-sizing: border-box;
    /* padding: 50px 20px 50px 20px; */
    display: flex;
    position: relative;
    flex-direction: column;
    margin-top: 75px;
}

.loader {
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.overlay {
    /* position: absolute; */
    /* left: 0; */
    /* top: 0; */
}

.selector-container {
    width: 95%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
    margin-top: 50px;
    position: relative;
    z-index: 1;
}

.selector {
    width: max-content;
    font-size: 14px;
    margin: 0px 20px 0px 0px;
    box-sizing: border-box;
    padding: 5px 10px 5px 10px;
    cursor: pointer;
}

.selector-selected {
    background-color: #F1F1F2;
    border-left: 2px solid #8EC448;
    font-weight: 600;
}

@media(max-width: 700px) {

    .selector-container {
        flex-direction: column;
    }

    .selector {
        font-size: 12.5px;
        font-weight: 600;
        margin: 0px 0px 20px 0px;
    }
}

</style>