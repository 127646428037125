<template>

    <div class="submitting-container">

        <div class="icon first-icon">

            <img src="@/assets/new_phone.svg" />

            <div class="icon-backdrop"></div>

        </div>

        <div class="line">
            <div class="second-line"></div>
            <div class="third-line"></div>
        </div>

        

        <div class="icon second-icon">

            <img src="@/assets/order_plate.svg" />

            <div class="icon-backdrop"></div>

        </div>


    </div>



</template>

<script>

export default {
    name: 'Submitting'
}



</script>

<style scoped>

.submitting-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    max-width: 337.5px;
    margin: auto;
}

.icon {
    width: 50px !important;
    height: 50px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    border-radius: 50%;
    margin: 0px 25px 0px 25px;
    opacity: 0;
    animation-name: bounceIn;
    animation-duration: 500ms;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    position: relative;
}

.icon-backdrop {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    animation-name: bounceInDontBounceOut;
    animation-iteration-count: infinite;
    border: 1px solid rgba(0, 0, 0, 0.1);
    animation-duration: 1000ms;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    position: absolute;
}

.icon img {
    width: 25px;
    height: 25px;
}

.second-icon {
    animation-delay: 1s;
}

.line {
    /* width: 200px; */
    border-bottom: 1px dotted lightgray;
    position: absolute;
    animation-delay: 500ms;
    animation-duration: 500ms;
    animation-name: moveLine;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    position: relative;
    /* width: 200px; */
}

.second-line {
    /* width: 200px; */
    border-bottom: 1px dotted gray;
    position: absolute;
    animation-delay: 2000ms;
    animation-duration: 500ms;
    animation-name: moveSecondLine;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    top: 0;
    left: 0;
    /* width: 200px; */
}

.third-line {
    /* width: 200px; */
    border-bottom: 1px solid black;
    position: absolute;
    animation-delay: 3000ms;
    animation-duration: 500ms;
    animation-name: moveSecondLine;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    top: 0;
    left: 0;
    /* width: 200px; */
}

@keyframes moveLine {
  0% {
    /* left: 0; */
    width: 0px;
  }
  100% {
      /* left: 100%; */
      width: 125px;
  }
}

@keyframes moveSecondLine {
  0% {
    /* left: 0; */
    width: 0px;
  }
  100% {
      /* left: 100%; */
      width: 125px;
  }
}

@keyframes bounceInDontBounceOut{
  0%{
    opacity: 1;
    transform: scale(1) translate3d(0,0,0);
    display: flex;
  }
  50%{
    opacity: 0.9;
    transform: scale(1.1);
    display: flex;
  }
  80%{
    opacity: 1;
    transform: scale(0.89);
    display: flex;
  }
  100%{
    opacity: 1;
    transform: scale(1) translate3d(0,0,0);
    display: flex;
  }
}

@keyframes bounceIn{
  0%{
    opacity: 0;
    transform: scale(0.3) translate3d(0,0,0);
    display: flex;
  }
  50%{
    opacity: 0.9;
    transform: scale(1.1);
    display: flex;
  }
  80%{
    opacity: 1;
    transform: scale(0.89);
    display: flex;
  }
  100%{
    opacity: 1;
    transform: scale(1) translate3d(0,0,0);
    display: flex;
  }
}



</style>