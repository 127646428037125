

const state = () => ({
    order: [],
    menu_id: Number,
    foods: [],
    currency: ''
})


const mutations = {
    addToOrder (state, item) {
        state.order.push(item)
        state.menu_id = item.menu
        localStorage.setItem('order', JSON.stringify(state.order));
        localStorage.setItem('ots', new Date())
    },
    setFoods (state, foods) {
        state.foods = foods
        localStorage.setItem('foods', JSON.stringify(state.foods))
    },
    addNote (state, item) {
        for(let i = 0; i < state.order.length; i++) {
            if (state.order[i].food === item.food && state.order[i].item_unit === item.item_unit && (JSON.stringify(state.order[i].selections) === JSON.stringify(item.selections))) {
                state.order[i] = item
            }
        }
        localStorage.setItem('order', JSON.stringify(state.order));
    },
    removeFromOrder (state, item) {
        var order = state.order
        var index = 0
        for(let i = 0; i < order.length; i++) {
            if (state.order[i].food.id === item.food.id) {
                index = i
            }
        }
        state.order.splice(index, 1)
        localStorage.setItem('order', JSON.stringify(state.order));
    },
    removeFromCheckoutPage (state, item, unit, selections) {
        var order = state.order
        var index = 0
        console.log(item)
        for(let i = 0; i < order.length; i++) {
            if (state.order[i].food.id === item.food.id && state.order[i].item_unit === item.item_unit && (JSON.stringify(state.order[i].selections) === JSON.stringify(item.selections))) {
                index = i
            }
        }
        state.order.splice(index, 1)
        localStorage.setItem('order', JSON.stringify(state.order));
    },
    clearOrder (state) {
        state.order = []
        localStorage.order = []
    }
}

const getters = {
    getOrder (state) {
        return state.order
    },
    getFoods (state) {
        return state.foods
    },
}

export default {
    state,
    getters,
    mutations
}