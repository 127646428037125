<template>
    
    <div id="header" :class="{ 'small-header' : scrolled_from_top === true }">

        <div class="nav-link" @click="$router.push('/')">
            <img class="logo" src='@/assets/logo/menulingua_logo_v2.svg' />
        </div>
        <div class="filler"></div>

        <div class="nav-link" @click="goToDashboard()" v-if="signedIn()" :class="{ 'blue' : getPath('/dashboard') }">
            <img class="nav-link-icon" src="@/assets/dashboard.svg" v-if="getPath('/dashboard') === false"/>
            <img class="nav-link-icon" src="@/assets/dashboard_blue.svg" v-if="getPath('/dashboard') === true" />
            <span v-if="scrolled_from_top === false">Dashboard</span>
        </div>
        
        <div class="nav-link" @click="goToMenus()" v-if="signedIn()" :class="{ 'blue' : getPath('/menus') }">
            <img class="nav-link-icon" src="@/assets/menus-black-new.svg" v-if="getPath('/menus') === false" />
            <img class="nav-link-icon" src="@/assets/menus-blue-new.svg" v-if="getPath('/menus') === true" />
            <span v-if="scrolled_from_top === false">Menus</span>
            
        </div>
        <div class="nav-link" @click="goToOrders()" v-if="signedIn()" :class="{ 'blue' : getPath('/orders') }">
            <img class="nav-link-icon" src="@/assets/order_plate_black.svg" v-if="getPath('/orders') === false" />
            <img class="nav-link-icon" src="@/assets/orders/order_plate_blue.svg" v-if="getPath('/orders') === true" />
            <span v-if="scrolled_from_top === false">Orders</span>
            <div class="active-orders" v-if="activeOrders()"></div>
        </div>

        <div class="nav-link" @click="signOut()" v-if="signedIn()">
            <img class="nav-link-icon" src="@/assets/sign-out-icon.svg"/>
            <span v-if="scrolled_from_top === false">Sign out</span>
        </div>
        
        <div class="nav-link" @click="signUp()" v-if="this.$store.getters.getCreatorMenu === true && this.$store.getters.getLoggedIn === false">
            <img class="nav-link-icon" src="@/assets/account_icon.svg"/>
            <span>Create account</span>
        </div>
    
    </div>


</template>


<script>
import { EventBus } from '@/event-bus.js';
const newOrderNotifSound = require("@/assets/orders/to-the-point.mp3");

export default {
    name: 'Header',
    props: ['user_id'],
    data () {
        return {
            url: process.env.VUE_APP_API_URL,
            scrolled_from_top: false,
            active_order_count: 0
        }
    },
    created() {
         window.addEventListener('scroll', this.handleScroll);
    },
    mounted() {
        EventBus.$on('order_received', orderReceived => {
            console.log("HEADER KNOWS ABOUT ORDER")
            this.active_order_count = this.active_order_count + 1
            localStorage.setItem('active_orders', true);
            var audio = new Audio(newOrderNotifSound); 
            audio.play();
        });
        EventBus.$on('help_request_received', helpRequestReceived => {
            this.active_order_count = this.active_order_count + 1
            localStorage.setItem('active_orders', true);
            var audio = new Audio(newOrderNotifSound); 
            audio.play();
        });
        EventBus.$on('order_seen', orderSeen => {
        if (this.active_order_count > 0) {
            console.log("ORDER SEEN")
            this.active_order_count = this.active_order_count - 1
            localStorage.removeItem('active_orders')
        }
        });
    },
    methods: {
        handleScroll (event) {
            var target = document.querySelector('#header');
            if (window.scrollY >= target.offsetHeight + 30) {
                this.scrolled_from_top = true
            } else if (window.scrollY < target.offsetHeight + 30) {
                this.scrolled_from_top = false
            }
        },
        signedIn() {
            // if (this.$store.getters.getLoggedIn === true || localStorage.signedIn === 'true') {
            if (this.$store.getters.getLoggedIn === true || localStorage.signedIn === 'true') {
                return true
            }
        },
        goToDashboard() {
            this.$router.push('/dashboard');
            // window.location.replace(this.url + `/user/${this.$store.getters.getUserId}/dashboard`)
            EventBus.$emit('clicked', this.signedIn);
        },
        activeOrders() {
            if (this.active_order_count > 0 || localStorage.active_orders) {
                return true
            }
        },
        goToMenus() {
            EventBus.$emit('clicked', this.signedIn);
            this.$router.push('/menus');
        },
        goToOrders() {
            // window.location.replace('https://orders.menulingua.com')
            this.$router.push('/orders')
            EventBus.$emit('clicked', this.signedIn);
        },
        signUp() {
            this.$router.push('/sign_up');
            EventBus.$emit('clicked', this.signedIn);
        },
        signOut () {
            this.$http.secured.delete('/app_session_delete')
            .then(response => {
                delete localStorage.token
                delete localStorage.csrf
                delete localStorage.signedIn
                delete localStorage.refresh
                this.$store.commit('setLoggedOut')
                EventBus.$emit('clicked', this.signedIn);
                this.$router.replace('/login')
            })
            .catch(error => this.setError(error, 'Cannot sign out'))
        },
        getPath(path) {
            if (this.$router.currentRoute.path === path) {
                return true
            } else {
                return false
            }
        }
    }
}


</script>


<style scoped>

#header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: rgba(255, 255, 255, 1);
    background-color: white;
    box-sizing: border-box;
    position: fixed;
    transition: 0.2s;
    top: 0;
    /* height: 100px; */
    height: 75px;
    z-index: 10000;
    border-bottom: 1px solid lightgray;
}

.small-header {
    height: 50px !important;
    transition: 0.2s;
    border-bottom: 0px !important;
}

.nav-link {
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 0px 20px 0px 20px;
    /* padding: 0px 20px 0px 20px; */
    /* border-right: 1px solid lightgray; */
    cursor: pointer;
    width: max-content;
    /* min-width: 200px; */
}

div:hover {
    background-color: #cfe3b1;
    transition: 0.2s;
}

.logo {
    width: 150px;
    height: auto;
}

.nav-link-icon {
    width: 25px;
    height: 25px;
}

p {
    font-size: 11.5px;
    font-weight: 400;
    /* text-transform: uppercase; */
    letter-spacing: 1px;
    margin-right: 20px;
    /* margin: 0px 0px 2.5px 0px; */
}

.nav-link span  {
    font-size: 11.5px;
    font-weight: 600;
    margin: 0px 5px 0px 5px;
}

.blue {
    color: #28A8E0;
    border-top: 2px solid rgba(0, 0, 0, 0);
    border-bottom: 2px solid #28A8E0;
}

.active-orders {
    width: 7.5px;
    height: 7.5px;
    border-radius: 50%;
    background-color: #9E1E62;
}

@media(max-width: 800px) {
    .nav-link span {
        display: none;
    }
}

@media(max-width: 500px) {
    .nav-link-icon {
        width: 15px !important;
        height: 15px !important;
    }
}

@media(max-width: 400px) {
    .logo {
        width: 75px;
    }
}


</style>