<template>

    <div class="overall-container">

        <HomeNavbar v-if="signed_in != true" :sticky_navbar="scrolled_from_top" />

        <div id="contact-us">
        

            <div v-if="is_loading == true" class="loader">
                
                <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>

            </div> 

            <div v-else>

                

                <div class="overlay">

                    <img src="@/assets/banner-overlay.svg" />

                </div>

                <img class="contact-img" src="@/assets/homepage/contact_icon.svg" />

                <h2>Get in touch</h2>

                <form @submit.prevent="sendMessage()">

                    <div class="field">

                        <label>Email</label>

                        <input type="email" v-model="email" :placeholder="'Email address'" required />

                    </div>

                    <div class="field new-field">

                        <label>Name</label>

                        <input v-model="name" />

                    </div>

                    <div class="field">

                        <label>Message</label>

                        <textarea v-model="message" maxlength="500" :placeholder="'Message content'" required />

                    </div>
    

                    <vue-hcaptcha sitekey="10000000-ffff-ffff-ffff-000000000001" @verify="can_submit = true" v-if="url === 'http://localhost:3000'"></vue-hcaptcha>
                    <vue-hcaptcha v-else sitekey="6a949cf2-a9f4-49f2-87c5-bdce28f4a2ec" @verify="can_submit = true"></vue-hcaptcha>

    
                    <button :class="{ 'can-submit' : can_submit === true }">

                        Send message

                    </button>


                </form>

            </div>

        </div>
    </div>

</template>


<script>
import VueHcaptcha from '@hcaptcha/vue-hcaptcha';
import HomeNavbar from '@/components/home/HomeNavbar.vue'

export default {
    name: "ContactUs",
    metaInfo: {
        title: "Contact us | Menulingua Digital Menus",
        // titleTemplate: null,
        meta: [
            {
                name: 'description',
                content: "Reach out to us regarding your digital menu. We offer menu translation, menu design, custom menu sites and more.",
                keywords: 'menu, digital, menu translation, translation, menu design, custom menu site'
            }
        ],
        htmlAttrs: {
        lang: 'en'
        },
        link: [
            {rel: 'canonical', href: 'https://menulingua.com/contact'}
        ]
    },
    components: {
        VueHcaptcha,
        HomeNavbar
    },
    data () {
        return {
            is_loading: true,
            email: "",
            message: "",
            name: "",
            can_submit: false,
            signed_in: Boolean,
            scrolled_from_top: false,
            url: process.env.VUE_APP_API_URL
        }
    },
    created() {
        this.getUserInfo();
        this.scrollToTop();
        this.checkSignedIn();
        window.addEventListener('scroll', this.handleScroll);
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0,0);
        },
        handleScroll (event) {
            var target = document.querySelector('#home-navbar');
            if (window.scrollY >= target.offsetHeight + 30) {
                this.scrolled_from_top = true
            } else if (window.scrollY < target.offsetHeight + 30) {
                this.scrolled_from_top = false
            }
        },
        checkSignedIn() {
            if (localStorage.signedIn) {
                this.signed_in = true
            } else {
                this.signed_in = false
            }
        },
        getUserInfo() {
            if (localStorage.signedIn) {
                this.$http.secured.get('/app_current_user')
                    .then(response => { 
                        this.email = response.data.email,
                        this.is_loading = false
                    })
                .catch(error => console.log(error))
            } else {
                this.is_loading = false
            }
        },
        canSubmit() {
            if (this.email != "" && this.message_content != "") {
                this.can_sumbit = true
            } else {
                this.can_submit = false
            }
        },
        sendMessage() {
            this.is_loading = true
            if (this.can_submit === true) {    
                if (localStorage.signedIn) {
                    this.$http.secured.post(`/app_create_secured_message/` , {
                            email: this.email,
                            content: this.message,
                            name: this.name
                        })
                        .then(response => { 
                            console.log('Sent successfully')
                            this.$store.commit('setMessage', { 
                                    content: "Message received! We'll get back to you via email."
                                }
                            )
                            this.$router.replace('/dashboard')
                            
                        }).catch(error => this.$store.commit('setMessage', { 
                                    content: "Failed to send message",
                                    error: true
                                },
                                this.is_loading = false
                        ))
                } else {
                    this.$http.plain.post(`/app_create_unsecured_message/` , {
                            email: this.email,
                            content: this.message,
                            name: this.name
                        })
                        .then(response => { 
                            console.log('Sent successfully')
                            this.$store.commit('setMessage', { 
                                    content: "Message received! We'll get back to you via email."
                                }
                            )
                            this.$router.replace('/')
                            
                        }).catch(error => this.$store.commit('setMessage', { 
                                    content: "Failed to send message",
                                    error: true
                                },
                                this.is_loading = false
                        ))
                }
            } else {
                this.is_loading = false,
                this.$store.commit('setMessage', { 
                        content: "Please complete all forms and the captcha.",
                        error: true
                    }
                )         
            }
        }
    }
}




</script>

<style scoped>

#contact-us {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: auto;
    max-width: 700px;
    margin-top: 200px;
}

form {
    width: 100%;
    margin: auto;
    /* margin-top: 100px; */
    z-index: 1000;
    display: flex;
    position: relative;
    z-index: 100;
    flex-direction: column;
}

input {
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    box-sizing: border-box;
    border: 0px;
    padding: 10px 0px 10px 0px;
    border-bottom: 1px solid rgba(200, 200, 200, 1);
}

h2 {
    font-weight: 600;
    /* font-size: 20px; */
    margin: 0px 0px 50px 0px;
}

.field {
    margin: 0px 0px 75px 0px;
}

label {
    font-size: 12.5px;
    font-weight: 600;
}

button {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    border: 0px;
    box-sizing: border-box;
    padding: 20px;
    cursor: pointer;
    font-size: 20px;
    font-weight: 600;
    margin-top: 50px;
}

.contact-img {
    width: 50px;
    height: auto;
    margin-bottom: 50px;
}

textarea {
    width: 100%;
    padding: 10px;
    height: 200px;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    font-size: 17.5px;
    border: 1px solid lightgray;
    /* border: 0px; */
    resize: none;
    margin: 10px 0px 10px 0px;
}

.new-field {
    visibility: hidden;
    height: 0px;
    margin: 0px;
}

.can-submit {
    background-image: linear-gradient(to left, rgb(153, 202, 60), rgb(7, 153, 72));
    color: white;
}


</style>