<template>

    <div class="price-box" :style="{ 'color' : color, 'font-family' : font_family }">

        <div class="filler" v-if="price_position === 'default'"></div>

        <div class="number-container" v-if="item_price != 0 && item_price != null">

            <NumberFormat :number="item_price" :currency="currency" class="price" />

            <div class="unit" v-if="unit != null && unit != 'none'">{{ unit }}</div>

        </div>

        <div class="number-container" v-if="second_price != 0 && second_price != null">

            <NumberFormat v-if="second_price != 0 && second_price != null" :number="second_price" :currency="currency" class="price" />

            <div class="unit" v-if="second_unit != null && second_unit != 'none'">{{ second_unit }}</div>

        </div>

        <div class="number-container" v-if="third_price != 0 && third_price != null">

            <NumberFormat v-if="third_price != 0 && third_price != null" :number="third_price" :currency="currency" class="price" />

            <div class="unit" v-if="third_unit != null && third_unit != 'none'">{{ third_unit }}</div>
        
        </div>

    </div>



</template>


<script>
import NumberFormat from '@/components/NumberFormat.vue'

export default {
    name: 'ItemPrice',
    props: ['can_edit', 'food', 'currency', 'item_price', 'unit', 'second_price', 'second_unit', 'third_price', 'third_unit', 'color', 'price_position', 'font_family'],
    components: {
        NumberFormat,
    }
}



</script>


<style scoped>

.price-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* align-self: flex-end; */
    /* overflow: hidden; */
    white-space: nowrap;
    flex-wrap: wrap;
}

.price {
    font-size: 14px;
    font-weight: 600;
}

.unit {
  font-weight: 400;
  font-size: 12.5px;
  /* word-wrap: break-word; */
  /* word-break: break-all; */
  white-space: normal;
  max-width: 200px;
}

.number-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-right: 0px;
    box-sizing: border-box;
}

.number-container:first-child {
    padding-right: 5px;
}

.number-container:nth-child(2) {
    padding-right: 5px;
}

@media(max-width: 550px) {
    .price-box {
        flex-direction: column;
        align-items: flex-start;
        /* align-items: flex-end; */
    }

    .unit {
        margin-right: 0px;
        max-width: 200px;
    }

    .number-container {
        padding-right: 0px;
    }

    .number-container:first-child {
        padding-right: 0px;
    }

    .number-container:nth-child(2) {
        padding-right: 0px;
    }
}


</style>