
import firebase from 'firebase/app'
import 'firebase/firebase-messaging'

var firebaseConfig = {
    apiKey: "AIzaSyDZFIDaV49hRzODBrwuf3zHcXugoAEcl8M",
    authDomain: "menulingua.firebaseapp.com",
    projectId: "menulingua",
    storageBucket: "menulingua.appspot.com",
    messagingSenderId: "863472915640",
    appId: "1:863472915640:web:a8134e28b8c6c284ae904d",
    measurementId: "G-GMWQ92H16N"
  };

  firebase.initializeApp(firebaseConfig)

  export default firebase