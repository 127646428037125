import axios from 'axios';

// const API_URL = "https://shielded-brushlands-26226.herokuapp.com"

// const API_URL = "http://localhost:3000"

// const API_URL = "http://192.168.18.7:3000"

// const API_URL = "https://menulingua.com"

const API_URL = process.env.VUE_APP_API_URL

const securedAxiosInstance = axios.create({
    baseURL: API_URL,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': 'https://menulingua-order-dashboard.onrender.com',
        // 'Authorization': 'Bearer ' + localStorage.token
    }
})

const uploadAxiosInstance = axios.create({
    baseURL: API_URL,
    withCredentials: true,
    headers: {
        // 'Content-Type': 'application/json',
        'Content-Type': 'multipart/form-data'
        // 'Access-Control-Allow-Origin': 'https://menulingua-order-dashboard.onrender.com',
        // 'Authorization': 'Bearer ' + localStorage.token
    }
})

const plainAxiosInstance = axios.create({
    baseURL: API_URL,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json'
        // 'Access-Control-Allow-Origin': 'https://menulingua-order-dashboard.onrender.com'
        // 'Authorization': 'Bearer ' + localStorage.token
    }   
})


uploadAxiosInstance.interceptors.request.use(config => {

        config.headers = {
            ...config.headers,
            'Authorization': 'Bearer ' + localStorage.token,
            'X-CSRF-TOKEN': localStorage.csrf,
            'X-Refresh-Token': localStorage.refresh
            // 'X-CSRF-TOKEN': localStorage.csrf
        }


    return config
});

securedAxiosInstance.interceptors.request.use(config => {

    config.headers = {
        ...config.headers,
        'Authorization': 'Bearer ' + localStorage.token,
        'X-CSRF-TOKEN': localStorage.csrf,
        'X-Refresh-Token': localStorage.refresh
        // 'X-CSRF-TOKEN': localStorage.csrf
    }


return config
});

securedAxiosInstance.interceptors.response.use(null, error => {
    if (error.response && error.response.config && error.response.status === 401) {
            return plainAxiosInstance.post('/refresh', {}, { headers: {
                // 'X-CSRF-TOKEN': localStorage.csrf,
                // 'Authorization': 'Bearer ' + localStorage.token
                'X-Refresh-Token': localStorage.refresh
            } 
        })
        .then(response => {
            console.log('heres a response')
            console.log(response.data.access)
            console.log(response.data.csrf)
            localStorage.token = response.data.access
            localStorage.csrf = response.data.csrf
            localStorage.refresh = response.data.refresh
            localStorage.signedIn = true
            let retryConfig = error.response.config
            retryConfig.headers['Authorization'] = 'Bearer ' + localStorage.token
            return plainAxiosInstance.request(retryConfig)
        }).catch(error => {
            console.log(error)
            console.log('there is an error')
            delete localStorage.token
            delete localStorage.csrf
            delete localStorage.signedIn
            // location.replace('/login')
            return Promise.reject(error)
        })
    } else {
        return Promise.reject(error)
    }
})

uploadAxiosInstance.interceptors.response.use(null, error => {
    if (error.response && error.response.config && error.response.status === 401) {
            return plainAxiosInstance.post('/refresh', {}, { headers: {
                // 'X-CSRF-TOKEN': localStorage.csrf,
                // 'Authorization': 'Bearer ' + localStorage.token
                'X-Refresh-Token': localStorage.refresh
            } 
        })
        .then(response => {
            console.log('heres a response')
            console.log(response.data.access)
            console.log(response.data.csrf)
            localStorage.token = response.data.access
            localStorage.csrf = response.data.csrf
            localStorage.refresh = response.data.refresh
            localStorage.signedIn = true
            let retryConfig = error.response.config
            retryConfig.headers['Authorization'] = 'Bearer ' + localStorage.token
            return plainAxiosInstance.request(retryConfig)
        }).catch(error => {
            console.log(error)
            console.log('there is an error')
            delete localStorage.token
            delete localStorage.csrf
            delete localStorage.signedIn
            location.replace('/')
            return Promise.reject(error)
        })
    } else {
        return Promise.reject(error)
    }
})

export { securedAxiosInstance, plainAxiosInstance, uploadAxiosInstance }

