<template>

    <div class="order-button-container" :style="{ 'background-color' : food.background_colour }">

        <div class="order-button" v-if="getQuantity() > 0" :style="{ 'border-color' : stroke_color, 'color' : food.item_name_font_colour }" @click="removeFromOrder(order_row)">
            -
        </div>

        <transition name="fade">
            <div class="quantity-ordered" v-if="getQuantity() > 0" :style="{ 'color' : food.item_name_font_colour }">
                {{ getQuantity() }}
            </div>
        </transition>

        <div class="order-button" :style="{ 'border-color' : stroke_color, 'color' : food.item_name_font_colour  }" @click="addItem(food, food.price, food.unit, menu.id)">
            +
        </div>

        <transition name="fade">

            <div v-if="show_price_selector === true" class="price-selector">

                <div class="price-selector-modal">

                    <div class="close" @click="show_price_selector = false"><img src="../assets/close_new.svg" /></div>

                    <h2 :style="{ 'font-family' : food.item_name_font }">{{ name }}</h2>

                    <div v-if="food.price != 0 && food.price != null && number_of_prices > 1" :style="{ 'font-family' : item_name_font }" @click="selected_price = food.price, selected_unit = food.unit" class="additional-price-selector" :class="{ 'sold-out-strikethrough' : sold_out_multiple_price_items.some(obj => obj.food_id === food.id && obj.sold_out_price == 1) }">
                        <span class="button-selector" :class="{ 'selected' : selected_price === food.price && selected_unit === food.unit }"></span>
                        <p><NumberFormat :number="food.price" :currency="currency" /></p>
                        <p v-if="food.unit != '' && food.unit != 'none'"> {{ food.unit }}</p>
                    </div>

                    <div v-if="food.second_price_number != 0 && food.second_price_number != null && number_of_prices > 1" :style="{ 'font-family' : item_name_font }" @click="selected_price = food.second_price_number, selected_unit = food.second_unit" class="additional-price-selector" :class="{ 'sold-out-strikethrough' : sold_out_multiple_price_items.some(obj => obj.food_id === food.id && obj.sold_out_price == 2) }">
                        <span class="button-selector" :class="{ 'selected' : selected_price === food.second_price_number && selected_unit === food.second_unit }" @click="selected_price = food.second_price_number"></span>
                        <p><NumberFormat :number="food.second_price_number" :currency="currency" /></p>
                        <p v-if="food.second_unit != '' && food.second_unit != 'none'"> {{ food.second_unit }}</p>
                    </div>

                    <div v-if="food.third_price_number != 0 && food.third_price_number != null && number_of_prices > 1" :style="{ 'font-family' : item_name_font }" @click="selected_price = food.third_price_number, selected_unit = food.third_unit" class="additional-price-selector" :class="{ 'sold-out-strikethrough' : sold_out_multiple_price_items.some(obj => obj.food_id === food.id && obj.sold_out_price == 3) }">
                        <span class="button-selector" :class="{ 'selected' : selected_price === food.third_price_number && selected_unit === food.third_unit }" @click="selected_price = food.third_price_number"></span>     
                        <p><NumberFormat :number="food.third_price_number" :currency="currency" /></p>
                        <p v-if="food.third_unit != '' && food.third_unit != 'none'"> {{ food.third_unit }}</p>
                    </div>

                    <div v-for="selection_group in selection_groups" :key="selection_group.id" class="selection-group-container" :style="{ 'font-family' : food.item_name_font }">

                        <h3>{{ selection_group.name }}</h3>

                        <div v-for="selection in selection_group.selections" :key="selection.id" class="selections">

                            <div class="selection-info" @click="toggleSelection(selection, selection_group)">
                                <span class="button-selector" :class="{ 'selected' : added_selections.includes(selection) }"></span> 
                                <p class="selection-name">{{ selection[`name_${language}`] || selection.name }}</p>
                                <p class="additional-fee" v-if="selection.additional_fee != null && selection.additional_fee != 0">
                                    +<NumberFormat :number="selection.additional_fee" :currency="currency" v-if="selection.additional_fee != null && selection.additional_fee != 0" />
                                </p>
                            </div>
                        </div>

                    </div>

                    <transition name="fade">
                        <div class="add-to-order" v-if="selected_price != Number && minSelectionRequirementsReached() && maxSelectionRequirementsNotExceeded()" @click="addToOrder(food, selected_price, selected_unit, menu.id)">
                            <img src="@/assets/check_mark_white.svg" />
                        </div>
                    </transition>

                </div>

            </div>

        </transition>

    </div>


</template>

<script>
import NumberFormat from '@/components/NumberFormat.vue'

export default {
    name: 'OrderButton',
    components: {
        NumberFormat
    },
    props: ['food', 'currency', 'name', 'menu', 'languages', 'language', 'foods', 'foods_from_connected_outlets', 'item_name_font', 'sold_out_multiple_price_items'],
    data () {
        return {
            stroke_color: '',
            number_of_prices: Number,
            show_price_selector: false,
            selected_price: Number,
            selected_unit: '',
            order_row: {},
            parsed_selection_groups: {},
            parsed_selections: {},
            added_selections: [],
            selection_groups: [],
            selections: []
        }
    },
    created() {
        this.getStrokeColor();
        this.getOrderRow();
        this.numberOfPrices();
        this.getSelections();
        localStorage.currency = this.currency
    },
    methods: {
      getSelections() {
        this.selection_groups = this.food.selection_groups
        let selections = []
        for (let i = 0; i < this.selection_groups.length; i++) {
          selections.push(this.selection_groups[i].selections)
        }
        this.selections = selections
      },
        addItem(food, item_price_selected, unit, menu) {
            if (this.number_of_prices > 1 || this.selections.length > 0) {
                this.show_price_selector = true
            } else {
                this.addToOrder(food, item_price_selected, unit, menu)
            }
        },
        getOrderRow() {
            this.order_row = { 'food': this.food, 'menu': this.menu }
        },
        getSelectionsInGroup(selection_group_id) {
            return this.selections.filter(selection => selection.selection_group_id === selection_group_id);
        },
        addToOrder(food, item_price, item_unit, menu, selections) {
            if (this.minSelectionRequirementsReached() === true && this.maxSelectionRequirementsNotExceeded() === true) {
                var item = { 
                    'food': food, 
                    'item_price': item_price, 
                    'item_unit': item_unit, 
                    'menu': menu, 
                    'selections': this.added_selections, 
                    'created_at':  new Date() }
                this.$store.commit('addToOrder', item)
                this.$store.commit('setFoods', this.foods)
                this.show_price_selector = false
                // this.selected_price = Number;
                this.selected_unit = '';
                this.added_selections = [];
                this.getQuantity();
                localStorage.setItem('menu', JSON.stringify(this.menu));
            }
        },
        toggleSelection(selection, selection_group) {
            var array = this.added_selections
            var index = array.indexOf(selection);
            console.log(selection_group.id)
            if (index === -1) {  
                var addedSelectionsForThisGroup = array.filter(added_selection => added_selection.selection_group_id === selection_group.id)
                if (addedSelectionsForThisGroup.length < selection_group.max_selection_count) {
                    array.push(selection);
                } else {
                    console.log('already selected maximum number of items for this')
                }
            } else {
                array.splice(index, 1);
            }
            this.added_selections = array
        },
        minSelectionRequirementsReached() {
            var added_selections = this.added_selections
            var selection_groups = this.selection_groups
            // var counter = 0
            var requirementsMetCount = 0
            for (let i = 0; i < selection_groups.length; i++) {
                var minSelections = 0
                if (selection_groups[i].can_be_blank === true) {
                    minSelections = 0
                } else {
                    minSelections = 1
                }
                var addedSelectionsForThisGroup = added_selections.filter(added_selection => added_selection.selection_group_id === selection_groups[i].id);
                if (addedSelectionsForThisGroup.length >= minSelections) {
                    requirementsMetCount++
                    
                } else {
                    console.log('requirements not met')
                }
            }
            if (selection_groups.length === requirementsMetCount) {
              console.log('min requirements met');
              return true;
                
            } else {
                return false
            }
        },
        maxSelectionRequirementsNotExceeded() {
            var added_selections = this.added_selections
            var selection_groups = this.selection_groups
            // var counter = 0
            var requirementsMetCount = 0
            console.log(selection_groups)
            for (let i = 0; i < selection_groups.length; i++) {
                console.log(selection_groups[i])
                var maxSelections = selection_groups[i].max_selection_count
                var addedSelectionsForThisGroup = added_selections.filter(added_selection => added_selection.selection_group_id === selection_groups[i].id);
                if (addedSelectionsForThisGroup.length <= maxSelections) {
                    requirementsMetCount++
                } else {
                    console.log('requirements not met')
                }
            }
            if (selection_groups.length === requirementsMetCount) {
                return true
            } else {
                return false
            }
        },
        removeFromOrder(order_row) {
            this.$store.commit('removeFromOrder', order_row)
            this.getQuantity();
            console.log(this.$store.getters.getOrder)
        },
        toggleSelector() {
            this.show_price_selector = true
        },
        getStrokeColor() {
            if (this.food.item_name_font_colour === 'default') {
                this.stroke_color = '#000000'
            } else {
                this.stroke_color = this.food.item_name_font_colour
            }
        },
        getQuantity() {
            var order = this.$store.getters.getOrder
            var quantity = 0
            for (let i = 0; i < order.length; i++) {
                if (order[i].food.id === this.food.id) {
                    quantity++
                }
            }
            return quantity
        },
        numberOfPrices() {
            var prices = 0
            if (this.food.price != 0 && this.food.price != null) {
                prices++
                this.selected_price = this.food.price
            }
            if (this.food.second_price_number != 0 && this.food.second_price_number != null) {
                prices++
                this.selected_price = this.food.second_price_number
            }
            if (this.food.third_price_number != 0 && this.food.third_price_number != null) {
                prices++
                this.selected_price = this.food.third_price_number
            }
            this.number_of_prices = prices
            if (prices > 1) {
                this.selected_price = Number
            }
        }
    }
    
}




</script>

<style scoped>

.order-button-container {
    /* width: 100%; */
    display: flex;
    justify-content: flex-end;
    box-sizing: border-box;
    padding: 10px 5px 10px 5px;
    align-items: center;
    /* position: absolute; */
    z-index: 1000;
}

.order-button {
    border-width: 1px;
    border-style: solid;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    border-radius: 2.5px;
    cursor: pointer;
    /* stroke: 1px  */
}

.order-button:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.quantity-ordered {
    font-size: 13px;
    font-weight: 600;
    /* margin: 0px 10px 0px 10px; */
    width: 30px;
    display: flex;
    justify-content: center;
}

.price-selector {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    /* transform: translate(-50%, -50%); */
    /* transform: translateY(-50%);
    transform: translateX(-50%); */
    background-color: white;
    display: flex;
    z-index: 1000000;
    flex-direction: column;
    /* max-width: 500px; */
    box-sizing: border-box;
    padding: 20px;
    overflow-y: scroll;
    border-radius: 5px;
}

.price-selector-modal {
    width: 90%;
    max-width: 600px;
    margin: auto;
    border: 1px dotted lightgray;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 10px;
}

.price-selector h2 {
    font-size: 20px;
    margin: 40px 0px 10px 0px;
}

.price-selector h3 {
    font-size: 15px;
    margin: 10px 0px 10px 0px;
}

.additional-price-selector {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 5px 0px 5px 0px;
    cursor: pointer;
}

.price-selector div p {
    margin: 0px 3px 0px 0px;
}

.button-selector {
    width: 15px;
    height: 15px;
    border: 1px solid lightgray;
    border-radius: 50%;
    margin-right: 10px;
    box-sizing: border-box;
    padding: 5px;
}

.selected {
    background-color: black;
    border: 1px solid black;
}

.add-to-order {
    width: 100%;
    height: 50px;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 50px;
}

.add-to-order img {
    max-height: 30px;
    max-width: 100px;
}

.add-to-order:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.close {
    width: 10px;
    height: 10px;
    margin-top: 0px;
    margin-bottom: 10px;
}

.close img {
    max-width: 10px !important;
    max-height: 10px !important;
}

.selection-group-container {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.selection-group-container h3 {
    font-weight: 600;
}

.selections {
    display: flex;
    flex-direction: column;
    margin: 5px 0px 5px 0px;
}

.selection-name {
    margin-right: 20px;
}

.additional-fee {
    font-weight: 600;
    margin-left: 20px;
}

.selection-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* margin: 5px 0px 5px 0px; */
    /* justify-content: space-between; */
}

.selection-info p {
    display: flex;
    flex-direction: row;
}

.sold-out-strikethrough {
  /* text-decoration: line-through; */
  opacity: 0.3;
  pointer-events: none;
}

</style>